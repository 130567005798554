<template>
  <div>
    <form class="top-box" @submit.prevent="clickSearch()">
      <button type="button" class="btn btn-block btn-warning btn-add mb-10" @click="add()" :disabled="isEditing">
        <i class="fa fa-plus"></i> 新增內容
      </button>
    </form>

    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width: 80px; width: 5%">排序</th>
            <th style="min-width:100px; width:10%">冠軍頭銜</th>
            <th style="min-width:100px; width:10%">冠軍圖示</th>
            <th style="min-width:240px; width:70%">指定遊戲</th>
            <th style="min-width: 80px; width: 5%">動作</th>
          </tr>
          <tr v-if="!isEditing && message"><td colspan="10">{{ message }}</td></tr>
          <tr v-else-if="!isEditing && total <= 0"><td colspan="10">查無資料</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.ChampId" :class="{'bg-gray-light': isEditing && !item.isEdit }">
            <td class="text-nowrap">
              <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                <input type="number" class="form-control p-5" v-model.number="item.Sort" min="1" max="" style="padding-right:0; text-align:center;" />
                <button type="button" class="btn btn-warning btn-add btn-upDate" @click="save(item, index)">
                  <i class="fa fa-fw fa-arrows-v"></i>更新
                </button>
              </fieldset>
            </td>
            <template v-if="!item.isEdit">
              <td>{{ item.ChampionTitle }}</td>
              <td><img :src="getImage(item,'icon')" alt="" style="max-height:100px;"></td>
            </template>
            <td class="text-left" :colspan="item.isEdit ? 3 : 1">
              <TagSelect v-if="!item.isEdit" :value="getTags(item)" readonly></TagSelect>
              <form v-else class="form-horizontal" id="editForm" @submit.prevent="save(item, index)">
                <fieldset class="form-group">
                  <legend class="mt-10">冠軍圖示：</legend>
                  <div style="grid-column: 1/-1;">
                    <!-- <label class="pull-left control-label required" for="championIcon">
                      冠軍圖示：
                    </label> -->
                    <ImageSelect id="championIcon" ref="icon" v-model="item.files"
                      :gid="item.ChampId" module="ChampionGames" info="icon"
                      accept="image/webp, image/jpeg, image/png, image/gif, image/svg+xml" :maxsize="200*1024" required="required"
                    >
                      <p class="help-block">※尺寸為 W200&times;H200，檔案上限為 200KB。</p>
                    </ImageSelect>
                  </div>
                </fieldset>
                
                <fieldset class="form-group">
                  <legend class="mt-5">冠軍頭銜：</legend>
                  <div style="grid-column: 1/-1;">
                    <MultilangInput class="mb-10 mt-5" :langs="langs" v-model="item.Langs" prop="Content" langProp="LanguageCode"></MultilangInput>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <legend class="mt-5">指定遊戲：</legend>
                  <div style="grid-column: 1/-1;">
                    <TagSelect class="mb-10 mt-5" v-model="tagEdit" :options="tagOptions"></TagSelect>
                  </div>
                </fieldset>
              </form>
            </td>
            <td>
              <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                <button v-show="!item.isEdit" @click="edit(item)" class="btn btn-primary">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </button>
                <button v-show="!item.isEdit" @click="copyItem(item)" class="btn btn-warning">
                  <i class="fa fa-fw fa-copy"></i>複製
                </button>
                <button v-show="item.isEdit" type="submit" form="editForm" class="btn btn-success">
                  <i class="fa fa-fw fa-save"></i>儲存
                </button>
                <button v-show="!item.isEdit" @click="deleteItem(item, index)" class="btn btn-danger" :disabled="datas.length <= 1">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
                <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default">
                  <i class="fa fa-fw fa-reply"></i>取消
                </button>
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div v-show="total" class="box-footer text-center no-border" :style="isEditing ? 'pointer-events:none; opacity:.6':''">
      <Pagination
        ref="pagination"
        :total="total "
        :pageLimitCount="searchData.pageSize"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div> -->

    <!-- <pre>{{ datas }}</pre>
    <pre>{{ gameList }}</pre> -->
  </div>
</template>

<style scoped>
.form-horizontal{
  display:grid;
  gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 50% - 1em)), 1fr));
}
fieldset.form-group{
  display: contents;
}
.form-group::before, .form-horizontal::before, .form-group::after, .form-horizontal::after{
  content: none;
}
.nav-tabs-custom, .tab-content{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.nav-tabs-custom>.nav-tabs>li{
  margin-right: 0;
  cursor: pointer;
}
.table .form-group .help-block{
  display: block;
}
legend{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0;
  grid-column: 1 / -1;
}
[class|="col"]{
  padding-inline: 0;
}
label{
  flex: 1 0 auto;
}
.required::before{
  content: "* ";
  color: #dd4b39;
  position: absolute;
  transform: translate(-120%);
}
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import {default as TagSelect, TagOption} from "@/views/projects/XSG/components/TagSelect";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { WebChampionAPI as api, WebChampionPermission as auth } from "@/views/projects/AWH5/_API";
import { default as utils, objToPascalCase } from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    ImageSelect,
    MultilangInput,
    TagSelect,
    iCheckRedioButton,
  },
  props: {

  },
  data() {
    return {
      langs: types.projectData.langs,
      projectId: this.$store.state.projectId,
    
      searchData: {
        "ChampId": "",
        "LanguageCode": "zh-TW"
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      
      can列表: this.$user.hasPermission(auth.QueryList),
      can查詢: this.$user.hasPermission(auth.Query),
      can新增: this.$user.hasPermission(auth.Create),
      can修改: this.$user.hasPermission(auth.Update),
      can刪除: this.$user.hasPermission(auth.Delete),
      can複製: this.$user.hasPermission(auth.Copy),

      isEditing: false,

      gameListLoaded: false,
      gameList: {},
      tagEdit: [],
      editBackup: null,
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },
  computed: {
    tagOptions(){
      let arr = [];
      for (let [id, langs] of Object.entries(this.gameList)){
        let name = langs[this.searchData.LanguageCode] || langs['zh-TW'] || id;
        arr.push(new TagOption(id, name));
      }
      return arr;
    },
    getImage() {
      return (item, info) => {
        const image = (item.files || item.Files).find((file) => (file.info || file.Info) === info);
        console.log(item, image)
        return image ? image.filesUrl || image.FilesUrl : "";
      };
    },
    getTags: () => {
      return function (item){
        let tags = item.AssignedGamesModel.map(i => {
          if (this.gameList[i.GId]) return this.gameList[i.GId][this.searchData.LanguageCode] || this.gameList[i.GId]["zh-TW"] || i.GId
          else return "";
        });
        if (!tags) return [];
        else return tags;
      }
    } ,
  },
  watch:{
  },
  methods: {
    getDataList() {
      let waitLoad = setTimeout(()=>{this.message = "查詢中"}, 100);
      //列表
      this.$store.dispatch("xsa/post", {
        path: api.QueryList,
        data: this.searchData,
      }).then((result) => {
        if (result) {
          clearTimeout(waitLoad);
          this.datas = objToPascalCase(result) || [];
          for (let data of this.datas){
            for (let game of data.AssignedGamesModel){
              if (this.gameList[game.GId]) this.$set(this.gameList[game.GId], this.searchData.LanguageCode, game.Name);
              else this.$set(this.gameList, game.GId, {[this.searchData.LanguageCode]: game.Name});
            }
          }
          this.total = this.datas.length;
          this.message = "";
        }
      }, (err) => {
          clearTimeout(waitLoad);
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },
    getGameList(){
      this.$store.dispatch("xsw/遊戲清單列表查詢", {
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          let dict = {}; 
          for (let game of data){
            dict[game.gid] = (()=>{
              let obj = {};
              for (let x of game.gameListLanguages){
                obj[x.lang] = x.gameName;
              }
              return obj
            })()
          }
          this.gameList = dict;
        }
      });
    },

    add(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      let newItem = {
        ChampId: utils.getTimeId(),
        Sort: 1,
        Langs: [],
        files: [],
        AssignedGamesModel: [],
        isNew: true
      }
      this.datas.unshift(newItem);
      this.tagEdit = [];
      this.edit(this.datas[0]);
    },
    async edit(item){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      if (!this.gameListLoaded){
        this.getGameList();
        this.gameListLoaded = true;
      }
      if (!item.isNew){
        try {
          let res = await this.$store.dispatch("xsa/post", {
            path: api.Query,
            data: {ChampId: item.ChampId}
          });
          // this.$set(item, "AssignedGamesModel", res.AssignedGamesModel);
          // this.$set(item, "Files", res.Files);
          item.files = res.files;
          res = objToPascalCase(res);
          item.AssignedGamesModel = res.AssignedGamesModel;
          item.Langs = res.Langs;
          this.tagEdit = item.AssignedGamesModel.map(x => new TagOption(x.GId, x.Name));
        } catch (err){
          this.$eventBus.$emit("showAlert", err);
        }
      }
      this.editBackup = utils.deepCloneObj(item);
      this.isEditing = true;
      item.isEdit = true;
    },
    cancel(item, index){
      if (item.isNew) this.datas.shift();
      else this.$set(this.datas, index, this.editBackup);
      this.isEditing = false;
    },
    save(item, index){
      if (this.isEditing){
        let arr = [];
        for (let tag of this.tagEdit) arr.push(tag.id);
        item.AssignedGames = arr.join(",");
      }

      let err = [];
      if (item.AssignedGames == ""){
        err.push(`請選擇指定遊戲`)
      } 
      // for (let lang of this.langs){
      //   if (lang.required){
      //     let files = item.Files.filter(x => x.info.indexOf(lang.id) >= 0);
      //   }
      // }
      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let sendData = objToPascalCase(Object.assign({}, item, {
        Files: undefined,
        files: undefined,
        isEdit: undefined,
        isNew: undefined
      }));

      this.$store.dispatch("xsa/post", {
        path: item.isNew ? api.Create : api.Update,
        data: sendData
      }).then(async reulst => {
        if (!this.isEditing){
          this.$eventBus.$emit("showAlert", this.$t((item.isNew ? "新增" : "修改") + '成功'));
          this.getDataList();
          return;
        }
        let uploadQueue = this.$refs.icon.map(x => x.upload);
        let total = uploadQueue.length;
        let count = 0;
        let errLog = "";
        while (uploadQueue.length > 0){
          try {
            let uploadImage = uploadQueue.shift();
            let result = await uploadImage();
            count++;
            this.$eventBus.$emit("showAlert", progressMsg());
          } catch (err) {
            errLog += `圖${++count}<div class="text-red">${err}</div>`;
            this.$eventBus.$emit("showAlert", progressMsg());
          }
        }
        this.isEditing = false;
        this.getDataList();
        function progressMsg(){
          return `
            ${count == total ? "圖檔上傳完成!" : "圖檔上傳中..."} ${ count } / ${ total }<br>
            <progress style="width:100%" value="${count}" max="${total}"></progress>
            ${errLog}
          `;
        }
      }, error => {
        this.$eventBus.$emit("showAlert", error );
      });
    },
    copyItem(item){
      this.$eventBus.$emit("showConfirm", `確定要複製嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Copy,
            data: {ChampId: item.ChampId}
          }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('複製成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    deleteItem(item){
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Delete,
            data: {ChampId: item.ChampId}
          }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>