<template>
    <section class="content" v-if="ServerIsOnline != 'Connected'">
        <div class="login-box-body" style="width:400px;margin-left: auto;margin-right: auto;">
            <p class="login-box-msg">{{ $t(`ServerStatus-${ServerIsOnline}`) }}</p>
            <p class="login-box-msg" v-show="ServerIsOnline == 'Disconnected'">{{ $t(`請重新整理以建立連線`) }}</p>
        </div>
    </section>
</template>
<style lang="scss">
@use "~/src/assets/scss/message" as *;
</style>
<script>
import { mapState/*, mapGetters*/ } from "vuex";
export default {
    components: {
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            ServerIsOnline: state => state.messageSystemServer.ServerIsOnline,
        }),
    },
    watch: {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
    },
    filters: {
    }
};
</script>
<style scoped></style> 