<template>
  <div id="ForumReportList">
    <section class="content-header">
      <h1>留言與回覆列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Qppsocial/AnnouncementList">公告管理</router-link>
        </li>
        <li class="active">留言與回覆列表</li>
      </ol>
    </section>
    <section class="content forum-report">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 公告列表
            </button>
            <button
              class="btn btn-block btn-danger btn-cog"
              type="button"
              @click="deleteAll()"
            >
              <i class="fa fa-trash"></i> 批次刪除
            </button>
            <div class="form-group clearfix">
              <label class="control-label">開始時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="detailSearchData.EndTime"
                  :propStartDate.sync="detailSearchData.StartTime"
                  :propTimePicker="true"
                  />
                  <!-- v-on:restList="GetRestList" -->
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="搜尋門號/ID"
                type="text"
                v-model="detailSearchData.uId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model.trim="detailSearchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1200">
              <tbody>
                <tr class="bg-gray">
                  <th>
                    <div class="checkbox-inline">
                      <label>
                        <!-- <iCheckCheckBox
                          :propChecked="checkAllStatus"
                          v-model="checkAllStatus"
                          v-on:input="checkAll()"
                        /> -->
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 80px;">ID</th>
                  <th style="min-width: 200px;">內容</th>
                  <th style="min-width: 120px;">發佈日期</th>
                  <th style="min-width: 100px;">發佈門號</th>
                  <th style="min-width: 50px;">發佈ID</th>
                  <th style="min-width: 80px;">隱私</th>
                  <th style="min-width: 50px;">狀態</th>
                  <th style="min-width: 50px;">愛心數</th>
                  <th style="min-width: 50px;" v-if="action == 'comment'">回覆</th>
                  <th style="min-width: 200px;">功能</th>
                </tr>
                <tr v-if="total === 0">
                  <td class="text-center" colspan="11">沒有資料</td>
                </tr>
                <tr :key="index" v-else v-for="(item, index) in list">
                  <template v-if="action == 'comment'">
                    <td class="text-center">
                      <div class="checkbox-inline" v-if="item.cDelete === 0">
                        <label>
                          <iCheckCheckBox
                            :propValue="item"
                            :propChecked="item.checked"
                            v-on:update:checked="checkChange"
                          />
                        </label>
                      </div>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.cId }}
                    </td>
                    <td class="text-nowrap text-center">
                      <span v-html="item.cContent"></span>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.cTimeStamp | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td class="text-nowrap text-center">0900000000</td>
                    <td class="text-nowrap text-center">
                      {{ item.uId }} <br>
                      ({{ item.uNick }})
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.cState == 0 ? '公開' : '悄悄話' }}
                    </td>
                    <td class="text-nowrap text-center" :class="{ 'text-red': item.cDelete }">
                      {{ define.deleteStatus[item.cDelete] }}
                    </td>
                    <td class="text-nowrap text-center">
                      {{  item.cLikes }}
                    </td>
                    <td class="text-nowrap text-center">
                      <span
                        class="link"
                        v-if="item.rCounts > 0"
                        @click="showDetail(item)"
                        >
                        {{ item.rCounts || 0 }}
                      </span>
                    <span v-else>{{ item.rCounts || 0 }}</span>
                    </td>
                    <td class="text-nowrap text-center">
                      <button
                        @click="showContent(item)"
                        class="btn btn-primary"
                      >
                        詳細
                      </button>
                      &nbsp;
                      <button
                        v-if="!item.isDelete"
                        @click="deleteItem(item)"
                        class="btn btn-danger ml-5"
                      >
                        刪除
                      </button>
                    </td>
                  </template>
                  <template v-else>
                    <td class="text-center">
                      <div class="checkbox-inline" v-if="item.rDelete === 0">
                        <label>
                          <iCheckCheckBox
                            :propValue="item"
                            :propChecked="item.checked"
                            v-on:update:checked="checkChange"
                          />
                        </label>
                      </div>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.rId }}
                    </td>
                    <td class="text-nowrap text-center">
                      <span v-html="item.rContent"></span>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.rTimeStamp | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td class="text-nowrap text-center">0900000000</td>
                    <td class="text-nowrap text-center">
                      {{ item.uId }} <br>
                      ({{ item.uNick }})
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.rState == 0 ? '公開' : '悄悄話' }}
                    </td>
                    <td class="text-nowrap text-center" :class="{ 'text-red': item.rDelete }">
                      {{ define.deleteStatus[item.rDelete] }}
                    </td>
                    <td class="text-nowrap text-center">
                      {{  item.rLikes }}
                    </td>
                    <td class="text-nowrap text-center">
                      <button
                        @click="showContent(item)"
                        class="btn btn-primary"
                      >
                        詳細
                      </button>
                      &nbsp;
                      <button
                        v-if="!item.isDelete"
                        @click="deleteItem(item)"
                        class="btn btn-danger ml-5"
                      >
                        刪除
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <Pagination
            :pageLimitCount="detailSearchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-show="total > 0"
          />
        </div>
      </div>
    </section>
    <!-- <forumReportAlert
      :propsList="getMuteList"
      ref="forumReportAlert"
      v-on:restList="GetRestList"
      v-on:haveRead="haveRead"
    /> -->
    <forumContent
      ref="forumContent" 
      :isReportPage="false"
      :getDataList="getDataList"
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import iCheckCheckBox from '@/components/iCheck_CheckBox';
import forumContent from '@/views/projects/qppSocial/ForumContent';

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    forumContent,
  },
  data() {
    return {
      detailSearchData: {
        id: this.$route.params.id,
        uId: "",
        keyword: "",
        StartTime: new moment()
          .subtract(3, 'M')
          .format('YYYY/MM/DD'),
        EndTime: new moment().format('YYYY/MM/DD'),
        page: 1,
        pageSize: 10,
      },
      action: this.$route.params.type,
      list: [],
      total: '',
      checkIds: [],
      checkDatas: [],
    };
  },
  mounted() {
    this.detailSearchData =
      this.$route.params.detailSearchData || this.detailSearchData;
    this.getDataList();
  },
  computed: {
    // ...mapState({
    //   reportList: (state) => state.qppSocialReport.reportList.accuseList,
    //   reportTotal: (state) => state.qppSocialReport.reportList.totalCount,
    //   response: (state) => state.qppReport.response,
    // }),
  },
  watch: {
    $route(val) {
      this.detailSearchData.id = val.params.id;
      this.action = val.params.type;
      this.resetDataList();
    }
  },
  methods: {
    getDataList() {
      if(this.action == 'comment') {
        this.detailSearchData.pId = this.detailSearchData.id;
        this.detailSearchData.cKeyword = this.detailSearchData.keyword;
      } else {
        this.detailSearchData.cId = this.detailSearchData.id;
        this.detailSearchData.rKeyword = this.detailSearchData.keyword;
      }
      this.$store
        .dispatch(`qppSocialReport/${this.action}`, this.detailSearchData)
        .then((result) => {
          if(this.action == 'comment') this.list = result.commentList
          else this.list = result.replyList
          this.total = result.totalCount
        })
    },
    resetDataList() {
      this.list = [];
      this.checkIds = [];
      this.checkDatas = [];
      this.detailSearchData.page = 1;
      this.detailSearchData.pId = "";
      this.detailSearchData.cKeyword = "";
      this.detailSearchData.cId = "";
      this.detailSearchData.rKeyword = "";
      this.getDataList();
    },
    showDetail(item) {
      this.detailSearchData.backId = this.detailSearchData.id
      this.$router.push({
        name: "QppSocialAnnouncementDetailList",
        params: {
          type: 'reply',
          id: item.cId,
          detailSearchData: this.detailSearchData,
        },
      });
    },
    showContent(item) {
      let actionType = this.action == 'comment' ? 4 : 5;
      this.$refs.forumContent.showForumContent(item, actionType);
    },
    checkChange(data) {
      const id = 
        this.action == 'comment'? 
        data.value.cId : data.value.rId;

      if(data.checked && !this.checkIds.includes(id)) {
        let item = 
          this.action == 'comment'?
          { cId: id } : { rId: id };
        this.checkIds.push(id);
        this.checkDatas.push(item);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    deleteAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇刪除對象！");
        return;
      }
      this.deleteData(this.checkDatas);
    },
    deleteItem(item) {
      this.checkDatas = []
      const _item = 
        this.action == 'comment' ?
        { cId: item.cId } : { rId: item.rId }
      var data = []
      data.push(_item)
      this.deleteData(data, item);
    },
    deleteData(data) {
      const storePath = 
        this.action == 'comment' ?
        'deleteComment' : 'deleteReply'
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch(`qppSocialReport/${storePath}`, data).then((res) => {
            if(res.status == '1') {
              this.$eventBus.$emit('showAlert', '刪除成功');
              setTimeout(() => {
                this.resetDataList();
              }, 500);
            }
          });
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.detailSearchData.page = pageIdx;
      this.detailSearchData.pageSize = pageSize;
      this.getDataList();
    },
    backList() {
      const detail =
        this.$route.params.detailSearchData
          ? "Detail"
          : "";
      if(this.$route.params.detailSearchData) {
        this.$router.push({
          name: `QppSocialAnnouncementDetailList`,
          params: {
            type: 'comment',
            id: this.$route.params.detailSearchData.backId,
            searchData: this.$route.params.searchData,
          },
        });
        return
      }
      this.$router.push({
        name: `QppSocialAnnouncementList`,
        params: {
          type: 'comment',
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
