<template>
  <div id="MemberPoint" class="tab-content" style="    max-width: 1000px;">
    <form
      class="top-box clearfix"
      @submit.prevent="
        searchData.pageNum = 1;
        getData();
      "
    >
      <div class="form-group m-0">
        <label class="control-label pull-left mt-7"
          >{{ $t("查詢區間") }}：</label
        >
        <div class="input-group date pull-left mb-10 mr-10">
          <div class="input-group-addon fa fa-calendar"></div>
          <DateRangePicker
            :propStartDate.sync="searchData.開始時間"
            :propEndDate.sync="searchData.結束時間"
            :propTimePicker="false"
          />
        </div>
        <button type="submit" class="btn btn-primary pull-left mb-10">
          {{ $t("查詢") }}
        </button>
      </div>
      <!-- <button v-if="can新增" type="button" @click="$refs.dialog.add(user)" class="btn btn-warning pull-right mb-10 ml-10">
        <i class="fa fa-plus"></i> {{$t('新增')}}
      </button> -->
    </form>
    <div class="table-responsive mb-10">
      <table class="table table-bordered text-center">
        <thead>
          <tr class="bg-gray">
            <th style="min-width: 65px; width: 2%">{{ $t("編號") }}</th>
            <!-- <th style="min-width: 100px; width: 5%">{{ $t("角色暱稱") }}</th> -->
            <th style="min-width: 100px; width: 5%">{{ $t("UID") }}</th>
            <th style="min-width: 100px; width: 5%">{{ $t("點數") }}</th>
            <th style="min-width: 100px; width: 5%">{{ $t("動作") }}</th>
                        <th style="min-width: 80px; width: 5%">{{ $t("服務類型") }}</th>
            <th style="min-width: 80px; width: 5%">{{ $t("服務原因") }}</th>
            <th style="min-width: 80px; width: 5%">{{ $t("異動者") }}</th>            
            <th style="min-width: 135px; width: 5%">{{ $t("時間") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="11">{{ $t("查詢中") }}</td>
          </tr>
          <tr v-else-if="message">
            <td colspan="11">{{ $t(message) }}</td>
          </tr>
          <tr v-else-if="total === 0">
            <td colspan="11">{{ $t("查無紀錄") }}</td>
          </tr>
          <tr v-else v-for="(item, index) in paginatedDatas" :key="item.玩家異動紀錄ID">
            <td>{{ index + 1 }}</td>
            <!-- <td>{{ item.nickName }}</td> -->
            <td>{{ item.uid }}</td>
            <td>{{ item.points }}</td>
            <td>{{ item.eventName }}</td>
            <td>{{ item.服務類型 }}</td>
            <td>{{ item.服務原因 }}</td>
            <td>{{ item.異動者 }}</td>   
            <td>{{ item.createTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div  class="text-center mt-10">
      <Pagination
        :pageLimitCount="pageSize"
        :total="total"
        @pageMethod="onPageChange"
      />
    </div>

    <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->

    <MemberServiceDialog
      ref="dialog"
      :types="types"
      @update="
        getData();
        $emit('update');
      "
    ></MemberServiceDialog>
  </div>
</template>

<style lang="scss" scoped>
/* eslint-disable */
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import MemberServiceDialog from "./MemberServiceDialog";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    MemberServiceDialog,
    Pagination,
  },
  emits: ["update"],
  props: {
    user: { type: Object, required: true },
    types: { type: Object, required: true },
  },
  data() {
    return {
      searchData: {
        玩家識別值: "",
        開始時間: new moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),    
      },
      pageNum: 1,
      pageSize: 10,
      message: "", //錯誤訊息
      loading: false,
      total: 0, //總筆數
      datas: [],
      paginatedDatas: [], // 当前页的数据
      // 權限
      // can新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      // can修改: this.$user.hasPermission(dfn.permission.會員服務記錄修改),
      // can刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
    };
  },
  async created() {
    // this.getData();
    // this.getTypes();
    // window.v = this;
  },
  watch: {
    "user.玩家識別值": {
      handler(value) {
        this.searchData.玩家識別值 = value;
        this.pageNum = 1;
        this.getData();
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.updatePaginatedDatas();
      // this.searchData.pageNum = pageIdx;
      // this.searchData.pageSize = pageSize;
      //this.getData();
    },
    updatePaginatedDatas(){
      console.log("update")
      const startIndex = (this.pageNum - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.paginatedDatas = this.datas.slice(startIndex, endIndex);
    },
    getData() {
      this.loading = true;
      this.$store.dispatch("xsw/會員活動點數查詢", this.searchData).then(
        (res) => {
          this.datas = res.datas || [];
          this.total = res.total || 0;
          this.message = "";
          this.loading = false;
          this.updatePaginatedDatas();
          //this.paginatedDatas = this.datas.slice(this.pageNum, this.pageSize);
         
        },
        (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
          this.loading = false;
        }
      );
    },
    get內容(item) {
      if (!item.新內容) return "";
      if (!/^{.*}$/.test(item.新內容)) return item.新內容;

      let obj = JSON.parse(item.新內容);
      if (obj)
        return obj.內容 || obj.備註 || obj.原因 || obj.服務原因 || item.新內容;
    },
  },
};
</script>