<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('關鍵字管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('關鍵字管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="tool">
                        <select class="form-control" style="width: 150px;" v-model="category">
                            <option v-for="i of ChannelCategoryEnum" :value="i">{{ $t(`ChannelCategoryEnum${i}`) }}</option>
                        </select>
                        <button class="btn btn-warning" @click="onAdd()" :disabled="!isKeyWordPermission"><i
                                class="fa fa-fw fa-plus"></i>{{ $t('新增') }}</button>
                    </div>

                    <div class="mt-10">
                        <table class="table table-bordered text-center" style="width: 500px;">
                            <thead>
                                <tr class="bg-gray">
                                    <th>{{ $t('關鍵字') }}</th>
                                    <th style="width: 130px;">{{ $t('功能') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item of list" :key="item.id">
                                    <td>
                                        <input type="text" class="form-control" v-show="item.st == STEnum.Update"
                                            v-model.trim.lazy="item.text" />
                                        <span v-show="item.st == STEnum.View">
                                            {{ item.原始Text }}
                                        </span>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary" :disabled="!isKeyWordPermission"
                                            @click="onUpd(item)" v-show="item.st == STEnum.View">{{ $t('修改') }}</button>
                                        <button class="btn btn-danger" :disabled="!isKeyWordPermission"
                                            @click="onDel(item)" v-show="item.st == STEnum.View">{{ $t('刪除') }}</button>
                                        <button class="btn btn-success" :disabled="!isKeyWordPermission"
                                            @click="onSave(item)" v-show="item.st == STEnum.Update">{{ $t('儲存')
                                            }}</button>
                                        <button class="btn btn-normal" @click="onCancel(item)"
                                            v-show="item.st == STEnum.Update">{{ $t('取消') }}</button>
                                    </td>
                                </tr>
                                <tr v-if="list.length == 0">
                                    <td colspan="2">{{ $t('查詢無任何資料') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <KeyWordManagerContent ref="content" @refresh="onSearch({ Project: projectId, category })">
        </KeyWordManagerContent>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import KeyWordManagerContent from "@/views/projects/ChannelMonitor/KeyWordManagerContent";
import { STEnum, parseData,ChannelCategoryEnum } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
import { 移除特殊字元 } from "@/assets/js/Tool/parseStr";
import { 關鍵字特殊處理Function } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
import { mapState } from "vuex";
export default {
    components: {
        KeyWordManagerContent
    },
    data() {
        return {
            ChannelCategoryEnum,
            STEnum,
            category: ChannelCategoryEnum.public,//true,//true:公開 false:密語
            list:[],
        };
    },
    computed: {
        ...mapState({
            projectId: state => state.projectId,
        }),
        是否有資料修改中() {
            let index = this.list.findIndex((i) => {
                return i.st == STEnum.Update;
            });
            return index >= 0;
        },
        isKeyWordPermission() {
            let isKeyWordPermission = false;
            if (this.category) {
                isKeyWordPermission = this.$user.hasPermission('PublicKeyWordPermission');//public的話，看是否有權限
            } else {
                isKeyWordPermission = true;//private的話，一律有權限
            }
            return isKeyWordPermission;
        }
    },
    watch: {
        async category(v) {
            await this.onSearch({
                Project: this.projectId,
                category: v
            });
        },
    },
    async mounted() {
        await this.onSearch({
            Project: this.projectId,
            category: this.category,
        });
    },
    methods: {
        onDel(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            this.$eventBus.$emit("showAsyncConfirm", this.$t('刪除後無法復原，確定刪除嗎？'), this.$t('刪除確認'), async () => {
                $(this.$refs.loading).show();
                try {
                    let res = await api.CS.RemoveKeyword({
                        Project: this.projectId,
                        Id: item.id,
                    });
                    let isSuccess = (res && res.data && res.data.success) || false;
                    if (!isSuccess) {
                        throw res && res.data && res.data.errMsg;
                    }
                    await this.search({ Project: this.projectId, category: this.category });//刷新
                    this.$eventBus.$emit("showAlert", this.$t('成功'));
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                $(this.$refs.loading).fadeOut(400);
            });
        },
        onUpd(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            item.st = STEnum.Update;
        },
        onSave(item) {
            if (!item.text) {
                this.$eventBus.$emit("showAlert", this.$t('關鍵字不可為空'));
                return;
            }
            this.$eventBus.$emit("showAsyncConfirm", this.$t('確定修改嗎？'), this.$t('修改確認'), async () => {
                $(this.$refs.loading).show();
                try {
                    let res = await api.CS.UpdateKeyword({
                        Project: this.projectId,
                        Id: item.id,
                        Text: 移除特殊字元(item.text, 關鍵字特殊處理Function)
                    });
                    let isSuccess = (res && res.data && res.data.success) || false;
                    if (!isSuccess) {
                        throw res && res.data && res.data.errMsg;
                    }
                    await this.search({ Project: this.projectId, category: this.category });//刷新
                    this.$eventBus.$emit("showAlert", this.$t('成功'));
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                $(this.$refs.loading).fadeOut(400);
            });
        },
        onCancel(item) {
            item.text = item.原始Text;
            item.st = STEnum.View;
        },
        onAdd() {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            this.$refs.content.Show(this.category, this.projectId);
        },
        async onSearch({ Project, category }) {
            $(this.$refs.loading).show();
            try {
                this.search({ Project, category });
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async search({ Project, category }) {
            try{
                let res = (await api.CS.GetKeywordList({
                    Project,
                    Category:category,
                })).data.result;
                this.list = parseData(res);
            }catch(e){
                this.list = [];
            }
        },
    }
}
</script>
<style scoped>
.tool {
    display: flex;
    justify-content: space-between;
}
</style>