export default class {
  static permission = {
    //:'', 

    // 會員管理
    會員列表查詢:'會員列表查詢',
    會員查詢: '會員查詢',
    會員控管: '會員控管',
    會員控管記錄: '會員控管記錄',
    會員服務記錄查詢: '會員服務記錄查詢',
    會員服務記錄新增: '會員服務記錄新增',
    會員服務記錄修改: '會員服務記錄修改',
    會員服務記錄刪除: '會員服務記錄刪除',
    會員榮譽值記錄: '會員榮譽值記錄',
    會員榮譽值異動: '會員榮譽值異動',
    會員轉正:'會員轉正',
    會員配點:'會員配點',
    會員配發回饋金: '會員配發回饋金',
    會員配點記錄:'會員配點記錄',
    會員星幣異動:'會員星幣異動', 
    會員星幣禁止轉出:'會員星幣禁止轉出', 

    //
    客戶列表查詢:'客戶列表查詢',
    客戶資料查詢:'客戶資料查詢',
    客戶資料新增:'客戶資料新增',
    客戶資料批次新增:'客戶資料批次新增',
    客戶資料修改:'客戶資料修改',
    客戶關聯新增:'客戶關聯新增',
    客戶關聯修改:'客戶關聯修改',
    客戶關聯刪除:'客戶關聯刪除',
    客戶關聯列表查詢:'客戶關聯列表查詢',
  
    // 信件管理
    信件發送: '信件發送',
    信件歷程查詢: '信件歷程查詢',
    持有信件查詢: '持有信件查詢',
    語言檔查詢: '語言檔查詢',

    // 公會管理
    公會查詢: '公會查詢',
    公會建立: '公會建立',
    公會解散: '公會解散',
    公會解散歷程查詢: '公會解散歷程查詢',

    // 金流管理
    金流儲值查詢: '金流儲值查詢',

    // 系統管理
    操作歷程查詢: '操作歷程查詢',
    服務類別新增: '服務類別新增',
    服務類別修改: '服務類別修改',
    服務類別查詢: '服務類別查詢',
    服務類別刪除: '服務類別刪除',

    // 遊戲歷程
    遊戲歷程查詢: '遊戲歷程查詢',
    勝負分歷程查詢: '勝負分歷程查詢',

    
  };
}
