@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">活動管理</li>
        <li class="active"> {{ id == "New" ? "新增" : edit.活動名稱 }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">
              {{ id == "New" ? "新增" : "編輯" }}內容
            </h4>
            <router-link
              :to="{
                name: 'EventList',
                params: { searchData: $route.params.searchData },
              }"
              class="btn btn-block btn-default btn-cog"
            >
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>
          <form
            class="form-horizontal"
            name="announcementForm"
            @submit="
              $event.preventDefault();
              Save();
            "
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label required">活動名稱：</label>
                <div class="col-sm-9 mt-7" v-if="id !== 'New'">
                  {{ edit.活動名稱 }}
                </div>
                <div class="col-sm-9" v-else>
                  <input
                    type="text"
                    class="form-control col-sm-9"
                    id="acname"
                    v-model="edit.活動名稱"
                    required
                    placeholder=""
                  />
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic1"
                  >Banner圖片(電腦版)：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic1"
                    ref="imageSelect1"
                    :gid="edit.活動編號 ? edit.活動編號.toString() : ''"
                    info="desk"
                    module="EventBanner"
                    :maxsize="1920 * 1500"
                    accept=".jpg,.png,.jpeg"
                    v-model="edit.files"
                  >
                    <p class="help-block">
                      *
                      <span class="text-red">圖片大小 1920&times;1500</span
                      >，檔案格式為png或jpg。
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic2"
                  >Banner圖片(手機版)：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic2"
                    ref="imageSelect2"
                    :gid="edit.活動編號 ? edit.活動編號.toString() : ''"
                    info="mobile"
                    module="EventBanner"
                    :maxsize="1024 * 1250"
                    accept=".jpg,.png,.jpeg"
                    v-model="edit.files"
                  >
                    <p class="help-block">
                      *
                      <span class="text-red">圖片大小 1024&times;1700</span
                      >，檔案格式為png或jpg。
                    </p>
                  </ImageSelect>
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic5"
                  >背景圖片(中央)：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic5"
                    ref="imageSelectBGmid"
                    :gid="edit.活動編號 ? edit.活動編號.toString() : ''"
                    info="mid"
                    module="EventBanner"
                    :maxsize="1190 * 600"
                    accept=".jpg,.png,.jpeg"
                    v-model="edit.files"
                  >
                    <p class="help-block">
                      *
                      <span class="text-red">圖片大小 1190&times;600</span
                      >，檔案格式為png或jpg。
                    </p>
                  </ImageSelect>
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic3"
                  >背景圖片(左側)：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic3"
                    ref="imageSelectBGleft"
                    :gid="edit.活動編號 ? edit.活動編號.toString() : ''"
                    info="left"
                    module="EventBanner"
                    :maxsize="500 * 1024"
                    accept=".jpg,.png,.jpeg"
                    v-model="edit.files"
                  >
                    <p class="help-block">
                      *
                      <span class="text-red">圖片大小 370&times;900</span
                      >，檔案格式為png或jpg。
                    </p>
                  </ImageSelect>
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic4"
                  >背景圖片(右側)：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic4"
                    ref="imageSelectBGright"
                    :gid="edit.活動編號 ? edit.活動編號.toString() : ''"
                    info="right"
                    module="EventBanner"
                    :maxsize="500 * 1024"
                    accept=".jpg,.png,.jpeg"
                    v-model="edit.files"
                  >
                    <p class="help-block">
                      *
                      <span class="text-red">圖片大小 370&times;900</span
                      >，檔案格式為png或jpg。
                    </p>
                  </ImageSelect>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label required"
                  >活動開始時間：</label
                >
                <div class="col-sm-9">
                  <DateAndTimePicker
                    v-model="edit.活動開始時間"
                    format="YYYY/MM/DD HH:mm:ss"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required"
                  >活動結束時間：</label
                >
                <div class="col-sm-9">
                  <DateAndTimePicker
                    v-model="edit.活動結束時間"
                    format="YYYY/MM/DD HH:mm:59"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required"
                  >抽獎券截止兌換時間：</label
                >
                <div class="col-sm-9">
                  <DateAndTimePicker
                    v-model="edit.兌換截止時間"
                    format="YYYY/MM/DD HH:mm:ss"
                  />
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
input[type="checkbox"] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.2em;
  accent-color: #3498db;
  cursor: pointer;
}
label.required::before {
  content: "* ";
  color: #dd4b39;
}
.form-control.sort {
  display: inline-block;
  width: 54px;
  padding: 5px;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
}
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    tinymce,
    ImageSelect,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton,
  },
  props: {
    id: "",
    searchData: {},
  },
  data() {
    return {
      // bannerType: types.EventBannerType,
      // def: define,
      loading:false,
      edit: {
        //活動編號: utils.getTimeId(),
        活動開始時間: new moment().add(1, 'day').format("YYYY/MM/DD"),
        活動結束時間: new moment().add(1, 'months').format("YYYY/MM/DD"),
        兌換截止時間: new moment().add(1, 'months').format("YYYY/MM/DD"),
        活動編號: null,
        files: [],
      },
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {},
  watch: {
    // "edit.動作1": function(value){
    //   this.updateActions();
    // },
    // "isForever": function(value){
    //   //如果常駐顯示=true，顯示結束時間帶null
    //   this.edit.顯示結束時間 = value ? null : new moment().add('month', 1).format("YYYY/MM/DD 00:00:00");
    // },
    // "isOptLevel": function(value){
    //   this.edit.minlevel = value ? 1 : 0;
    //   this.edit.maxlevel = value ? 1000 : 0;
    // }
  },
  methods: {
    getData() {
      if (this.id == "New") return;
      this.$store
        .dispatch("xsw/活動管理查詢", { 活動編號: Number(this.id) })
        .then(
          (res) => {
            Object.assign(this.edit, res);
          },
          (err) => {
            this.$eventBus.$emit("showAlert", err);
          }
        );
    },
    //*---- 返回
    backList() {
      this.$router.push({
        name: "EventList",
        params: { searchData: this.$route.params.searchData },
      });
    },

    //送出
    async Save() {
      let err = [];

      const files = this.$refs.imageSelect1.files; // 獲取 files

      if (files && files.length >= 5) {
        // 確保有至少 5 個檔案
        for (let i = 0; i < 4; i++) {
          const file = files[i];
          if (!file.filesUrl) {
           this.$eventBus.$emit("showAlert", "有圖片尚未新增");
            return;
          }
        }
      } else {
          this.$eventBus.$emit("showAlert", "有圖片尚未新增");
         return;
      }

      let api = this.id == "New" ? "新增" : "修改";

      let sendData = Object.assign({}, this.edit);
      delete sendData.files;

      if (this.id == "New") {
        delete sendData.活動編號;
      }
      if (this.loading ) {
        return
      }
      this.loading=true;
      this.$store.dispatch("xsw/活動管理" + api, sendData).then((result) => {
        if (this.id == "New") {
          this.edit.活動編號 = result;
        }

        this.$nextTick(
          () => {
            Promise.all([
              this.$refs.imageSelect1.upload(),
              this.$refs.imageSelect2.upload(),              
              this.$refs.imageSelectBGmid.upload(),
              this.$refs.imageSelectBGleft.upload(),
              this.$refs.imageSelectBGright.upload(),
            ]).then(
              (res) => {
                if(this.id == "New"){
                  this.$eventBus.$emit("showAlert", "成功新增");
                  this.loading=false;
                }else{
                  this.$eventBus.$emit("showAlert", "修改成功");
                }
                this.backList();
              },
              (err) => {
                this.$eventBus.$emit("showAlert", err);
              }
            );
          },
          (error) => {
            this.$eventBus.$emit("showAlert", error);
          }
        );
      });
    },

    uiDate(date) {
      let d = new Date(date);
      if (date && d)
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
      else return "";
    },
  },
};
</script>