@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{$t('活動總覽')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('活動總覽')}}</li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $t(id == "New" ? "新增內容" : "編輯內容") }}</h4>
            <router-link :to="{name:'XsgGameBanner', params:{ searchData: $route.params.searchData }}" class="btn btn-block btn-default btn-cog">
              <i class="fa fa-reply"></i> {{$t('返回列表')}}
            </router-link>
          </div>
          <form class="form-horizontal" name="announcementForm" @submit="$event.preventDefault(); Save()">
            <div class="box-body">
              
              <div class="form-group mb-2" v-if="id !== 'New'">
                <span class="col-sm-3 control-label">{{$t('活動編號')}}：</span>
                <div class="col-sm-9 mt-7">{{ edit.公告ID }}</div>
              </div>
              <div class="form-group mb-2" v-if="id !== 'New'">
                <span class="col-sm-3 control-label">{{$t('建立日期')}}：</span>
                <div class="col-sm-9 mt-7">{{ uiDate(edit.建立時間) }}</div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameType">{{$t('公告類別')}}：</label>
                <div class="col-sm-9">
                  <select v-if="id == 'New'" class="form-control" id="gameType" style="max-width:320px" required v-model="edit.類別" @change="edit.標籤 = ''">
                    <option v-for="(name, key) in types.gameBannerType" :key="key" :value="name">{{ $t(name) }}</option>
                  </select>
                  <div v-else class="mt-7">{{ $t(edit.類別) }}</div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameOrder">{{$t('排序')}}：</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="gameOrder" v-model.number="edit.排序" min="1" required :placeholder="$t('排序')" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameStatus">{{$t('狀態')}}：</label>
                <div class="col-sm-9 mt-5">
                  <fieldset class="ckbx-style-8 ckbx toggleInput">
                    <input type="checkbox" id="gameStatus" :true-value="1" :false-value="0" v-model.number="edit.狀態" name="ckbx-style-8" />
                    <label for="gameStatus"></label>
                  </fieldset>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">{{$t('顯示開始時間')}}：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.顯示開始時間" format="YYYY/MM/DD HH:mm:ss">
                    <label class="mt-7 ml-10" style="flex-shrink:0;">
                      <input type="checkbox" v-model="isForever"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      > {{$t('常駐顯示')}}
                    </label>
                  </DateAndTimePicker>
                </div>
              </div>

              <div class="form-group" v-if="!isForever">
                <label class="col-sm-3 control-label required">{{$t('顯示結束時間')}}：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.顯示結束時間" format="YYYY/MM/DD HH:mm:ss" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameAction">{{$t('動作')}} 1：</label>
                <div class="col-sm-9">
                  <div class="" style="margin-bottom:0;">
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton checkValue="0" name="gameAction" :message="$t('無功能')" v-model="edit.動作1" />
                    </div>
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton checkValue="1" name="gameAction" :message="$t('遊戲館')" v-model="edit.動作1" />
                    </div>
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton checkValue="2" name="gameAction" :message="$t('連結至遊戲功能')" v-model="edit.動作1" />
                    </div>
                  </div>
                  <div class="mt-10" style="display:flex; align-items:flex-start;">
                    <template v-if="edit.動作1 == '1'">
                      <label class="control-label mr-5 required" style="flex-shrink:0;" for="gameId">{{$t('遊戲館分類')}}：</label>
                      <div class="select-group mr-10">
                        <select class="form-control" v-model="actions[edit.動作1].選項" @change="updateActions()" required>
                          <option value="" disabled hidden>{{$t('請選擇幣別')}}</option>
                          <option v-for="(option, key) in types.gameCoin" :value="key" v-text="`${$t(option)} | ${key}`"></option>
                        </select>
                      </div>
                      <div class="select-group">
                        <input type="text" class="form-control" id="gameId" v-model.trim="actions[edit.動作1].附加動作" @input="updateActions()" min="1" required :placeholder="$t('遊戲館ID')" />
                        <!-- <select class="form-control" v-model.string="edit.附加動作" required>
                          <option value="" disabled hidden>請選擇遊戲館</option>
                          <option v-for="(option, key) in types.gameAction" :value="key" v-text="`${option} | ${key}`"></option>
                        </select> -->
                      </div>
                    </template>
                    <template v-if="edit.動作1 == '2'">
                      <label class="control-label mr-5 required" style="flex-shrink:0;">{{$t('遊戲功能')}}：</label>
                      <div class="select-group">
                        <select class="form-control" v-model.string="actions[edit.動作1].附加動作" @change="updateActions()" required>
                          <option value="" disabled hidden>{{$t('請選擇遊戲功能')}}</option>
                          <option v-for="(option, key) in types.gameAction" :value="key" v-text="`${option} | ${key}`"></option>
                        </select>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameAction2">{{$t('動作')}} 2：</label>
                <div class="col-sm-9">
                  <div class="" style="margin-bottom:0;">
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton :checkValue="null" name="gameAction2" :message="$t('無功能')" v-model="edit.動作2" />
                    </div>
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton :checkValue="''" name="gameAction2" message="URL" v-model.number="edit.動作2" />
                    </div>
                  </div>
                  <div class="mt-10" style="display:flex; align-items:flex-start;">
                    <template v-if="edit.動作2 != null">
                      <label class="control-label mr-5 required" style="flex-shrink:0;">{{$t('網址')}}：</label>
                      <div class="input-group" style="flex:1;">
                        <input class="form-control" v-model.trim="edit.動作2" type="url" :placeholder="$t('請輸入網址連結')" required />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="edit.類別 == '活動總覽'" class="form-group image-part">
                <label class="col-sm-3 control-label required" for="gameLang">{{ $t(edit.類別 == '活動總覽' ? '標籤':'按鈕選項') }}：</label>
                <div class="col-sm-9">
                  <select class="form-control" id="gameLang" style="max-width:320px" v-model="edit.標籤">
                    <option value="">{{$t('無')}}</option>
                    <option v-if="edit.類別 == '活動總覽'" v-for="(name, key) in types.gameBannerBadge" :key="key" :value="name">{{ name }}</option>
                    <option v-if="edit.類別 != '活動總覽'" v-for="(name, key) in types.gameBannerButton" :key="key" :value="name">{{ name }}</option>
                  </select>
                </div>
              </div>

              <hr>
              
              <div class="form-group">
                <label class="col-sm-3 control-label" for="gameTop">{{$t('顯示條件')}}：</label>
                <div class="col-sm-9">
                  <div style="display:flex; align-items:center;">
                    <label class="mr-10" style="flex-shrink:0;margin:0;"> {{$t('等級')}}
                      <input type="checkbox" v-model="isOptLevel"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                    </label>
                    <fieldset style="display:flex; width:100%; align-items:center;" :disabled="!isOptLevel">
                      <input type="number" class="form-control" style="padding-right:0;" v-model.number="edit.minlevel" min="1" :max="edit.maxlevel" :required="isOptLevel" />
                      <span class="mt-7 mr-5 ml-5">~</span>
                      <input type="number" class="form-control" style="padding-right:0;" v-model.number="edit.maxlevel" :min="edit.minlevel" :required="isOptLevel" :disabled="edit.isLevelNoLimit" />
                      <label class="ml-10" style="flex-shrink:0;margin:0;"> {{$t('無上限')}}
                        <input type="checkbox" v-model="edit.isLevelNoLimit"
                          style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                        >
                      </label>
                    </fieldset>
                  </div>
                  <p class="help-block text-red">
                    {{$t('若無選擇則全玩家皆可看到')}}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="gameTop">{{$t('置頂')}}：</label>
                <div class="col-sm-9">
                  <div class="" style="margin-bottom:0;">
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton :checkValue="false" name="gameTop" :message="$t('否')" v-model="edit.isTop" />
                    </div>
                    <div class="radio-inline ml-0 mr-20">
                      <iCheckRedioButton :checkValue="true" name="gameTop" :message="$t('是')" v-model="edit.isTop" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputPlatform">
                  <span class="text-red">*</span> {{$t('所屬地區(可複選)')}} ：
                </label>
                <div class="col-sm-9" style="margin-bottom:0;">
                  <div class="checkbox-inline ml-0 mr-20" v-for="(region, i) in types.gameRegion">
                    <label>
                      <input type="checkbox" v-model="regions[i]" @change="updateRegions()"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >&nbsp;{{ $t(region) }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="gameLang">語系：</label>
                <div class="col-sm-9">
                  <select class="form-control" id="gameLang" style="max-width:320px" required v-model="edit.語系">
                    <option value="" disabled hidden>請選擇語系</option>
                    <option v-for="(name, key) in types.gameLocale" :key="key" :value="key">{{ name }}</option>
                  </select>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputPlatform">
                  <span class="text-red">*</span> {{$t('顯示平台(可複選)')}} ：
                </label>
                <div class="col-sm-9" style="margin-bottom:0;">
                  <div class="checkbox-inline ml-0 mr-20" v-for="(platform, i) in types.gamePlatform">
                    <label>
                      <input type="checkbox" v-model="platforms[i]" @change="updatePlatforms()"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >&nbsp;{{ platform }}
                    </label>
                  </div>
                </div>
              </div>

              <hr>

              <div class="form-group image-part">
                <label class="col-sm-3 control-label" for="AdPic1">{{$t('活動圖片連結')}} 1：</label>
                <div class="col-sm-9">
                  <ImageSelect id="AdPic1" ref="imageSelect1"
                    :gid="edit.公告ID" info="1" module="GameBanner"
                    :maxsize="2000000" accept="image/png, image/jpeg"
                    v-model="edit.files">
                    <p class="help-block" v-if="edit.類別 == '活動總覽'">
                      {{$t('圖片尺寸')}} <span class=text-red>740&times;370</span>
                      {{$t('檔案格式')}} png, jpg
                      {{$t('大小上限')}} 2000KB
                    </p>
                    <p class="help-block" v-else>
                      {{$t('圖片尺寸')}} <span class=text-red>1280&times;720</span>
                      {{$t('檔案格式')}} png, jpg
                      {{$t('大小上限')}} 2000KB
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group image-part" v-if="edit.類別 == '活動總覽'">
                <label class="col-sm-3 control-label" for="AdPic2">{{$t('活動圖片連結')}} 2：</label>
                <div class="col-sm-9">
                  <ImageSelect id="AdPic2" ref="imageSelect2"
                    :gid="edit.公告ID" info="2" module="GameBanner"
                    :maxsize="500*1024" accept="image/png, image/jpeg"
                    v-model="edit.files">
                    <p class="help-block">
                      {{$t('圖片尺寸')}}<span class=text-red>362&times;510</span>
                      {{$t('檔案格式')}} png, jpg
                      {{$t('大小上限')}} 2000KB
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputTitle">{{$t('標題')}}：</label>
                <div class="col-sm-9">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.標題" maxlength="50" required :placeholder="$t('上限X字',['50'])" />
                  <p class="help-block">{{ edit.標題.length }} / 50</p>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputDescription">{{$t('備註')}}：</label>
                <div class="col-sm-9">
                  <textarea id="inputDescription" class="form-control" v-model="edit.備註" rows="3" placeholder=""></textarea>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputContent">{{$t('內文')}}：</label>
                <div class="col-sm-9">
                  <div class="editor">
                    <tinymce v-model="edit.內文" height="600" />
                  </div>
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-3 control-label" for="inputNote">
                  備註：
                </label>
                <div class="col-sm-9">
                  <textarea id="inputNote" class="form-control" v-model="edit.note" rows="3" required placeholder=""></textarea>
                </div>
              </div> -->
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  {{$t('確認送出')}}
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
  input[type="checkbox"]{
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    tinymce,
    ImageSelect,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton
  },
  props: {
    id: "",
    searchData: {},
  },
  data() {
    return {
      types: types,
      // bannerType: types.gameBannerType,
      // def: define,

      edit: {
        公告ID: utils.getTimeId(),
        類別: this.searchData && this.searchData.類別 ? this.searchData.類別 : types.gameBannerType[0],
        排序: 1,
        狀態: 1,
        顯示開始時間: new moment().format("YYYY/MM/DD 00:00:00"),
        顯示結束時間: null, //如果常駐顯示=true，顯示結束時間帶null
        動作1: "0", //0=無功能、1=遊戲館、2=連結至遊戲功能
        附加動作: "",
        動作2: null, //null=無功能 string=網址連結
        選項: "", // N=星幣，B=銅幣
        minlevel: 0,
        maxlevel: 0,
        isLevelNoLimit: true, 
        isTop: false,
        地區: "",
        語系: "",
        顯示平台: "",
        標籤: "",
        標題: "",
        備註: "",
        內文: ""
      },
      platforms: new Array(types.gamePlatform.length).fill(false),
      regions: new Array(types.gameRegion.length).fill(false),
      actions: [
        {附加動作:"", 選項: ""}, //0=無功能
        {附加動作:"", 選項: ""}, //1=遊戲館
        {附加動作:"", 選項: ""}, //2=連結至遊戲功能
      ],
      isOptLevel: false,
      isForever: true,
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
  },
  watch: {
    "edit.動作1": function(value){
      this.updateActions();
    },
    "isForever": function(value){
      //如果常駐顯示=true，顯示結束時間帶null
      this.edit.顯示結束時間 = value ? null : new moment().add('month', 1).format("YYYY/MM/DD 00:00:00");
    },
    "isOptLevel": function(value){
      this.edit.minlevel = value ? 1 : 0;
      this.edit.maxlevel = value ? 1000 : 0;
    }
  },
  methods: {
    getData(){
      if (this.id == 'New') return;
      this.$store.dispatch("xsw/活動總覽查詢", { 公告ID: this.id }).then(res => {
        Object.assign(this.edit, res);
        if (this.edit.顯示平台 != null){
          let arrIn = this.edit.顯示平台.split(',');
          let arrOut = [];
          for (let [i, value] of this.types.gamePlatform.entries()){
            arrOut[i] = arrIn.includes(value);
          }
          this.platforms = arrOut;
          // console.log(arrIn, arrOut)
        }
        if (this.edit.地區 != null){
          let arrIn = this.edit.地區.split(',');
          let arrOut = [];
          for (let [i, value] of this.types.gameRegion.entries()){
            arrOut[i] = arrIn.includes(value);
          }
          this.regions = arrOut;
          // console.log(arrIn, arrOut)
        }
        this.actions[this.edit.動作1].附加動作 = this.edit.附加動作;
        this.actions[this.edit.動作1].選項 = this.edit.選項;
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    updateActions(){
      this.edit.附加動作 = this.actions[this.edit.動作1].附加動作;
      this.edit.選項 = this.actions[this.edit.動作1].選項;
    },
    updatePlatforms(){
      let arr = [];
      for (let i = 0, len = this.platforms.length; i < len; i++){
        if (!!this.platforms[i] == true) arr.push(this.types.gamePlatform[i]);
      }
      this.edit.顯示平台 = arr.join(",");
      // console.log(arr, this.edit.顯示平台)
    },
    updateRegions(){
      let arr = [];
      for (let i = 0, len = this.regions.length; i < len; i++){
        if (!!this.regions[i] == true) arr.push(this.types.gameRegion[i]);
      }
      this.edit.地區 = arr.join(",");
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XsgGameBanner",
        params: { searchData: this.$route.params.searchData },
      });
    },

    //送出
    async Save() {
      let err = [];

      /* if (this.edit.斷線規則 == "") {
          this.$eventBus.$emit("showAlert", "ISP業者斷線為必填欄位");
          return;
      } */
      //console.log(this.edit);
      // this.waitTimeout = setTimeout(()=>{
        this.$eventBus.$emit("showAlert", "資料已送出處理中");
      // }, 2000);

      let api = this.id == 'New' ? "新增" : "修改";

      let sendData = Object.assign({}, this.edit);
      delete sendData.files;


      this.$store.dispatch("xsw/活動總覽" + api, sendData).then(result => {       
        
        if(this.edit.類別 == '活動總覽'){
          Promise.all([
            this.$refs.imageSelect1.upload(),
            this.$refs.imageSelect2.upload(),
          ]).then( res => {
            // clearTimeout(this.waitTimeout);
            this.backList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }else{
          Promise.all([
            this.$refs.imageSelect1.upload(),
          ]).then( res => {
            // clearTimeout(this.waitTimeout);
            this.backList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
        




      }, error => {
        this.$eventBus.$emit("showAlert", error );
      });
    },

    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>