<template>
  <FormDialog ref="memberServiceDialog" 
    :title="isAdd === true ? '新增服務紀錄' : '編輯服務紀錄'" 
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr v-if="hasRole">
            <th>暱稱</th>
            <td v-if="isAdd">
              <select v-model="data.暱稱" class="form-control" required>
                <option disabled="disabled" value>請選取暱稱</option>
                <option v-for="nick in nickNames" :value="nick" :key="nick">
                  {{ nick }}
                </option>
              </select>
            </td>
            <td v-else>
              {{data.暱稱}}
            </td>
          </tr>

          <tr>
            <th>服務類型</th>
            <td v-if="isAdd">
              <select  v-model.number="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>請選擇服務類型</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
            <td v-else>
              {{data.分類}}
            </td>
          </tr>
          
          <tr>
            <th>狀態</th>
            <td>
              <select id="狀態" v-model="data.處理狀態" class="form-control" required>
                <option value="" selected disabled hidden>請選擇處理狀態</option>
                <option value="0" >處理中</option>
                <option value="1" >已處理</option>
              </select>
            </td>
          </tr>

          <tr>
            <th>內容</th>
            <td v-if="isAdd">
              <textarea id="內容" v-model="data.內容" class="form-control" min="1" rows="5" required></textarea>
            </td>
            <td v-else>
              <textarea id="內容" v-model="data.新內容" class="form-control" min="1" rows="5" required
                placeholder="修改內容"></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <pre class="mt-20">{{ data }}</pre>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types',
    'players',
    'nickNames',
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        門號:"",
        暱稱:"",
        內容:"",
        處理狀態:"",
        分類:"",

        玩家異動紀錄ID:"",
        新內容:"",
      },
      player: null,
      isAdd: true, 
      hasRole:true,
    };
  },
  mounted(){
  },
  methods: {
    async show(player, isAdd) {
      Object.assign(this.$data, this.$options.data()); // 重設data

      if(isAdd) {
        this.data.門號 = this.players.聯絡電話;

        if(this.nickNames.length == 1) {
          this.data.暱稱 = this.nickNames[0];
        }
      } else {
        this.data = player;
        this.data.暱稱 = this.data.玩家識別值;
        //this.data.內容 = this.data.新內容;
        this.data.分類 = this.data.異動動作;
        this.data.門號 = this.data.聯絡電話;
        
        this.isAdd = isAdd;
      }
      this.hasRole = this.nickNames.length > 0 ? true : false;
      
      this.$refs.memberServiceDialog.show();
    },
    
    submit() {
      if (this.hasRole && !this.data.暱稱) {
        this.$eventBus.$emit("showAlert", "請選取暱稱");
        return;
      }

      if (!this.data.分類) {
        this.$eventBus.$emit("showAlert", "請選擇服務類型");
        return;
      }

      if (this.data.處理狀態 === '') {
        this.$eventBus.$emit("showAlert", "請選擇處理處理狀態");
        return;
      }

      if (this.isAdd) {
        this.$store.dispatch("xinCRM/會員服務記錄新增", this.data).then(() => {
          this.$refs.memberServiceDialog.hide();
          this.$eventBus.$emit("showAlert", "新增成功！");
          this.$emit("updateList");
        }, (message) => {
          this.$eventBus.$emit("showAlert", message);
        });
      } else {
        this.$store.dispatch("xinCRM/會員服務記錄修改", this.data).then(() => {
          this.$refs.memberServiceDialog.hide();
          this.$eventBus.$emit("showAlert", "修改成功！");
          this.$emit("updateList");
        }, (message) => {
          this.$eventBus.$emit("showAlert", message);
        });
      }

      
    },

    commonSubmit() {

    },
  
  },
};
</script>
