import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    reportList: [],
    response: '',
    comment: [],
    commentQuery: [],
    reply: [],
    replyQuery: [],
}

// getters
const getters = {
}

// actions
const actions = {
    search({ commit }, data) {
        api.qppSocial.accuse.search(data).then(res => {
            if (res.data.status == "1") {
                commit('setReportList', res.data.resultData);
            }
        })
    },
    edit({ commit }, data) {
        api.qppSocial.accuse.edit(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data.content);
            }
        })
    },

    comment({ commit }, data) {
        // api.qppSocial.comment.queryAll(data).then(res => {
        //     console.log(res.data.resultData);
        //     if (res.data.status == "1") {
        //         commit('setComment', res.data.content);
        //     }
        // })
        return new Promise((resolve, reject) => {
            api.qppSocial.comment.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.resultData;
                    resolve(content);
                }
            });
        });
    },
    commentQuery({ commit }, data) { //查詢單筆留言
        api.qppSocial.comment.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setCommentQuery', res.data.resultData);
            }
        })
    },
    deleteComment({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.comment.delete(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data;
                    resolve(content);
                }
            });
        });
    },

    reply({ commit }, data) {
        // api.qppSocial.reply.queryAll(data).then(res => {
        //     console.log(res.data.resultData);
        //     if (res.data.status == "1") {
        //         commit('setReply', res.data.content);
        //     }
        // })
        return new Promise((resolve, reject) => {
            api.qppSocial.reply.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.resultData;
                    resolve(content);
                }
            });
        });
    },
    replyQuery({ commit }, data) { //查詢單筆留言
        api.qppSocial.reply.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setReplyQuery', res.data.resultData);
            }
        })
    },
    deleteReply({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.reply.delete(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data;
                    resolve(content);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setReportList(state, reportList) {
        state.reportList = reportList;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setCommentQuery(state, commentQuery) {
        state.commentQuery = commentQuery;
    },
    setReply(state, reply) {
        state.reply = reply;
    },
    setReplyQuery(state, replyQuery) {
        state.replyQuery = replyQuery;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}