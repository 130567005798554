import { render, staticRenderFns } from "./WebHomeBanner.vue?vue&type=template&id=23ec582f&scoped=true&"
import script from "./WebHomeBanner.vue?vue&type=script&lang=js&"
export * from "./WebHomeBanner.vue?vue&type=script&lang=js&"
import style0 from "./WebHomeBanner.vue?vue&type=style&index=0&id=23ec582f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ec582f",
  null
  
)

export default component.exports