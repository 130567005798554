/* eslint-disable */
import api from '@/assets/js/app-api';
import dfn from "@/views/projects/xin/_Define";

// actions
const actions = {
    會員列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員列表查詢, data) },
    會員查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員查詢, data) },
    會員配發回饋金({commit}, data){ return callAPI.call(this, dfn.permission.會員配發回饋金, data) },
    會員控管({commit}, data){ return callAPI.call(this, dfn.permission.會員控管, data) },
    會員控管記錄({commit}, data){ return callAPI.call(this, dfn.permission.會員控管記錄, data) },
    會員星幣異動({commit}, data){ return callAPI.call(this, dfn.permission.會員星幣異動, data) },
    會員星幣禁止轉出({commit}, data){ return callAPI.call(this, dfn.permission.會員星幣禁止轉出, data) },
    
    會員服務記錄查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄查詢, data) },
    會員服務記錄新增({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄新增, data) },
    會員服務記錄修改({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄修改, data) },
    會員服務記錄刪除({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄刪除, data) },
    會員榮譽值記錄({commit}, data){ return callAPI.call(this, dfn.permission.會員榮譽值記錄, data) },
    會員榮譽值異動({commit}, data){ return callAPI.call(this, dfn.permission.會員榮譽值異動, data) },
    
    客戶列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.客戶列表查詢, data) },
    客戶資料查詢({commit}, data){ return callAPI.call(this, dfn.permission.客戶資料查詢, data) },
    客戶資料新增({commit}, data){ return callAPI.call(this, dfn.permission.客戶資料新增, data) },
    客戶資料批次新增({commit}, data){ return callAPI.call(this, dfn.permission.客戶資料批次新增, data) },
    客戶資料修改({commit}, data){ return callAPI.call(this, dfn.permission.客戶資料修改, data) },
    客戶關聯新增({commit}, data){ return callAPI.call(this, dfn.permission.客戶關聯新增, data) },
    客戶關聯修改({commit}, data){ return callAPI.call(this, dfn.permission.客戶關聯修改, data) },
    客戶關聯刪除({commit}, data){ return callAPI.call(this, dfn.permission.客戶關聯刪除, data) },
    客戶關聯列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.客戶關聯列表查詢, data) },
    
    信件發送({commit}, data){ return callAPI.call(this, dfn.permission.信件發送, data) },
    信件歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.信件歷程查詢, data) },
    持有信件查詢({commit}, data){ return callAPI.call(this, dfn.permission.持有信件查詢, data) },
    語言檔查詢({commit}, data){ return callAPI.call(this, dfn.permission.語言檔查詢, data) },
    
    公會查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會查詢, data) },
    公會建立({commit}, data){ return callAPI.call(this, dfn.permission.公會建立, data) },
    公會解散({commit}, data){ return callAPI.call(this, dfn.permission.公會解散, data) },
    公會解散歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會解散歷程查詢, data) },

    操作歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.操作歷程查詢, data) },

    服務類別新增({commit}, data){ return callAPI.call(this, dfn.permission.服務類別新增, data) },
    服務類別修改({commit}, data){ return callAPI.call(this, dfn.permission.服務類別修改, data) },
    服務類別查詢({commit}, data){ return callAPI.call(this, dfn.permission.服務類別查詢, data) },
    服務類別刪除({commit}, data){ return callAPI.call(this, dfn.permission.服務類別刪除, data) },

    遊戲歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲歷程查詢, data) },
    勝負分歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.勝負分歷程查詢, data) },
    
}

function callAPI(command, data = {}){
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = `使用者無開通「${command}」API權限`;
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        api.XinStarsTw
            .post({permission:command,lang:"zh-TW", data:data})
            .then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                reject(res.data.message);
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}

export default {
    namespaced: true,
    actions,
}
