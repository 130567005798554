<template>
  <div>
    <!-- 要在 tabs 上寫上權限 -->
    <ul class="nav nav-tabs">
      <li v-for="item in tabs" :key="item.id" :class="{ active: tab === item.id }">
        <a @click="selectTab(item.id)">{{ item.name }}</a>
      </li>
    </ul>

    <div class="nav-subTab mb-10" v-if="activeSubTabs">
      <ul class="nav">
        <li v-for="subTab in activeSubTabs" :key="subTab" 
            :class="{ active: currentSubTab === subTab }">
          <a @click="currentSubTab = subTab">{{ subTab }}</a>
        </li>
      </ul>
    </div>

    <!-- <div>
      <slot :name="`${tab}-${currentSubTab}`"></slot>
    </div> -->

    <div v-for="(subTabs, tabId) in subTabs" :key="tabId">
      <div v-for="subTab in subTabs" :key="subTab" 
        v-show="currentSubTab === subTab">
        <slot :name="`${tabId}-${subTab}`"></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    subTabs: Object,
  },
  data() {
    return {
      tab: this.tabs[0].id,
      currentSubTab: this.subTabs[this.tabs[0].id][0]
      /* tab: this.tabs[1].id,
      currentSubTab: this.subTabs[this.tabs[1].id][5] */
    };
  },
  computed: {
    activeSubTabs() {
      return this.subTabs[this.tab];
    }
  },
  methods: {
    selectTab(tabId) {
      this.tab = tabId;
      this.currentSubTab = this.subTabs[tabId][0];
    }
  }
};
</script>

<style lang="scss" scoped>

.nav-tabs>li {
    a {
        color: #555;
    }
}
.nav-tabs-custom li a {
  cursor: pointer;
  font-size: 1.15em;
}

.box-header {
  box-shadow: none;
  border-bottom: none !important;
}

.nav-subTab {
  & , ul {
     display: flex;
    width: 100%;
  }

  ul {
    border-bottom: 1px solid #ddd
  }
  
  li {
    list-style: none;
    

    a {
      color: #333;
      padding: 15px 25px;
      font-size: 1.1em;
      border-bottom: 2px solid transparent;
      transition: all 0.2s;

      &:hover {
          background: none;
          opacity: 0.8;
      }
    }

    &.active {
      a {
        border-bottom-color: #f39c12;
      }
    }
  }
}
</style>