<template>
  <!-- 新增禁言 -->
  <div class="modal fade" id="addSocialMute" ref="add_mute">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增禁言</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteId">
                <span class="text-red">*</span> ID：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputMuteId"
                  required="required"
                  type="text"
                  v-model="data.uId"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteDay">
                <span class="text-red">*</span> 禁言時間：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputMuteDay"
                  min="1"
                  placeholder="輸入天數"
                  required="required"
                  type="number"
                  v-model="data.bDays"
                />
                <p class="text-red mt-10">* 不輸入或天數大於3650天，為永久禁言。</p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteNote">備註：</label>
              <div class="col-sm-9">
                <input class="form-control" id="inputMuteNote" type="text" v-model="data.bNote" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button @click="submitMute()" class="btn btn-primary" type="button">新增</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      data: {
        uId: '',
        bDays: '',
        bNote: '',
      }
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      response: (state) => state.qppSocialMute.muteResponse,
      query: (state) => state.qppSocialMute.muteQuery,
    }),
  },
  watch: {
    // [api] AddMute res跑來這
    response(value) {
      if (value.status == 1) {
        this.showSuccess(value);
      }
    },

    // [api] MuteQuery res跑來這
    query(value) {
      console.log(value);
      if (value.totalCount === 0) {
        this.addMute();
      } else {
        this.$eventBus.$emit(
          'showConfirm',
          '此會員已經為禁言狀態，是否新增最新禁言？',
          (isOK) => {
            if (isOK) {
              this.addMute();
            }
          }
        );
      }
    },
  },
  methods: {
    showAddMuteBox() {
      this.data.uId = '';
      this.data.bDays = '';
      this.data.bNote = '';
      $(this.$refs.add_mute).modal('show');
    },
    showSuccess(content) {
      if (content.status == '1') {
        $(this.$refs.add_mute).modal('hide');
        this.$eventBus.$emit('showAlert', content.message);
        setTimeout(() => {
          this.$emit('restList');
        }, 500);
      } else {
        if (content.message == '') {
          this.$eventBus.$emit('showAlert', content.message);
        } else {
          this.$eventBus.$emit('showAlert', content.message);
        }
      }
    },
    submitMute() {
      if (this.data.uId == '') {
        this.$eventBus.$emit('showAlert', '請輸入ID');
        return;
      }

      if (this.data.bDays == '' || this.data.bDays > 3650) {
        this.$eventBus.$emit(
          'showConfirm',
          '天數為空或超過3650天，將設定為永久禁言，是否確認?',
          (isOK) => {
            if (isOK) {
              this.checkID();
            }
          }
        );
      } else {
        this.checkID();
      }
    },
    checkID() {
      let data = {
        uId: this.data.uId,
        startTime: "2000/01/01",
        endTime: "2999/12/31",
      };
      this.$store.dispatch('qppSocialMute/search', data);
    },
    addMute() {
      if (this.data.bDays == '') this.data.bDays = 0;
      this.data.bDays = parseInt(this.data.bDays)
      let _data = []
      _data.push(this.data)
      this.$store.dispatch('qppSocialMute/add', _data);
    },
  },
};
</script>
