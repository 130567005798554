<template>
  <div>
    <FormDialog ref="importCustomerDialog" :title="'匯入客戶資料'" 
      @submit="submit">
      <table v-if="!result" class="table table-bordered">
        <tbody>
          <tr>
            <th>匯入名單</th>
            <td>
              <button type="button" class="btn btn-primary mt-0" @click="importShow()" style="vertical-align:baseline;">
                <i class="fa fa-file-alt"></i> 匯入名單
              </button>
              <span class="ml-10">{{ importFileName }}</span> 

              <div v-if="pagedTable.length > 0" class="mt-10">
                <table class="table table-data table-bordered">
                  <tbody>
                    <tr>
                      <th>聯絡門號</th>
                      <th>真實姓名</th>
                      <th>加入官方<br>Line</th>
                      <th>生日</th>
                      <th>聯絡地址</th>
                      <th>綁定門號</th>
                      <th>暱稱</th>
                    </tr>
                    <tr v-for="row in pagedTable" style="line-height:1.2;">
                      <template v-for="col in row">
                        <td>{{ col.聯絡門號 }}</td>
                        <td>{{ col.真實姓名 }}</td>
                        <td align="center">{{ col.是否加入官方Line }}</td>
                        <td>{{ col.生日 }}</td>
                        <td>{{ col.聯絡地址 }}</td>
                        <td>{{ col.綁定門號 }}</td>
                        <td>{{ col.暱稱 }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center mt-5" v-show="pageTotal > pageSize">
                  <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal"
                    v-on:pageMethod="onPageChange" />
                </div>
              </div>
            </td>
          </tr>
         
          <tr>
            <th>備註</th>
            <td>
              <textarea class="form-control" type="text" v-model.trim="data.內容"
                placeholder="" rows="3"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      
      <template v-if="result" v-slot:footer>
        <button type="button" data-dismiss="modal" class="btn btn-default">確定</button></template>
    </FormDialog>
    <ImportData ref="ImportData" title="客戶資料" :checks="checks" @importData="importData" />
  </div>
</template>


<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";
import ImportData from "@/views/projects/xin/ImportData";
import Pagination from "@/components/Pagination";

export default {
  components: {
    FormDialog,
    ImportData,
    Pagination,
  },
  props:['types'],
  data() {
    return {
      player: null,
      result: null,
      data: {
        //array帶入多筆數字
        聯絡門號: [], 
        真實姓名: [],
        是否加入官方Line: [], 
        生日: [],
        聯絡地址: [],
        綁定門號: [],
        暱稱: [],
        分類:"",
        內容:"",
      },
      pageNum: 1,
      pageSize: 5,
      pageCol: 2,
      pageTotal: 0,
      importFileName: "",
      importList: [],
      checks: ["聯絡門號", "真實姓名", "加入官方Line", "生日","聯絡地址", "綁定門號", "暱稱"],
      // checks: ["聯絡門號", "真實姓名", "加入官方Line", "生日", "聯絡地址", "綁定門號", "暱稱" ],

    }
  },
  watch: {
    "filterString": function () { this.pageNum = 1 }
  },
  computed: {
    "playerTable": function () {
      let list = this.importList;
      let arr = [];
      for (let i = 0, len = list.length; i < len;) {
        let row = [];
        for (let j = 0; j < this.pageCol; j++) {
          if (list[i]) row.push(list[i++]);
        }
        arr.push(row);
      }
      this.pageTotal = arr.length;
      return arr;
    },
    "pagedTable": function () {
      return this.playerTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
  },
  methods: {
    async show(player) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player || null;
      if (this.player) {
        this.data.暱稱 = [player];
      }
      this.$refs.importCustomerDialog.show();
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },
    importShow() {
      this.$refs.ImportData.show();
    },
    importData(data, fileName) {
      this.importList = data.map(p => { return { 
        聯絡門號: p.聯絡門號.toString().trim(), 
        真實姓名: p.真實姓名.toString().trim(),
        是否加入官方Line: Number(p.加入官方Line),
        生日: p.生日.toString().trim(), 
        聯絡地址: p.聯絡地址.toString().trim(), 
        綁定門號: p.綁定門號.toString().trim(), 
        暱稱: p.暱稱.toString().trim(),
      }});
      this.data.聯絡門號 = this.importList.map(item => item.聯絡門號);
      this.data.真實姓名 = this.importList.map(item => item.真實姓名);
      this.data.是否加入官方Line = this.importList.map(item => item.是否加入官方Line);
      this.data.生日 = this.importList.map(item => item.生日);
      this.data.聯絡地址 = this.importList.map(item => item.聯絡地址);
      this.data.綁定門號 = this.importList.map(item => item.綁定門號);
      this.data.暱稱 = this.importList.map(item => item.暱稱);
      this.importFileName = fileName;
    },

    submit() {
      // this.$store.dispatch("xinCRM/會員配發回饋金", this.data).then((res) => {
        this.$refs.importCustomerDialog.hide();
        this.$emit("updateList");
        this.$eventBus.$emit("showAlert", "成功！");
      //   this.pageCol = 3;
      //   this.pageSize = 10;
      //   this.result = res;
      //   this.importList = this.result.成功;
      // }, (message) => {
        // this.$eventBus.$emit("showAlert", message);
      // });
    },
  },
};
</script>

<style scoped lang="scss">
  
  .table-data {
    border-color: #ddd;
    
    th, td { padding:.2em .3em; border-color:#ddd; }
    th { border-right-style:dotted; text-align:center; 
      background:rgba(#e8e8e8, .25); width:2%;
      font-size: 13px;}
    td { border-left-style:none; width:1%; font-size: 12px;}
  }
</style>