/* eslint-disable */
import api from '@/assets/js/app-api';
import dfn from "@/views/projects/XSW/_Define";
import i18n from "@/assets/js/i18nFunc";

// actions
const actions = {
    會員列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員列表查詢, data) },
    會員查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員查詢, data) },
    會員轉正({commit}, data){ return callAPI.call(this, dfn.permission.會員轉正, data) },
    會員配點({commit}, data){ return callAPI.call(this, dfn.permission.會員配點, data) },
    會員配點記錄({commit}, data){ return callAPI.call(this, dfn.permission.會員配點記錄, data) },
    會員配發回饋金({commit}, data){ return callAPI.call(this, dfn.permission.會員配發回饋金, data) },
    會員星幣異動({commit}, data){ return callAPI.call(this, dfn.permission.會員星幣異動, data) },
    會員控管({commit}, data){ return callAPI.call(this, dfn.permission.會員控管, data) },
    會員控管記錄({commit}, data){ return callAPI.call(this, dfn.permission.會員控管記錄, data) },
    會員星幣禁止轉出({commit}, data){ return callAPI.call(this, dfn.permission.會員星幣禁止轉出, data) },
    會員服務記錄查詢({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄查詢, data) },
    會員服務記錄新增({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄新增, data) },
    會員服務記錄修改({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄修改, data) },
    會員服務記錄刪除({commit}, data){ return callAPI.call(this, dfn.permission.會員服務記錄刪除, data) },
    會員榮譽值記錄({commit}, data){ return callAPI.call(this, dfn.permission.會員榮譽值記錄, data) },
    會員榮譽值異動({commit}, data){ return callAPI.call(this, dfn.permission.會員榮譽值異動, data) },
    
    //活動獎品管理列表查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理列表查詢, data, "zh-TW", { name: "EventPrizeListQuery" });}
    會員異動金幣({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.會員異動金幣, data, "zh-TW", { name: "SetCoins" });},
    會員異動銅幣({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.會員異動銅幣, data, "zh-TW", { name: "SetBronzeCoins" });},

    會員異動皇家點數({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.會員異動皇家點數, data, "zh-TW", { name: "SetRoyalPoint" });},
    會員活動點數查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.會員活動點數查詢, data, "zh-TW", { name: "EventPointsHistory" });},
    
    
    信件發送({commit}, data){ return callAPI.call(this, dfn.permission.信件發送, data) },
    信件歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.信件歷程查詢, data) },
    持有信件查詢({commit}, data){ return callAPI.call(this, dfn.permission.持有信件查詢, data) },
    語言檔查詢({commit}, data){ return callAPI.call(this, dfn.permission.語言檔查詢, data) },
    
    公會查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會查詢, data) },
    公會明細({commit}, data){ return callAPI.call(this, dfn.permission.公會明細, data) },
    公會建立({commit}, data){ return callAPI.call(this, dfn.permission.公會建立, data) },
    公會解散({commit}, data){ return callAPI.call(this, dfn.permission.公會解散, data) },
    公會解散歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會解散歷程查詢, data) },
    公會成員列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會成員列表查詢, data) },
    PlayerRebateQuery({commit}, data){ return callAPI.call(this, dfn.permission.PlayerRebateQuery, data) },

    公會審核清單查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會審核清單查詢, data) },
    公會審核歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.公會審核歷程查詢, data) },
    公會審核通過({commit}, data){ return callAPI.call(this, dfn.permission.公會審核通過, data) },
    公會審核駁回({commit}, data){ return callAPI.call(this, dfn.permission.公會審核駁回, data) },

    公會金庫交易歷程查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.公會金庫交易歷程查詢, data, "zh-TW", { name: "GuildMoneyTradeRecord" });},

    操作歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.操作歷程查詢, data) },
    服務類別新增({commit}, data){ return callAPI.call(this, dfn.permission.服務類別新增, data) },
    服務類別修改({commit}, data){ return callAPI.call(this, dfn.permission.服務類別修改, data) },
    服務類別查詢({commit}, data){ return callAPI.call(this, dfn.permission.服務類別查詢, data) },

    遊戲歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲歷程查詢, data) },
    勝負分歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.勝負分歷程查詢, data) },

    // ApexWin(新H5)
    遊戲介紹列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹列表查詢, data) },
    遊戲介紹查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹查詢, data) },
    遊戲介紹新增({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹新增, data) },
    遊戲介紹修改({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹修改, data) },
    遊戲介紹刪除({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹刪除, data) },
    遊戲介紹複製({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹複製, data) },
    遊戲介紹清暫存({commit}, data){ return callAPI.call(this, dfn.permission.遊戲介紹清暫存, data) },
    
    遊戲操作說明列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明列表查詢, data) },
    遊戲操作說明查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明查詢, data) },
    遊戲操作說明新增({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明新增, data) },
    遊戲操作說明修改({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明修改, data) },
    遊戲操作說明刪除({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明刪除, data) },
    遊戲操作說明複製({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明複製, data) },
    遊戲操作說明清暫存({commit}, data){ return callAPI.call(this, dfn.permission.遊戲操作說明清暫存, data) },

    官網Banner列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner列表查詢, data) },
    官網Banner查詢({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner查詢, data) },
    官網Banner新增({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner新增, data) },
    官網Banner修改({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner修改, data) },
    官網Banner刪除({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner刪除, data) },
    官網Banner複製({commit}, data){ return callAPI.call(this, dfn.permission.官網Banner複製, data) },
    
    官網News列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.官網News列表查詢, data) },
    官網News查詢({commit}, data){ return callAPI.call(this, dfn.permission.官網News查詢, data) },
    官網News新增({commit}, data){ return callAPI.call(this, dfn.permission.官網News新增, data) },
    官網News修改({commit}, data){ return callAPI.call(this, dfn.permission.官網News修改, data) },
    官網News刪除({commit}, data){ return callAPI.call(this, dfn.permission.官網News刪除, data) },
    官網News複製({commit}, data){ return callAPI.call(this, dfn.permission.官網News複製, data) },

    分類列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.分類列表查詢, data) },
    分類列表明細查詢({commit}, data){ return callAPI.call(this, dfn.permission.分類列表明細查詢, data) },
    分類查詢({commit}, data){ return callAPI.call(this, dfn.permission.分類查詢, data) },
    分類新增({commit}, data){ return callAPI.call(this, dfn.permission.分類新增, data) },
    分類修改({commit}, data){ return callAPI.call(this, dfn.permission.分類修改, data) },
    分類刪除({commit}, data){ return callAPI.call(this, dfn.permission.分類刪除, data) },

    遊戲清單列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單列表查詢, data) },
    遊戲清單查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單查詢, data) },
    遊戲清單新增({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單新增, data) },
    遊戲清單修改({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單修改, data) },
    遊戲清單刪除({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單刪除, data) },
    遊戲清單複製({commit}, data){ return callAPI.call(this, dfn.permission.遊戲清單複製, data) },

    // 國際APP版
    類別與標籤查詢({commit}, data){ return callAPI.call(this, dfn.permission.類別與標籤查詢, data) },
    類別與標籤新增({commit}, data){ return callAPI.call(this, dfn.permission.類別與標籤新增, data) },
    類別與標籤修改({commit}, data){ return callAPI.call(this, dfn.permission.類別與標籤修改, data) },

    遊戲館列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲館列表查詢, data) },
    遊戲館查詢({commit}, data){ return callAPI.call(this, dfn.permission.遊戲館查詢, data) },
    遊戲館新增({commit}, data){ return callAPI.call(this, dfn.permission.遊戲館新增, data) },
    遊戲館修改({commit}, data){ return callAPI.call(this, dfn.permission.遊戲館修改, data) },
    遊戲館刪除({commit}, data){ return callAPI.call(this, dfn.permission.遊戲館刪除, data) },
    
    活動總覽列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動總覽列表查詢, data) },
    活動總覽查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動總覽查詢, data) },
    活動總覽新增({commit}, data){ return callAPI.call(this, dfn.permission.活動總覽新增, data) },
    活動總覽修改({commit}, data){ return callAPI.call(this, dfn.permission.活動總覽修改, data) },
    活動總覽刪除({commit}, data){ return callAPI.call(this, dfn.permission.活動總覽刪除, data) },

    Google帳單查詢({commit}, data){ return callAPI.call(this, dfn.permission.Google帳單查詢, data) },
    Google帳單批次查詢({commit}, data){ return callAPIBatch.call(this, dfn.permission.Google帳單批次查詢, data) },
    Google每日點數合計查詢({commit}, data){ return callAPI.call(this, dfn.permission.Google每日點數合計查詢, data) },
    iOS帳單查詢({commit}, data){ return callAPI.call(this, dfn.permission.iOS帳單查詢, data) },
    iOS帳單批次查詢({commit}, data){ return callAPIBatch.call(this, dfn.permission.iOS帳單批次查詢, data) },
    iOS每日點數合計查詢({commit}, data){ return callAPI.call(this, dfn.permission.iOS每日點數合計查詢, data) },
    OrderDetailListQuery({commit}, data){ return callAPI.call(this, dfn.permission.OrderDetailListQuery, data) },
    QueryFunOneBillList({commit}, data){ return callAPI.call(this, dfn.permission.QueryFunOneBillList, data) },
    
    序號兌換活動列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.序號兌換活動列表查詢, data) },
    序號兌換狀態列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.序號兌換狀態列表查詢, data) },
    玩家序號兌換記錄查詢({commit}, data){ return callAPI.call(this, dfn.permission.玩家序號兌換記錄查詢, data) },

    聊天室公告查詢({commit}, data){ return callAPI.call(this, dfn.permission.聊天室公告查詢, data) },
    聊天室公告異動({commit}, data){ return callAPI.call(this, dfn.permission.聊天室公告異動, data) },

    // 活動名稱列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動名稱列表查詢, data) },
    // 活動管理列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動管理列表查詢, data) },
    // 活動管理查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動管理查詢, data) },
    // 活動管理新增({commit}, data){ return callAPI.call(this, dfn.permission.活動管理新增, data) },
    // 活動管理修改({commit}, data){ return callAPI.call(this, dfn.permission.活動管理修改, data) },
    // 活動管理刪除({commit}, data){ return callAPI.call(this, dfn.permission.活動管理刪除, data) },

    // 活動兌換歷程新增({commit}, data){ return callAPI.call(this, dfn.permission.活動兌換歷程新增, data) },
    // 活動兌換歷程查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動兌換歷程查詢, data) },
    // 活動兌換歷程統計({commit}, data){ return callAPI.call(this, dfn.permission.活動兌換歷程統計, data) },
    // 活動兌換管理查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動兌換管理查詢, data) },
    // 活動兌換管理刪除({commit}, data){ return callAPI.call(this, dfn.permission.活動兌換管理刪除, data) },

    // 活動獎品管理修改({commit}, data){ return callAPI.call(this, dfn.permission.活動獎品管理修改, data) },
    // 活動獎品管理刪除({commit}, data){ return callAPI.call(this, dfn.permission.活動獎品管理刪除, data) },
    // 活動獎品管理新增({commit}, data){ return callAPI.call(this, dfn.permission.活動獎品管理新增, data) },
    // 活動獎品管理查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動獎品管理查詢, data) },
    // 活動獎品管理列表查詢({commit}, data){ return callAPI.call(this, dfn.permission.活動獎品管理列表查詢, data) },

    // 共用功能
    檔案上傳({commit}, data){ return callAPIUpload.call(this, dfn.permission.檔案上傳, data) },
    檔案刪除({commit}, data){ return callAPI.call(this, dfn.permission.檔案刪除, data) },

    活動管理列表查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動管理列表查詢, data, "zh-TW", { name: "eventManagementList" });},
    活動管理查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動管理查詢, data, "zh-TW", { name: "eventManagement" });},
    活動管理新增({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動管理新增, data, "zh-TW", { name: "eventManagementCreate" });},
    活動管理修改({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動管理修改, data, "zh-TW", { name: "eventManagementUpdate" });},
    // 活動管理刪除({ commit }, data,) {//     return callAPIDirect.call(this, dfn.permission.活動管理刪除, data, "zh-TW", { name: "eventManagementDelete" });// },
    活動名稱列表查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動名稱列表查詢, data, "zh-TW", { name: "eventNameList" });},

    活動兌換歷程查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動兌換歷程查詢, data, "zh-TW", { name: "eventRedemptionHistory" });},
    活動兌換歷程統計({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動兌換歷程統計, data, "zh-TW", { name: "EventRedemptionHistoryStatistics" });},

    活動獎品管理修改({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理修改, data, "zh-TW", { name: "EventPrizeUpdate" });},
    活動獎品管理刪除({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理刪除, data, "zh-TW", { name: "EventPrizeDelete" });},
    活動獎品管理新增({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理新增, data, "zh-TW", { name: "EventPrizeCreate" });},
    活動獎品管理查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理查詢, data, "zh-TW", { name: "EventPrizeQuery" });},
    活動獎品管理列表查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動獎品管理列表查詢, data, "zh-TW", { name: "EventPrizeListQuery" });},

    活動中獎名單管理新增({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動中獎名單管理新增, data, "zh-TW", { name: "EventWinnerListManagementCreate" });},
    活動中獎名單管理修改({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動中獎名單管理修改, data, "zh-TW", { name: "EventWinnerListManagementUpdate" });},
    活動中獎名單管理查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動中獎名單管理查詢, data, "zh-TW", { name: "EventWinnerListManagementQuery" });},
    活動中獎名單管理列表查詢({ commit }, data,) {return callAPIDirect.call(this, dfn.permission.活動中獎名單管理列表查詢, data, "zh-TW", { name: "EventWinnerListManagementListQuery" });}

}
function callAPIDirect(command,data = {} , lang = "zh-TW", nameapi = {}){
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    lang = 'zh-TW';
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        
        api.xsw[nameapi.name]( data ).then(res => {         
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}

function callAPI(command, data = {}, lang = "zh-TW"){
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        api.xsw.post({ permission:command, data:data, lang:lang}).then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}
function callAPIUpload(command, data = new FormData(), lang = "zh-TW"){
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        data.append("permission", command);
        data.append("lang", lang);
        // console.log (api);
        api.xsw.upload(data).then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}
function callAPIBatch(command, data = new FormData(), lang = "zh-TW"){
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        data.append("permission", command);
        data.append("lang", lang);
        // console.log (api);
        api.xsw.batch(data).then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}
const mutations = {
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    actions,
    mutations
}