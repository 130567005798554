<template>
    <div class="itemPage" :class="!item.買方 ? `page` : ``" style="font-size: 20px;">
        <div class="center">交易明細</div>
        <table style="width: 100%;">
            <tr>
                <th>品名</th>
                <th class="right" style="white-space: nowrap;">數量</th>
                <th class="right" style="white-space: nowrap;">單價</th>
                <th class="right" style="white-space: nowrap;">金額</th>
            </tr>
            <tr v-for="(商品, index) of item.商品明細" :key="index">
                <td>{{ 商品.品名 }}</td>
                <td class="right" style="white-space: nowrap;">{{ 商品.數量 }}</td>
                <td class="right" style="white-space: nowrap;">{{ 商品.單價 }}</td>
                <td class="right" style="white-space: nowrap;">{{ 商品.金額 }}</td>
            </tr>
            <tr>
                <td colspan="4" style="height: 30mm;vertical-align: text-top;">備註:{{ item.備註
                }}</td>
            </tr>
            <tr>
                <td>營業稅別</td>
                <td colspan="3" class="right">{{ item.營業稅別 | TransEnum(稅別Enum) }}</td>
            </tr>
            <tr>
                <td>銷售額</td>
                <td colspan="3" class="right">{{ item.銷售總額 }}</td>
            </tr>
            <tr>
                <td>稅額</td>
                <td colspan="3" class="right">{{ item.營業稅 }}</td>
            </tr>
            <tr>
                <td>總計</td>
                <td colspan="3" class="right">
                    {{ item.發票金額 }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
import { TransEnum, 稅別Enum, 網銀統編, 手開發票列印狀態Enum } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
export default {
    props: ['item'],
    data() {
        return {
            手開發票列印狀態Enum,
            網銀統編,
            稅別Enum,
        }
    },
    mounted() {
        this.$nextTick().then(() => {
            console.error("c2", "nextTick");
            return true;
        });
    }, methods: {

    }, filters: {
        TransEnum
    }
}
</script>
<style scoped>
@media print {
    @page {
        /*長寬不調了，直接拿印表機對*/
        /* size: A4 landscape; */
        /*隱藏頁首頁尾*/
        margin: 0px;
        /* width: 430px; */
    }
}

.page {
    /**水平顯示或垂直顯示用 */
    /* display: inline-block; */
    /* page-break-inside: avoid; */
    /* 若順著向下排列時會切開此元素，則直接換頁 */
    page-break-after: always;
    /* 碰到此元素會直接換頁 */
    background-color: white;
    box-sizing: border-box;
    width: 430px;
    /* border: 1px solid black; */
    /* overflow: hidden; */
    /* height: 600px; */
    padding-top: 55px;
    padding-left: 25px;
    padding-right: 65px;
    /* padding-bottom: 55px; */
}

/*取消強制換頁*/
.page:last-of-type {
    page-break-after: auto;
}

.itemPage {
    padding-bottom: 20px;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

th,
td {
    border: 6px solid white;
}
</style>