<template>
  <FormDialog
    ref="customerDialog"
    :title="(isAddCustomer ? '新增' : '編輯') + '客戶資料'"
    @submit="submit"
  >
    <p>新增客戶資料，至少填寫聯絡門號和真實姓名</p>
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th width="110">聯絡門號</th>
            <td>
              <input
                class="form-control"
                id="inputPhone"
                type="text"
                v-model.trim="data.聯絡電話"
                placeholder="+886xxxxxxxxx"
              />
            </td>
            <th width="110">真實姓名</th>
            <td>
              <input
                class="form-control"
                id="inputName"
                type="text"
                v-model.trim="data.真實姓名"
              />
            </td>
          </tr>

          <tr>
            <th>性別</th>
            <td>
              <select v-model.trim="data.性別" class="form-control">
                <option value="0" selected disabled hidden>請選擇性別</option>
                <option value="1">男</option>
                <option value="2">女</option>
                <option value="3">其它</option>
              </select>
            </td>

            <th>加入官方Line</th>
            <td>
              <div class="col-xs-5">
                <div class="radio">
                  <iCheckRedioButton
                    :checkValue="0"
                    message="否"
                    name="radioNeeds"
                    v-model.trim="data.加入官方Line"
                  />
                </div>
              </div>
              <div class="col-xs-6">
                <div class="radio">
                  <iCheckRedioButton
                    :checkValue="1"
                    message="是"
                    name="radioNeeds"
                    v-model.trim="data.加入官方Line"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <th>生日</th>
            <td>
              <input
                type="date"
                class="input-date"
                name="trip-start"
                value=""
                v-model.trim="data.生日"
              />
            </td>

            <th>年齡</th>
            <td>(系統自動計算)</td>
          </tr>

          <tr data-title="地址">
            <th>地址</th>
            <td colspan="3" class="td-add">
              <div class="col-sm-3 pl-0">
                <select
                  id="city"
                  class="form-control"
                  v-model.trim="data.縣市"
                  @change="updateZipcodes"
                >
                  <option value="" selected disabled hidden>請選擇縣市</option>
                  <option
                    v-for="county in twAdd.counties"
                    :key="county.id"
                    :value="county.name"
                  >
                    {{ county.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3 pl-0">
                <select id="zipcode" class="form-control" v-model.trim="data.區域">
                  <option value="" selected disabled hidden>請選擇地區</option>
                  <option
                    v-for="zipcode in filteredZipcodes"
                    :key="zipcode.id"
                    :value="zipcode.id"
                  >
                    {{ zipcode.id }}
                  </option>
                </select>
              </div>
              <div class="col-sm-6 pl-0">
                <input type="text" class="form-control" v-model.trim="data.地址" />
              </div>
            </td>
          </tr>

          <tr>
            <th>備註</th>
            <td colspan="3">
              <textarea
                v-model.trim="data.備註"
                class="form-control"
                min="1"
                rows="3"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-bordered">
        <tbody>
          <tr>
            <th colspan="3" style="background: #dcdcdc">
              關聯帳號
              <small v-if="!data.客戶資料ID" class="ml-10"
                >(新增資料要新增關聯，請先填寫聯絡門號或真實姓名)</small
              >
            </th>
          </tr>
          <tr>
            <th style="width: 30%">類型</th>
            <th style="width: 50%">資料</th>
            <th style="width: 20%">
              <button
                class="btn-warning btn btn-add"
                type="button"
                v-if="this.data.聯絡電話 && this.data.真實姓名"
                @click="addAssociation()"
                :disabled="isNotAssociation"
              >
                <i class="fa fa-plus"></i> 新增關聯
              </button>
            </th>
          </tr>

          <tr v-for="(item, index) in associatedInfo" :key="item.客戶關聯ID">
            <td>
              <span v-if="!item.editor">
                {{ item.關聯類型 == 2 ? "角色暱稱" : "角色門號" }}
              </span>

              <select
                class="form-control"
                v-model.trim="item.關聯類型"
                v-if="item.editor"
              >
                <option value="0" selected disabled hidden>
                  請選擇關聯類型
                </option>
                <option value="2">角色暱稱</option>
                <option value="1">角色門號</option>
              </select>
            </td>

            <td>
              <!-- {{ item.editor }} -->
              <span v-if="!item.editor">
                {{ item.關聯資料 }}
              </span>
              <input
                v-if="item.editor"
                type="text"
                class="form-control"
                v-model.trim="item.關聯資料"
                style="width: 80%; display: inline-block"
              />
            </td>

            <td class="btn-td" v-if="!item.editor">
              <button
                type="button"
                @click="editAssociation(item)"
                class="btn btn-primary pull-left"
              >
                修改
              </button>
              <button
                v-if="can刪除"
                class="btn-danger btn btn-remove pull-right"
                type="button"
                @click="deleAssociation(item.客戶關聯ID)"
              >
                刪除
              </button>
            </td>

            <td class="btn-td" v-if="item.editor">
              <button
                type="button"
                @click="cancelAssociation(item, index)"
                class="btn btn-default pull-left"
              >
                取消
              </button>
              <button
                type="button"
                @click="saveAssociation(item)"
                class="btn btn-success btn-save pull-right"
              >
                儲存
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import twzipcode from "twzipcode-data";
import FormDialog from "./FormDialog.vue";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import dfn from "@/views/projects/xin/_Define";

export default {
  components: {
    FormDialog,
    iCheckRedioButton: iCheckRedioButton,
    twzipcode,
    DatePicker,
    moment,
    utils,
  },
  data() {
    return {
      isNew: false,
      data: {
        聯絡電話: "",
        真實姓名: "",
        加入官方Line: false,
        生日: null,
        地址: "",
        性別: 0,
        備註: "",
        縣市: "",
        區域: "",
        地址: "",
      },
      associatedInfo: [],
      tempEdit: false,
      editBackup: {},

      twAdd: {
        counties: [],
        zipcodes: [],
      },
      player: null,
      isAddCustomer: null, //
      isNotAssociation: false,

      can刪除: this.$user.hasPermission(dfn.permission.客戶關聯刪除),
    };
  },
  async created() {
    let twAddData = twzipcode();
    this.twAdd.counties = twAddData.counties;
    this.twAdd.zipcodes = twAddData.zipcodes;
    this.adjustZipcodes();
  },
  mounted() {},

  watch: {},
  computed: {
    filteredZipcodes() {
      return this.twAdd.zipcodes.filter(
        (zipcode) => zipcode.county === this.data.縣市
      );
    },
  },
  methods: {
    async show(player, isAddCustomer) {
      // console.log(player)
      if (!isAddCustomer) {
        this.isNew = false;
        this.isNotAssociation = false;
        this.data = utils.deepCloneObj(player);
        this.associatedInfo = this.data.linkedCustomList.map((item) => ({
          ...item,
          editor: 0,
        }));

        
      } else {
        this.isNew = true;
      }

      this.isAddCustomer = isAddCustomer !== undefined ? isAddCustomer : null;
      this.$refs.customerDialog.show();
    },

    addAssociation() {
      if (this.isNotAssociation) {
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      } else {
        this.isNotAssociation = true;
        this.tempEdit = true;
        this.associatedInfo.push({
          關聯類型: 0,
          關聯資料: "",
          editor: 1,
        });
      }
    },

    saveAssociation(item) {
      console.log(item);
      console.log('isNew:' , this.isNew);

      if (this.isNew) {

        if (!this.checkData()) { return; }
        this.$store.dispatch("xinCRM/客戶資料新增", this.data).then(
          (res) => {
            this.isNew = false;
            this.data.客戶資料ID = res;  
            this.$nextTick(() => {
              this.createAssocationAPI(item);
            });
          },
          (message) => {
            this.$eventBus.$emit("showAlert", message);
          }
        );

      } else {
        if (item.客戶關聯ID) {
          this.reviseAssocationAPI(item);
        } else {
          this.createAssocationAPI(item);
        }
      }

      console.log();
    },

    checkAssocation(item){

      if (!item.關聯類型 ) {
        this.$eventBus.$emit(
            "showAlert",
            "請選擇關聯類型"
        );
        return false;
      }

      if (!item.關聯資料 ) {
        this.$eventBus.$emit(
            "showAlert",
            "請填寫關聯資料"
        );
        return false;
      }
      
      if (item.關聯類型 ==1 ) {
        const phoneRegex = /^\+8869\d{8}$/;
        if (!phoneRegex.test(item.關聯資料)) {
            this.$eventBus.$emit(
                "showAlert",
                "請確認關聯電話格式為: ex:+8869xxxxxxxx"
            );
            return false;
        }
      }

      return true;
    },
    createAssocationAPI(item) {
      console.log("run createAssocationAPI");
      item.客戶資料ID = this.data.客戶資料ID;
      item.關聯類型 = parseInt(item.關聯類型);

      if (!this.checkAssocation(item)) {
        return
      }
      console.log(item);
      this.$store.dispatch("xinCRM/客戶關聯新增", item).then(
        () => {
          this.$eventBus.$emit("showAlert", "已新增關聯");
          
          this.$emit("updateList");
          this.queryAllAssocation();
          this.initAssocation(item);
        },
        (err) => {
          this.$eventBus.$emit("showAlert", err);
        }
      );
    },
    reviseAssocationAPI(item) {
      console.log("run reviseAssocationAPI");
      this.$store.dispatch("xinCRM/客戶關聯修改", item).then(
        () => {
          this.$eventBus.$emit("showAlert", "已修改關聯");
          this.initAssocation(item);
          this.$emit("updateList");

        },
        (err) => {
          this.$eventBus.$emit("showAlert", err);
        }
      );
    },
    queryAllAssocation(){
      this.$store.dispatch("xinCRM/客戶關聯列表查詢", 
        {customID: this.data.客戶資料ID}).then(
        (res) => {
          console.log(res);
          this.associatedInfo = res;
        },
        (err) => {
          this.$eventBus.$emit("showAlert", err);
        }
      );
    },
    initAssocation(item) {
      item.editor = 0;
      this.tempEdit = false;
      this.isNotAssociation = false;
    },

    editAssociation(item) {
      if (this.tempEdit) {
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      this.editBackup = Object.assign({}, item);
      this.tempEdit = true;
      item.editor = 1;
    },
    cancelAssociation(item, index) {
      if (item.客戶關聯ID) {
        console.log('取消客戶關聯',item.客戶關聯ID);
        // this.item = this.editBackup;
        this.$set(this.associatedInfo, index, this.editBackup);
        item.editor = 0;
        
      } else {
        console.log('無取消客戶關聯ID')
        this.associatedInfo.splice(index, 1);
        this.isNotAssociation = false;
      }
      this.tempEdit = false;
    },
    deleAssociation(id) {
      if (id) {
        this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
          if (isOK) {
            this.$store
              .dispatch("xinCRM/客戶關聯刪除", { 客戶關聯ID: id })
              .then(() => {
                this.associatedInfo = this.associatedInfo.filter(
                  (item) => item.客戶關聯ID !== id
                );

                this.$eventBus.$emit("showAlert", "已刪除關聯");
                this.$emit("updateList");
              });
          }
        });
      } else {
        //刪掉空資料
        this.associatedInfo = this.associatedInfo.filter(
          (item) => item.id !== id
        );
      }
    },

    adjustZipcodes() {
      this.twAdd.zipcodes.forEach((zipcode) => {
        if (zipcode.id.includes(zipcode.county)) {
          zipcode.id = zipcode.id.replace(zipcode.county, "");
        }
      });
    },
    updateZipcodes() {
      this.data.區域 = ""; // 清空選擇的區域
    },

    relativeSubmit() {
      /* const lastAccount = this.data.linkedCustomList[this.data.linkedCustomList.length - 1];
      if (lastAccount.關聯類型 === '' || lastAccount.關聯資料 === '') {
          this.$eventBus.$emit("showAlert", '新增的關聯帳號類型和內容不能為空');
          return;
      } */
    },

    checkData() {
      if (!this.data.聯絡電話) {
        this.$eventBus.$emit("showAlert", "請填寫聯絡電話");
        return false;
      }

      const phoneRegex = /^((\+8869\d{8}))$/;
      if (!phoneRegex.test(this.data.聯絡電話)) {
        this.$eventBus.$emit("showAlert",
          "請填寫正確'聯絡電話'格式: ex:+8869xxxxxxxx");
        return false;
      }

      if (!this.data.真實姓名) {
        this.$eventBus.$emit("showAlert", "請填寫真實姓名");
        return false;
      }

      if (
        (this.data.縣市 && !this.data.區域) ||
        (this.data.地址 && !this.data.區域)
      ) {
        this.$eventBus.$emit("showAlert", "請填寫縣市區域");
        return;
      }

      return true;
    },

    submit() {
      console.log(this.data);

      if (!this.checkData()) {
          return;
      }
      console.log('checkData pass');
      
      // let apiUrl = '';
      let apiUrl = this.isNew ? "客戶資料新增" : "客戶資料修改";

      this.$store.dispatch("xinCRM/" + apiUrl, this.data).then(
        (res) => {
          this.$refs.customerDialog.hide();
          this.$eventBus.$emit("showAlert", "更新成功！");
          this.$emit("updateList");

          this.isNotAssociation = false;
          this.data = {}; //data init

          // console.log(res);
        },
        (message) => {
          this.$eventBus.$emit("showAlert", message);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  th,
  td {
    position: relative;
  }
}

.btn-add {
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px 10px;
}

.td-add {
  & > div {
    padding-left: 0;
    padding-right: 10px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.btn-td {
  padding-left: 0;
  padding-right: 0;

  .btn {
    width: 48%;
  }
}

.input-date {
  border: 1px solid #d2d6de;
  background: transparent;
  box-shadow: none;
  /* margin-top: 1px; */
  padding: 5px;
  outline: none;
  width: 100%;
}
</style>