<template>
    <div class="with-border-top" style="padding: 12px 5px;background-color: white;">
        <div class="input-group sendMsg_group">
            <textarea id="inputArea" ref="inputArea" class="form-control input_textarea" rows="10" :placeholder=提示訊息
                v-model="msg" :disabled="!是否可發訊息" v-on:keydown.enter.exact="keyEnter"
                v-on:input="inputEvent"></textarea>
            <div class="input-group-btn">
                <button ref="sendBtn" type="button" class="btn btn-warning btn-flat sendMsg" :disabled="!是否可發訊息"
                    @click="SendChatMessage">
                    <i class="fa fa-send"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {
    FixChatResize,
} from "@/store/MessageSystem/messageSystemLib";
export default {
    components: {},
    props: {
        是否可發訊息: {
            type: Boolean
        },
        提示訊息: {
            type: String
        },
    },
    data() {
        return {
            msg: "",
        };
    },
    watch: {
        msg(nowV, oldV) {
            this.$emit("UpdChatMessage", nowV);
        },
    },
    mounted() {
        FixChatResize();
        this.$nextTick(() => {
            this.updateInputHeight(this.$refs.inputArea)
        });
    },
    methods: {
        InitMsg(msg) {
            this.msg = msg;
        },
        SendChatMessage() {
            if (!this.是否可發訊息) {
                return;
            }
            if (this.msg.trim() == "") {
                return;
            }
            this.$emit("SendChatMessage", this.msg);
            this.msg = "";
            this.$nextTick(() => {
                this.updateInputHeight(this.$refs.inputArea);
                $(".input-group-btn .sendMsg").removeClass("focus");
            });

        },
        keyEnter(event) {
            if (event.keyCode == 13) {
                this.SendChatMessage();
                event.cancelBubble = true;
                event.preventDefault();
                event.stopPropagation();
            }
        },
        addCanMessag(msg) {
            if (!this.是否可發訊息) {
                return;
            }
            this.msg = msg;
            this.$nextTick(() => {
                this.updateInputHeight(this.$refs.inputArea);
                if (msg != "") {
                    this.$refs.inputArea.focus();
                    this.$refs.sendBtn.classList.add("focus");
                } else {
                    this.$refs.sendBtn.classList.remove("focus");
                }
            });
        },
        inputEvent(e) {
            this.updateInputHeight(e.target);
            if (e.target.value.length > 0) {
                this.$refs.sendBtn.classList.add("focus");
            } else {
                this.$refs.sendBtn.classList.remove("focus");
            }

        },
        updateInputHeight(target) {
            target.style.height = "";//這行要加，不然內容變少時，不會復原
            target.style.height = /*target.scrollHeight >= 76 ? "76px" :*/ target.scrollHeight + "px";
            let h = FixChatResize(target.scrollHeight);
            target.style.height = h + "px";
        }
    },
};
</script>
<style src="@/assets/css/default/message.css" lang="css" scoped />
<style scoped></style>