export default class {
    static bannerType = {
        1: "大廳輪播",
        2: "活動公告",
    };
    static bannerTag = {
        1: "活動",
        2: "公告",
    };
    static bannerBadge = {
        1: "不顯示",
        2: "NEW!",
        3: "HOT!",
    };
    static marqueeType = {
        0: "緊急公告",
        1: "一般公告",
    }
    static marqueeState = {
        0: "草稿",
        1: "立即發送",
        2: "已刪除",
        3: "排程發送",
    };
    static notificationState = {
        發送對象: {
            1: "全用戶",
            2: "限定用戶",
            3: "iOS",
            4: "Android",
        },
        是否登入: {
            1: "不限",
            2: "是",
            3: "否"
        },
        狀態: {
            1: "未發出",
            2: "已發出",
            3: "已取消",
        }
    };
    static notificationLogin = {
        1: "不限",
        2: "不限",
        3: "iOS",
        4: "Android",
        5: "全用戶",
        6: "全用戶"
    };
    static gamePlatform = {
        0: "APK",
        1: "Android",
        2: "iOS"
    }
    static gameAction = {
        0: "NONE",
        // 1: "段位匹配", //移除
        2: "好友對決",
        3: "個人檔案",
        4: "背包",
        5: "成就",
        6: "排行榜",
        7: "儲值",
        8: "休閒房",
        9: "雀幣爭霸戰",
        10: "小遊戲",
        11: "兌換",
        12: "俱樂部",
        13: "每日簽到",
        14: "在線獎勵",
        15: "任務"
    };
    static gameLevel = {
        0: "新人",
        1: "9級",
        2: "8級",
        3: "7級",
        4: "6級",
        5: "5級",
        6: "4級",
        7: "3級",
        8: "2級",
        9: "1級",
        10: "一段",
        11: "二段",
        12: "三段",
        13: "四段",
        14: "五段",
        15: "六段",
        16: "七段",
        17: "八段",
        18: "九段",
        19: "十段",
        20: "鳳皇",
    };
    static mailSender = {
        1: "麻將之星營運團隊",
        2: "麻將之星官方客服"
    };
    static mailState = {
        '-1': "所有狀態",
        '0': "草稿",
        '1': "等待中",
        '2': "已發送",
        '3': "已刪除"
    };
    static serviceState = {
        '-1': "處理進度",
        '0': "未回覆",
        '1': "草稿",
        '2': "已回覆",
        '3': "不需回復",
    };
    static playerAction = {
        0: "經驗值",
        1: "牌技",
        2: "貨幣",
        3: "物品",
        4: "成就",
        5: "成就條件",
        6: "登入",
        7: "登出",
        8: "段位賽成局",
        9: "好友對決成局",
        10: "雀幣爭霸戰成局",
        11: "休閒桌成局",
        12: "報名雀幣爭霸戰",
        13: "俱樂部",
        14: "任務進度",
        15: "任務條件",
        16: "閃電戰",
    }
    static gameCoins = {
        0: "雀幣",
        1: "雀銀"
    }
}
