<template>
  <div class="tab-content" ref="RecordService">

    <form class="top-box clearfix"  v-if="this.nickNames.length > 0 ">
      <div class="form-group m-0">
        <label class="control-label pull-left mt-7">查詢區間：</label>
        <div class="input-group date pull-left mb-10 mr-10">
          <div class="input-group-addon fa fa-calendar"></div>
          <DateRangePicker
            :propStartDate.sync="searchData.開始時間"
            :propEndDate.sync="searchData.結束時間"
            :propTimePicker="false"
          />
        </div>
        <div
          class="select-group input-group mb-10 mr-10"
          v-if="tableType === 'customer'"
        >
          <select v-model="searchData.玩家識別值" class="form-control" required>
            <option :value="allNickName" selected>全部角色</option>
            <option v-for="nick in nickNames" :value="nick" :key="nick">
              {{ nick }}
            </option>
          </select>
        </div>
        <button
          type="button" v-if="can服務"
          @click="getServiceInfo()"
          class="btn btn-primary pull-left mb-10"
        >
          <i class="fa fa-search"></i>查詢
        </button>
      </div>

      <button v-if="can服務新增"
        @click="memberService(null, true)"
        type="button"
        class="btn btn-warning pull-right mb-10 ml-10"
      >
        <i class="fa fa-plus"></i> 新增服務
      </button>
    </form>

    <div class="table-responsive mb-10">
      <table class="table table-bordered text-center">
        <thead>
          <tr class="bg-gray">
            <th style="min-width: 50px; width: 5%">編號</th>
            <th style="min-width: 100px; width: 5%">角色暱稱</th>
            <th style="min-width: 110px; width: 5%">狀態</th>
            <th style="min-width: 110px; width: 5%">異動項目</th>
            <th style="min-width: 110px; width: 5%">類別名稱</th>
            <th style="min-width: 150px; width: 15%">內容</th>
            <th style="min-width: 100px; width: 10%">操作時間</th>
            <th style="min-width: 110px; width: 5%">操作人員</th>
            <th style="min-width: 110px; width: 10%">功能</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="10">查詢中</td>
          </tr>
          <tr v-else-if="message">
            <td colspan="10">{{ message }}</td>
          </tr>
          <tr v-else-if="total === 0">
            <td colspan="10">查無紀錄</td>
          </tr>
          <tr v-else v-for="(item, index) in list" :key="item.日期">
            <td>{{ index + 1 }}</td>
            <td>{{ item.玩家識別值 || " - " }}</td>
            <td>{{ item.處理狀態 ? "已處理" : "處理中" }}</td>
            <td>{{ item.異動項目 }}</td>
            <td>
              {{ item.異動動作 }}
              <span v-if="serviceTypesDisabled.includes(item.異動動作)">
                (停用)
              </span>
            </td>
            <td style="white-space: pre-wrap">{{ item.新內容 }}</td>
            <td>{{ uiDate(item.異動時間) }}</td>
            <td>{{ item.異動者 }}</td>
            <td>
              <button
                v-if="item.處理狀態 === 0 && can服務修改"
                class="btn btn-primary mr-5 mb-5"
                @click="memberService(item, false)"
              >
                修改
              </button>

              <button v-if="can服務刪除"
                type="button"
                class="btn btn-danger mb-5"
                @click="deletePlayer(item)"
              >
                刪除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="total > searchData.pageSize" class="text-center mt-10">
      <Pagination
        :pageLimitCount="searchData.pageSize"
        :total="total"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <MemberServiceDialog
      ref="MemberServiceDialog"
      :types="serviceTypes"
      :nickNames="nickNames"
      :players="players"
      @updateList="onService()"
    />
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import MemberServiceDialog from "../components/MemberServiceDialog";
import dfn from "@/views/projects/xin/_Define";

export default {
  props: ["types", "endTypes"],
  components: {
    moment,
    DateRangePicker,
    Pagination,
    utils,
    MemberServiceDialog,
  },
  data() {
    return {
      searchData: {
        玩家識別值: "",
        門號: "",
        處理狀態: -1,
        開始時間: new moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      nickNames: [],
      allNickName: "",
      players: [],
      tableType: "", //customer, member

      message: "", 
      loading: false,
      total: 0, 
      list: [],
      serviceTypes: {},
      serviceTypesDisabled: [],

      can服務: this.$user.hasPermission(dfn.permission.會員服務記錄查詢),
      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      can服務修改: this.$user.hasPermission(dfn.permission.會員服務記錄修改),
      can服務刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
    };
  },

  mounted() {
    this.show();
    this.serviceTypes = this.types;
    this.serviceTypesDisabled = this.endTypes;

    // console.log(this.serviceTypesDisabled); 
  },

  methods: {
    show(user) {
      if (user) {
        this.players = user;

        if (this.players.客戶資料ID) {
          // console.log("customer:", this.players.客戶資料ID);
          this.nickNames = user.players.map((player) => player.暱稱);
          this.allNickName = this.nickNames.join(",");
          this.searchData.玩家識別值 = this.allNickName;
          this.searchData.門號 = this.players.聯絡電話;
          this.tableType = "customer";
        } else {
          // console.log("member:", this.players.暱稱);
          let nick = this.players.暱稱;
          this.searchData.玩家識別值 = nick;
          this.nickNames.push(nick);
          this.tableType = "member";
        }

        this.getServiceInfo();
      }
    },

    getServiceInfo() {
      this.loading = true;
      this.$store.dispatch("xinCRM/會員服務記錄查詢", this.searchData).then(
        (res) => {
          // console.log(res);
          this.list = res.datas || [];
          this.total = res.total || 0;
          this.message = "";
          this.loading = false;
        },
        (err) => {
          this.list = [];
          this.total = 0;
          this.message = err;
          this.loading = false;
        }
      );
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      console.log(this.searchData.pageNum, this.searchData.pageSize);
      this.getServiceInfo();
    },

    memberService(player, isAdd) {
      this.$refs.MemberServiceDialog.show(player, isAdd);
    },

    onService() {
      this.getServiceInfo();
    },

    deletePlayer(item) {
      console.log(item.玩家異動紀錄ID);
      this.$eventBus.$emit(
        "showConfirm",
        "確定要刪除這則服務記錄嗎?",
        (isOK) => {
          if (isOK) {
            this.$store
              .dispatch("xinCRM/會員服務記錄刪除", {
                玩家異動紀錄ID: item.玩家異動紀錄ID,
              })
              .then(
                (res) => {
                  this.$eventBus.$emit("showAlert", "刪除成功！");
                  this.getServiceInfo();
                },
                (err) => {
                  this.$eventBus.$emit("showAlert", err);
                }
              );
          }
        }
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>
