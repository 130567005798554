<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-bullhorn"></i>站長推薦</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">站長推薦</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="select-group mr-10 mb-10">
              <select class="form-control" v-model="searchData.LanguageCode" @change="getDataList()">
                <option v-for="lang in langs" :value="lang.id">{{ lang.id + ' ' + lang.name }}</option>
              </select> 
            </div>
            <div class="pull-left mt-5">
              <span class="control-label" for="checkActive">
                <strong>此功能區塊是否開啟：</strong>
              </span>
              <div class="ckbx-style-8 ckbx toggleInput mr-5 mb-2">
                <input type="checkbox" v-model="isHidden" :false-value="1" :true-value="0" id="checkActive" name="ckbx-style-8"
                  @change="changeStatus(item)" />
                <label for="checkActive"></label>
              </div>
              {{ isHidden ? '關閉' : '開啟' }}
            </div>

            <router-link v-if="can新增" class="btn btn-block btn-warning btn-add mb-10" :to="{name:'AWH5WebRecommendEdit', params:{id: 'New'}}">
              <i class="fa fa-plus"></i> 新增次分類
            </router-link>
          </div>

          <div class="table-responsive">
            <table class="table table-hover table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 3%">排序</th>
                  <th style="min-width:120px; width: 5%">次分類名稱</th>
                  <th style="min-width:200px; width:20%">指定遊戲</th>
                  <th style="min-width: 80px; width: 5%">遊戲數量</th>
                  <th style="min-width:100px; width: 5%">更新日期</th>
                  <th style="min-width:100px; width: 5%">操作者</th>
                  <th style="min-width: 80px; width: 3%">功能</th>
                </tr>
                <tr v-if="message"><td colspan="9">{{ $t(message) }}</td></tr>
                <tr v-else-if="!total"><td colspan="9">查無資料</td></tr>
                <tr v-else v-for="(item) in datas" :key="item.RecId">
                  <td class="text-nowrap">
                    <fieldset :disabled="!can修改" style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control" v-model.number="item.Sort" min="1" :disabled="item.isStop == 1" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add btn-upDate" @click="updateOrder(item)" :disabled="item.isStop == 1">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </fieldset>
                  </td>
                  <td>{{ item.CategoryName }}</td>
                  <td><TagSelect :value="getTags(item)" readonly></TagSelect></td>
                  <td>{{ item.AssignedGamesModel.length }}</td>
                  <td>{{ item.UpdateTime  || item.CreateTime  }}</td>
                  <td>{{ item.UpdateUName || item.CreateUName }}</td>
                  <td>
                    <div style="display:flex;  flex-direction:column; gap:5px">
                      <router-link v-if="can修改" class="btn btn-primary" :to="{name:'AWH5WebRecommendEdit', params:{id: item.RecId}}">
                        <i class="fa fa-fw fa-pencil"></i> 修改
                      </router-link>
                      <button v-if="can刪除" class="btn btn-danger" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>
      {{ datas }}
      {{ gameList }}
    </pre> -->
  </div>
</template>

<script>
/* eslint-disable */
// import Pagination from "@/components/Pagination";
import TagSelect from "@/views/projects/XSG/components/TagSelect";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import { objToPascalCase } from "@/assets/js/app-utils";
import { WebRecommendAPI as api, WebRecommendPermission as auth } from "@/views/projects/AWH5/_API";


export default {
  components: {
    // Pagination,
    TagSelect
  },
  props: {
    id: "",
  },
  data() {
    return {
      langs: types.projectData.langs,
      lang: types.projectData.langs[0].id,

      searchData: {
        "RecId": -1,   // -1 = 全部
        "LanguageCode": "zh-TW"
      },
      total: 0, //總筆數
      datas: [],
      message: "",
      pageNum: 1,
      pageSize: 10, //每頁顯示筆數
      isHidden: 0,

      gameList: {},

      can新增: this.$user.hasPermission(auth.Create), 
      can修改: this.$user.hasPermission(auth.Update),
      can刪除: this.$user.hasPermission(auth.Delete),
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
    // this.getGameList();
  },
  computed:{
    getTags: () => {
      return function (item){
        let tags = item.AssignedGamesModel.map(i => {
          if (this.gameList[i.GId]) return this.gameList[i.GId][this.searchData.LanguageCode] || this.gameList[i.GId]["zh-TW"] || i.GId
          else return "";
        });
        if (!tags) return [];
        else return tags;
      }
    } ,
  },  
  methods: {
    getDataList() {
      let waitLoad = setTimeout(()=>{this.message = "查詢中"}, 100);
      
      //列表
      this.$store.dispatch("xsa/post", {
        path: api.QueryList,
        data: Object.assign(this.searchData, {RecId: -1}),
      }).then((result) => {
          clearTimeout(waitLoad);
          this.datas = objToPascalCase(result) || [];
          this.isHidden =  this.datas[0] ? this.datas[0].IsHidden : 0;
          for (let data of this.datas){
            for (let game of data.AssignedGamesModel){
              if (this.gameList[game.GId]) this.$set(this.gameList[game.GId], this.searchData.LanguageCode, game.Name);
              else this.$set(this.gameList, game.GId, {[this.searchData.LanguageCode]: game.Name});
            }
          }
          this.total = this.datas.length;
          this.message = "";
      }, err => {
        clearTimeout(waitLoad);
        this.message = err;
        this.total = 0;
        this.datas = [];
      });

    },
    getGameList(){
      this.$store.dispatch("xsw/遊戲清單列表查詢", {
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          let dict = {}; 
          for (let game of data){
            dict[game.gid] = (()=>{
              let obj = {};
              for (let x of game.gameListLanguages){
                obj[x.lang] = x.gameName;
              }
              return obj
            })()
          }
          this.gameList = dict;
        }
      });
    },

    //*---- 排序
    updateOrder(item) {
      this.$store.dispatch("xsa/post", {
        path: api.Update,
        data: item,
      }).then((result) => {
        this.$eventBus.$emit("showAlert", "排序更新成功！");
        this.getDataList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    changeStatus(){
      this.$eventBus.$emit("showConfirm", "確定要更新功能區塊狀態嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Update,
            data: { RecId: 0, IsHidden: this.isHidden} // 若要開啟/關閉站長推薦區塊，則RecId帶0，IsHidden帶 1：隱藏 0：顯示
          }).then(res => {
            this.$eventBus.$emit("showAlert", "更新成功！");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
        else {
          this.isHidden = +!this.isHidden;
        }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Delete,
            data: { RecId: item.RecId }
          }).then((result) => {
            this.$eventBus.$emit("showAlert", "刪除成功！");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },
  },
};
</script>