<template>
    <div id="AnnouncementEdit">
        <section class="content-header">
            <h1>遊戲介紹編輯</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>遊戲介紹列表</li>
                <li class="active">遊戲介紹編輯</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">編輯內容</h4>
                        <p class="pull-left color-red ml-5 mt-10">
                            *請依序編輯內容
                        </p>
                        <button
                            @click="backList()"
                            class="btn btn-block btn-default btn-cog"
                            type="button"
                        >
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>

                    <form class="form-horizontal" name="announcementForm">
                        <div class="box-body">
                            <!-- sr-only -->
                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="inputTitle"
                                >
                                    <span class="text-red">*</span>Gid：
                                </label>
                                <div class="col-sm-10">
                                    <label class="control-label">{{
                                        edit.gid
                                    }}</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="inputTitle"
                                >遊戲館ID：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        id="inputTitle"
                                        required="required"
                                        type="text"
                                        v-model="edit.遊戲編號"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 遊戲分類：
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        class="form-control select-part"
                                        v-model="edit.遊戲分類"
                                        @change="updateTypeClassID"
                                    >
                                        <option disabled="disabled" value>
                                            請選擇分類
                                        </option>
                                        <option
                                            :key="index"
                                            :value="item.classTypeId"
                                            :class_id="item.classId"
                                            v-for="(item,
                                            index) in newTypeClass"
                                        >
                                            {{ item.className }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="inputTitle"
                                >
                                    <span class="text-red">*</span>中文名稱：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        id="inputTitle"
                                        required="required"
                                        type="text"
                                        v-model="edit.遊戲名稱"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="inputEngTitle"
                                >
                                    <span class="text-red">*</span>英文名稱：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        id="inputEngTitle"
                                        required="required"
                                        type="text"
                                        v-model="edit.遊戲英文名稱"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="summary"
                                >
                                    <span class="text-red">*</span>遊戲機制：
                                </label>
                                <div class="col-sm-10">
                                    <textarea
                                        class="form-control"
                                        id="summary"
                                        v-model="edit.遊戲機制"
                                        placeholder="內容建議100字內"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="summary"
                                >
                                    <span class="text-red">*</span>遊戲簡介：
                                </label>
                                <div class="col-sm-10">
                                    <textarea
                                        class="form-control"
                                        id="summary"
                                        v-model="edit.遊戲簡介"
                                        placeholder="內容建議100字內"
                                    ></textarea>
                                </div>
                            </div>

                            <div v-if="edit.遊戲分類 && edit.遊戲英文名稱">
                                <div class="form-group image-part">
                                    <label
                                        class="col-sm-2 control-label"
                                        for="logo1"
                                    >
                                        <span class="text-red">*</span
                                        >格狀Logo：
                                    </label>
                                    <div class="col-sm-9">
                                        <div
                                            class="pic d-inline chooseBox"
                                            v-if="logo1"
                                        >
                                            <div
                                                class="img-close fa fa-fw fa-close"
                                                title="刪除檔案"
                                                @click="DeletePic(logo1.id)"
                                            ></div>
                                            <img
                                                draggable="false"
                                                :src="logo1.filesUrl"
                                                style="width: 100px"
                                            />
                                        </div>
                                        <input
                                            accept="image/*"
                                            id="logo1"
                                            type="file"
                                            @change="
                                                onFileChange($event, 'logo1')
                                            "
                                            v-if="!logo1"
                                        />

                                        <div class="help-block">
                                            尺寸188*208px,背景透明png
                                        </div>
                                        <div class="help-block">
                                            <!-- <b v-if="logo1"
                                                >重選檔案，請先刪除圖片</b
                                            > -->
                                            <span v-if="isCreate"
                                                >(圖片綁定貼文，新增圖片即新增貼文)</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group image-part">
                                    <label
                                        class="col-sm-2 control-label"
                                        for="logo2"
                                    >
                                        <span class="text-red">*</span
                                        >長形Logo：
                                    </label>
                                    <div class="col-sm-9">
                                        <div
                                            class="pic d-inline chooseBox"
                                            v-if="logo2"
                                        >
                                            <div
                                                class="img-close fa fa-fw fa-close"
                                                title="刪除檔案"
                                                @click="DeletePic(logo2.id)"
                                            ></div>
                                            <img
                                                draggable="false"
                                                :src="logo2.filesUrl"
                                                style="width: 150px"
                                            />
                                        </div>
                                        <input
                                            accept="image/*"
                                            id="logo2"
                                            type="file"
                                            @change="
                                                onFileChange($event, 'logo2')
                                            "
                                            v-if="!logo2"
                                        />

                                        <div class="checkbox">
                                             <label class="check-game">
                                                <iCheckCheckBox
                                                    v-bind:propChecked="
                                                        edit.是否出現首頁
                                                    "
                                                    v-on:update:checked="edit.是否出現首頁 = $event['checked']"
                                                />顯示於首頁最新遊戲列表中
                                            </label>
                                        </div>

                                        <div class="checkbox">
                                            <label class="check-name">
                                                <iCheckCheckBox
                                                    v-bind:propChecked=" edit.是否隱藏遊戲名稱 "
                                                    v-on:update:checked="edit.是否隱藏遊戲名稱 =$event['checked'] "
                                                />隱藏遊戲名稱
                                            </label>
                                        </div>
                                        
                                        <div class="help-block">
                                            尺寸222*448px, 背景透明png
                                        </div>
                                        <div class="help-block">
                                            <!-- <b v-if="logo2"
                                                >重選檔案，請先刪除圖片</b
                                            > -->
                                            <span v-if="isCreate"
                                                >(圖片綁定貼文，新增圖片即新增貼文)</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group image-part">
                                    <label
                                        class="col-sm-2 control-label"
                                        for="trademark"
                                    >商標Logo
                                    </label>
                                    <div class="col-sm-9">
                                        <div
                                            class="pic d-inline chooseBox"
                                            v-if="trademark"
                                        >
                                            <div
                                                class="img-close fa fa-fw fa-close"
                                                title="刪除檔案"
                                                @click="DeletePic(trademark.id)"
                                            ></div>
                                            <img
                                                draggable="false"
                                                :src="trademark.filesUrl"
                                                style="width: 100px"
                                            />
                                        </div>
                                        <input
                                            accept="image/*"
                                            id="trademark"
                                            type="file"
                                            @change="
                                                onFileChange($event, 'trademark')
                                            "
                                            v-if="!trademark"
                                        />

                                        <div class="help-block">
                                            尺寸123*67px,背景透明png
                                        </div>
                                        <div class="help-block">
                                           <!-- <b v-if="trademark"
                                                >重選檔案，請先刪除圖片</b
                                            > -->
                                            <span v-if="isCreate"
                                                >(圖片綁定貼文，新增圖片即新增貼文)</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    遊戲影片ID：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        type="text"
                                        v-model="edit.遊戲影片ID"
                                        placeholder="填入代碼即可。ex:hjXrL7CuAvc"
                                    />
                                    <div class="help-block">
                                        ID位置:
                                        https://www.youtube.com/watch?v=<span
                                            class="color-red"
                                            >8zf6Wi2BtsE</span
                                        ><br />
                                        v= 後面文字 8zf6Wi2BtsE 就是影片ID
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" v-if="edit.遊戲影片ID">
                                <label class="col-sm-2 control-label">
                                    預覽：
                                </label>
                                <div class="col-sm-8">
                                    影片連結： {{ videoUrl }}
                                    <div class="video-container">
                                        <iframe
                                            width="700"
                                            height="395"
                                            :src="videoUrl"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="unboxingText"
                                >
                                    開箱文標題：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        id="unboxingText"
                                        type="text"
                                        v-model="edit.開箱文文字"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="unboxingLink"
                                >
                                    開箱文連結：
                                </label>
                                <div class="col-sm-10">
                                    <input
                                        class="form-control"
                                        id="unboxingLink"
                                        type="text"
                                        v-model="edit.開箱文連結"
                                    />
                                </div>
                            </div>

                            <div class="form-group image-part">
                                <label
                                    class="col-sm-2 control-label"
                                    for="infoImg"
                                >
                                    <span class="text-red">*</span>遊戲說明圖：
                                </label>
                                <div
                                    class="col-sm-10"
                                    v-if="edit.遊戲分類 && edit.遊戲英文名稱"
                                >
                                    <input
                                        multiple="multiple"
                                        accept="image/*"
                                        id="infoImg"
                                        type="file"
                                        @change="
                                            onFileChange($event, 'infoImg')
                                        "
                                    />
                                    <div class="help-block mb-10-md">
                                        *圖片格式:寬930，背景透明png。<br />
                                        <span v-if="infos.length > 0"
                                            >*拖移可改變圖片順序。 * 建議數量
                                            <span class="color-red">15</span>
                                            張內。
                                            (系統自帶透明度灰底，方便觀看圖片內容)
                                        </span>
                                        <span v-if="isCreate"
                                            >(圖片綁定貼文，新增圖片即新增貼文)</span
                                        >
                                    </div>

                                    <div
                                        class="row gameGallery mt-10"
                                        v-if="infos"
                                    >
                                        <draggable
                                            animation="500"
                                            :list="infos"
                                            :disabled="!enabled"
                                            class="list-group gameGallery"
                                            ghost-class="ghost"
                                            @start="dragging = true"
                                            @end="
                                                dragging = false;
                                                getGalleryOrder();
                                            "
                                        >
                                            <div
                                                class="col-sm-4 col-xs-6 chooseBox"
                                                v-for="(item, index) in infos"
                                                :key="index"
                                            >
                                                <div
                                                    class="img-close fa fa-fw fa-close"
                                                    :data-id="item.id"
                                                    title="刪除檔案"
                                                    @click="DeletePic(item.id)"
                                                ></div>
                                                <img
                                                    :src="item.filesUrl"
                                                    :data-id="item.id"
                                                />
                                                <p>
                                                    id:{{ item.id }} 順序:{{
                                                        item.info
                                                    }}
                                                </p>
                                            </div>
                                        </draggable>
                                    </div>
                                </div>
                                <div class="col-sm-10 pt-10" v-else>
                                    (請填寫遊戲分類,遊戲名稱 ，再上傳圖片)
                                </div>
                            </div>

                            <!-- 遊戲操作說明 (應該 value 是 id)-->
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span>
                                    遊戲操作說明：
                                </label>
                                <div class="col-sm-10">
                                    <select
                                        class="form-control select-part"
                                        v-model="edit.遊戲操作說明"
                                    >
                                        <option disabled="disabled" value>
                                            請選擇操作說明版本
                                        </option>
                                        <option
                                            :key="item.gid"
                                            :value="item.gid"
                                            v-for="item in operationTypes"
                                        >
                                            {{ item.version }}
                                        </option>
                                    </select>
                                    <div class="help-block">
                                        *進入
                                        <router-link
                                            :to="'/Xin/GameOperationList'"
                                            target="_blank"
                                            >遊戲操作說明列表</router-link
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="Limits"
                                    >遊戲館限制：
                                </label>
                                <div class="col-sm-10">
                                    <div class="Limits editor">
                                        <tinymce v-model="edit.遊戲館限制" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0" id="DisconnectionRules">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span>斷線規則 -
                                </label>

                                <div class="col-sm-10">
                                    <div class="w100  disconContnet"
                                        v-for="(item, index) in edit.斷線規則"
                                        :key="index">

                                        <label class="control-label pt-0 mb-10 mr-5">
                                            <span class="text-red">*</span>因素： 
                                        </label>

                                        <textarea
                                            class="form-control mb-10 d-c"
                                            placeholder="因素"
                                            v-model="item.標題"
                                            rows="3"
                                        ></textarea>

                                        <div class="clear w100"></div>

                                        <label class="control-label pt-0 mb-10 mr-5">
                                            <span class="text-red">*</span>處理： 
                                        </label>

                                        <textarea
                                            class="form-control mb-10 d-c"
                                            placeholder="處理"
                                            v-model="item.內容"
                                            rows="3"
                                        ></textarea>
                                        <button
                                            class="btn btn-danger mb-10"
                                            type="button"
                                            @click="deleteDiscon(index)"
                                             v-show="index !== 0"
                                        >
                                            刪除
                                        </button> 
                                    </div>
                                                                       
                                </div>
                            </div>

                            <div class="form-group mb-30">
                                <label class="col-sm-2 control-label">
                                </label>

                                 <div class="col-sm-10  disconContnet">
                                     <button
                                        class="btn btn-default"
                                        type="button"
                                        @click="addDiscon()"
                                    >
                                        <i class="fa fa-plus"></i>&nbsp;新增規則
                                    </button>
                                 </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label"
                                    >description：
                                </label>
                                <div class="col-sm-9">
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="網頁的描述"
                                        v-model="edit.description"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label"
                                    >keyword：
                                </label>
                                <div class="col-sm-9">
                                    <input
                                        class="form-control"
                                        placeholder="網頁的關鍵字"
                                        type="text"
                                        v-model="edit.keyword"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label
                                    class="col-sm-2 control-label"
                                    for="inputLink"
                                >
                                    <span class="text-red">*</span> 排序：
                                </label>
                                <div class="col-sm-9">
                                    <input
                                        class="form-control"
                                        id="inputOrderNum"
                                        type="number"
                                        v-model="edit.排序"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 發佈：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton
                                            checkValue="true"
                                            message="上架"
                                            name="radioMode"
                                            v-model="edit.是否發佈"
                                        />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton
                                            checkValue="false"
                                            message="下架"
                                            name="radioMode"
                                            v-model="edit.是否發佈"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span>
                                    正式開始時間：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker
                                                    :propValue.sync="啟用時間"
                                                />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input
                                                class="form-control timepicker"
                                                id="StartTime"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span>
                                    測試開始時間：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker
                                                    :propValue.sync="測試時間"
                                                />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input
                                                class="form-control timepicker"
                                                id="testStartTime"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-footer">
                            <div class="btns">
                                <button
                                    class="btn btn-warning btn-lg"
                                    style="margin-right: 10px"
                                    type="button"
                                    @click="Save(true)"
                                >
                                    預覽
                                </button>

                                <button
                                    class="btn btn-primary btn-lg"
                                    type="button"
                                    @click="Save(false)"
                                >
                                    確認送出
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import draggable from "vuedraggable";

export default {
    components: {
        iCheckCheckBox,
        iCheckRedioButton: iCheckRedioButton,
        DatePicker,
        moment,
        tinymce,
        draggable
    },
    delimiters: ["${", "}"],
    name: "app",
    props: {
        id: ""
    },
    data() {
        return {
            isEdit: false,
            isCreate: false,
            lang: "zh-tw",

            edit: {
                gid: "", //遊戲介紹Id
                遊戲分類: "",
                遊戲名稱: "", // 遊戲中文名稱
                遊戲英文名稱: "", // 遊戲英文名稱
                遊戲簡介: "",

                是否出現首頁: 1, //是否出現在首頁
                是否隱藏遊戲名稱: 0,
                遊戲影片ID: "",
                開箱文文字: "",
                開箱文連結: "",

                遊戲操作說明: "", //遊戲操作說明 id

                遊戲館限制: "", // 遊戲館限制
                斷線規則: [
                    {標題:"",內容:""}
                ],
                keyword: "",
                description: "",

                正式開始時間: "",
                測試開始時間: "",

                排序: 1, // 排序
                是否發佈: "true", // 發佈

                createUId: this.$store.state.loginUser.EmployeeId,
                createUName: this.$store.state.loginUser.username
            },
            newTypeClass: [],
            typeClassID: "",
            youtubeUrl: "https://www.youtube.com/embed/",
            videoUrl: "",

            啟用時間: new moment()
                .add(+7, "days")
                .format("YYYY-MM-DD 00:00:00"),
            測試時間: new moment().format("YYYY-MM-DD 23:59:59"),

            logo1: null,
            logo2: null,
            trademark: null,
            infos: [],
            infosOld: [],
            infosChange: false, //圖片是否被修改
            dragging: false,
            enabled: true,

            //Operation
            gameOperationSearch: {
                pageNum: 1,
                pageSize: 100 //每頁顯示筆數
            },
            operationTypes: [],

            isPreview: false //預覽
        };
    },
    created() {},
    beforeMount() {
        this.GetBannerClassTypes();
        this.getGameOperationList();

        if (this.$route.params.id > 0) {
            this.isEdit = true;
            this.GetGameIntro();
        } else {
            this.isCreate = true;
            this.edit.gid = utils.getTimeId();
            this.getGameRestriction();
        }
    },
    mounted() {
        $(".timepicker").timepicker({
            showInputs: false
        });

        this.updateTime();

        //濾掉 isDelete=1 的項目
        setTimeout(() => {
            this.newTypeClass = this.gameTypes.datas.filter(
                item => item.isDelete === 0
            );
        }, 200);

        this.clearCatch();
    },
    
    computed: {
        ...mapState({
            gameTypes: state => state.classTypes.queryAll,
            game: state => state.game.query,
            response: state => state.xinGameIntro.response
        }),
    },
    watch: {
        response(value) {
            if (value.status == "1") {
                this.$eventBus.$emit("showAlert", "更新成功!!");
            } else this.$eventBus.$emit("showAlert", "更新失敗!!");
        },

        "edit.遊戲影片ID"() {
            this.videoUrl = this.edit.遊戲影片ID
                ? this.youtubeUrl + this.edit.遊戲影片ID
                : "";
        }
    },
    methods: {
        //取得該遊戲介紹資料
        GetGameIntro() {
            let _id = this.isCreate ? this.edit.gid : this.$route.params.id;

            this.$store
                .dispatch("xinGameIntro/GameIntroQuery", { gid: _id })
                .then(result => {
                    this.edit = result;
                    // this.edit.是否出現首頁 = this.edit.是否出現首頁 ? 1 : 0;
                    if ( this.edit.是否出現首頁 ) {
                        this.edit.是否出現首頁 = 1;
                        this.$nextTick(function () {
                            $(".check-game .icheckbox_flat-blue").click();
                        });
                    }
                    // this.edit.是否隱藏遊戲名稱 = this.edit.是否隱藏遊戲名稱 ? 1 : 0;
                    
                    if ( this.edit.是否隱藏遊戲名稱 ) {
                        this.edit.是否隱藏遊戲名稱 = 1;
                        this.$nextTick(function () {
                            $(".check-name .icheckbox_flat-blue").click();
                        });
                    }

                    this.edit.是否發佈 = this.edit.是否發佈.toString();

                    // this.edit.遊戲操作說明 = this.edit.遊戲操作說明.toString()
                    this.getInitImg(); //files 裡的圖片，分類
                    this.updateTime(); //日期
                    this.updateTypeClassID();

                    console.log('api正式:',this.edit.正式開始時間 , ' ,api測試:' ,this.edit.測試開始時間);

                });

            // console.log('isCreate:', this.isCreate ," ,$route.params.id:",this.$route.params.id
            //   ," ,edit.gid:",this.edit.gid);
            
        },

        //取得 遊戲分類 資料
        GetBannerClassTypes() {
            //分類
            this.$store.dispatch("classTypes/classTypeQueryAll", {
                module: "game",
                lang: "zh-tw",
                isDelete: 0,
                isStop: 0
            });
        },

        updateTypeClassID() {
            let selectedClassTypeId = this.edit.遊戲分類;
            let selectedTypeClass = this.gameTypes.datas.find(
                item => item.classTypeId === selectedClassTypeId
            );

            if (selectedTypeClass) {
                this.typeClassID = selectedTypeClass.classId;
            } else {
                this.typeClassID = ""; // 如果找不到匹配項，可以設定為預設值
            }
        },

        //取得 遊戲操作說明 資料
        getGameOperationList() {
            this.$store
                .dispatch(
                    "xinGameIntro/GameOperationList",
                    this.gameOperationSearch
                )
                .then(result => {
                    this.operationTypes = result.datas || [];
                });
        },

        //img: logo1, logo2, trademark,info gallery, other init
        getInitImg() {
            this.logo1 = this.edit.files.find(file => file.info === "logo1");
            // console.log(this.logo1);

            this.logo2 = this.edit.files.find(file => file.info === "logo2");

            this.trademark = this.edit.files.find(file => file.info === "trademark");

            this.infos = this.edit.files.filter(
                item => item.info !== "logo1" && item.info !== "logo2" && item.info !== "trademark"
            );
            this.infos.sort((a, b) => a.info - b.info);
            this.infosOld = utils.deepCloneObj(this.infos);
            
            if (!this.edit.斷線規則 || this.edit.斷線規則.length === 0) {
                this.edit.斷線規則 = [{ 標題: "", 內容: "" }];
            }

        },

        getGalleryOrder() {
            console.log("run getGalleryOrder");
            // this.infos.sort((a, b) => a.info - b.info);

            this.infos.forEach((info, index) => {
                info.info = (index + 1).toString();
            });
        },

        infoGalleryUpdate() {
            this.infos.forEach((info, i) => {
                let ob = {
                    id: info.id,
                    info: info.info
                };

                setTimeout(() => {
                    this.$store.dispatch("xinGameIntro/xinUpdateImg", ob);
                }, i * 90);
            });
        },

        onFileChange(evt, item) {
            var files = evt.target.files;

            if (files.length > 0) {
                var isCheckType = true; //是否檢查圖片副檔名
                var fileSize = files[0].size;
                var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
                var fileName = files[0].name;

                if (isCheckType && !re.test(fileName)) {
                    this.$eventBus.$emit(
                        "showAlert",
                        "您選擇的檔案格式不符，請重新選擇。"
                    );
                    return;
                }

                if (fileSize > 300000) {
                    this.$eventBus.$emit(
                        "showAlert",
                        "您選擇的檔案超過上限 300KB，請重新選擇。"
                    );
                    return;
                }
            }

            let AdPic = document.getElementById(item);
            let info =
                item == "logo1" || item == "logo2" || item == "trademark"
                    ? item
                    : this.infos.length + 1;
            // console.log("info:", info);
            let folderName = 'GameIntro';
            // let folderName = this.edit.遊戲分類;

            if (!this.isEdit) {
                //新增 creat
                // console.log("run creat");
                this.isEdit = true;
                this.isCreate = false;
                this.saveCommon();
                this.$store.dispatch("xinGameIntro/GameIntroCreat", this.edit);

                setTimeout(() => {
                    this.uploadFile(
                        AdPic.files[0],
                        this.edit.gid,
                        info,
                        folderName
                        // this.edit.遊戲分類
                    );

                }, 500);
            } else {
                //編輯
                // console.log("has ID. run upload img");
                this.uploadFile(
                    files, // AdPic.files[0],
                    this.edit.gid,
                    info,
                    folderName
                    // this.edit.遊戲分類
                );
            }
        },

        uploadFile(files, id, info, folderName, multi = true) {
            console.log(id, info, folderName);
            let data = new FormData();

            for (var i = 0; i < files.length; i++) {
                data.append("files", files[i]);
            }

            data.append("files", files);
            data.append("moduleId", id);
            data.append("Info", info);
            data.append("module", folderName);
            data.append("multi", multi);

            this.$store
                .dispatch("xinGameIntro/xinUploadImg", data)
                .then(res => {
                    if (res.status != "1") {
                        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
                    } else {
                        this.getImgs();
                    }
                });
        },

        getImgs() {
            // let _id = this.isCreate ? this.edit.gid : this.$route.params.id;
            this.$store
                .dispatch("xinGameIntro/GameIntroQuery", { gid: this.edit.gid })
                .then(result => {
                    this.edit.files = result.files;
                    this.getInitImg();
                    this.getGalleryOrder();
                    if(!this.edit.斷線規則) this.edit.斷線規則 = [{標題:"",內容:""}]
                });
            
        },

        DeletePic(item) {
            let delOb = { id: item };
            console.log(delOb);

            this.$store.dispatch("xinGameIntro/xinDelImg", delOb).then(() => {
                this.getImgs();
            });
        },

        addDiscon() {
            this.edit.斷線規則.push({標題:"",內容:""});
        },
        deleteDiscon( index) {
            this.edit.斷線規則.splice(index, 1);
        },

        //Date, Time
        updateTime() {
            this.啟用時間 = this.edit.正式開始時間 || this.啟用時間;
            this.測試時間 = this.edit.測試開始時間 || this.測試時間;

            $("#StartTime").val(this.global.getTime(this.啟用時間));
            $("#testStartTime").val(this.global.getTime(this.測試時間));
        },

        editDateUpdate() {
            const StartTime = $("#StartTime").val();
            const testStartTime = $("#testStartTime").val();
            this.啟用時間 = new Date(
                `${this.啟用時間.split(" ")[0]} ${StartTime}`
            );
            this.測試時間 = new Date(
                `${this.測試時間.split(" ")[0]} ${testStartTime}`
            );

            if (StartTime === "" || !this.global.isValidDay(this.啟用時間)) {
                this.$eventBus.$emit("showAlert", "請填寫正式開始時間！");
                return;
            }

            if (
                testStartTime === "" ||
                !this.global.isValidDay(this.測試時間)
            ) {
                this.$eventBus.$emit("showAlert", "請填寫測試開始時間！");
                return;
            }

            this.edit.正式開始時間 = new Date(`${this.啟用時間} UTC`);
            this.edit.測試開始時間 = new Date(`${this.測試時間} UTC`);
        },

        // clear
        clearCatch() {
            this.$store.dispatch("xinGameIntro/GameIntroClear").then(result => {
                console.log("已清除Catch");
            });
        },

        saveCommon() {
            // console.log('saveCommon 前 this.edit.是否發佈:',this.edit.是否發佈);
            this.edit.是否發佈 =
                this.edit.是否發佈 == "true" || this.edit.是否發佈 == true;
            this.edit.是否出現首頁 = this.edit.是否出現首頁 ? true : false;
            this.edit.是否隱藏遊戲名稱 = this.edit.是否隱藏遊戲名稱
                ? true
                : false;
            this.edit.排序 = parseInt(this.edit.排序);



            this.editDateUpdate(); //date

            this.edit.斷線規則 = this.edit.斷線規則.filter(item => item.標題 !== '' || item.內容 !== '');

            // console.log('saveCommon 後 this.edit.是否發佈:',this.edit.是否發佈);
        },

        //送出
        async Save(isPreview) {
            this.isPreview = isPreview;

            if (this.edit.遊戲分類 == "") {
                this.$eventBus.$emit("showAlert", "請選擇遊戲分類");
                return;
            }

            if (this.edit.遊戲名稱 == "") {
                this.$eventBus.$emit("showAlert", "請輸入遊戲中文名稱");
                return;
            }

            if (this.edit.遊戲英文名稱 == "") {
                this.$eventBus.$emit("showAlert", "請輸入遊戲英文名稱");
                return;
            }

            if (this.edit.遊戲簡介 == "") {
                this.$eventBus.$emit("showAlert", "請輸入遊戲簡介");
                return;
            }

            if (!this.logo1) {
                this.$eventBus.$emit("showAlert", "請上傳格狀Logo圖片");
                return;
            }

            if (!this.logo2) {
                this.$eventBus.$emit("showAlert", "請上傳長形Logo圖片");
                return;
            }

            if (this.infos.length < 1) {
                this.$eventBus.$emit("showAlert", "請上傳遊戲說明圖");
                return;
            }

            if (this.edit.遊戲操作說明 == "") {
                this.$eventBus.$emit("showAlert", "請選擇遊戲操作說明");
                return;
            }

            /* if (this.edit.斷線規則 == "") {
                this.$eventBus.$emit("showAlert", "ISP業者斷線為必填欄位");
                return;
            } */

            this.checkDifferences();
            if (this.isCreate || this.infosChange) {
                this.infoGalleryUpdate();
            }

            console.log(this.edit);
            this.saveCommon();

             setTimeout(() => {
                this.GameIntroUpdat();
                console.log('run GameIntroUpdat()');
            }, 800);
        },

        GameIntroUpdat(){
            this.$store
                .dispatch("xinGameIntro/GameIntroUpdate", this.edit)
                .then(result => {
                    if (this.isPreview) {
                        window.open(
                            `${process.env.VUE_APP_XIN_PATH}/GameIntro/GAME_Details/${this.typeClassID}/${this.edit.遊戲英文名稱}?preview=1`
                        );
                        this.$eventBus.$emit("showAlert", "預覽資料儲存");
                        this.edit.是否發佈 = this.edit.是否發佈.toString();
                        // window.open(`${process.env.VUE_APP_XIN_PATH}/GameIntro/GAME_Details/${this.edit.newsId}&preview=1`);
                    } else {
                        if (this.isCreate || this.infosChange) {
                            setTimeout(() => {
                                this.$eventBus.$emit(
                                    "showAlert",
                                    "資料送已送出處理中，請稍後回到列表"
                                );
                            }, 2000);

                            setTimeout(() => {
                                this.backList();
                                window.location.reload();
                            }, 3500);
                        } else {
                            this.$eventBus.$emit("showAlert", "資料送已送出");
                            this.backList();
                        }
                    }
                });
        },

        checkDifferences() {
            // 長度不同，數組不同
            if (this.infos.length !== this.infosOld.length) {
                this.infosChange = true;
                console.log("this.infosChange = true; ");
                return;
            }

            for (let i = 0; i < this.infos.length; i++) {
                const info = this.infos[i];
                const infoOld = this.infosOld[i];

                // 比較每個鍵的值
                for (const key in info) {
                    if (info[key] !== infoOld[key]) {
                        this.infosChange = true;
                        console.log("this.infosChange = true; ");
                        return;
                    }
                }
            }
        },

        //*---- 返回
        backList() {
            this.$router.push({
                name: "XinGameIntroList",
                params: { searchData: this.$route.params.searchData }
            });
        },

        //遊戲館限制 文字模版
        getGameRestriction() {
            const word = `
                <ul>
                    <li>開放等級：等級3以上會員。</li>
                    <li>可使用代幣：星幣、銀幣、PK幣、小鬥幣。</li>
                    <li>【星幣】押注限制：</li>
                </ul>
                <table class="c-table d-lg-none mt-3 mb-4" data-type="電腦版">
                    <thead>
                        <tr class="bg-red2">
                            <th style="min-width: 100px;">會員<br />階級</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/e471d84faca06fb0e5c5e40028bce851.png" alt="LV0"
                                    width="56" height="56" loading="lazy" />無(LV0)</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/b78d3f4dfade2ed0c0da88d7b217ee08.png" alt="青銅"
                                    width="56" height="56" loading="lazy" />青銅</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/5f0151fdae1dea126d5e10d4b1735187.png" alt="白銀"
                                    width="56" height="56" loading="lazy" />白銀</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/cebe371ec1869be50bc428f736132890.png" alt="黃金"
                                    width="56" height="56" loading="lazy" />黃金</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/a1b56f2e74624e2ad7a354674be61cc9.png" alt="白金"
                                    width="56" height="56" loading="lazy" />白金</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/d69d3afdf9d83a17666656b33bc7eaa6.png" alt="鉑金"
                                    width="56" height="56" loading="lazy" />鉑金</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/325ce61d4581a36cc6bd019cbe4ee90e.png" alt="鑽石"
                                    width="56" height="56" loading="lazy" />鑽石</th>
                            <th style="width: 11%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/4d731c0f39bde429571513f6b66978f8.png" alt="彩鑽"
                                    width="56" height="56" loading="lazy" />彩鑽</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>押注<br />上限</th>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                    </tbody>
                </table>
                <table class="c-table d-none table-xs d-lg-table mt-3 mb-4" data-type="手機版">
                    <thead>
                        <tr class="bg-red2">
                            <th style="width: 20%;">會員<br />階級</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/e471d84faca06fb0e5c5e40028bce851.png" alt="LV0"
                                    width="56" height="56" loading="lazy" />無(LV0)</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/b78d3f4dfade2ed0c0da88d7b217ee08.png" alt="青銅"
                                    width="56" height="56" loading="lazy" />青銅</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/5f0151fdae1dea126d5e10d4b1735187.png" alt="白銀"
                                    width="56" height="56" loading="lazy" />白銀</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/cebe371ec1869be50bc428f736132890.png" alt="黃金"
                                    width="56" height="56" loading="lazy" />黃金</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>押注<br />上限</th>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                    </tbody>
                </table>
                <table class="c-table d-none table-xs d-lg-table mt-3 mb-4" data-type="手機版">
                    <thead>
                        <tr class="bg-red2">
                            <th style="width: 20%;">會員<br />階級</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/a1b56f2e74624e2ad7a354674be61cc9.png" alt="白金"
                                    width="56" height="56" loading="lazy" />白金</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/d69d3afdf9d83a17666656b33bc7eaa6.png" alt="鉑金"
                                    width="56" height="56" loading="lazy" />鉑金</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/325ce61d4581a36cc6bd019cbe4ee90e.png" alt="鑽石"
                                    width="56" height="56" loading="lazy" />鑽石</th>
                            <th style="width: 20%;"><img draggable="false"
                                    src="https://storage.xin-stars.com/omp_xinstars/news/4d731c0f39bde429571513f6b66978f8.png" alt="彩鑽"
                                    width="56" height="56" loading="lazy" />彩鑽</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>押注<br />上限</th>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                    </tbody>
                </table>
                <p>備註： <br />※ 部分星幣機台遊戲押注限制為特殊設定，依遊戲中顯示為主。 <br />※ 5000以上的押注以500為區間。 <br />※ 【銀幣&amp;PK幣】 全開10000。</p>
            `;

            this.edit.遊戲館限制 = word;
        }
    }
};
</script>

<style scoped>
.editor .tox-tinymce {
    height: 300px !important;
}

.d-inline {
    display: inline-block;
}

/* .chooseBox .img-close {
  right: -10px !important;
  z-index: 9 !important;
} */

.gameGallery {
    display: flex;
    flex-wrap: wrap;
}

.gameGallery .chooseBox {
    margin-bottom: 10px;
}

.gameGallery .chooseBox img {
    background: #ccc;
}

.chooseBox .img-close {
    right: 5px !important;
}

#DisconnectionRules .disconContnet {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border-bottom: 1px solid #f4f4f4;
    padding-top: 10px;
    position: relative;
}


#DisconnectionRules .disconContnet .form-control {
    margin-right: 10px;
    margin-bottom: 10px;
    width: auto;
    min-width: 140px;
}

#DisconnectionRules .disconContnet .d-c {
    width: 80%;
}

#DisconnectionRules .disconContnet .d-c {
    width: 80%;
}

#DisconnectionRules .disconContnet button {
    position: absolute;
    right: 0;
    width: 80px;
    bottom: 0;
}



</style>
