<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{ $t('會員資料') }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
        <li v-if="name" class="active"><router-link :to="goBack">{{ $t('會員資料') }}</router-link></li>
        <li v-else class="active">{{ $t('會員資料') }}</li>
        <li v-if="name" class="active">{{ name }}</li>
      </ol>
    </section>
    <section v-if="!name" class="content" style="min-height:auto;">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box clearfix" @submit.prevent="searchData.pageNum = 1; getDataList();">
            <div class="select-group mb-10 mr-10" style="width:120px;">
              <select class="form-control" v-model="searchData.精準">
                <option :value="true">{{$t('精準搜尋')}}</option>
                <option :value="false">{{$t('模糊搜尋')}}</option>
              </select>
            </div>
            <div class="select-group mb-10 mr-10" style="width:120px;">
              <select class="form-control" v-model="searchData.type" @change="searchData.value = ''">
                <option v-for="value, key in selectType" :value="key">{{$t(value)}}</option>
              </select>
            </div>
            <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t(selectType[searchData.type])" v-model.trim="searchData.value" />
            </div>
            <!-- <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" placeholder="手機門號" v-model.trim="searchData.phoneNumber" />
            </div> -->
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                {{$t('查詢')}}
              </button>
              <button type="button" class="btn btn-default" @click="exportPlayers()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
            <!-- <button type="button" @click="$router.go(-1)" class="btn btn-default pull-right mb-10 ml-10" >
              <i class="fa fa-arrow-left"></i> 返回
            </button> -->
            <router-link v-if="$route.params.back" :to="$route.params.back" class="btn btn-default pull-right mb-10 ml-10">
              <i class="fa fa-arrow-left"></i> {{$t('返回')}}
            </router-link>
            <!-- <router-link :to="{ name: 'NmjPlayerSetting' }" class="btn btn-warning pull-right mb-10 ml-10">大量停權</router-link> -->
            <!-- <button type="button" @click="memberRebate()" class="btn btn-warning pull-right mb-10 ml-10">
              {{$t('回饋金批次派發')}}
            </button> -->
          </form>
          <div class="table-responsive mb-10" :style="!searchData.精準 ? 'max-width:640px':''">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:105px; width:10%" v-if="searchData.精準">{{$t('最後登入日期')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width:120px; width:10%" v-if="searchData.精準">{{$t('綁定門號')}}</th>
                  <th style="min-width: 90px; width:10%" v-if="searchData.精準">{{$t('登入方式')}}</th>
                  <th style="min-width: 80px; width:10%" v-if="searchData.精準">{{$t('等級')}}</th>
                  <th style="min-width:230px; width: 5%" :style="{minWidth: /^(en)/.test($root.$options.i18n.locale) ? '320px' : '230px'}">
                    {{$t('玩家歷程')}}
                  </th>
                  <th style="min-width: 80px; width:10%" v-if="searchData.精準">{{$t('停權狀態')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{$t(message)}}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(player, index) in datas" :key="index">
                  <td v-if="searchData.精準">{{ uiDate(player.最後登入) }}</td>
                  <td>{{ player.暱稱 }}</td>
                  <td v-if="searchData.精準">
                    <span v-if="player.綁定門號">{{ player.綁定門號 }}</span>
                    <button v-else class="btn-link" @click="getDetails(player).then(res => player.綁定門號 = res.綁定門號 || '-')">{{ $t('查看') }}</button>
                  </td>
                  <td v-if="searchData.精準">{{ $t(player.登入方式) }}</td>
                  <td v-if="searchData.精準">{{ player.level }}</td>
                  <td>
                    <div style="display:grid; grid-template-columns: 1fr 1fr; gap:5px"
                      :data-style="{gridTemplateColumns: /^(en)/.test($root.$options.i18n.locale) ? '1fr' : '1fr 1fr'}"
                    >
                      <router-link class="btn btn-purple" :to="{params:{ name:player.暱稱 }}">
                        <i class="fa fa-user-circle-o fa-fw"></i> {{ $t('會員資料') }}
                      </router-link>
                      <router-link class="btn btn-primary" :to="{ name:'XswGameHistory', query:{ nick:player.暱稱 }}">
                        <i class="fa fa-history fa-fw"></i> {{$t('玩家歷程')}}
                      </router-link>
                      <router-link class="btn btn-success" :to="{ name:'TransactionInfoList', query:{ userId:player.暱稱 }}">
                        <i class="fa fa-dollar fa-fw"></i> {{$t('儲值歷程')}}
                      </router-link>
                      <router-link class="btn btn-warning" :to="{ name:'XswGameMessage', query:{ nick:player.暱稱 }}">
                        <i class="fa fa-envelope fa-fw"></i> {{$t('信件歷程')}}
                      </router-link>
                    </div>
                  </td>
                  <td v-if="searchData.精準">
                    <div style="width:max-content; margin:0 auto; text-align:left;" v-html="banStatus(player)"></div>
                    <p v-if="can控管" class="mt-10" style="width:max-content; margin:0 auto;">
                      <button class="btn btn-info" @click="memberModerate(player)">{{$t('控管')}}</button>
                      <button class="btn btn-danger ml-5" @click="memberModerate(player, true, player.停權狀態 == 0)"
                      v-show="banStatus() != banStatus(user)">{{$t('解鎖')}}</button>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
      </div>
    </section>

    <section v-else class="content" style="min-height:auto;">
      <div class="box box-primary mb-10">
        <div class="box-body">
          <div class="top-box mb-10">
            <router-link class="btn btn-default pull-right" :to="goBack">
              <i class="fa fa-reply"></i> {{$t('返回')}}
            </router-link>
            <h2 class="pull-left mb-5 mt-5" style="font-size:1.5em;">{{$t('玩家暱稱')}}：{{ name }}</h2> 
          </div>
          <div class="mb-10" v-if="user.暱稱">
            <table class="table table-grid table-data table-bordered table-condensed">
              <tbody>
                <tr>
                  <th>{{$t('登入方式')}}</th><td>{{ $t(user.登入方式) }}</td>
                  <th>{{$t('綁定門號')}}</th><td>{{ user.綁定門號 ? user.綁定門號 : "-" }}</td>
                </tr><tr>
                  <th>{{$t('申請日期')}}</th><td>{{ uiDate(user.apply) }}</td>
                  <th>{{$t('綁定日期')}}</th><td>{{ uiDate(user.apply) }}</td>
                </tr><tr>
                  <th>{{$t('暱稱')}}</th><td>{{ user.暱稱 }}</td>
                  <th>{{$t('帳號')}}</th><td>
                    {{ $t(user.vip == 0 ? '一般會員' : '正式會員') }}
                    <button v-if="user.vip == 0 && can轉正" type="button" class="btn btn-warning btn-sm" @click="memberOfficial(name)" style="margin-left:auto;">
                      {{$t('轉正')}}
                    </button>
                  </td>
                </tr><tr>
                  <th>{{ $t('平台識別值') }}</th><td>{{ user.分潤碼 || '-' }}</td>
                  <th>{{$t('登入日期')}}</th><td>{{ uiDate(user.最後登入) }}</td>
                </tr><tr>
                  <th>{{$t('遊戲等級')}}</th><td>{{ user.level }}</td>
                  <th>{{$t('角色狀態')}}</th><td><div v-html="banStatus(user)"></div></td>
                </tr><tr>
                  <th>{{$t('登入狀態')}}</th><td>{{ user.sid == "1" ? $t("在線") : $t("離線") }}</td>
                  <th>{{$t('VIP階級')}}</th><td>{{ user.vip }}</td>
                  <!-- <th></th><td></td> -->
                </tr><tr>
                  <th>{{$t('活躍值')}}</th><td>{{ user.活躍值 }}</td>
                  <th>{{$t('榮譽值')}}</th><td>{{ user.榮譽值 }}</td>
                </tr><tr>
                  <th></th><td></td>
                  <th></th><td></td>
                </tr><tr>
                  <th>{{$t('公會')}}</th><td>{{ user.公會 || "-" }}</td>
                  <th>{{$t('公會會長')}}</th><td>
                    <router-link v-if="user.公會會長" :to="{params:{name:user.公會會長}}">{{ user.公會會長 }}</router-link>
                    <span v-else>-</span>
                  </td>
                </tr><tr>
                  <th>{{$t('停權次數')}}</th><td>{{ banList.filter(i=>i.異動動作 == "角色停權").length || "-" }}</td>
                  <th>{{$t('停權截止')}}</th><td>{{ user.停權狀態 != 0 ? uiDate(user.停權截止) : "-" }}</td>
                </tr><tr>
                  <th>{{$t('剩餘星幣')}}</th><td>{{ user.xinvalues }}
                    <button v-if="can星幣" type="button" class="btn btn-danger btn-sm" @click="memberCoin(user, false)" style="margin-left:auto;">
                      {{$t('扣除')}}
                    </button>
                  </td>
                  <th></th><td></td>
                  <!-- <th>{{$t('回饋金')}}</th><td>{{ user.回饋金 }}
                    <button v-if="can回饋" type="button" class="btn btn-warning btn-sm" @click="memberRebate(name)" style="margin-left:auto;">
                      {{$t('配發')}}
                    </button>
                  </td> -->
                </tr><tr>
                  <th>{{$t('保險箱餘額')}}</th><td>{{ user.保險 }}</td>
                  <th>{{$t('剩餘點數')}}</th><td>{{ user.storepoints }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="tab != 0" class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li v-if="can服務" :class="{ active: tab == 1 }"><a @click="tab = 1" style="cursor:pointer;">{{$t('服務紀錄')}}</a></li>
          <li v-if="can控管記錄" :class="{ active: tab == 2 }"><a @click="tab = 2" style="cursor:pointer;">{{$t('控管紀錄')}}</a></li>
          <li v-if="can配點查詢" :class="{ active: tab == 4 }"><a @click="tab = 4" style="cursor:pointer;">{{$t('玩家儲值歷程')}}</a></li>
          <li v-if="can榮譽" :class="{ active: tab == 3 }"><a @click="tab = 3" style="cursor:pointer;">{{$t('榮譽值紀錄')}}</a></li>
          <li v-if="can序號查詢" :class="{ active: tab == 5 }"><a @click="tab = 5" style="cursor:pointer;">{{$t('序號兌換紀錄')}}</a></li>
        </ul>
        <div class="tab-content" v-if="tab == 1 && can服務">
          <form class="top-box clearfix" @submit.prevent="serviceSearchData.pageNum = 1; getServiceInfo()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="serviceSearchData.開始時間" :propEndDate.sync="serviceSearchData.結束時間" :propTimePicker="false" :key="'serviceSearch'" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
            </div>
            <button v-if="can服務新增" type="button" @click="memberService(name)" class="btn btn-warning pull-right mb-10 ml-10">
              <i class="fa fa-plus"></i> {{$t('新增')}}
            </button>
          </form>
          <div class="table-responsive mb-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 65px; width: 5%">{{$t('編號')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('角色暱稱')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('異動項目')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('類別名稱')}}</th>
                  <th style="min-width:150px; width:15%">{{$t('內容')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('操作時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('操作人員')}}</th>
                  <th style="min-width: 75px; width: 5%" v-if="can服務刪除">{{$t('功能')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="serviceLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="serviceMessage"><td colspan="11">{{ $t(serviceMessage) }}</td></tr>
                <tr v-else-if="serviceList.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in serviceList" :key="item.玩家異動紀錄ID">
                  <td>{{ item.玩家異動紀錄ID }}</td>
                  <td>{{ item.玩家識別值 }}</td>
                  <td>{{ $t(item.異動項目) }}</td>
                  <td>{{ item.異動動作 }}</td>
                  <td>{{ get內容(item) }}</td>
                  <td>{{ uiDate(item.異動時間) }}</td>
                  <td>{{ item.異動者 }}</td>
                  <td v-if="can服務刪除">
                    <button type="button" class="btn btn-danger" @click="deletePlayerService(item)">{{$t('刪除')}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="serviceTotal > serviceSearchData.pageSize" class="text-center mt-10">
            <Pagination
                :pageLimitCount="serviceSearchData.pageSize"
                :total="serviceTotal"
                v-on:pageMethod="onPageChange"
              />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 2 && can控管">
          <form class="top-box clearfix" @submit.prevent="honorSearchData.pageNum = 1; getBanInfo()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="banSearchData.開始時間" :propEndDate.sync="banSearchData.結束時間" :propTimePicker="false" :key="'banSearch'" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
            </div>
            <div v-if="can控管" class="pull-right" style="width:max-content; margin:0 auto;">
              <button type="button" class="btn btn-info" @click="memberModerate(user)">
                <i class="fa fa-gavel"></i> {{$t('控管')}}</button>
              <button type="button" class="btn btn-danger ml-5" @click="memberModerate(user, true, user.停權狀態 == 0)"
              v-show="banStatus() != banStatus(user)"><i class="fa fa-unlock"></i> {{$t('解鎖')}}</button>
            </div>
          </form>
          <div class="table-responsive mb-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 65px; width: 5%">{{$t('編號')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('角色暱稱')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('類型')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('期限')}}</th>
                  <th style="min-width:150px; width:15%">{{$t('原因')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('操作時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('操作人員')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="banLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="banMessage"><td colspan="11">{{ $t(banMessage) }}</td></tr>
                <tr v-else-if="banList.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedBanData" :key="item.玩家異動紀錄ID">
                  <td>{{ item.玩家異動紀錄ID }}</td>
                  <td>{{ item.玩家識別值 }}</td>
                  <td>{{ $t(item.異動動作) }}</td>
                  <td>{{ uiDate(item.新內容.控管期限) || uiDate(item.新內容.期限) }}</td>
                  <td>{{ get內容(item) }}</td>
                  <td>{{ uiDate(item.異動時間) }}</td>
                  <td>{{ item.異動者 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="banTotal > banPageSize" class="text-center mt-10">
            <Pagination 
              :pageLimitCount="banPageSize" 
              :total="banTotal"
              v-on:pageMethod="onPageChange" />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 3 && can榮譽">
          <form class="top-box clearfix" @submit.prevent="honorSearchData.pageNum = 1; getHonorInfo()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="honorSearchData.開始時間" :propEndDate.sync="honorSearchData.結束時間" :propTimePicker="false" :key="'honorSearch'" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
            </div>
            <div v-if="can榮譽異動">
              <button type="button" @click="memberHonor(user, false)" class="btn btn-danger pull-right mb-10 ml-5">
                <i class="fa fa-minus"></i> {{$t('回收')}}
              </button>
              <button type="button" @click="memberHonor(user, true)" class="btn btn-success pull-right mb-10 ml-5">
                <i class="fa fa-plus"></i> {{$t('添加')}}
              </button>
            </div>
          </form>
          <div class="table-responsive mb-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 65px; width: 5%">{{$t('編號')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('角色暱稱')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('類型')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('榮譽值')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('榮譽值餘額')}}</th>
                  <th style="min-width:150px; width:10%">{{$t('內容')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('操作時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('操作人員')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="honorLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="honorMessage"><td colspan="11">{{ $t(honorMessage) }}</td></tr>
                <tr v-else-if="honorList.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in honorList" :key="item.玩家異動紀錄ID">
                  <td>{{ item.玩家異動紀錄ID }}</td>
                  <td>{{ item.玩家識別值 }}</td>
                  <td>{{ $t(item.異動動作) }}</td>
                  <td>{{ item.新內容.配發數量 }}</td>
                  <td>{{ item.新內容.餘額 }}</td>
                  <td>{{ get內容(item)}}</td>
                  <td>{{ uiDate(item.異動時間) }}</td>
                  <td>{{ item.異動者 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="honorTotal > honorSearchData.pageSize" class="text-center mt-10">
            <Pagination
              :pageLimitCount="honorSearchData.pageSize"
              :total="honorTotal"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 4 && can配點查詢">
          <form class="top-box clearfix" @submit.prevent="pointSearchData.pageNum = 1; getPointInfo()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="pointSearchData.開始時間" :propEndDate.sync="pointSearchData.結束時間" :propTimePicker="false" :key="'pointSearch'" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
            </div>
            <div>
              <button v-if="can配點" type="button" @click="memberPoint(name)" class="btn btn-warning pull-right mb-10 ml-5">
                <i class="fa fa-plus"></i> {{$t('新增')}}
              </button>
              <button type="button" @click="exportPoints()" class="btn btn-default pull-right mb-10 ml-5" :disabled="pointList.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </form>
          <div class="table-responsive mb-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:15%">{{$t('配點時間')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('角色暱稱')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('類型')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('點數')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('卡別')}}</th>
                  <th style="min-width:120px; width:15%">{{$t('備註')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="pointLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="pointMessage"><td colspan="11">{{ $t(pointMessage) }}</td></tr>
                <tr v-else-if="pointList.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in pointList" :key="item.時間">
                  <td>{{ uiDate(item.時間) }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ $t(item.類型) }}</td>
                  <td>{{ item.點數 }}</td>
                  <td>{{ item.類型 == '配點' ? '-' : item.卡別 }}</td>
                  <td>{{ item.備註 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="pointTotal > pointSearchData.pageSize" class="text-center mt-10">
            <Pagination
              :pageLimitCount="pointSearchData.pageSize"
              :total="pointTotal"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 5 && can序號查詢">
          <form class="top-box clearfix" @submit.prevent="serialSearchData.pageNum = 1; getSerialInfo()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="serialSearchData.開始時間" :propEndDate.sync="serialSearchData.結束時間" :propTimePicker="false" :key="'serialSearch'" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
            </div>
          </form>
          <div class="table-responsive mb-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 5%">{{$t('編號')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('序號')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('兌換項目')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width: 80px; width:10%">{{$t('兌換數量')}}</th>
                  <th style="min-width: 80px; width:10%">{{$t('道具')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('兌換日期')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="serialLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="serialMessage"><td colspan="11">{{ $t(serialMessage) }}</td></tr>
                <tr v-else-if="serialList.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in serialList" :key="item.序號">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.序號 }}</td>
                  <td>{{ item.兌換項目 }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ item.兌換數量 }}</td>
                  <td>{{ item.道具 }}</td>
                  <td>{{ uiDate(item.兌換時間) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="serialTotal > serialSearchData.pageSize" class="text-center mt-10">
            <Pagination
              :pageLimitCount="serialSearchData.pageSize"
              :total="serialTotal"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ user }}</pre> -->
    <!-- <pre>{{ serviceList }}</pre> -->
    <!-- <pre>{{ banList }}</pre> -->
    <!-- <pre>{{ honorList }}</pre> -->
    <!-- <pre>{{ serialList }}</pre> -->
    <!-- <pre>{{ pointList }}</pre> -->

    <MemberOfficialDialog ref="MemberOfficialDialog" :types="serviceTypes" @updateList="onOfficial()" />
    <MemberModerateDialog ref="MemberModerateDialog" :types="serviceTypes" @update="onModerate()" /> <!-- APP版組件 -->
    <MemberServiceDialog ref="MemberServiceDialog" :types="serviceTypes" @updateList="onService()" />
    <MemberRebateDialog ref="MemberRebateDialog" :types="serviceTypes" @updateList="onRebate()" />
    <MemberPointDialog ref="MemberPointDialog" :types="serviceTypes" @updateList="onPoint()" />
    <MemberHonorDialog ref="MemberHonorDialog" :types="serviceTypes" @updateList="onHonor()" />
    <MemberCoinDialog ref="MemberCoinDialog" :types="serviceTypes" @updateList="onCoin()" />
  </div>
</template>

<style lang="scss" scoped>
/* eslint-disable */
table.table>tbody>tr { word-break: break-word; }
table.table>thead>tr>th { word-break: keep-all; }
.table-responsive { overflow-y:hidden; }
// @media (min-width:600px){
.table-grid{
  @supports(display:contents){
    tbody{ 
      display:grid; grid-template-columns:repeat(auto-fill, minmax(80px, 2fr) minmax(160px, 4fr));
      @media (min-width:1280px){ 
        grid-template-columns:repeat(4, minmax(80px, 2fr) minmax(160px, 4fr));
        th,td{padding: .5em;}
      }
    }
    tr{ display: contents; }
  }
  border-color: #e4e4e4;
  th, td{ margin:0 -1px -1px 0; border-width:0 1px 1px 0; border-color: #e4e4e4; }
  th { display:flex; align-items:center; justify-content:flex-end; word-break:normal; word-wrap:break-word; }
  td { display:flex; align-items:center; justify-content:flex-start; }
}
// }

.table-data{
  th{ text-align:right; width:auto; min-width: 70px; background:rgba(#e8e8e8, .5); border-right: none; }
  td{ text-align: left; width:auto; min-width:110px; border-left: none; }
}
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import MemberServiceDialog from "./components/MemberServiceDialog";
import MemberRebateDialog from "./components/MemberRebateDialog";
import MemberHonorDialog from "./components/MemberHonorDialog";
import MemberModerateDialog from "../XSG/components/MemberModerateDialog.vue";
import MemberOfficialDialog from "./components/MemberOfficialDialog.vue";
import MemberPointDialog from "./components/MemberPointDialog.vue";
import MemberCoinDialog from "./components/MemberCoinDialog.vue";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    MemberOfficialDialog,
    MemberModerateDialog,
    MemberServiceDialog,
    MemberRebateDialog,
    MemberHonorDialog,
    MemberPointDialog,
    MemberCoinDialog,
    Pagination,
    router
  },
  props:{
    name: ""
  },
  data() {
    return {
      selectType:{
        "phoneNumber": "綁定門號",
        "nickName": "暱稱",
      },
      searchData: {
        精準: true,
        type: "nickName",
        value: "",
        pageNum: 1,
        pageSize: 10,
      },
      message: "", //錯誤訊息
      loading: false,
      total: 0,
      datas: [],
      user: {},
      tab: 0,

      // serviceList
      serviceSearchData: {
        玩家識別值: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      serviceMessage: "", //錯誤訊息
      serviceLoading: false,
      serviceTotal: 0, //總筆數
      serviceList: [],
      serviceTypes: {},
      serviceTypesDisabled: {},

      // banList
      banSearchData: {
        玩家識別值: "",
        開始時間: "2024-01-01 00:00:00",
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 0, //每頁顯示筆數
      },
      banPageNum: 1,
      banPageSize: 10, // 前端分頁
      banMessage: "", //錯誤訊息
      banLoading: false,
      banTotal: 0, //總筆數
      banList: [],

      // honorList
      honorSearchData: {
        玩家識別值: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        專案識別: this.$store.state.projectId,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      honorMessage: "", //錯誤訊息
      honorLoading: false,
      honorTotal: 0, //總筆數
      honorList: [],
      
      // serialList
      pointSearchData: {
        玩家識別值: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      pointMessage: "", //錯誤訊息
      pointLoading: false,
      pointTotal: 0, //總筆數
      pointList: [],

      // serialList
      serialSearchData: {
        暱稱: "",
        遊戲館: "",
        關鍵字: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        序號兌換記錄查詢: true,
      },
      serialMessage: "", //錯誤訊息
      serialLoading: false,
      serialTotal: 0, //總筆數
      serialList: [],

      // 權限
      can轉正: this.$user.hasPermission(dfn.permission.會員轉正),
      can服務: this.$user.hasPermission(dfn.permission.會員服務記錄查詢),
      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      can服務刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
      can榮譽: this.$user.hasPermission(dfn.permission.會員榮譽值記錄),
      can榮譽異動: this.$user.hasPermission(dfn.permission.會員榮譽值異動),
      can回饋: this.$user.hasPermission(dfn.permission.會員配發回饋金),
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
      can控管記錄: this.$user.hasPermission(dfn.permission.會員控管記錄),
      can序號查詢: this.$user.hasPermission(dfn.permission.遊戲歷程查詢),
      can配點: this.$user.hasPermission(dfn.permission.會員配點),
      can配點查詢: this.$user.hasPermission(dfn.permission.會員配點記錄),
      can星幣: this.$user.hasPermission(dfn.permission.會員星幣異動),

      updateInterval: 0,
      updateTime: 0,
    };
  },
  async created() {
    if (this.can服務) this.tab = 1;
    else if (this.can控管記錄) this.tab = 2;
    else if (this.can配點查詢) this.tab = 4;
    else if (this.can榮譽) this.tab = 3;
    else if (this.can序號查詢) this.tab = 5;

    if (this.name) {
        // this.searchData.type = "nickName";
        // this.searchData.value = this.name;
        this.serviceSearchData.玩家識別值 = this.name;
        this.serialSearchData.暱稱 = this.name;
        this.pointSearchData.玩家識別值 = this.name;
        this.honorSearchData.玩家識別值 = this.name;
        this.banSearchData.玩家識別值 = this.name;
        this.getUserInfo();
    }

    if (this.$route.query.nick) {
      this.searchData.type = "nickName";
      this.searchData.value = this.$route.query.nick;
      this.getDataList();
    }
    else if (this.$route.query.tel) {
      this.searchData.type = "phoneNumber";
      this.searchData.value = this.$route.query.tel;
      this.getDataList();
    }
    this.getTypes();
    window.v = this;

    this.updateInterval = setInterval(() => {
      this.updateTime = Date.now();
    }, 1000);
  },
  beforeDestroy(){
    clearInterval(this.updateInterval);
  },
  watch: {
    name(value){
      if (typeof value === "string"){
        // this.searchData.type = "nickName";
        // this.searchData.value = value;
        this.serviceSearchData.玩家識別值 = value;
        this.serialSearchData.暱稱 = value;
        this.pointSearchData.玩家識別值 = value;
        this.honorSearchData.玩家識別值 = value;
        this.banSearchData.玩家識別值 = value;
        this.getUserInfo();
      }
    },
    "serviceSearchData.玩家識別值": function () {
      if (!this.can服務) return;
      this.serviceSearchData.pageNum = 1;
      this.getServiceInfo();
    },
    "serialSearchData.暱稱": function () {
      if (!this.can序號查詢) return;
      this.serialSearchData.pageNum = 1;
      this.getSerialInfo();
    },
    "pointSearchData.玩家識別值": function () {
      if (!this.can配點查詢) return;
      this.pointSearchData.pageNum = 1;
      this.getPointInfo();
    },
    "honorSearchData.玩家識別值": function () {
      if (!this.can榮譽) return;
      this.honorSearchData.pageNum = 1;
      this.getHonorInfo();
    },
    "banSearchData.玩家識別值": function () {
      if (!this.can控管) return;
      this.banPageNum = 1;
      this.getBanInfo();
    },
  },
  computed:{
    // user(){
    //   return this.datas.find(item => item.暱稱 == this.name) || {}
    // },
    goBack(){
      if (this.searchData.type == "phoneNumber"){
        return {params:{name:null}, query:{tel:this.searchData.value}}
      } else {
        return {params:{name:null}, query:{nick:this.name}}
      }
    },
    pagedBanData(){
      return this.banList.slice((this.banPageNum - 1) * this.banPageSize, (this.banPageNum * this.banPageSize));
    },
    banStatus(){
      return function(user){
        let updateTime = this.updateTime; // 定時刷新狀態用
        let OK = this.$t('正常');
        if (user === undefined) return `<p class="text-green">${ OK }</p>`;
        let banText = getStatus.call(this, 0, user.停權狀態, user.停權截止);
        let muteText = getStatus.call(this, 1, user.禁言狀態, user.禁言截止);
        let htmlString = "";
        if (banText != OK) htmlString += `<p class="text-red">${ banText }</p>`;
        if (muteText != OK) htmlString += `<p class="text-red">${ muteText }</p>`;
        if (user.星幣鎖交易) htmlString += `<p class="text-orange">${ this.$t('星幣鎖交易') }</p>`;
        if (user.雙平台鎖購買) htmlString += `<p class="text-orange">${ this.$t('雙平台鎖購買') }</p>`;
        if (htmlString == "") htmlString = `<p class="text-green">${ OK }</p>`;
        return htmlString;

        function getStatus(banType = 0, banState = 0, endTime = 0){
          banState = banState.toString();
          if (banState == 1 && Date.now() > new Date(endTime).valueOf()) return OK;
          if (banType == 0) switch(banState){
            case "1": return this.$t("停權至x", [new moment(endTime).format('M/D H:mm')]);
            case "2": return this.$t("停權(終止契約)");
            case "3": return this.$t("停權(暫時凍結)");
            default: return OK;
          }
          if (banType == 1) switch(banState){
            case "1": return this.$t("禁言至x", [new moment(endTime).format('M/D H:mm')]);
            case "2": return this.$t("永久禁言");
            default: return OK;
          }
          return "";
        }
      }
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (!this.name){
        this.searchData.pageNum = pageIdx;
        this.searchData.pageSize = pageSize;
        this.getDataList();
      } else if (this.tab == 1) {
        this.serviceSearchData.pageNum = pageIdx;
        this.serviceSearchData.pageSize = pageSize;
        this.getServiceInfo();
      } else if (this.tab == 2) {
        this.banPageNum = pageIdx;
        this.banPageSize = pageSize;
        // this.getBanInfo(); // 前端分頁
      } else if (this.tab == 3){
        this.honorSearchData.pageNum = pageIdx;
        this.honorSearchData.pageSize = pageSize;
        this.getHonorInfo();
      } else if (this.tab == 4){
        this.pointSearchData.pageNum = pageIdx;
        this.pointSearchData.pageSize = pageSize;
        this.getPointInfo();
      } else if (this.tab == 5){
        this.serialSearchData.pageNum = pageIdx;
        this.serialSearchData.pageSize = pageSize;
        this.getSerialInfo();
      }
    },
    getTypes() {
      this.$store.dispatch("xsw/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
            // console.log(item);
          }
          else{
            this.$set(this.serviceTypesDisabled, item.編號, item.名稱);
          }
        }
      });
    },
    getDataList() {
      let type = this.searchData.type;
      let value = this.searchData.value;
      if (!value) {
        this.$eventBus.$emit("showAlert", this.$t(`請填寫${this.selectType[type]}！`));
        return;
      }
      if (type == "phoneNumber"){
        value = value.replace(/^([^+])/, "+$1");
      }
      
      this.loading = true;
      if (this.searchData.精準 && type != "phoneNumber"){
        this.$store.dispatch("xsw/會員查詢", {
          [type]: value,
        }).then(res => {
          this.message = "";
          this.datas = [res] || [];
          this.total = res.length;
          this.loading = false;
          success.call(this, res);
        }, err => {
          fail.call(this, err)
        });
        return;
      }
      this.$store.dispatch("xsw/會員列表查詢", {
        [type]: value,
        account: this.searchData.account,
        pageNum: this.searchData.pageNum,
        pageSize: this.searchData.pageSize,
      })
      .then( res => {
        this.message = "";
        this.datas = res.list || [];
        this.total = res.count;
        this.loading = false;
        success.call(this, res);
      }, err => {
        fail.call(this, err)
      });
      function success(res){
        if (this.datas.length > 0 && !this.name) {
          if (type == "phoneNumber") this.datas.map(item => item.綁定門號 = item.綁定門號 || value);
          let query = {};
          if (this.searchData.type == "nickName") query['nick'] = this.searchData.value;
          if (this.searchData.type == "phoneNumber") query['tel'] = this.searchData.value;
          // console.log(this.$route.query.nick, query.nick, this.$route.query.tel, query.tel)
          if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
            this.$router.replace({query:query});
          }
        }
      }
      function fail(err){
        this.message = err;
        this.total = 0;
        this.datas = [];
        this.loading = false;
      }
    },
    async getDetails(player){
        return this.$store.dispatch("xsw/會員查詢", {
          nickName: player.暱稱
        });
    },
    getUserInfo(){
      if (!this.name) return;
      this.$store.dispatch("xsw/會員查詢", {
        nickName: this.name
      })
      .then( res => {
        this.message = "";
        this.user = res || {};
        this.loading = false;
      }, err => {
        this.message = err;
        this.user = {};
        this.loading = false;
      });
    },
    getServiceInfo() {
      if (!this.name) return;
      this.serviceLoading = true;
      this.$store.dispatch("xsw/會員服務記錄查詢", this.serviceSearchData)
      .then( res => {
        this.serviceList = res.datas || [];
        this.serviceTotal = res.total || 0;
        this.serviceMessage = "";
        this.serviceLoading = false;
      }, err => {
        this.serviceList = [];
        this.serviceTotal = 0;
        this.serviceMessage = err;
        this.serviceLoading = false;
      });
    },
    getHonorInfo(){
      if (!this.name) return;
      this.honorLoading = true;
      this.$store.dispatch("xsw/會員榮譽值記錄", this.honorSearchData)
      .then( res => {
        let list = res.datas || [];
        list.map(i => i.新內容 = JSON.parse(i.新內容));
        this.honorList = list;
        this.honorTotal = res.total || 0;
        this.honorMessage = "";
        this.honorLoading = false;
      }, err => {
        this.honorList = [];
        this.honorTotal = 0;
        this.honorMessage = err;
        this.honorLoading = false;
      });
    },
    getBanInfo() {
      this.banLoading = true;
      this.$store.dispatch("xsw/會員控管記錄", this.banSearchData)
        .then((res) => {
          let list = res.datas || [];
          list.map(i => i.新內容 = JSON.parse(i.新內容));
          this.banList = list;
          this.banTotal = res.total || 0;
          this.banMessage = "";
          this.banLoading = false;
        }, (msg) => {
          this.banList = [];
          this.banTotal = 0;
          this.banMessage = msg;
          this.banLoading = false;
        });
    },
    getPointInfo() {
      this.pointLoading = true;
      this.$store.dispatch("xsw/會員配點記錄", this.pointSearchData)
        .then((result) => {
          let list = result ? result : [];
          this.pointList = list;
          this.pointTotal = result.length || 0;
          this.pointMessage = "";
          this.pointLoading = false;
        }, (msg) => {
          this.pointList = [];
          this.pointTotal = 0;
          this.pointMessage = msg;
          this.pointLoading = false;
        });
    },
    getSerialInfo() {
      this.serialLoading = true;
      this.$store.dispatch("xsw/遊戲歷程查詢", this.serialSearchData)
        .then((result) => {
          let list = result ? result : [];
          this.serialList = list;
          this.serialTotal = result.length || 0;
          this.serialMessage = "";
          this.serialLoading = false;
        }, (msg) => {
          this.serialList = [];
          this.serialTotal = 0;
          this.serialMessage = msg;
          this.serialLoading = false;
        });
    },
    
    memberOfficial(player){
      this.$refs.MemberOfficialDialog.show(player);
    },
    memberModerate(player, unban) {
      if (!this.name && !player.綁定門號){
        this.getDetails(player).then(res => {
          player.綁定門號 = res.綁定門號 || '-';
          this.$refs.MemberModerateDialog.show(player, unban);
        });
      } else {
        this.$refs.MemberModerateDialog.show(player, unban);
      }
    },
    memberService(player) {
      this.$refs.MemberServiceDialog.show(player);
    },
    memberRebate(player){
      this.$refs.MemberRebateDialog.show(player);
    },
    memberPoint(player){
      this.$refs.MemberPointDialog.show(player);
    },
    memberHonor(player, isAddHonor){
      this.$refs.MemberHonorDialog.show(player, isAddHonor);
    },
    memberCoin(player, isAddCoin){
      this.$refs.MemberCoinDialog.show(player, isAddCoin);
    },
    onOfficial(){
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); }
    },
    onModerate(){
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); this.getBanInfo(); }
    },
    onService(){ 
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); }
    },
    onRebate(){ 
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); }
    },
    onPoint(){
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); this.getPointInfo(); }
    },
    onHonor(){ 
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); this.getHonorInfo(); }
    },
    onCoin(){
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); }
    },

    deletePlayerService(item) {
      this.$store.dispatch("xsw/會員服務記錄刪除", {
        "玩家異動紀錄ID": item.玩家異動紀錄ID
      }).then(res => {
        this.$eventBus.$emit("showAlert", this.$t("刪除成功"));
        this.getServiceInfo();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    exportPlayers() {
        let data = utils.deepCloneObj(this.datas);
        let items = [];
        data.forEach(item => {
            items.push(item);
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchData.value}_會員資料.xlsx`,
            '會員資料'
        );
    },
    exportPoints() {
        let data = utils.deepCloneObj(this.pointList);
        let items = [];
        data.forEach(item => {
            items.push({
              "配點時間": item.時間,
              "角色暱稱": item.暱稱,
              "類型": item.類型,
              "點數": item.點數,
              "卡別": item.卡別,
              "備註": item.備註,
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.name}_會員點數資料.xlsx`,
            '會員點數資料'
        );
    },
    uiDate(date) {
      if (/^0/.test(date)) return '-';
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
    },
    get內容(item){
      if (!item.新內容) return "";
      if (typeof item.新內容 == "string" && !/^{.*}$/.test(item.新內容)) return item.新內容;
      let obj = typeof item.新內容 == "object" ? item.新內容 : JSON.parse(item.新內容);
      if (obj) return obj.內容 || obj.備註 || obj.原因 || obj.服務原因 || item.新內容;
    }
  },
};
</script>