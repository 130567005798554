<template>
  <FormDialog ref="memberCoinDialog" :title="this.type+'異動' "
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr v-if="isAddCoin !== null">
            <th><span class="isAddCoin">異動</span>{{ this.type }}</th>
            <td><input type="number" v-model.number="data.points" class="form-control"  required><span class="text-red">若為扣除，請於輸入數值前加上「-」號</span></td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('配發原因')}}</th>
            <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types'
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        UID: "",
        points: 0,
        內容: "",
        分類: ""
      },
      type: '',
      player: null,
      isAddCoin: true,
    };
  },
  mounted(){
    
  },
  methods: {
    async show(from,player, isAddCoin) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.type=from;
      this.player = player;
      this.isAddCoin = isAddCoin;
      this.data.UID = player.玩家識別值;
      this.$refs.memberCoinDialog.show();
    },
    submit(){
      if(this.data.points == 0){
        this.$eventBus.$emit("showAlert", "點數不能為0");
        return;
      }

      if(this.type == '金幣'){
        this.$store.dispatch("xsw/會員異動金幣",
          {
                "UID": this.data.UID,
                "Coins": this.data.points,
                "內容": this.data.內容,
                "分類": this.data.分類
          }
        ).then(() => {
          this.$refs.memberCoinDialog.hide();
          this.$eventBus.$emit("showAlert", "更新成功");
          this.$emit("updateList");
        }, (message) => {
          this.$eventBus.$emit("showAlert", message);
        });
      }
      if(this.type == '銅幣'){
        this.$store.dispatch("xsw/會員異動銅幣", {
                "UID": this.data.UID,
                "Coins": this.data.points,
                "內容": this.data.內容,
                "分類": this.data.分類          
        }).then(() => {
          this.$refs.memberCoinDialog.hide();
          this.$eventBus.$emit("showAlert", "更新成功");
          this.$emit("updateList");
        }, (message) => {
          this.$eventBus.$emit("showAlert", message);
        });
      }
      if(this.type == '皇家點數'){
        this.$store.dispatch("xsw/會員異動皇家點數", this.data).then(() => {
          this.$refs.memberCoinDialog.hide();
          this.$eventBus.$emit("showAlert", "更新成功");
          this.$emit("updateList");
        }, (message) => {
          this.$eventBus.$emit("showAlert", message);
        });
      }
     
    },
  },
};
</script>
