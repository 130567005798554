<template>
  <div id="NewtalkAnnouncementList" class="normal">
    <section class="content-header">
      <h1>討論區公告管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">討論區公告管理</li>
      </ol>
    </section>
    <section class="content forum-announcement">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="updateArticle()"
              class="btn btn-block btn-warning btn-add"
              type="button"
              v-if="searchData.articleType === 1"
            >
              <i class="fa fa-plus"></i> 新增公告
            </button>
            <button
              @click="deleteAll()"
              class="btn btn-block btn-danger btn-cog"
              type="button"
              v-else
            >
              <i class="fa fa-trash"></i> 批次刪除
            </button>
            <div class="form-group" style="margin-right: 0px">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                />
              </div>
            </div>
            <div class="select-group">
              <select
                id="selectUnit"
                class="form-control"
                required="required"
                @change="changeType()"
                v-model.number="searchData.articleType"
              >
                <option value="1">官方</option>
                <option value="2">個人</option>
                <option value="3">自動</option>
                <option value="4">留言</option>
                <option value="5">回覆</option>
              </select>
            </div>
            <div class="select-group" v-if="actionType === 'article'">
              <select
                id="selectUnit"
                class="form-control"
                required="required"
                @change="resetDataList()"
                v-model.number="recommend"
              >
                <option value="0">全部文章</option>
                <option value="1">推薦中</option>
              </select>
            </div>
            <div class="select-group" v-if="actionType === 'article'">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋文章ID"
                v-model.trim="searchData.id"
              />
            </div>
            <div class="select-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋發布ID"
                v-model.trim="searchData.uId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model.trim="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered AdListTable"
              v-if="this.actionType == 'article'"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 50px">ID</th>
                  <th style="min-width: 50px" v-if="searchData.articleType === 1">預設語系</th>
                  <th style="min-width: 50px" v-if="searchData.articleType !== 3">推薦</th>
                  <th style="min-width: 150px" v-if="searchData.articleType !== 3">推薦排序</th>
                  <th style="min-width: 80px" v-if="searchData.articleType !== 1">來源</th>
                  <th style="min-width: 250px">內容</th>
                  <th style="min-width: 100px">發佈日期</th>
                  <th style="min-width: 100px">發佈ID</th>
                  <th style="min-width: 50px">狀態</th>
                  <th style="min-width: 60px">愛心數</th>
                  <th style="min-width: 50px">留言數</th>
                  <th style="min-width: 230px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="13">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="13">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="item.pDelete === 0">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ link: item.pDelete === 0 }"
                  >
                    <span v-if="item.pDelete === 0" @click="linkItem(item)">{{ item.pId }}</span>
                    <span v-else>{{ item.pId }}</span>
                  </td>
                  <td v-if="searchData.articleType === 1">
                    {{ item.pLang }}
                  </td>
                  <td class="text-nowrap text-center" v-if="searchData.articleType !== 3">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.pRecommend == 1"
                          v-on:update:checked="checkChanger"
                          :autoChecked = true
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap" v-if="searchData.articleType !== 3">
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="3"
                        v-model.number="item.pRecommendSort"
                      />
                      <button
                        @click="setHotTop(item, 'officialHotTop', 1)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        :disabled="item.pRecommend == 0"
                      >
                        更新
                      </button>
                    </div>
                  </td>
                  
                  <td class="text-nowrap text-center" v-if="searchData.articleType !== 1">
                    來源
                  </td>
                  <td>
                    <img :src="item.pMedia" v-if="item.pMedia != '' && !showText(item)" style="width: 100px;">
                    <div class="text-ellipsis" v-html="showText(item)" v-if="showText(item)"></div>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.pTimeStamp | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.uId }} <br>
                    ({{ define.users ? define.users[`${item.mbKey}_Name`] : '' }})
                  </td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.pDelete }"
                  >
                    {{ define.deleteStatus[item.pDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.pLikes }}
                  </td>
                  <td class="text-nowrap text-center">
                    <span
                      class="link"
                      v-if="item.cCounts > 0"
                      @click="showDetail(item, 'comment')"
                    >
                      {{ item.cCounts }}
                    </span>
                    <span v-else>{{ item.cCounts }}</span>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary mr-5"
                      @click="updateArticle(item)"
                      v-if="searchData.articleType == 1"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger mr-5"
                      @click="deleteItem(item)"
                      v-if="item.pDelete == 0"
                    >
                      刪除
                    </button>
                    <button
                      class="btn btn-primary"
                      @click="showContent(item)"
                    >
                      詳細
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-hover table-bordered"
              v-else
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 100px">ID</th>
                  <th style="min-width: 250px">內容</th>
                  <th style="min-width: 100px">發佈日期</th>
                  <th style="min-width: 80px">發佈ID</th>
                  <th style="min-width: 100px">隱私</th>
                  <th style="min-width: 60px">狀態</th>
                  <th style="min-width: 60px">愛心數</th>
                  <th style="min-width: 60px" v-if="actionType == 'comment'">回覆</th>
                  <th style="min-width: 200px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="12">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="12">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="item.cDelete == 0 || item.rDelete == 0">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">{{ item.cId }}</td>
                  <td>
                    <span
                      class="text-ellipsis"
                      :class="{ 'text-red': item.cDelete != 0 }"
                      v-html="item.cContent"
                      v-if="actionType == 'comment'"
                    ></span>
                    <span
                      class="text-ellipsis"
                      :class="{ 'text-red': item.rDelete != 0 }"
                      v-html="item.rContent"
                      v-else
                    ></span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.cTimeStamp | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.uId }} <br> 
                    ({{ define.users ? define.users[`${item.mbKey}_Name`] : '' }})
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.cState == 0 || item.rState == 0 ? '公開' : '悄悄話' }}
                  </td>
                  <td class="text-nowrap text-center">
                    <span
                      :class="{ 'text-red': item.cDelete != 0 }"
                      v-if="actionType == 'comment'"
                    >
                      {{ define.deleteStatus[item.cDelete] }}
                    </span>
                    <span
                      :class="{ 'text-red': item.rDelete != 0 }"
                      v-else
                    >
                      {{ define.deleteStatus[item.rDelete] }}
                    </span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ actionType == 'comment' ? item.cLikes : item.rLikes }}
                  </td>
                  <td class="text-nowrap text-center" v-if="actionType == 'comment'">
                    <span
                      class="link"
                      v-if="item.rCounts > 0"
                      @click="showDetail(item, 'reply')"
                    >
                      {{ item.rCounts }}
                    </span>
                    <span v-else>{{ item.rCounts }}</span>
                  </td>
                  <td class="text-center">
                    <button
                      @click="showContent(item)"
                      class="btn btn-primary mr-5"
                    >
                      詳細
                    </button>
                    &nbsp;
                    <button
                      v-if="item.cDelete == 0 || item.rDelete == 0"
                      @click="deleteItem(item)"
                      class="btn btn-danger mr-5"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumContent
      ref="forumContent" 
      :isReportPage="false"
      :getDataList="getDataList"
    />
    <announcementReplyAlert
      ref="announcementReplyAlert"
      :actionType="actionType"
      :checkDatas="checkDatas"
      v-on:updateList="getDataList"
    />
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import forumContent from '@/views/projects/qppSocial/ForumContent';
import announcementReplyAlert from "@/views/projects/Newtalk/AnnouncementReplyAlert";
import axios from "axios";
const qs = require("qs");
import htmlToText from "html-to-formatted-text";
const headers = { "content-type": "application/x-www-form-urlencoded" };
const qppApiURL =
  "https://dev-api.qpptec.com/TestQppClient/Qpp_CloudStorageClient.php?v=";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    forumContent,
    announcementReplyAlert,
  },
  data() {
    return {
      loading: true,
      total: 0,
      recommend: 0, // 官方推薦{預設0:false,1:true}(貼文)
      searchData: {
        id: '',
        pStart: 0,
        uId: '',
        keyword: '', // 內容關鍵字搜尋(貼文)
        pRecommendKind: '', // 推薦來源(貼文)
        articleType: 1, // 分類(貼文)
        cDesc: true, //遞減YN；false:1不遞減，true:遞減(留言)
        rDesc: true, //(回覆)
        startTime: new moment()
          .subtract(3, "y")
          .format("YYYY/MM/DD"),
        endTime: new moment().format("YYYY/MM/DD"),
        page: 1,
        pageSize: 20,
      },
      datas: [],
      checkIds: [],
      checkDatas: [],
      actionType: "",
      isCheckAll: false,
      itemID: '',
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      if(this.recommend == 1) {
        this.searchData.pRecommend = 1
      } else {
        delete this.searchData.pRecommend
      }
      this.searchData.pType = this.searchData.articleType
      if(this.searchData.articleType == 5) {
        this.searchData.rKeyword = this.searchData.keyword
      } else if (this.searchData.articleType == 4) {
        this.searchData.cKeyword = this.searchData.keyword
      } else {
        this.searchData.pKeyword = this.searchData.keyword
      }

      this.searchData.pId = this.actionType == 'article' ? this.searchData.id : '';
      this.actionType =
        this.searchData.articleType === 5
          ? "reply"
          : this.searchData.articleType === 4
          ? "comment"
          : "article";
      this.loading = true;
      if(this.actionType == "article") {
        this.$store
        .dispatch(`qppSocialArticle/QueryList`, this.searchData)
        .then((result) => {
          this.datas = result.postList || [];
          this.total = result.totalCount;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.page, this.total);
          this.GetUserData();
        })
      } else {
        this.$store
        .dispatch(`qppSocialReport/${this.actionType}`, this.searchData)
        .then((result) => {
          this.datas = result.commentList || result.replyList || [];
          this.total = result.totalCount;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.page, this.total);
          this.GetUserData();
        })
      }
      this.$forceUpdate();
    },
    GetUserData() {
      const mbKey = [];
      this.datas.forEach(item => {
        if(!mbKey.includes(item.mbKey)) {
          mbKey.push(item.mbKey);
        }
      })
      this.GetUserInfo(mbKey)
    },
    GetUserInfo(mbKey) {
      const data = {
        action: "UserSelectInfo_Multi",
        hash_UID: `${mbKey.join(",")}`,
      };
      axios({
        method: "post",
        headers: headers,
        url: qppApiURL + new Date().getTime(),
        data: qs.stringify(data),
      })
        .then((res) => {
          this.define.users = !this.define.users ? res.data : Object.assign(this.define.users, res.data);
          this.$forceUpdate();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    resetDataList() {
      this.checkIds = [];
      this.checkDatas = [];
      this.searchData.page = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    linkItem(item) {
      window.open(`${process.env.VUE_APP_QPPSOCIALWEB_PATH}/person/${item.uId}/${item.pId}`);
    },
    previewItem(item) {
      this.$refs.forumContent.previewItem(item);
    },
    clearCheckAll() {
      this.checkIds = [];
      this.checkDatas = [];
      this.checkAll({ checked: 0 });
      this.$forceUpdate();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.datas.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    checkChanger(item) {
      if(item.checked == 0) {
        let data = {
          pId: item.value.pId,
          pRecommend: 0,
          pRecommendSort: 0
        }
        this.recommendEdit(data)
        return
      }
      this.datas.forEach(val => {
        if(val.pId == item.value.pId) {
          val.pRecommend = item.checked
        }
      })
      this.$forceUpdate();
    },
    checkChange(data) {
      const id = 
        this.actionType == 'comment'? 
        data.value.cId : this.actionType == 'reply'?
        data.value.rId : data.value.pId;

      if(data.checked && !this.checkIds.includes(id)) {
        let item = 
          this.actionType == 'comment'?
          { cId: id } : this.actionType == 'reply'?
          { rId: id } : { pId: id };
        this.checkIds.push(id);
        this.checkDatas.push(item);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    changeType() {
      this.searchData.id = '';
      this.searchData.keyword = '';
      this.searchData.uId = '';
      this.searchData.pRecommend = 0;
      this.resetDataList();
    },
    showText(item) {
      if(item.pType != 1){
        return item.pContent
      }
      let content = ''
      item.contents.forEach(val => {
        if(val.lang == item.pLang) {
          content = val.content
        }
      })
      return content
    },
    showContent(item) {
      this.$refs.forumContent.showForumContent(item, this.searchData.articleType) ;
    },
    showDetail(item, type) {
      this.$router.push({
        name: "QppSocialAnnouncementDetailList",
        params: {
          type: type,
          id: type == 'comment' ? item.pId : item.cId,
          searchData: this.searchData,
        },
      });
    },
    updateArticle(item) {
      this.$router.push({
        name: "QppSocialAnnouncementEdit",
        params: {
          id: item ? item.pId : null,
          searchData: this.searchData,
        },
      });
    },
    deleteAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇刪除對象！");
        return;
      }
      this.deleteData(this.checkDatas);
    },
    deleteItem(item) {
      this.checkDatas = []
      const _item = 
        this.actionType == 'comment' ?
        { cId: item.cId } : this.actionType == 'reply' ?
        { rId: item.rId } : { pId: item.pId }
      var data = []
      data.push(_item)
      this.deleteData(data, item);
    },
    deleteData(data, item) {
      const storeName = 
        this.actionType == 'comment' || this.actionType == 'reply' ?
        'qppSocialReport' : 'qppSocialArticle'
      const storePath = 
        this.actionType == 'comment' ?
        'deleteComment' : this.actionType == 'reply' ?
        'deleteReply' : 'DeleteQuery'
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch(`${storeName}/${storePath}`, data).then((res) => {
            if(res.status == '1') {
              this.$eventBus.$emit('showAlert', '刪除成功');
              
              setTimeout(() => {
                this.getDataList();
              }, 1000);
            }
          });
        }
      });
    },
    recommendEdit(data){
      this.$store.dispatch("qppSocialArticle/RecommendEdit", data)
        .then((res) => {
          if(res.status == 1) {
            this.$eventBus.$emit("showAlert", "更新成功!");
            setTimeout(() => {
              this.getDataList();
            }, 1000);
          } else {
            this.$eventBus.$emit("showAlert", res.message);
          }
        })
    },
    setHotTop(item) {
      item.pRecommend = 1;
      if(item.pRecommendSort == 0) {
        this.$eventBus.$emit("showAlert", "排序不可為0!");
        return;
      }

      let data = {
        pId: item.pId,
        pRecommend: item.pRecommend,
        pRecommendSort: item.pRecommendSort
      }

      this.recommendEdit(data)
    },
  },
};
</script>
<style lang="scss">
.text-ellipsis {
  display: -webkit-box;
  margin: 0;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  overflow: hidden;
}
</style>