<template>
  <div class="tab-content">
    <div class="StayTuned tc">點數查詢 敬請期待</div>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
// import MemberControlDialog from "@/components/MemberControlDialog";

export default {
  components: {
    moment,
    DateRangePicker,
    Pagination,
    utils,
    // MemberControlDialog,
  },
  data() {
    return {
      searchData: {
        暱稱: "mimi",
        開始時間: new moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
      },

      message: "", //錯誤訊息
      loading: false,
      total: 13, //總筆數
      //從外邊傳進來
      user: { },

      list: [
      ],
      serviceTypes: {},
      serviceTypesDisabled: {},
      pageNum: 1,
      pageSize: 10,
    };
  },
  mounted() {},

  computed: {
    pagedData() {
      return this.list.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    },
  },

  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
      console.log(this.pageNum, this.pageSize);
    },

    getServiceInfo() {
      /*  this.loading = true;
      this.$store.dispatch("xsw/會員服務記錄查詢", this.searchData)
      .then( res => {
        this.list = res.datas || [];
        this.total = res.total || 0;
        this.message = "";
        this.loading = false;
      }, err => {
        this.list = [];
        this.total = 0;
        this.message = err;
        this.loading = false;
      }); */
    },

    memberService(player, addOrEdit) {
      this.$refs.MemberServiceDialog.show(player, addOrEdit);
    },

    onService() {
      // if (!this.searchData.nickName && !this.searchData.phoneNumber) return;
      this.getServiceInfo();
    },

    openMemberControlDialog(player, unban) {
      this.$refs.MemberControlDialog.show(player, unban);
    },

    deletePlayer(item) {
      this.$eventBus.$emit(
        "showConfirm",
        "確定要刪除這則控管紀錄嗎?",
        (isOK) => {
          if (isOK) {
            /*  this.$store.dispatch("xinCRM/會員服務記錄刪除", {
            "暱稱": item.暱稱,
            "日期": item.日期,
            "啟用YN": 1,
          }).then(res => {
            this.$eventBus.$emit("showAlert", "刪除成功！");
            this.getServiceInfo();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          }); */
          }
        }
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.StayTuned {
  font-size: 1.5em;
  padding: 1em;
  width: 100%;
}

</style>
