<template>
  <div class="modal fade" ref="importData">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" data-dismiss="modal">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">匯入{{ title }}資料</h4>
        </div>
        <div class="modal-body" style="display:flex; gap:.7em">
          <label type="button" class="btn btn-primary btn-cog" style="flex: 1 1 auto; cursor:pointer;">
            <input type="file" id="inputFile" ref="inputFile" accept=".xls,.xlsx" @change="readExcel()"
              style="margin:auto; width:100%; cursor:pointer;"
            />
          </label>
          <a type="button" :href="`/uploads/Xin/${title}範例.xlsx`" class="btn btn-success btn-cog" download>
            下載匯入{{ title }}範例
          </a>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<style scoped>
  .modal-body .btn{
    margin-top: .5em;
  }
</style>

<script>
/* eslint-disable */
import xlsx from "xlsx";

export default {
  props: {
    title: String,
    checks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      edit: {
        snCount: 0,
        snCodeLen: 0,
      },
    };
  },
  methods: {
    show() {
      $("#inputFile").val("");
      $(this.$refs.importData).modal("show");
    },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.inputFile.files;
      if (files.length <= 0) {
        this.$eventBus.$emit("showAlert", "尚未選擇檔案!!");
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
            cellDates: true,
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容

          if (ws.length === 0) {
            this.$eventBus.$emit("showAlert", "無資料內容可匯入");
            return;
          }

          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (let i = 0; i < ws.length && !error; i++) {
            for (let j = 0; j < this.checks.length; j++) {
              const check = this.checks[j];
              if (!ws[i][check] && isNaN(ws[i][check])) {
                error = true;
                errMessage = `第${i + 1}筆資料內容有空值!!`;
                break;
              }
            }
            outputs.push(ws[i]);
          }

          if (error) {
            this.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          this.$eventBus.$emit("showAlert", "匯入成功!!");
          this.$emit("importData", outputs, files[0].name);
          $(this.$refs.importData).modal("hide");
          return true;
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    },
  },
};
</script>