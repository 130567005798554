<template>
  <div class="modal fade" ref="memberControlDialog">
    <div class="modal-dialog">
      <form class="modal-content" ref="form" @submit.prevent="submit">
        <div class="modal-header">
          <slot name="header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{ ban ? "玩家控管" : "玩家解鎖" }}</h4>
          </slot>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-0">
            <table class="table table-bordered">
              <tbody>
                <tr data-title="對象">
                  <th>{{ ban ? "控管" : "解鎖" }}對象</th>
                  <td>
                    <select v-if="this.nickNames" 
                      v-model="data.暱稱" class="form-control" required>
                      <option value="" selected disabled hidden>
                        請選擇暱稱
                      </option>
                      <option
                        v-for="nick in nickNames"
                        :value="nick"
                        :key="nick"
                      >
                        {{ nick }}
                      </option>
                    </select>

                    <label v-else>
                      {{this.data.暱稱}}
                    </label>
                  </td>
                </tr>

                <tr data-title="狀態類型">
                  <th>{{ ban ? "控管" : "解鎖" }}類型</th>
                  <td v-if="ban">
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="0"
                      message="停權"
                      name="blockType"
                      class="radio-inline"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="1"
                      message="禁言"
                      name="blockType"
                      class="radio-inline ml-25"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="3"
                      message="禁止頭圖"
                      name="blockType"
                      class="radio-inline ml-25"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="2"
                      message="其他控管"
                      name="blockType"
                      class="radio-inline ml-25"
                    />
                  </td>

                  <td v-else>
                    <iCheckRedioButton
                      
                      v-model.number="data.動作"
                      checkValue="0"
                      message="復權"
                      name="blockType"
                      class="radio-inline"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="1"
                      message="解除禁言"
                      name="blockType"
                      class="radio-inline"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="3"
                      message="解禁頭圖"
                      name="blockType"
                      class="radio-inline"
                    />
                    <iCheckRedioButton
                      v-model.number="data.動作"
                      checkValue="2"
                      message="解除鎖定"
                      name="blockType"
                      class="radio-inline"
                    />
                  </td>
                </tr>

                <tr data-title="限制">
                  <th>{{ ban ? "限制鎖定" : "限制解除" }}</th>
                  <td>
                    <div class="checkbox-inline mr-10">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.星幣鎖交易"
                          v-on:update:checked="
                            data.星幣鎖交易 = $event['checked']
                          "
                        />
                        {{ ban ? "星幣轉出鎖定" : "星幣解除禁止" }}
                      </label>
                    </div>
                    <div class="checkbox-inline">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.雙平台鎖交易"
                          v-on:update:checked="
                            data.雙平台鎖交易 = $event['checked']
                          "
                        />
                        {{ ban ? "雙平台購買鎖定" : "雙平台購買解除禁止" }}
                      </label>
                    </div>
                    <!-- <div class="checkbox-inline">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.限制頭圖上傳"
                          v-on:update:checked="
                            data.限制頭圖上傳 = $event['checked']
                          "
                        />
                        {{ ban ? "限制頭圖上傳" : "解除頭圖上傳" }}
                      </label>
                    </div> -->
                  </td>
                </tr>

                <tr data-title="期限" v-if="ban">
                  <th>期限</th>
                  <td>
                    <template v-if="data.動作 == 0">
                      <div class="radio-group">
                        <iCheckRedioButton
                          v-model.number="data.類型"
                          checkValue="1"
                          message=""
                          name="timeType"
                          class="radio radioCenter radio-inline"
                        />
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="data.期限"
                          min="1"
                        />
                        <span>天</span>
                      </div>

                      <iCheckRedioButton
                        v-model.number="data.類型"
                        checkValue="3"
                        message="暫時凍結"
                        name="timeType"
                        class="radio radioCenter radio-inline"
                      />

                      <iCheckRedioButton
                        v-model.number="data.類型"
                        checkValue="2"
                        message="永久停權"
                        name="timeType"
                        class="radio radioCenter radio-inline ml-0"
                      />
                    </template>

                    <template v-else-if="data.動作 == 1">
                      <div class="radio-group">
                        <iCheckRedioButton
                          v-model.number="data.類型"
                          checkValue="1"
                          message=""
                          name="timeType"
                          class="radio radioCenter"
                        />
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="data.期限"
                          min="1"
                        />
                        <span>分鐘</span>
                      </div>
                      <iCheckRedioButton
                        v-model.number="data.類型"
                        checkValue="2"
                        message="永久禁言"
                        name="timeType"
                        class="radio radioCenter"
                      />
                    </template>

                    <template v-else>
                      <iCheckRedioButton
                        v-model.number="data.類型"
                        checkValue="2"
                        message="永久鎖定"
                        name="timeType"
                        class="radio radioCenter"
                      />
                    </template>
                  </td>
                </tr>

                <tr data-title="服務類型">
                  <th>服務類型</th>
                  <td>
                    <select v-model.number="data.分類" class="form-control">
                      <option value="" selected disabled hidden>
                        請選擇類別
                      </option>
                      <option
                        v-for="(name, id) in types"
                        :value="name"
                        :key="id"
                      >
                        {{ name }}
                      </option>
                    </select>
                  </td>
                  <!-- <td v-else>
                    {{data.分類}}
                  </td> -->
                </tr>

                <tr data-title="原因">
                  <th>原因</th>
                  <td>
                    <textarea
                      class="form-control"
                      type="text"
                      v-model.trim="data.原因"
                      placeholder="*必填"
                      rows="3"
                      required
                    ></textarea>
                  </td>
                </tr>

                <!--  <tr data-title='發送密語' v-if="ban">
                  <th>發送密語</th>
                  <td>
                    <select id="secretClass" v-model="selectedSecret" class="form-control mb-5">
                      <option value="" selected disabled hidden>請選擇類別</option>
                      <option v-for="option in 密語分類" :value="option.text" :key="option.id">
                        {{ option.text }}
                      </option>
                    </select>
                    <textarea
                      id="secretContent"
                      class="form-control"
                      type="text"
                      v-model.trim="data.密語"
                      placeholder="請填入密語內容"
                      rows="3"
                    ></textarea>
                  </td>
                </tr> -->

                <tr data-title="通知" v-if="ban && data.動作 !== 2">
                  <th>通知</th>
                  <td>
                    <div class="checkbox-inline">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.官網公告"
                          v-on:update:checked="
                            data.官網公告 = $event['checked']
                          "
                        />
                        於官網公告
                      </label>
                    </div>
                    <div class="checkbox-inline mr-10">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.遊戲公告"
                          v-on:update:checked="
                            data.遊戲公告 = $event['checked']
                          "
                        />
                        於遊戲公告
                      </label>
                    </div>
                    <div class="checkbox-inline mr-10">
                      <label class="check">
                        <iCheckCheckBox
                          v-bind:propChecked="data.發送簡訊"
                          v-on:update:checked="
                            data.發送簡訊 = $event['checked']
                          "
                        />
                        發送簡訊
                      </label>
                    </div>
                  </td>
                </tr>

                <tr data-title="通知內容" v-if="showNotification">
                  <th>通知內容</th>
                  <td>
                    <textarea
                      id="secretContent"
                      class="form-control"
                      type="text"
                      v-model.trim="data.通知內容"
                      placeholder="請填入通知內容"
                      rows="3"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer text-center">
          <slot name="footer">
            <button type="submit" class="btn btn-primary">確認送出</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              <span style="padding-inline: 1em">取消</span>
            </button>
          </slot>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import iCheckRedioButton from "@/components/iCheckRedioButton";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    iCheckRedioButton,
    iCheckCheckBox,
  },
  props: ["types", "players", "nickNames"],
  data() {
    return {
      title: "",
      player: null,
      ban: false,
      // playerList: "",
      data: {
        暱稱: "",
        動作: 0, //0 停權 1 禁言 2 其他 3圖頭
        類型: 0, //停權(1期限 2終止契約 3暫時凍結), 禁言(1暫時禁言 2永久禁言)
        期限: 4, // 天/分
        解鎖: false,
        原因: "",
        分類: "", // 服務類型
        解鎖: false,
        遊戲公告: 0,
        發送簡訊: 0,
        官網公告: 0,
        密語: "",
        通知內容: "",
        星幣鎖交易: 0,
        雙平台鎖交易: 0,
        // 限制頭圖上傳: 0,
      },

      selectedSecret: "",
      secretContent: "",
      密語分類: [
        { id: "001", text: "提醒您，全頻請勿進行廣告行為的發話，感謝" },
        {
          id: "002",
          text: "提醒您，請勿使用文字影響其他玩家，留意公共留言規則",
        },
        { id: "003", text: "提醒您，自訂貼圖請勿使用違反善良風俗、觸弄法律" },
        { id: "004", text: "提醒您，請留意發話頻率，以免造成洗頻的情況" },
      ],

      resultData: {},
      filterString: "",
      pageNum: 1,
      pageSize: 5,
      pageTotal: 0,

      // importFileName: "",
      // importList: [],
      // checks: ["玩家暱稱"],
    };
  },
  watch: {
    selectedSecret(newVal) {
      this.data.密語 = newVal;
    },
  },
  computed: {
    showNotification() {
      return (
        this.ban &&
        (this.data.遊戲公告 || this.data.發送簡訊 || this.data.官網公告) &&
        this.data.動作 !== 2
      );
    },
  },

  mounted() {},
  methods: {
    async show(player, ban , nick) {
      console.log(player);
      Object.assign(this.$data, this.$options.data()); // 重設data


      if (player && player.暱稱) {
        this.data.暱稱 = player.暱稱
      }

      if (this.nickNames && this.nickNames.length == 1) {
        this.data.暱稱 = this.nickNames[0];
      }


      this.player = player || null;
      this.ban = ban || false;
      this.data.解鎖 = !this.ban;

      /* if (this.player) {
        this.data.暱稱 = this.player.暱稱;
        if (ban && this.player.停權狀態 == 0) {
          this.data.動作 = 1;
        }
      } */
      $(this.$refs.memberControlDialog).modal("show");
    },

    convertValues() {
        this.data.遊戲公告 = this.data.遊戲公告 === 1;
        this.data.發送簡訊 = this.data.發送簡訊 === 1;
        this.data.官網公告 = this.data.官網公告 === 1;
        this.data.星幣鎖交易 = this.data.星幣鎖交易 === 1;
        this.data.雙平台鎖交易 = this.data.雙平台鎖交易 === 1;
        this.data.限制頭圖上傳 = this.data.限制頭圖上傳 === 1;
    },

    submit() {
      console.log(this.data);

      if (!this.ban && this.data.期限 == "") {
        this.$eventBus.$emit("showAlert", "請設定控管期限天數或永久停權");
        return;
      }

      if (this.data.分類 == "") {
        this.$eventBus.$emit("showAlert", "請選擇服務類型");
        return;
      }

      if (this.data.原因 == "") {
        this.$eventBus.$emit("showAlert", "請填寫原因");
        return;
      }

      this.convertValues();

      this.$store.dispatch("xinCRM/會員控管", this.data).then(
        () => {
          
          this.$emit("updateList");
          $(this.$refs.memberControlDialog).modal("hide");
        },
        (message) => {
          this.$eventBus.$emit("showAlert", message);
        }
      );
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  width: 700px;
}

.radio-group {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.radio-group > input {
  margin: 0 5px;
  max-width: 100px;
}

.radio-group > span {
  white-space: nowrap;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
