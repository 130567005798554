import BandMode from "@/assets/js/Tool/NSpeex/BandMode";
import SpeexDecoder from "@/assets/js/Tool/NSpeex/NSpeex.Decoder";
import axios from 'axios'
//https://developers.google.com/web/updates/2012/01/Web-Audio-FAQ
//根據文件說明AudioContext應該只有1個實體
let m_AudioCtx = new AudioContext();
let m_GainNode = m_AudioCtx.createGain();
m_GainNode.gain.value = 100;
m_GainNode.connect(m_AudioCtx.destination);
function XinDecoder(Data/*: ArrayBuffer*/) {
    /**先轉成Byte陣列*/
    let U8Arr/*: Uint8Array*/ = new Uint8Array(Data);
    /**建立short陣列*/
    let I16Arr/*: Int16Array*/ = new Int16Array(U8Arr.length * 4);
    let SpDecoder/*: SpeexDecoder*/ = new SpeexDecoder(BandMode.Narrow, false);
    SpDecoder.Decode(U8Arr, 0, U8Arr.length, I16Arr, 0, false); // 解壓縮
    return I16Arr;
}
let isPlaying = false;
function filterNoise(I16Arr, SampleRate, DegreeNoiseRate) {
    let F32Arr/*: Float32Array*/ = new Float32Array(I16Arr); /**建立訊號格式所需的資料陣列*/
    //change變數的名稱是亂取的，不知道取什麼比較好
    //change的數值愈大，過濾的雜音愈多，但音量也愈小
    //星城的change值是32767
    //為了方便，直接拿SampleRate去做計算，
    //如果之後有第三家，但想要個別調整的話，考慮改成設定值
    let change = DegreeNoiseRate;//32767 * (SampleRate / 8000);//星城：32767；
    for (let i = 0; i < F32Arr.length; i++) {
        if (F32Arr[i] != 0) F32Arr[i] = F32Arr[i] / change; /**轉換訊號值-1~1*/
    }
    return F32Arr;
}
export const H5AudioPlayer = function (audioURL, SampleRate, DegreeNoiseRate) {
    return new Promise(async (resolve, reject) => {
        try {
            if (isPlaying) {
                reject("音訊播放中，請稍後再試");
            }
            isPlaying = true;
            let binaryData = (await axios({
                method: 'get',
                url: audioURL,
                // data: {
                // },
                responseType: 'arraybuffer'
            })).data;
            let decodeDataI16Arr = XinDecoder(binaryData);
            let decodeData = filterNoise(decodeDataI16Arr, SampleRate, DegreeNoiseRate);
            /**不能重複使用 https://developers.google.com/web/updates/2012/01/Web-Audio-FAQ 文件說明*/
            let VoiceSource/*: AudioBufferSourceNode*/ = m_AudioCtx.createBufferSource()
            let VoiceBuff/*: AudioBuffer*/ = m_AudioCtx.createBuffer(1, decodeData.length, SampleRate);//星城採樣率是8000
            VoiceBuff.copyToChannel(decodeData, 0, 0)
            VoiceSource.buffer = VoiceBuff;
            VoiceSource.connect(m_GainNode);
            //VoiceSource.loop = true; //不重複播放
            VoiceSource.start();

            //音訊播完時觸發
            VoiceSource.onended = function () {
                VoiceSource.stop();
                VoiceSource.disconnect();
                isPlaying = false;
                resolve();
            };
        } catch (e) {
            isPlaying = false;
            reject(e);
        }
    });
}