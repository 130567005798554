<template>
  <div id="NewtalkVoteEdit" class="newtalk">
    <section class="content-header">
      <h1>{{ data.pollsCategory === "民調" ? "民調" : "投票" }}內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Newtalk/VoteList">投票管理</router-link>
        </li>
        <li class="active">
          {{ data.pollsCategory === "民調" ? "民調" : "投票" }}內容
        </li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form
            class="form-horizontal"
            name="voteForm"
            method="post"
            @submit.prevent="submit"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 投票分類：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    required
                    @change="changeVoteType"
                    v-model.trim="data.pollsCategory"
                  >
                    <option
                      v-for="(item, index) in list"
                      :key="index"
                      :value="item.classTypeId"
                    >
                      {{ item.className }}
                    </option>
                    <option value="電競大賞">電競大賞</option>
                    <option value="2023電競大賞">2023電競大賞</option>
                    <option value="民調">民調</option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="data.pollsCategory !== '民調'">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 投票類型：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    required
                    @change="changeVoteType()"
                    v-model.trim="voteType"
                  >
                    <template v-if="data.pollsCategory.indexOf('電競大賞') > -1">
                      <option value="圖片模式">圖片模式</option>
                    </template>
                    <template v-else>
                      <option value="調查">調查</option>
                      <option value="辯論">辯論</option>
                    </template>
                  </select>
                </div>
              </div>

              <div class="form-group" v-if="data.pollsCategory.indexOf('電競大賞') > -1">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 子類別：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    required
                    v-model.trim="data.pollsSubcategory"
                    @change="setTitle()"
                  >
                    <option value="射擊類選手獎">射擊類選手獎</option>
                    <option value="射擊類新人獎">射擊類新人獎</option>
                    <option value="射擊類校園獎">射擊類校園獎</option>
                    <option value="綜合類選手獎">綜合類選手獎</option>
                    <option value="綜合類新人獎">綜合類新人獎</option>
                    <option value="綜合類校園獎">綜合類校園獎</option>
                    <option value="MOBA類選手獎">MOBA類選手獎</option>
                    <option value="MOBA類新人獎">MOBA類新人獎</option>
                    <option value="MOBA類校園獎">MOBA類校園獎</option>
                    <option value="賽事評論獎">賽事評論獎</option>
                    <option value="評論報導獎">評論報導獎</option>
                    <option value="教育貢獻獎">教育貢獻獎</option>
                    <option value="電競團隊獎">電競團隊獎</option>
                    <option value="娛樂效果獎">娛樂效果獎</option>
                    <option value="專業技術獎">專業技術獎</option>
                    <option value="影音特效獎">影音特效獎</option>
                    <option value="造型服裝獎">造型服裝獎</option>
                    <option value="最佳意見領袖獎">最佳意見領袖獎</option>
                    <option value="最佳人氣選手獎">最佳人氣選手獎</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>
                  {{ data.pollsCategory.indexOf('電競大賞') > -1 ? "主旨" : "標題" }}：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    maxlength="50"
                    placeholder="請輸入標題，上限50字"
                    type="text"
                    required
                    v-model.trim="data.articleTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model="data.articleContent" :propLang="lang" />
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="data.pollsCategory !== '民調'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 投票選項：
                </label>
                <div class="col-sm-10">
                  <div class="table-responsive mt-10">
                    <table
                      class="table table-bordered tc"
                      v-for="(poll, index) in data.polls"
                      :key="index"
                    >
                      <tr>
                        <th style="min-width: 60px; width: 60px"></th>
                        <th style="min-width: 150px"></th>
                        <th
                          style="min-width: 160px"
                          :style="{
                            width: poll.options.length > 2 ? '160px' : '110px',
                          }"
                        ></th>
                      </tr>
                      <tr>
                        <td class="question">Q{{ index + 1 }}</td>
                        <td>
                          <input
                            class="form-control mt-10"
                            type="text"
                            placeholder="題目"
                            required
                            v-model.trim="data.polls[0].title"
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr
                        v-for="(option, oIndex) in poll.options"
                        :key="oIndex"
                      >
                        <td>
                          <div class="mt-10" v-if="data.pollsType === '辯論'">
                            <span class="blue" v-if="oIndex === 0">正方</span>
                            <span class="red" v-else>反方</span>
                          </div>
                        </td>
                        <td>
                          <input
                            class="form-control mt-10"
                            type="text"
                            :placeholder="`選項${oIndex + 1}`"
                            required
                            v-model.trim="option.title"
                          />
                        </td>
                        <td class="text-left" style="min-width: 200px">
                          <button
                            class="btn btn-primary mt-10 ml-10"
                            type="button"
                            v-show="data.pollsCategory.indexOf('電競大賞') > -1"
                            @click="showImgContent(poll, oIndex)"
                          >
                            <i class="fa fa-paperclip"></i>
                          </button>
                          <button
                            class="btn btn-warning mt-10 ml-10"
                            type="button"
                            v-show="poll.options.length > 1"
                            @click="sortOption(poll, oIndex, true)"
                          >
                            <i class="fa fa-arrow-up"></i>
                          </button>
                          <button
                            class="btn btn-warning mt-10 ml-10"
                            type="button"
                            v-show="poll.options.length > 1"
                            @click="sortOption(poll, oIndex, false)"
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                          <button
                            class="btn btn-danger mt-10 ml-10"
                            type="button"
                            v-show="poll.options.length > 2"
                            @click="removeOption(poll, option)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="data.pollsType === '辯論'">
                        <td></td>
                        <td>
                          <button
                            class="btn btn-primary mt-10 fl"
                            type="button"
                            @click="showContent()"
                          >
                            <i class="fa fa-upload"></i> 變更圖示
                          </button>
                          <div style="min-width: 500px">
                            <span class="fl ml-25">
                              <img
                                class="mt-10 ml-10"
                                width="100px"
                                :src="data.positiveSideUrl"
                              />
                              <div class="blue">正方</div>
                            </span>
                            <span class="fl" style="margin: 50px">VS</span>
                            <span class="fl">
                              <img
                                class="mt-10 ml-10"
                                width="100px"
                                :src="data.negativeSideUrl"
                              />
                              <div class="red">反方</div>
                            </span>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="text-left" colspan="2">
                          <button
                            class="btn btn-warning mt-10 mb-10"
                            type="button"
                            v-show="
                              data.pollsType === '調查' ||
                              data.pollsCategory.indexOf('電競大賞') > -1
                            "
                            @click="addOption(poll)"
                          >
                            <i class="fa fa-plus"></i> 新增選項
                          </button>
                          <button
                            class="btn btn-primary mt-10 mb-10 ml-10"
                            type="button"
                            v-show="data.pollsType === '調查'"
                            @click="copyQuestion(index)"
                          >
                            <i class="fa fa-plus"></i> 複製題組
                          </button>
                          <div
                            class="mb-10"
                            v-show="data.pollsType !== '調查'"
                          ></div>
                          <button
                            class="btn btn-primary mt-10 mb-10 ml-10"
                            type="button"
                            v-show="data.polls.length > 1"
                            @click="sortQuestion(index, true)"
                          >
                            <i class="fa fa-arrow-up"></i>
                          </button>
                          <button
                            class="btn btn-primary mt-10 mb-10 ml-10"
                            type="button"
                            v-show="data.polls.length > 1"
                            @click="sortQuestion(index, false)"
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                          <button
                            class="btn btn-danger mt-10 mb-10 ml-10"
                            type="button"
                            v-show="data.polls.length > 1"
                            @click="removeQuestion(poll)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <button
                      class="btn btn-primary mt-10"
                      type="button"
                      v-show="data.pollsType === '調查'"
                      @click="addQuestion()"
                    >
                      <i class="fa fa-plus"></i> 新增題目
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="data.pollsCategory.indexOf('電競大賞') == -1">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 標籤：
                </label>
                <div class="col-sm-10">
                  <multiselect
                    placeholder="自行輸入標籤"
                    label="Tag"
                    track-by="Tag"
                    required
                    :options="options"
                    :hide-selected="true"
                    :internal-search="false"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="data.tags"
                    selectLabel=""
                    @tag="addTag"
                    @search-change="updateTags"
                    max-elements="已達標籤上限"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.Tag }}
                        </span>
                        <span class="option__small" style="float: right">
                          使用文章數：{{ props.option.UsedCount || 0 }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group" v-if="data.pollsCategory !== '民調'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 投票開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="data.pollsCategory !== '民調'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 投票結束時間：
                </label>
                <div class="col-sm-10">
                  <!-- <input
                    class="form-control"
                    type="text"
                    :value="endTime"
                    disabled="disabled"
                    v-if="data.pollsCategory.indexOf('電競大賞') > -1"
                  /> -->
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="data.pollsCategory === '民調'">
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    <span class="text-red" v-if="!pollJsonUrl">*</span>
                    <span v-else>更換</span>JSON檔：
                  </label>
                  <div class="col-sm-10">
                    <input
                      accept="application/JSON"
                      type="file"
                      id="pollJson"
                      name="pollJson"
                      :required="!pollJsonUrl ? true : false"
                      @change="changeFile($event, 'json')"
                    />
                  </div>
                </div>
                <div class="form-group" v-show="pollJsonUrl">
                  <label class="col-sm-2 control-label"></label>
                  <div class="col-sm-10">
                    <div v-show="voteImgName">
                      <a target="_blank" :href="pollJsonUrl">
                        {{ voteImgName }}
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="data.pollsCategory.indexOf('電競大賞') == -1">
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    <span class="text-red">*</span> 主圖：
                  </label>
                  <div class="col-sm-10">
                    <input
                      accept="image/*"
                      type="file"
                      id="voteImg"
                      name="voteImg"
                      :required="
                        !voteImgId && data.pollsCategory.indexOf('電競大賞') == -1
                      "
                      @change="changeFile($event)"
                    />
                  </div>
                </div>
                <div class="form-group" v-show="voteImgUrl">
                  <label class="col-sm-2 control-label"></label>
                  <div class="col-sm-10">
                    <img width="100px" :src="voteImgUrl" />
                    <div v-show="voteImgName">
                      <a target="_blank" :href="voteImgUrl">
                        {{ voteImgName }}
                      </a>
                    </div>
                  </div>
                </div>
              </template>

              <div class="form-group" v-if="data.pollsCategory !== '民調'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 投票結果：
                </label>
                <div class="col-sm-10">
                  <div class="radio" v-if="voteType === '調查'">
                    <iCheckRedioButton
                      checkValue="2"
                      message="截止後顯示"
                      name="resultMode"
                      v-model.number="data.pollsResult"
                    />
                  </div>
                  <div :class="['radio', voteType === '調查' ? 'mt-10' : '']" v-if="data.pollsCategory.indexOf('電競大賞') == -1">
                    <iCheckRedioButton
                      checkValue="1"
                      message="投票完即顯示"
                      name="resultMode"
                      v-model.number="data.pollsResult"
                    />
                  </div>
                  <div class="radio" v-if="data.pollsCategory.indexOf('電競大賞') > -1">
                    <iCheckRedioButton
                      checkValue="3"
                      message="最高票前三名顯示入圍；其餘人顯示票數"
                      name="resultMode"
                      v-model.number="data.pollsResult"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model.number="data.isDelete"
                    />
                    <span
                      class="text-red ml-10"
                      v-if="data.pollsCategory !== '民調'"
                    >
                      (以投票開始時間自動上架)
                    </span>
                  </div>
                  <div class="radio mt-10">
                    <iCheckRedioButton
                      checkValue="2"
                      message="下架"
                      name="radioMode"
                      v-model.number="data.isDelete"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-warning btn-lg"
                  style="margin-right: 10px"
                  type="submit"
                  v-if="data.isDelete === 2"
                  @click="save(true)"
                >
                  預覽
                </button>
                <button
                  class="btn btn-primary btn-lg"
                  type="submit"
                  @click="save(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <voteContent ref="voteContent" v-on:updateContent="updateContent" />
    <voteImgContent
      ref="voteImgContent"
      v-on:updateContent="updateImgContent"
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import multiselect from "vue-multiselect";
import utils from "@/assets/js/app-utils";
import tinymce from "@/components/Tinymce";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import voteContent from "@/views/projects/Newtalk/VoteContent";
import voteImgContent from "@/views/projects/Newtalk/VoteImgContent";

export default {
  components: {
    multiselect,
    tinymce,
    DatePicker,
    iCheckRedioButton,
    voteContent,
    voteImgContent,
  },
  watch: {
    "data.pollsCategory"(newVal, oldVal) {
      this.changeVoteTypeId();
      this.prevCategory = oldVal;
    },
  },
  data() {
    return {
      projectId: this.$store.state.projectId, // 專案Id
      timer: null,
      lang: "zh-tw",
      titleMax: 50,
      contentMax: 500,
      id: this.$route.params.id,
      classData: {
        module: "vote",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      },
      options: [],
      list: [],
      data: {
        polls: [
          {
            title: "",
            options: [{ title: "" }, { title: "" }],
          },
        ],
        tags: [],
        categoryNo: 99, // 99: 投票
        isDelete: 2,
        articleId: "",
        articleTitle: "",
        articleContent: "",
        pollsCategory: "2023電競大賞",
        pollsType: "圖片模式",
        pollsSubcategory: "射擊類選手獎",
        pollsResult: 2, // 1:投票完即顯示  2:截止後顯示
      },
      voteImgId: "",
      voteImgUrl: "",
      voteImgName: "",
      voteType: "圖片模式",
      dontChange:true,
      isPreview: false,
      isLoading: false,
      startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      allowChangeType: false,
      prevCategory: "",
      pollJsonUrl: "",
    };
  },
  mounted() {
    if (this.$route.params.searchData) {
      this.data.pollsCategory = this.$route.params.searchData.pollsCategory || '2023電競大賞';
      if (this.data.pollsCategory === "民調") {
        this.data.pollsType = "";
        this.data.pollsSubcategory = "";
        this.setTitle();
      }
      this.$forceUpdate();
    }
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    if(this.data.pollsCategory.indexOf('電競大賞') > -1 && !this.id) this.setTitle();

    if(this.data.pollsCategory.indexOf('2023電競大賞') > -1 ) {
      
      //  this.set2023time()
    }else{
       this.dontChange=false;
    }


    this.checkSession();
    this.getClassTypes();
    this.getData();
    this.updateTags();
  },
  methods: {
    getClassTypes() {
      this.$store
        .dispatch("newtalk/classQueryAll", this.classData)
        .then((result) => {
          this.list = result.datas || [];
        });
    },
    getData() {
      if (this.id) {
        this.voteImgId = this.id;
        this.data.articleId = this.id;
        this.$store
          .dispatch("newtalk/articleQuery", this.data)
          .then((result) => {
            this.data = result || {};
            this.data.polls = this.data.polls || [];
            this.updateData();
            this.updateTime();
          });
      } else {
        this.updateTime();
      }
    },
    updateData() {
      this.voteType = this.data.pollsType;
      this.voteImgUrl = this.data.voteImgUrl;
      this.voteImgName = this.data.voteImgName;
      this.pollJsonUrl = this.data.pollJsonUrl;
      this.updateVoteType();
      if (!this.data.polls.length && this.data.pollsCategory !== "民調") {
        this.data.polls = [this.getEmptyQuestion()];
      }
    },
    updateTags(query) {
      if (this.lastTime) clearTimeout(this.lastTime);
      this.lastTime = setTimeout(() => {
        this.$store
          .dispatch("newtalk/tagQueryAll", { search: query || "" })
          .then((result) => {
            if (result) {
              this.options = [];
              result.forEach((item) => {
                this.options.push({
                  Tag: item.title,
                  UsedCount: item.usedCount,
                });
              });
            }
          });
      }, 1000);
    },
    updateTime() {
      setTimeout(() => {
        this.startTime = this.data.pollsStartTime || this.startTime;
        this.endTime = this.data.pollsEndTime || this.endTime;
        $("#startTimeOnly").val(this.global.getTime(this.startTime));
        if(this.id) $("#endTimeOnly").val(this.global.getTime(this.endTime));
        else $("#endTimeOnly").val("11:59 PM");
      }, 100);
    },
    addTag(newTag) {
      const tag = { Tag: newTag.trim() };
      this.options.push(tag);
      this.data.tags.push(tag);
    },
    addOption(poll) {
      poll.options.push({ title: "" });
    },
    sortOption(poll, index, up) {
      if (up) {
        if (index >= 1) {
          const temp = poll.options[index];
          poll.options[index] = poll.options[index - 1];
          poll.options[index - 1] = temp;
          this.$forceUpdate();
        }
      } else {
        if (index + 1 <= poll.options.length - 1) {
          const temp = poll.options[index];
          poll.options[index] = poll.options[index + 1];
          poll.options[index + 1] = temp;
          this.$forceUpdate();
        }
      }
    },
    removeOption(poll, option) {
      const index = poll.options.indexOf(option);
      if (index !== -1) poll.options.splice(index, 1);
    },
    addQuestion() {
      this.data.polls.push(this.getEmptyQuestion());
    },
    copyQuestion(index) {
      const copy = JSON.parse(JSON.stringify(this.data.polls[index]));
      this.data.polls.splice(index, 0, copy);
    },
    sortQuestion(index, up) {
      if (up) {
        if (index >= 1) {
          const temp = this.data.polls[index];
          this.data.polls[index] = this.data.polls[index - 1];
          this.data.polls[index - 1] = temp;
          this.$forceUpdate();
        }
      } else {
        if (index + 1 <= this.data.polls.length - 1) {
          const temp = this.data.polls[index];
          this.data.polls[index] = this.data.polls[index + 1];
          this.data.polls[index + 1] = temp;
          this.$forceUpdate();
        }
      }
    },
    removeQuestion(poll) {
      if (poll.title) {
        this.$eventBus.$emit(
          "showConfirm",
          "刪除後無法復原，確定刪除？",
          (isOK) => {
            if (isOK) {
              const index = this.data.polls.indexOf(poll);
              if (index !== -1) this.data.polls.splice(index, 1);
            }
          }
        );
      } else {
        const index = this.data.polls.indexOf(poll);
        if (index !== -1) this.data.polls.splice(index, 1);
      }
    },
    getEmptyQuestion() {
      return {
        title: "",
        options: [{ title: "" }, { title: "" }],
      };
    },
    changeVoteTypeId() {
      this.dontChange=false;
      if (this.data.pollsCategory.indexOf('電競大賞') > -1) {
        this.voteType = "圖片模式";
        if (this.prevCategory) {
          this.data.pollsSubcategory = "射擊類選手獎";
        }
        this.endTime = "2022-10-28 23:59:59";
        this.data.pollsResult = 1;
      } else {
        if (
          this.voteType !== "圖片模式" ||
          this.newCategory !== this.data.pollsCategory
        ) {
          this.voteType = "調查";
        }
        this.updateTime();
        this.data.pollsSubcategory = "";
      }
    },
    changeVoteType() {
      this.$eventBus.$emit(
        "showConfirm",
        "更改投票類型會刪除投票選項內容，是否確定更改？",
        (isOK) => {
          if (isOK) {
            this.allowChangeType = true;
            this.setTitle();
            this.data.pollsType = this.voteType;
            this.data.polls = [this.getEmptyQuestion()];
            this.updateVoteType();
          } else {
            if (this.prevCategory) {
              this.data.pollsCategory = this.prevCategory;
              this.prevCategory = "";
            }
            this.allowChangeType = false;
            this.voteType = this.data.pollsType;
          }
        }
      );
    },
    updateVoteType() {
      if (this.data.pollsType === "辯論") {
        this.data.pollsResult = 1; // 投票完即顯示
        this.updateSide("positive");
        this.updateSide("negative");
      } else if (this.data.pollsCategory.indexOf('電競大賞') > -1) {
         if (this.data.pollsCategory.indexOf('2023電競大賞') > -1) {
          this.dontChange=true;
          // this.set2023time();
        }
      } else {
        this.data.pollsResult = 2; //截止後顯示
      }
    },
    updateSide(key) {
      this.data[`${key}SideUrl`] = this.data[`${key}SideUrl`] || "";
      if (!this.data[`${key}SideUrl`].startsWith("http")) {
        const name = key === "positive" ? "correct.png" : "uncorrect.png";
        const sideName = this.data[`${key}SideName`] || name;
        this.data[`${key}SideName`] = sideName;
        this.data[`${key}SideUrl`] = require(`@/assets/img/${sideName}`);
      }
    },
    showContent() {
      this.$refs.voteContent.showContent(this.data);
    },
    showImgContent(data, oIndex) {
      this.$refs.voteImgContent.showContent(data, oIndex);
    },
    updateContent() {
      this.$forceUpdate();
    },
    updateImgContent(data, oIndex) {
      this.data.polls[0].options[oIndex].imageName = data.imageName;
      this.data.polls[0].options[oIndex].imageUrl = data.imageUrl;
      this.data.polls[0].options[oIndex].teamName = data.teamName;
      this.data.polls[0].options[oIndex].gameName = data.gameName;
      this.data.polls[0].options[oIndex].social = data.social;
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
    setTitle() {
      if (this.data.pollsCategory.indexOf('電競大賞') > -1) {
        let content = `${this.data.pollsSubcategory}`;
        this.data.articleTitle = content;
        this.data.articleContent = content;
        setTimeout(() => {
          this.data.polls[0].title = content;
        }, 1500);
      } else if (this.data.pollsCategory === "民調") {
        this.data.articleContent =
          "迎接2024總統大選，各路參選人摩拳擦掌挑戰總統大位，Newtalk新聞帶來最新民意輿情，為您呈現各政黨參選人最即時的民意支持度。";
        this.addTag("2024總統大選");
      } else {
        this.data.articleTitle = "";
        this.data.articleContent = "";
      }
    },
    backList() {
      this.$router.push({
        name: "NewtalkVoteList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    preview() {
      const type = this.data.pollsCategory === "民調" ? "poll" : "vote";
      window.open(
        `${process.env.VUE_APP_VOTEWEB_PATH}/${type}/${this.id}?preview=1`
      );
    },
    changeFile(event, type) {
      const files = event.target.files;
      console.log(event, type);
      if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (type === "json") {
            if (e.target.result.startsWith("data:application/json")) {
              this.pollJsonUrl = e.target.result;
              this.voteImgName = "";
            }
          } else {
            if (e.target.result.startsWith("data:image")) {
              this.voteImgUrl = e.target.result;
              this.voteImgName = "";
            }
          }
        };
        reader.readAsDataURL(file);
      }
    },
    updateFirstImage() {
      this.data.articleContent = this.data.articleContent.replace(/amp;/g, "");
      const imgs = this.data.articleContent.split(
        /<img\s.*?src=(?:'|")([^'">]+)(?:'|")/g
      );
      this.data.imgFirstUrl = imgs.length > 1 ? imgs[1] : "";
    },
    save(preview) {
      this.isPreview = preview;
    },
    // set2023time(){
    //   //10/1~10/22
    //   this.startTime=new moment().format("2023-10-01 00:00:00"),
    //   this.endTime= new moment().format("2023-10-22 23:59:59"),
    //    $("#startTimeOnly").val('12:00 AM');
    //    $("#endTimeOnly").val('11:59 PM');

    //   const startTimeOnly = $("#startTimeOnly").val();
    //   const endTimeOnly = $("#endTimeOnly").val();

    //   this.startTime = new Date(
    //     `${this.startTime.split(" ")[0]} ${startTimeOnly}`
    //   );
    //   this.endTime = new Date(
    //     `${this.endTime.split(" ")[0]} ${endTimeOnly}`
    //   );

    //     console.log(this.startTime)
    //     console.log(this.endTime)
    // },
    submit() {
      if (this.isLoading) return;

      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      if (this.data.pollsCategory !== "民調") {
        this.startTime = new Date(
          `${this.startTime.split(" ")[0]} ${startTimeOnly}`
        );
        this.endTime = new Date(
            `${this.endTime.split(" ")[0]} ${endTimeOnly}`
          );
        // if (this.data.pollsCategory.indexOf('電競大賞') > -1) {
        //   this.endTime = new Date(`${this.endTime}`);
        // } else {
        //   this.endTime = new Date(
        //     `${this.endTime.split(" ")[0]} ${endTimeOnly}`
        //   );
        // }

        // if (this.data.pollsCategory.indexOf('2023電競大賞') > -1) {
        //   this.set2023time();
        // }


        if (this.data.pollsCategory === "") {
          this.$eventBus.$emit("showAlert", "投票分類不能為空");
          return;
        }
        if (this.data.pollsType === "") {
          this.$eventBus.$emit("showAlert", "投票類型不能為空");
          return;
        }
      }

      if (this.data.articleTitle === "") {
        this.$eventBus.$emit("showAlert", "標題不能為空");
        return;
      } else if (this.data.articleTitle.length > 50) {
        this.$eventBus.$emit("showAlert", "標題長度限制50字");
        return;
      }
      if (this.data.articleContent === "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }
      if (this.data.pollsCategory.indexOf('電競大賞') == -1 && this.data.tags.length < 1) {
        this.$eventBus.$emit("showAlert", "請輸入至少一個標籤");
        return;
      }

      if (this.data.pollsCategory === "民調") {
        if (!this.pollJsonUrl) {
          this.$eventBus.$emit("showAlert", "請選擇JSON檔");
          return;
        }

        this.isLoading = true;
        const pollJson = document.getElementById("pollJson");
        if (pollJson.files.length >= 1) {
          this.uploadFile(pollJson.files[0]);
        } else {
          this.submitData();
        }
      } else {
        if (startTimeOnly === "" || !this.global.isValidDay(this.startTime)) {
          this.$eventBus.$emit("showAlert", "請填寫投票開始時間！");
          return;
        }
        if (endTimeOnly === "" || !this.global.isValidDay(this.endTime)) {
          this.$eventBus.$emit("showAlert", "請填寫投票結束時間！");
          return;
        }
        if (!this.global.isValidDayRange(this.startTime, this.endTime)) {
          this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
          return;
        }
        this.isLoading = true;
        this.data.pollsStartTime = new Date(`${this.startTime} UTC`);
        this.data.pollsEndTime = new Date(`${this.endTime} UTC`);

        const voteImg = document.getElementById("voteImg");
        if (this.data.pollsCategory.indexOf('電競大賞') == -1 && voteImg.files.length >= 1) {
          this.data.voteImgId = this.voteImgId || utils.getTimeId();
          this.uploadFile(voteImg.files[0]);
        } else {
          this.submitData();
        }
      }
    },
    uploadFile(files) {
      const data = new FormData();
      data.append("files", files);
      data.append("qppPath", "vote");
      this.$store.dispatch("newtalk/fileUpload", data).then((result) => {
        if (result.status == "1") {
          if (this.data.pollsCategory === "民調") {
            this.data.pollJsonUrl = result.files[0].jsonUrl;
            this.data.voteImgName = files.name;
          } else {
            this.data.voteImgUrl = result.files[0].imageUrl.replace(
              /https:\/\/(s|v).newtalk.tw\//g,
              "https://images.newtalk.tw/resizeForum/800/"
            );
            this.data.voteImgName = files.name;
          }
          this.submitData();
        } else {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        }
      });
    },
    submitData() {
      this.isLoading = true;

      if (this.data.pollsCategory !== "民調") this.updateFirstImage();
      const action = this.id ? "Update" : "Create";
      this.$store
        .dispatch(`newtalk/vote${action}`, this.data)
        .then((result) => {
          if (this.isPreview) {
            this.id = result || this.id;
            this.data.articleId = this.id;
            this.isLoading = false;
            this.preview();
          } else {
            this.backList();
          }
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>

<style>
.multiselect__tags {
  min-height: 34px !important;
  height: auto !important;
}
.dontChange .col-sm-10{
  pointer-events: none;
      filter: contrast(0.8);
}
</style>
