<template>
  <div>
    <div class="modal fade" ref="content" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog">
        <div class="modal-content box">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ $t(`AddChannelCategoryEnum${category}`) }}
            </h4>
          </div>

          <div class="modal-body">
            <textarea class="form-control" v-model.trim="關鍵字" :placeholder="$t('請輸入關鍵字')"
              style="height: 100px;"></textarea>
            <div class="text-muted">
              {{ $t('大量新增關鍵字提示') }}
            </div>
            <div class="text-muted text-red">
              {{ $t('自動忽略特殊符號', ['(.^$*+?|()[]{}\/~!@#%&_=:"' + ";',.<>-" + 關鍵字特殊符號 + ")", "c*a*t=cat"]) }}
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" @click="save()">{{ $t('送出') }}</button>
            <button class="btn btn-normal" @click="cancel()">{{ $t('取消') }}</button>
          </div>
          <div class="overlay" ref="loading">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { 移除特殊字元List } from "@/assets/js/Tool/parseStr";
import { removeDuplicates, 關鍵字特殊處理Function, 關鍵字特殊符號 } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
export default {
  components: {

  },
  data() {
    return {
      關鍵字特殊符號,
      category: true,
      關鍵字: "",
      projectId: "",
    };
  },
  methods: {
    Show(category, projectId) {
      this.projectId = projectId;
      this.category = category;
      $(this.$refs.content).modal("show");
      $(this.$refs.loading).hide();
    },
    async save() {
      $(this.$refs.loading).show();
      let addList = 移除特殊字元List(this.關鍵字.split(","), 關鍵字特殊處理Function);
      addList = removeDuplicates(addList);//去除重覆(自己輸入的重覆)
      let failLog = [];
      for (let w of addList) {
        try {
          let res = await api.CS.AddKeyword({
            Project: this.projectId,
            Text: w,
            Category: this.category
          });
          let isSuccess = (res && res.data && res.data.success) || false;
          if (!isSuccess) {
            throw res && res.data && res.data.errMsg;
          }
        } catch (e) {
          failLog.push(` ${this.$t('新增資料')}: ${w}, ${this.$t('失敗原因')}: ${e} `);
        }
      }
      if (failLog.length > 0) {
        this.$eventBus.$emit("showAlert", `${this.$t('部份資料新增失敗：')}<br>${failLog.join("<br>")}`);
      } else {
        // `新增${addList.length}筆成功`
        this.$eventBus.$emit("showAlert", this.$t('新增X筆成功', [addList.length]));
      }
      this.關鍵字 = "";
      this.$emit("refresh");
      $(this.$refs.loading).fadeOut(400);
    },
    cancel() {
      $(this.$refs.content).modal('hide');
    }
  }
};
</script>
<style scoped>
h4 {
  text-align: center;
}

.text-muted {
  text-align: center;
  padding-top: 20px;
}

.modal-header,
.modal-body {
  padding: 30px;
}

.modal-footer {
  display: flex;
  justify-content: space-around;
}
</style>