<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>會員管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">會員管理</li>
      </ol>
    </section>

    <section class="content" style="min-height: auto">
      <div class="nav-tabs-custom tab-warning">
        <div class="tab-content">
          <div class="box-body">
            
            <div class="top-box box-header boxShadow-none">
              <h3 class="box-title mt-7">玩家暱稱: {{ user.暱稱 || " - " }}</h3>
              <router-link class="btn btn-block btn-success btn-cog"
                :to="{name:'XinMemberDataList'}"
              ><i class="fa fa-user"></i> 返回會員列表
              </router-link>
              <router-link class="btn btn-primary btn-cog"
                :to="{ name: 'XinMemberCustomerList'}"
              ><i class="fa fa-users"></i> 返回客戶列表
              </router-link>
            </div>

            <div class="table-responsive">
              <table
                class="table table-grid table-data table-bordered table-condensed"
              >
                <tbody>
                  <tr>
                    <th>登入方式</th>
                    <td>{{ user.登入方式 || " - " }}</td>
                    <th>綁定門號</th>
                    <td>{{ user.綁定門號 || " - " }}</td>
                    <th>申請日期</th>
                    <td>{{ user.apply || " - " }}</td>
                    <th>綁定日期</th>
                    <td>{{ user.綁定日期 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>暱稱</th>
                    <td>{{ user.暱稱 || " - " }}</td>
                    <th>帳號</th>
                    <td>
                      {{ user.account ? "正式會員" : "非正式會員" }}
                      <button
                        type="button"
                        @click="becomeRegular(user)"
                        v-if="!user.account"
                        class="btn btn-warning ml-10"
                      >
                        轉正
                      </button>
                    </td>
                    <th>遊戲等級</th>
                    <td>{{ user.level || " - " }}</td>
                    <th>登入日期</th>
                    <td>{{ user.最後登入 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>登入狀態</th>
                    <td>{{ user.sid == "1" ? "在線" : "離線" }}</td>
                    <th>角色狀態</th>
                    <td>
                      <p v-if="user.停權狀態 !== 0">
                          {{ getControlStatus("停權", user.停權狀態, user.停權截止) }}</p>
                        <p v-if="user.禁言狀態 !== 0">
                          {{ getControlStatus("禁言", user.禁言狀態, user.禁言截止) }}</p>
                        <p v-if="user.雙平台鎖購買">雙平台鎖交易</p>
                        <p v-if="user.星幣鎖交易">星幣鎖交易</p>
                        <p v-if="user.頭圖上傳">限制頭圖上傳</p>
                        <p v-else-if="isNormalStatus(user)">正常</p> 

                      <!-- <p v-if="user.停權狀態 != 0">{{ banStatus(0, user.停權狀態, user.停權截止) }}</p>
                      <p v-if="user.禁言狀態 != 0">{{ banStatus(1, user.禁言狀態, user.禁言截止) }}</p>
                      <p v-if="user.停權狀態 == 0 && user.禁言狀態 == 0">{{$t('正常')}}</p> -->

                    </td>
                    <th>VIP階級</th>
                    <td>
                      {{ user.vip || " - " }}
                      <button
                        type="button"
                        @click="levelAdjust(user)"
                        v-if="user.level === '6'"
                        class="btn btn-warning ml-10"
                      >
                        調整
                      </button>
                    </td>
                    <th>活躍值</th>
                    <td>{{ user.活躍值 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>榮譽值</th>
                    <td>{{ user.榮譽值 || " - " }}</td>
                    <th>積分</th>
                    <td>{{ user.積分 || " - " }}</td>
                    <th>介紹人</th>
                    <td>{{ user.介紹人 || " - " }}</td>
                    <th>包月截止日期</th>
                    <td>{{ user.包月到期 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>公會</th>
                    <td>{{ user.公會 || " - " }}</td>
                    <th>公會會長</th>
                    <td>{{ user.公會會長 || " - " }}</td>
                    <th>停權次數</th>
                    <td>{{ user.停權次數 || " - " }}</td>
                    <th>停權截止</th>
                    <td>{{ user.停權截止 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>PK幣</th>
                    <td>{{ user.PK || " - " }}</td>
                    <th>鬥幣</th>
                    <td>{{ user.鬥幣 || " - " }}</td>
                    <th>回饋金</th>
                    <td>{{ user.回饋金 || " - " }}</td>
                    <th>回饋卡截止日期</th>
                    <td>{{ user.回饋到期 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>剩餘點數</th>
                    <td>{{ user.storepoints || " - " }}</td>
                    <th>剩餘星幣</th>
                    <td>{{ user.xinvalues || " - " }}</td>
                    <th>銀幣餘額</th>
                    <td>{{ user.銀幣 || " - " }}</td>
                    <th>銅幣餘額</th>
                    <td>{{ user.銅幣 || " - " }}</td>
                  </tr>

                  <tr>
                    <th>紅寶石餘額</th>
                    <td>{{ user.紅寶石 || " - " }}</td>
                    <th>保險箱餘額</th>
                    <td>{{ user.保險箱餘額 || " - " }}</td>
                    <th></th>
                    <td></td>
                    <th></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <template>
              <div class="nav-tabs-custom tab-warning mt-20 box-header" v-if="user">
                <TabComponent :tabs="tabs" :subTabs="subTabs">
                  <!-- 客戶服務查詢的內容 -->
                  <template #1-服務紀錄>
                    <RecordService ref="RecordService" 
                      :types="serviceTypes"
                      :endTypes="serviceTypesDisabled"
                      @updateList="onService()"></RecordService>
                  </template>

                  <template #1-控管紀錄>
                    <RecordControl ref="RecordControl" 
                      :types="serviceTypes"
                      :endTypes="serviceTypesDisabled" 
                      :bindPhone="bindNumber"
                      @updateList="onService()"></RecordControl>
                  </template>

                  <template #1-問題回報紀錄>
                    <RecordProblem ref="RecordProblem"></RecordProblem>
                  </template>
                  <template #1-服務申請紀錄>
                    <RecordServiceApplication ref="RecordServiceApplication"></RecordServiceApplication>
                  </template>

                  <!-- 角色遊戲活動紀錄的內容 -->
                  <template #2-點數查詢>
                    <RecordPointsQuery ref="RecordPointsQuery"></RecordPointsQuery>
                  </template>

                  <template #2-購點欠費紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">購點欠費紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #2-道具查詢>
                    <div class="tab-content">
                      <div class="StayTuned">道具查詢敬請期待</div>
                    </div>
                  </template>
                  <template #2-回饋金查詢>
                    <div class="tab-content">
                      <div class="StayTuned">回饋金查詢敬請期待</div>
                    </div>
                  </template>
                  <template #2-交易紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">交易紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #2-角色階級歷程>
                    <RecordLevel ref="RecordLevel"></RecordLevel>
                  </template>
                  <template #2-遊戲歷程>
                    <div class="tab-content">
                      <div class="StayTuned">遊戲歷程敬請期待</div>
                    </div>
                  </template>

                  <!-- 玩家領獎歷程的內容 -->
                  <template #3-活動抽獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">活動抽獎紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #3-活動領獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">活動領獎紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #3-VIP禮品領獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">禮品領獎紀錄敬請期待</div>
                    </div>
                  </template>
                </TabComponent>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <pre>{{ user }}</pre>

    <MemberVIPDialog ref="MemberVIPDialog" @updateList="onService()" />
    <MemberRegulareDialog
      ref="MemberRegulareDialog"
      @updateList="onService()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "./_Define";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import MemberVIPDialog from "./components/MemberVIPDialog";
import MemberRegulareDialog from "./components/MemberRegulareDialog";

import TabComponent from "./tab/TabComponent.vue";
import RecordService from "./tab/RecordService";
import RecordControl from "./tab/RecordControl";
import RecordProblem from "./tab/RecordProblem";
import RecordServiceApplication from "./tab/RecordServiceApplication";
import RecordPointsQuery from "./tab/RecordPointsQuery";
import RecordLevel from "./tab/RecordLevel";

export default {
  components: {
    router,
    utils,
    MemberVIPDialog,
    MemberRegulareDialog,

    TabComponent,
    RecordService,
    RecordControl,
    RecordProblem,
    RecordServiceApplication,
    RecordPointsQuery,
    RecordLevel,
  },
  props: {
    name: "",
  },
  data() {
    return {
      searchData: {
        搜尋條件: "",
        searchInput: "mimi",
      },
      listSearchData: {},
      message: "", //錯誤訊息
      loading: false,
      user: {},

      tabs: [
        { id: 1, name: "客戶服務查詢" },
        { id: 2, name: "角色遊戲活動紀錄" },
        { id: 3, name: "玩家領獎歷程" },
      ],
      subTabs: {
        1: ["服務紀錄", "控管紀錄", "問題回報紀錄", "服務申請紀錄"],
        2: [
          "點數查詢",
          "購點欠費紀錄",
          "道具查詢",
          "回饋金查詢",
          "交易紀錄",
          "角色階級歷程",
          "遊戲歷程",
        ],
        3: ["活動抽獎紀錄", "活動領獎紀錄", "VIP禮品領獎紀錄"],
      },
      serviceTypes: {},
      serviceTypesDisabled: [],
      nickNames: [],
      bindNumber:'',

      // 權限
      can服務: this.$user.hasPermission(dfn.permission.會員服務記錄查詢),
      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      can服務修改: this.$user.hasPermission(dfn.permission.會員服務記錄修改),
      can服務刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
      can回饋: this.$user.hasPermission(dfn.permission.會員配發回饋金),
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
      can控管記錄: this.$user.hasPermission(dfn.permission.會員控管記錄),
    };
  },
  async created() {
    this.getTypes();
  },
  watch: {},
  computed: {},

  mounted() {
    console.log(this.$route.query);
    this.getDataList();
  },

  methods: {
    getDataList() {
      this.$store.dispatch("xinCRM/會員查詢", this.$route.query).then(
        (res) => {
          this.user = res || [];
          this.findBindNumber();
          
          this.$nextTick(() => {
            this.tabService();
            this.tabControl();
          });
        },
        (err) => {
          this.message = err;
        }
      );
    },

    getTypes() {
      this.$store.dispatch("xinCRM/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
            
          } else {
            //用陣列用作判斷
            this.serviceTypesDisabled.push(item.名稱);  
          }
        }

        console.log(this.serviceTypes);
        console.log(this.serviceTypesDisabled);
      });
    },

    findBindNumber() {
        this.bindNumber = this.user.綁定門號;
    },

    getControlStatus(action, status,  deadline) {
        const actionMap = {
            "停權": () => {
                if (status === 1) {
                    return `停權至 ${deadline} `;
                }
                if (status === 2) return "停權(終止契約)";
                if (status === 3) return "停權(暫時凍結)";
                
            },
            "禁言": () => {
                if (status === 1) {
                    return `禁言至 ${deadline} `;
                }
                if (status === 2) return "禁言(永久禁言)";
            },
            "禁止頭圖": () => "禁止頭圖",
            "星幣鎖交易": () => "禁止頭圖",
            "雙平台鎖交易": () => "雙平台鎖交易",
        };

        return actionMap[action] ? actionMap[action]() : "";
    },

    isNormalStatus(player) {
        return (
            player.停權狀態 === 0 &&
            player.禁言狀態 === 0 &&
            !player.雙平台鎖購買 &&
            !player.星幣鎖交易 &&
            !player.頭圖限制上傳
        );
    },

    onService() {
      // if (!this.searchData.nickName && !this.searchData.phoneNumber) return;
      this.getDataList();
    },

    levelAdjust(player) {
      this.$refs.MemberVIPDialog.show(player);
    },

    becomeRegular(player) {
      this.$refs.MemberRegulareDialog.show(player);
    },

    tabService(){
      this.$refs.RecordService.show(this.user);
    },
    tabControl(){
      this.$refs.RecordControl.show(this.user);
    },


    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },

  },
};
</script>

<style lang="scss" scoped>
/* eslint-disable */
table.table > tbody > tr {
  word-break: break-word;
}
table.table > thead > tr > th {
  word-break: keep-all;
}
.table-responsive {
  overflow-y: hidden;
}

.table-data {
  th,
  td {
    padding: 0.5em;
    border-color: #e0e0e0;
  }

  th {
    background: rgba(232, 232, 232, 0.5);
  }
}

.table-data {
  th {
    text-align: right;
    width: auto;
    min-width: 70px;
    background: rgba(#e8e8e8, 0.5);
    border-right: none;
  }
  td {
    text-align: left;
    width: auto;
    min-width: 110px;
    border-left: none;
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;

  button {
    flex: 1;
  }
}

.StayTuned {
  font-size: 1.5em;
  padding: 1em;
  text-align: left;
  width: 100%;
}

@media only screen and (max-width: 1145px) {
  .btn-unlock {
    margin-left: 0 !important;
    margin-top: 5px;
  }
}
</style>
