@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>中獎管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">中獎管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="nav-tabs-custom tab-warning" v-if="tab != 0">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="tab = 1" style="cursor: pointer">獎品管理</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="tab = 2" style="cursor: pointer"> 中獎名單管理 </a>
          </li>
        </ul>
        <div v-show="tab == 1" class="box-body">
          <div class="box-body">
            <form class="top-box" @submit.prevent="clickSearch()">
              <router-link
                :to="{
                  name: 'PrizeListEdit',
                  params: { id: 'New', searchData ,目前活動 },
                }"
                class="btn btn-block btn-warning btn-add fa fa-plus"
                >新增</router-link
              >
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="目前活動"
                  @change="clickSearch()"
                >
                  <option
                    v-for="(name, key) in listdata"
                    :key="key"
                    :value="name.活動編號"
                  >
                    {{ name.活動名稱 }}
                  </option>
                </select>
              </div>
              <!-- <div class="date-group" style="width: auto">
                <label class="control-label">查詢期間：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right" style="width: 320px">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.結束時間"
                      :propStartDate.sync="searchData.開始時間"
                      propsIn="format"
                    />
                  </div>
                </div>
              </div> -->
              <div
                class=""
                style="
                  display: flex;
                  align-items: center;
                  width: 500px;
                  word-break: keep-all;
                "
              >
                <span>獎品名稱查詢:</span>

                <input
                  type="text"
                  class="form-control p-5"
                  v-model="searchData.獎品名稱"
                  style="padding-right: 0; width: 200px; margin: 0 10px"
                />
                <button
                  class="btn btn-primary pull-left"
                  @click="clickSearch(item)"
                >
                  {{ $t("查詢") }}
                </button>
              </div>
                       <div class="text" style="    margin: 10px 0 0 0;">
                資料總筆數：{{ total }}
              </div> 
            </form>

            <div class="table-responsive mt-10 text-center">
              <table class="table table-hover table-bordered AdListTable">
                <tbody>
                  <tr class="bg-gray text-nowrap">
                    <th style="min-width: 50px; width: 2%">編號</th>
                    <th style="min-width: 80px; width: 5%">活動名稱</th>
                    <th style="min-width: 120px; width: 10%">獎品名稱</th>
                    <th style="min-width: 100px; width: 10%">個數</th>
                    <th style="min-width: 100px; width: 10%">價值(USD)</th>
                    <th style="min-width: 100px; width: 10%">排序</th>
                    <th style="min-width: 100px; width: 10%">異動人員</th>
                    <th style="min-width: 100px; width: 10%">異動時間</th>
                    <th style="min-width: 100px; width: 5%">編輯</th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="11">{{ $t("查詢中") }}</td>
                  </tr>
                  <tr v-else-if="message">
                    <td colspan="11">{{ $t(message) }}</td>
                  </tr>
                  <tr v-else-if="total === 0">
                    <td colspan="11">{{ $t("查無紀錄") }}</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ 目前活動名稱 }}</td>
                    <td>{{ item.獎品名稱 }}</td>
                    <td>{{ item.數量 }}</td>
                    <td>{{ item.價值 }} USD</td>
                    <td class="text-nowrap">
                      <fieldset
                        style="display: flex; flex-direction: column; gap: 5px"
                      >
                        <input
                          type="number"
                          class="form-control p-5"
                          v-model.number="item.排序"
                          min="1"
                          max=""
                          style="padding-right: 0; text-align: center"
                        />
                        <button
                          type="button"
                          class="btn btn-warning btn-add"
                          @click="changeStatus(item)"
                        >
                          <i class="fa fa-arrows-v"></i> 更新
                        </button>
                      </fieldset>
                    </td>
                    <td>
                      {{ item.修改者 == "" ? item.建立者 : item.修改者 }}
                    </td>
                    <td>
                      {{ item.修改者 == "" ? item.建立時間 : item.修改時間 }}
                    </td>

                    <td>
                      <div
                        style="display: flex; flex-direction: column; gap: 5px"
                      >
                        <router-link
                          :to="{
                            name: 'PrizeListEdit',
                            params: { id: item.獎品ID, searchData },
                          }"
                          class="btn btn-primary"
                          >查看</router-link
                        >
                        <button
                          class="btn btn-danger"
                          @click="deleteItem(item)"
                        >
                          <i class="fa fa-fw fa-close"></i> 刪除
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer text-center no-border">
              <Pagination
                ref="pagination"
                :total="total"
                :pageLimitCount="searchData.pageSize"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>
        </div>
        <div v-show="tab == 2" class="box-body">
          <div class="box-body">
            <form class="top-box" @submit.prevent="clickSearch()">
              <router-link
                :to="{
                  name: 'PrizeListEdit',
                  params: { id: 'New', searchData ,目前活動 },
                }"
                class="btn btn-block btn-warning btn-add fa fa-plus"
                >新增</router-link
              >
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="目前活動"
                  @change="clickSearch()"
                >
                  <option
                    v-for="(name, key) in listdata"
                    :key="key"
                    :value="name.活動編號"
                  >
                    {{ name.活動名稱 }}
                  </option>
                </select>
              </div>
              <!-- <div class="date-group" style="width: auto">
                <label class="control-label">查詢期間：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right" style="width: 320px">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.結束時間"
                      :propStartDate.sync="searchData.開始時間"
                      propsIn="format"
                    />
                  </div>
                </div>
              </div> -->
              <div
                class=""
                style="
                  display: flex;
                  align-items: center;
                  width: 500px;
                  word-break: keep-all;
                "
              >
                <span>獎品名稱查詢:</span>

                <input
                  type="text"
                  class="form-control p-5"
                  v-model="searchData.獎品名稱"
                  style="padding-right: 0; width: 200px; margin: 0 10px"
                />
                <button
                  class="btn btn-primary pull-left"
                  @click="clickSearchStatus(item)"
                >
                  {{ $t("查詢") }}
                </button>
              </div>
                       <div class="text" style="    margin: 10px 0 0 0;">
                資料總筆數：{{ total }}
              </div> 
            </form>

            <div class="table-responsive mt-10 text-center">
              <table class="table table-hover table-bordered AdListTable">
                <tbody>
                  <tr class="bg-gray text-nowrap">
                    <th style="min-width: 50px; width: 2%">編號</th>
                    <th style="min-width: 80px; width: 5%">活動名稱</th>
                    <th style="min-width: 120px; width: 10%">獎品名稱</th>
                    <th style="min-width: 100px; width: 10%">玩家暱稱</th>
                    <th style="min-width: 100px; width: 10%">UID</th>
                    <th style="min-width: 100px; width: 10%">抽獎券號碼</th>
                    <th style="min-width: 100px; width: 10%">獎品兌換截止日期</th>
                    <th style="min-width: 100px; width: 10%">玩家回報狀態</th>
                    <th style="min-width: 100px; width: 5%">處理進度</th>
                    <th style="min-width: 100px; width: 5%">處理人員</th>
                    <th style="min-width: 100px; width: 5%">完成時間</th>
                    <th style="min-width: 100px; width: 5%">編輯</th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="12">{{ $t("查詢中") }}</td>
                  </tr>
                  <tr v-else-if="message">
                    <td colspan="12">{{ $t(message) }}</td>
                  </tr>
                  <tr v-else-if="total === 0">
                    <td colspan="12">{{ $t("查無紀錄") }}</td>
                  </tr>
                  <tr v-else v-for="(item, index) in Awarddatas" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ 目前活動名稱 }}</td>
                    <td>{{ item.獎品名稱 }}</td>
                    <td>{{ item.暱稱 }}</td>
                    <td>{{ item.uid }} USD</td>
                    
                    <td>{{ item.獎項序號 }} USD</td>
                    <td>{{ item.兌換截止時間 }} USD</td>

                    <td>
                      {{ item.修改者 == "" ? item.建立者 : item.修改者 }}
                    </td>
                    <td>
                      {{ item.修改者 == "" ? item.建立時間 : item.修改時間 }}
                    </td>

                    <td>
                      {{ item.修改者 == "" ? item.建立者 : item.修改者 }}
                    </td>
                    <td>
                      {{ item.修改者 == "" ? item.建立時間 : item.修改時間 }}
                    </td>

                    <td>
                      <div
                        style="display: flex; flex-direction: column; gap: 5px"
                      >
                        <router-link
                          :to="{
                            name: 'PrizeListEdit',
                            params: { id: item.獎品ID, searchData },
                          }"
                          class="btn btn-primary"
                          >查看</router-link
                        >
                        <button
                          class="btn btn-danger"
                          @click="deleteItem(item)"
                        >
                          <i class="fa fa-fw fa-close"></i> 刪除
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer text-center no-border">
              <Pagination
                ref="pagination"
                :total="total"
                :pageLimitCount="searchData.pageSize"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>




        </div>
      </div>
      <div class="box"></div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->

    <div class="modal fade" ref="ticketAlert">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">
              <span>抽獎券詳細</span>
            </h4>
          </div>

          <div class="modal-body">
            <table class="table table-borderless">
              <tbody>
                <tr class="bg-gray">
                  <th
                    class="text-center"
                    style="width: 20%; border: 1px solid gray"
                  >
                    編號
                  </th>
                  <th style="width: 80%; border: 1px solid gray">抽獎券編號</th>
                </tr>
                <tr v-for="(item, index) in mydata" :key="index">
                  <td class="text-center" style="border: 1px solid gray">
                    {{ index + 1 }}
                  </td>
                  <td style="border: 1px solid gray">
                    <span>{{ item.獎項序號 }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
input[type="checkbox"] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.2em;
  accent-color: #3498db;
  cursor: pointer;
}
label.required::before {
  content: "* ";
  color: #dd4b39;
}
.form-control.sort {
  display: inline-block;
  width: 54px;
  padding: 5px;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
}
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    iCheckRedioButton,
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      bannerType: types.gameBannerType,
      // def: define,
      projectId: this.$store.state.projectId,
      tab: 1,
      目前活動: "",
      目前活動名稱: "",
      searchData: {
        活動編號: -1,
        // 標題: "",
        獎品名稱: "",
        開始時間:  new moment().subtract(3, "month").format("YYYY/MM/DD 00:00:00"),
        結束時間: new moment().format("YYYY/MM/DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      searchListData:{
        活動編號: -1,        
        回報狀態: -1,
        處理進度: -1,
        uid:'',
        獎品名稱: '',
        開始時間:  new moment().subtract(3, "month").format("YYYY/MM/DD 00:00:00"),
        結束時間: new moment().format("YYYY/MM/DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      startTime: new moment().subtract(3, "month").format("YYYY/MM/DD"),
      endTime: new moment().format("YYYY/MM/DD"),
      loading: false,
      message: "",
      listdata: [],
      mydata: [],
      dataStat: [],
      Awarddatas:[],
      messageStat: "",
      total: 0, //總筆數
      totalStat: 0,
      can新增: this.$user.hasPermission(dfn.permission.活動兌換歷程新增),
      can修改: this.$user.hasPermission(dfn.permission.活動兌換歷程修改),
      can刪除: this.$user.hasPermission(dfn.permission.活動兌換歷程刪除),
    };
  },
  async created() {
   if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    getImageUrl() {
      return (item, info) => {
        const logo1File = item.files.find((file) => file.info === info);
        return logo1File ? logo1File.filesUrl : "";
      };
    },
  },
  watch: {
     tab(newValue, oldValue) {
      if(newValue == 2){
          this.getAwarddatas();
      }else{
          this.getDataList();
      }
    },
    // response() {
    //   if (this.response.status == "1")
    //     this.$eventBus.$emit("showAlert", "更新成功!");
    //   else this.$eventBus.$emit("showAlert", "更新失敗!");
    //   this.GetBannerQueryAll();
    // },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // async onPageChange2(pageIdx, pageSize) {
    //   this.searchData.pageNum = pageIdx;
    //   this.searchData.pageSize = pageSize;
    //   this.getData();
    // },
    getAwarddatas(){
      this.loading = true;
      this.searchListData.活動編號 = this.目前活動;
      const selectedItem = this.listdata.find(
        (item) => item.活動編號 === this.目前活動
      );
      this.目前活動名稱 = selectedItem ? selectedItem.活動名稱 : "";
      this.$store.dispatch("xsw/活動中獎名單管理列表查詢", this.searchListData).then(
        (res) => {
          //console.log(res.datas);
          this.message = "";
          this.Awarddatas = res.datas || [];
          this.total = res.total || 0;
          this.loading = false;
          //if (this.$refs.pagination) this.$refs.pagination.setPage(this.searchData.pageNum, res.total);
        },
        (err) => {
          this.message = err;
          this.datas = [];
          this.total = 0;
          this.loading = false;
        }
      );
    },
    getData() {
      this.loading = true;
      //get 活動list
      this.$store
        .dispatch("xsw/活動名稱列表查詢", {
          
        })
        .then(
          (res) => {
            this.message = "";
            this.listdata = res || [];
            if (this.$route.params.searchData != undefined) {
                this.目前活動 = this.$route.params.searchData.活動編號;
            }else{
                this.目前活動 = this.listdata[0].活動編號; 
            }
             
            this.$nextTick(() => {
              this.getDataList();
            });
          },
          (err) => {
            this.message = err;
            this.listdata = [];
          }
        );
    },
    getDataList() {
      this.loading = true;
      this.searchData.活動編號 = this.目前活動;
      const selectedItem = this.listdata.find(
        (item) => item.活動編號 === this.目前活動
      );
      this.目前活動名稱 = selectedItem ? selectedItem.活動名稱 : "";
      this.$store.dispatch("xsw/活動獎品管理列表查詢", this.searchData).then(
        (res) => {
          //console.log(res.datas);
          this.message = "";
          this.datas = res.datas || [];
          this.total = res.total || 0;
          this.loading = false;
          if (this.$refs.pagination) this.$refs.pagination.setPage(this.searchData.pageNum, res.total);
        },
        (err) => {
          this.message = err;
          this.datas = [];
          this.total = 0;
          this.loading = false;
        }
      );
    },

    getStatus() {
      // this.loading = true;
      // this.searchData.活動編號 = this.目前活動;

      // const selectedItem = this.listdata.find(
      //   (item) => item.活動編號 === this.目前活動
      // );
      // this.目前活動名稱 = selectedItem ? selectedItem.活動名稱 : "";

      // this.$store.dispatch("xsw/活動兌換歷程統計", this.searchData).then(
      //   (res) => {
      //     console.log(res);
      //     this.message = "";
      //     this.dataStat = res || [];
      //     //this.totalStat = res.total || 0;
      //     this.loading = false;
      //   },
      //   (err) => {
      //     this.message = err;
      //     this.dataStat = [];
      //   //  this.total = 0;
      //     this.loading = false;
      //   }
      // );
    },
    clickSearchStatus() {
      this.searchListData.pageNum = 1;
      this.getAwarddatas();
    },
    clickSearch() {
      this.searchData.pageNum = 1;
      this.searchListData.pageNum = 1;
       if(this.tab == 2){
          this.getAwarddatas();
      }else{
          this.getDataList();
      }
    },
    openTicket(item) {
      console.log(item);
      this.loading = true;
      // this.$store
      //   .dispatch("xsw/活動兌換歷程查詢", {
      //     活動編號: item.活動編號,
      //     UID: item.uid,
      //     獎項序號: "",
      //     開始時間: this.searchData.開始時間,
      //     結束時間: this.searchData.結束時間,
      //     PageNum: 1,
      //     PageSize: 10,
      //   })
      //   .then(
      //     (res) => {
      //       this.mydata = res.datas || [];
      //       this.openBox();
      //     },
      //     (err) => {
      //       this.message = err;
      //       this.loading = false;
      //     }
      //   );
    },
    changeStatus(item) {
      console.log(item);

      this.$store
        .dispatch("xsw/活動獎品管理修改", {
          獎品ID: item.獎品ID,
          活動編號: item.活動編號,
          獎品名稱: item.獎品名稱,
          數量: item.數量,
          價值: item.價值,
          排序: item.排序,
        })
        .then(() => {
          this.$eventBus.$emit("showAlert", "修改成功");
          this.getDataList();
        });
    },
    openBox() {
      $(this.$refs.ticketAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.ticketAlert).modal("hide");
    },
    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/活動獎品管理刪除", item).then(() => {
            this.$eventBus.$emit("showAlert", "刪除成功");
            this.getData();
          });
        }
      });
    },
    uiDate(date) {
      let d = new Date(date);
      if (date && d)
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
      else return "";
    },
  },
};
</script>