<template>
  <!-- 新增禁言 -->
  <div class="modal fade" id="reportAlert" ref="report_alert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">是否禁言以下使用者ID？</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li :key="index" v-for="(item, index) in propsList">{{ item.uIdFrom }} ({{ item.uNickFrom }})</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button @click="submitMute()" class="btn btn-primary" type="button">確定</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {},
  props: {
    propsList: Array,
  },
  data() {
    return {
      isNewAdd: false,
      isPhone: false,
      muteId: '',
      muteDay: '',
      muteNote: '',
      muteReason: '',
      editorName: this.$store.state.loginUser.EmployeeName,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      response: (state) => state.qppSocialMute.muteResponse,
      query: (state) => state.qppMute.muteQuery,
    }),
  },
  watch: {
    response(value) {
      if (value.status == 1) {
        this.showSuccess(value);
      }
    },
  },
  methods: {
    showReportAlert() {
      $(this.$refs.report_alert).modal('show');
    },
    showSuccess(content) {
      if (content.status == '1') {
        this.$eventBus.$emit('showAlert', '禁言成功');
      } else {
        if (content.message == '') {
          this.$eventBus.$emit('showAlert', '禁言失敗!!');
        } else {
          this.$eventBus.$emit('showAlert', content.message);
        }
      }
      $(this.$refs.report_alert).modal('hide');
      // this.$emit('restList');
    },
    submitMute() {
      this.$eventBus.$emit(
        'showConfirm',
        '禁言後將會刪除文章/留言/回覆，且無法恢復，是否確定？',
        (isOK) => {
          if (isOK) {
            this.addMute(); // 禁言
          }
        }
      );
    },
    //已讀
    haveRead() {
      this.$emit('haveRead');
      this.removeItems(); // 刪除文章
    },
    returnOrder() {
      return;
    },
    //禁言
    addMute() {
      let list = this.propsList
      let data = [];
      for (var i = 0; i < list.length; i++) {
        if(list[i].bDays == 0) {
          this.$eventBus.$emit('showAlert', '天數不得為0！');
          $(this.$refs.report_alert).modal('hide');
          return
        } 
        let note = '';
        let day = 0;
        if(list[i].bDays !== undefined) day = parseInt(list[i].bDays)
        if (list[i].aNote !== null) note = list[i].aNote
        data.push(
          {
            uId: list[i].uIdFrom,
            bDays: day,
            bNote: note
          }
        );
      }
      this.$store.dispatch('qppSocialMute/add', data);
      this.haveRead(); // 已讀
    },
    removeItems() {
      for(var i = 0; i < this.propsList.length; i++) {
        if(this.propsList[i].aType === 4) {
          // 留言
          let comment = {
            cId: this.propsList[i].aTargetId
          }
          this.$store.dispatch('qppSocialReport/deleteComment', comment);
        } else if(this.propsList[i].aType === 5) {
          // 回覆
          let reply = {
            rId: this.propsList[i].aTargetId
          }
          this.$store.dispatch('qppSocialReport/deleteReply', reply);
        } else {
          // 官方、個人、自動貼文
          let query = {
            pId: this.propsList[i].aTargetId
          }
          this.$store.dispatch('qppSocialArticle/DeleteQuery', query);
        }
      }
    },
  },
};
</script>
