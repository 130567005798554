import { render, staticRenderFns } from "./AnnouncementEdit.vue?vue&type=template&id=acbc0f50&scoped=true&"
import script from "./AnnouncementEdit.vue?vue&type=script&lang=js&"
export * from "./AnnouncementEdit.vue?vue&type=script&lang=js&"
import style0 from "./AnnouncementEdit.vue?vue&type=style&index=0&id=acbc0f50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbc0f50",
  null
  
)

export default component.exports