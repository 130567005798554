<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>客戶管理 - 待辦事項</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">待辦事項</li>
      </ol>
    </section>

    <section class="content" style="min-height: auto">
      <div class="box-body box">
        <form
          class="top-box clearfix"
          @submit="
            $event.preventDefault();
            getServiceInfo();
          "
        >
          <!-- <div class="select-group input-group mb-10 mr-10">
            <select
              class="form-control"
              required="required"
              v-model="searchCondition.title"
            >
              <option disabled="disabled" value>請選擇搜尋條件</option>
              <option value="門號">綁定門號</option>
              <option value="玩家識別值">暱稱</option>
            </select>
          </div> -->

          <div class="input-group mb-10 mr-10">
            <input
              type="text"
              class="form-control"
              placeholder="請填寫暱稱"
              v-model.trim="searchData.玩家識別值"
            />
          </div>

          <div class="pull-left mb-10 mr-10">
            <button type="submit" class="btn btn-primary mr-10">
              <i class="fa fa-search"></i>查詢
            </button>
            <button
              type="button"
              class="btn btn-default"
              @click="exportPlayers()"
              :disabled="total == 0"
            >
              <i class="fa fa-upload"></i>匯出
            </button>
          </div>

          <router-link
            class="btn btn-warning pull-right mb-10 ml-10"
            :to="'/Xin/MemberCustomerList'"
            ><i class="fa fa-chevron-left"></i> 返回客戶列表</router-link
          >
        </form>

        <div class="table-responsive mb-10">
          <table class="table table-bordered text-center">
            <thead>
              <tr class="bg-gray">
                <th style="min-width: 50px; width: 5%">編號</th>
                <th style="min-width: 100px; width: 5%">角色暱稱</th>
                <th style="min-width: 110px; width: 5%">狀態</th>
                <th style="min-width: 110px; width: 5%">異動項目</th>
                <th style="min-width: 110px; width: 5%">類別名稱</th>
                <th style="min-width: 150px; width: 15%">內容</th>
                <th style="min-width: 100px; width: 10%">操作時間</th>
                <th style="min-width: 110px; width: 5%">操作人員</th>
                <th style="min-width: 110px; width: 10%">功能</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="8">查詢中</td>
              </tr>
              <tr v-else-if="message">
                <td colspan="8">{{ message }}</td>
              </tr>
              <tr v-else-if="total === 0">
                <td colspan="8">查無紀錄</td>
              </tr>
              <tr v-else v-for="(item, index) in list" :key="item.日期">
                <td>{{ index + 1 }}</td>
                <td>{{ item.玩家識別值 }}</td>
                <td>{{ item.處理狀態 ? "已處理" : "處理中" }}</td>
                <td>{{ item.異動項目 }}</td>
                <td>{{ item.異動動作 }}</td>
                <td style="white-space: pre-wrap">{{ item.新內容 }}</td>
                <td>{{ uiDate(item.異動時間) }}</td>
                <td>{{ item.異動者 }}</td>
                <td>
                  <button
                    class="btn btn-primary mr-5 mb-5"
                    @click="memberService(item, false)"
                  >
                    修改
                  </button>

                  <button
                    type="button"
                    class="btn btn-danger mb-5"
                    @click="deletePlayer(item)"
                  >
                    刪除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="total > searchData.pageSize" class="text-center mt-10">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>

    <MemberServiceDialog
      ref="MemberServiceDialog"
      :types="serviceTypes"
      :nickNames="nickNames"
      @updateList="onService()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import MemberServiceDialog from "./components/MemberServiceDialog";
import dfn from "@/views/projects/xin/_Define";

export default {
  components: {
    moment,
    DateRangePicker,
    Pagination,
    utils,
    MemberServiceDialog,
  },

  data() {
    return {
      /* searchCondition: {
        title: "",
        input: "",
      }, */
      searchData: {
        玩家識別值: "",
        門號: "",
        處理狀態: 0,
        開始時間: new moment()
          .subtract(12, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      nickNames: [],
      players: [],
      tableType: "", //customer, member

      message: "", //錯誤訊息
      loading: false,
      total: 0, //總筆數
      list: [],
      serviceTypes: {},

      can服務: this.$user.hasPermission(dfn.permission.會員服務記錄查詢),
      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      can服務刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
    };
  },
  async created() {
    this.getServiceInfo();

    this.getTypes();
  },

  methods: {
    getTypes() {
      this.$store.dispatch("xinCRM/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) {
            // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          } else {
            this.$set(
              this.serviceTypesDisabled,
              item.編號,
              item.名稱 + " (停用)"
            );
          }
        }

        console.log(this.serviceTypes);
      });
    },

    getServiceInfo() {
      
      this.message = "";
      this.loading = true;
      this.list = [];
      this.$store.dispatch("xinCRM/會員服務記錄查詢", this.searchData).then(
        (res) => {
          console.log(res);
          this.list = res.datas || [];
          // this.list = list.filter(data => data.處理狀態 === 0);
          this.total = res.total || 0;
          this.message = "";
          this.loading = false;
          this.nickNames.push(this.searchData.玩家識別值);
          this.routerSet();
        },
        (err) => {
          this.list = [];
          this.total = 0;
          this.message = err;
          this.loading = false;
        }
      );
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      // console.log(this.searchData.pageNum, this.searchData.pageSize);
      this.getServiceInfo();
    },

    memberService(player, isAdd) {
      this.$refs.MemberServiceDialog.show(player, isAdd);
    },

    onService() {
      this.getServiceInfo();
    },

    deletePlayer(item) {
      console.log(item.玩家異動紀錄ID);
      this.$eventBus.$emit(
        "showConfirm",
        "確定要刪除這則服務記錄嗎?",
        (isOK) => {
          if (isOK) {
            this.$store
              .dispatch("xinCRM/會員服務記錄刪除", {
                玩家異動紀錄ID: item.玩家異動紀錄ID,
              })
              .then(
                (res) => {
                  this.$eventBus.$emit("showAlert", "刪除成功！");
                  this.getServiceInfo();
                },
                (err) => {
                  this.$eventBus.$emit("showAlert", err);
                }
              );
          }
        }
      );
    },

    routerSet() {
      let query = {};
      query["玩家識別值"] = this.searchData.玩家識別值;
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({ query });
      }
      // console.log(this.$route.query);
    },

    /* updateSearchData() {
      this.searchData.玩家識別值 = "";
      this.searchData.門號 = "";

      //watch 連動
      if (this.searchCondition.title) {
        this.$set(
          this.searchData,
          this.searchCondition.title,
          this.searchCondition.input
        );

        this.getServiceInfo()
      }
    }, */

    deletePlayerService(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          /* this.$store
            .dispatch("xinGameIntro/GameOperationDel", item)
            .then((result) => {
              this.getServiceInfo();
            }); */
        }
      });
    },

    exportPlayers() {
      let data = utils.deepCloneObj(this.list);
      let items = [];
      data.forEach((item) => {
        items.push(item);
      });
      utils.exportData(
        items,
        `${utils.formatTime(new Date(), "YYYYMMDD")}_${
          this.searchData.玩家識別值
        }_客戶管理_待辦事項.xlsx`,
        "客戶管理 - 待辦事項"
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>
