<template>
  <div id="ForumReportList">
    <section class="content-header">
      <h1>動態牆檢舉訊息</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">動態牆檢舉訊息</li>
      </ol>
    </section>
    <section class="content forum-report">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix">
              <label class="control-label">檢舉時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                  :propTimePicker="true"
                  v-on:restList="GetRestList"
                />
              </div>
            </div>
            <div class="select-group">
              <select
                id="selectUnit"
                class="form-control"
                required="required"
                v-model="searchData.aStatus"
                @change="queryAll()"
              >
                <option value="-1">待確認</option>
                <option value="1">已讀</option>
              </select>
            </div>
            <div class="select-group">
              <select
                id="selectUnit"
                class="form-control"
                required="required"
                v-model="searchData.uIdType"
              >
                <option value="from">被檢舉人</option>
                <option value="to">檢舉人</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="被檢舉人ID"
                type="text"
                v-model="searchData.uId"
              />
            </div>
            <div class="form-group clearfix">
              <label class="control-label">檢舉原因：</label>
              <div class="select-group">
                <select
                  id="selectUnit"
                  class="form-control"
                  required="required"
                  v-model="searchData.aReasonType"
                  @change="queryAll()"
                >
                  <option value="">全部</option>
                  <option value="垃圾訊息">垃圾訊息</option>
                  <option value="不實資訊">不實資訊</option>
                  <option value="裸露或色情">裸露或色情</option>
                  <option value="暴力">暴力</option>
                  <option value="仇恨言論">仇恨言論</option>
                  <option value="詐騙與詐欺">詐騙與詐欺</option>
                  <option value="霸凌或騷擾">霸凌或騷擾</option>
                  <option value="侵犯智慧財產權">侵犯智慧財產權</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
            <div class="btns">
              <button @click="queryAll()" class="btn btn-default" type="button">查詢</button>
              <button @click="submitReport()" class="btn btn-danger" type="button">
                <i class="fa fa-minus-circle"></i> 禁言
              </button>
              <button @click="deleteAll()" class="btn btn-warning" type="button">
                <i class="fa fa-trash"></i> 批次刪除
              </button>
              <button @click="haveRead()" class="btn btn-primary" type="button">
                <i class="fa fa-check"></i> 已讀
              </button>
            </div>
            <p class="text text-red" style="width:100%">* 禁言天數不輸入或大於3650天，為永久禁言。</p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1200">
              <tbody>
                <tr class="bg-gray">
                  <th>
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propChecked="checkAllStatus"
                          v-model="checkAllStatus"
                          v-on:input="checkAll()"
                        />
                      </label>
                    </div>
                  </th>
                  <th>編號</th>
                  <th style="min-width: 80px;">來源</th>
                  <th style="min-width: 120px;">檢舉日期</th>
                  <th style="min-width: 120px;">檢舉人ID</th>
                  <th style="min-width: 120px;">被檢舉人ID</th>
                  <th style="min-width: 50px;">類型</th>
                  <th style="min-width: 100px;">文章ID</th>
                  <th style="min-width: 180px;">檢舉內容</th>
                  <th style="min-width: 100px;">禁言天數</th>
                  <th style="min-width: 200px;">禁言備註</th>
                </tr>
                <tr v-if="reportTotal === 0">
                  <td class="text-center" colspan="11">沒有資料</td>
                </tr>
                <tr :key="index" v-else v-for="(item, index) in reportList">
                  <td class="text-center">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propChecked="item.checked"
                          v-model="item.checked"
                          v-on:input="checkItem(item)"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-center">{{ index+1 }}</td>
                  <td class="text-center">{{ source[item.uKind] }}</td>
                  <td class="text-center">{{ item.aTimeStamp | dateFormat }}</td>
                  <td>{{ item.uIdTo }} <br> ({{ item.uNickTo }})</td>
                  <td>{{ item.uIdFrom }} <br> ({{ item.uNickFrom }})</td>
                  <td class="text-center">{{ type[item.aType] }}</td>
                  <td @click="showContent(item)" class="link">{{ item.aTargetId }}</td>
                  <td>{{item.aReasonType}}</td>
                  <td>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="min-width: 100px;"
                      type="number"
                      v-model="item.bDays"
                      v-on:input="itemDays(item)"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control text-center"
                      min="1"
                      style="min-width: 100px;"
                      type="text"
                      v-model="item.aNote"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="reportTotal"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-show="reportTotal > 0"
          />
        </div>
      </div>
    </section>
    <forumReportAlert
      :propsList="getMuteList"
      ref="forumReportAlert"
      v-on:restList="GetRestList"
      v-on:haveRead="haveRead"
    />
    <forumContent
      :isReportPage="true"
      ref="forumContent" 
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import iCheckCheckBox from '@/components/iCheck_CheckBox';
import iCheckMultiCheckBox from '@/components/iCheckMultiCheckBox';
import forumReportAlert from '@/views/projects/qppSocial/ForumReportAlert';
import forumContent from '@/views/projects/qppSocial/ForumContent';

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    iCheckMultiCheckBox,
    forumReportAlert,
    forumContent,
  },
  data() {
    return {
      editorName: this.$store.state.loginUser.EmployeeName,
      checkAllStatus: 0,
      propsItem: {},
      categoryNo: '',
      searchData: {
        uIdTo: '',
        uIdFrom: '',
        uId: '',
        uIdType: 'to',
        aStatus: -1,
        aReasonType: '',
        StartTime: new moment()
          .subtract(3, 'M')
          .format('YYYY/MM/DD'),
        EndTime: new moment().format('YYYY/MM/DD'),
        page: 1,
        pageSize: 20,
      },
      type: {
        1: '官方',
        2: '個人',
        3: '自動貼文',
        4: '留言',
        5: '回覆',
      },
      source: {
        S1947643262C: '星城',
        S9685080560C: 'QPP',
      },
      checkedList: [], // 勾選清單
      muteIdList: [], // 禁言ID (電話號碼)
      getMuteList: [], // 禁言者詳細清單
      getMuteId: [], // 整理不重複的 禁言ID (電話號碼)
      articleIdList: [], // 禁言文章ID
      getArticleId: [], // 整理不重複的 禁言文章ID
      getAIdList: [], // 檢舉單號列表
      deleteList: {
        post: [],
        comment: [],
        reply: [],
      }
    };
  },
  mounted() {
    this.queryAll();
  },
  computed: {
    ...mapState({
      reportList: (state) => state.qppSocialReport.reportList.accuseList,
      reportTotal: (state) => state.qppSocialReport.reportList.totalCount,
      response: (state) => state.qppReport.response,
    }),
  },
  watch: {
    reportList() {
      this.$refs.pagination.setPage(this.searchData.page, this.reportTotal);
    },
  },
  methods: {
    itemDays(item) {
      if (item.days == 0) {
        this.$eventBus.$emit('showAlert', '天數不得為0！');
        item.days = '';
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;

      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;

      this.resetList(); //重置清單
      this.queryAll();
    },
    pageDisplay() {
      let page = this.searchData.pageSize;
      this.onPageChange(1, Number(page));
    },
    clickSearch() {
      this.checkAllStatus = 0; //取消全選
      this.searchData.muteId = this.searchData.targetMobile;
      this.onPageChange(1, this.searchData.pageSize);
      if (this.searchData.muteId != '') {
        this.$store.dispatch('qppReport/search', this.searchData);
      }
    },
    //重置清單
    resetList() {
      this.muteIdList = [];
      this.checkedList = [];
      this.getMuteList = [];
      this.articleIdList = [];
    },
    GetRestList() {
      this.queryAll();
    },
    queryAll() {
      this.searchData.aStatus = parseInt(this.searchData.aStatus)
      if(this.searchData.uId != '') {
        if(this.searchData.uIdType == 'to') {
          this.searchData.uIdTo = this.searchData.uId;
          this.searchData.uIdFrom = '';
        } else {
          this.searchData.uIdTo = '';
          this.searchData.uIdFrom = this.searchData.uId;
        }
      } else {
        this.searchData.uIdTo = '';
        this.searchData.uIdFrom = '';
      }
      this.$store.dispatch('qppSocialReport/search', this.searchData);
    },
    checkAll() {
      if (this.reportList != undefined) {
        for (var i = 0; i < this.reportList.length; i++) {
          if (this.checkAllStatus === 1) {
            this.reportList[i].checked = 1;
          } else {
            this.reportList[i].checked = 0;
          }
        }
      }
    },
    checkItem(item) {
      let id = item.aTargetId;
      let aId = item.aId;
      let muteId = item.uIdFrom;
      let articleId = item.reportTargetId;
      let days = item.days == '' || item.days == undefined ? 0 : item.days;

      if (item.checked === 1) {
        if (this.checkedList.indexOf(id) === -1) {
          this.checkedList.push(id);
          this.getMuteList.push(item);
        }
        if (this.checkedList.indexOf(aId) === -1) {
          this.getAIdList.push(aId);
        }
        if (this.checkedList.indexOf(muteId) === -1) {
          this.muteIdList.push(muteId);
        }
        if (this.checkedList.indexOf(articleId) === -1) {
          this.articleIdList.push(articleId);
        }
      } else {
        let index = this.checkedList.indexOf(id);
        this.muteIdList.splice(index, 1); //被檢舉者ID (reportedId)
        this.checkedList.splice(index, 1); //編號ID (serialNumber)
        this.getAIdList.splice(index, 1); //檢舉單號ID (aId)
        this.articleIdList.splice(index, 1); //文章ID (reportTargetId)
        this.getMuteList.splice(index, 1); //全部
      }
    },
    submitReport() {
      if (this.getMuteList.length === 0) {
        this.$eventBus.$emit('showAlert', '您尚未選擇禁言對象！');
        return;
      }
      this.$refs.forumReportAlert.showReportAlert();

      let muteRes = Array.from(new Set(this.muteIdList));
      let idRes = Array.from(new Set(this.articleIdList));
      this.getMuteId = muteRes;
      this.getArticleId = idRes;
    },

    //已讀
    haveRead() {
      let data = [];
      this.getMuteList.forEach(item => {
        data.push({
          aId: item.aId,//檢舉Id
          aStatus: 1,//處理狀態{-1:預設,0:待確認,1:已讀}
          uKind: item.uKind,
          aReasonType: item.aReasonType,
        })
      })
      this.$store.dispatch('qppSocialReport/edit', data)
      setTimeout(() => {
        this.queryAll();
      }, 500);
    },

    //顯示文章內容
    showContent(data) {
      if(data !== undefined) {
        this.categoryNo = data.aType
        this.propsItem = data
      } else {
        this.categoryNo = 0
      }
      this.$refs.forumContent.showForumContent(data, data.aType);
    },
    deleteAll() {
      console.log(this.getMuteList);
    }
  },
};
</script>
