<template>
  <!-- 內容 -->
  <div class="modal fade" id="forumContent" ref="forum_content">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title blue">{{ type[actionType] }}-{{ id }}</h4>
        </div>
        <template v-if="loading">
          <div class="modal-body">Loading...</div>
        </template>
        <template v-else>
          <div class="modal-body" v-if="mainContent != '' || mainMedia != ''">
            <div class="text-center" v-if="mainMedia != ''">
              <img :src="mainMedia" style="width: 250px;">
            </div>
            <p v-html="mainContent" v-if="mainContent != ''"></p>
          </div>
          <div class="modal-body" v-else>查詢無任何資料</div>
          <div
            class="modal-body bg-gray-light ml-20 mr-20 mb-20"
            v-html="content"
            v-if="(actionType == 4 || actionType == 5) && content != ''"
          ></div>
          <div
            class="modal-body bg-gray-light ml-20 mr-20 mb-20"
            v-if="(actionType == 4 || actionType == 5) && content == ''"
          >查詢無任何資料</div>
        </template>
        <div class="modal-footer">
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
          <button
            class="btn btn-danger pull-right mr-10"
            type="button"
            @click="deleteItem"
            v-if="isDelete == 0"
          >
            刪除
        </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  // components: {},
  // props: {
  //   propsItem: Object, // 單筆文章/留言id
  //   propsCategoryNo: '', // 項目類別 1:官方、2:個人、3:自動、4:留言、5:回覆
  // },
  props: ["getDataList", "isReportPage"],
  data() {
    return {
      loading: true,
      actionType: 0,
      isDelete: 0,
      id: '',
      item: {},
      mainContent: '',
      mainMedia: '',
      content: '',
      type: {
        1: '官方',
        2: '個人',
        3: '自動貼文',
        4: '留言',
        5: '回覆',
      },
    };
  },
  computed: {

  },
  watch: {
  },
  methods: {
    showForumContent(item, actionType) {
      this.resetData();
      this.actionType = actionType
      this.item = item
      if(!this.isReportPage) {
        this.isDelete = 
          actionType == 4 ?
          item.cDelete : actionType == 5 ?
          item.rDelete : item.pDelete
      }
      this.getData();
      $(this.$refs.forum_content).modal('show');
    },
    resetData() {
      this.content = ''
      this.id = ''
      this.mainContent = ''
      this.mainMedia = ''
    },
    //取得該公告資料
    getData() {
      if(this.actionType == 4) {
        let id = this.isReportPage ? this.item.aTargetId : this.item.cId
        api.qppSocial.comment.query({ cId: id })
         .then(({data}) => {
            if(data.status == '1') {
              this.content = data.resultData.cContent;
              this.id = data.resultData.cId;
              this.mainContent = data.resultData.pContent;
            }
            this.loading = false
          })
      } else if(this.actionType == 5) {
        let id = this.isReportPage ? this.item.aTargetId : this.item.rId
        api.qppSocial.reply.query({ rId: id })
        .then(({data}) => {
          if(data.status == '1') {
                this.content = data.resultData.rContent;
                this.id = data.resultData.rId;
                this.mainContent = data.resultData.cContent;
              }
              this.loading = false
            })
      } else {
        let id = this.isReportPage ? this.item.aTargetId : this.item.pId
        api.qppSocial.post.query({ pId: id })
          .then(({data}) => {
              if(data.status == '1') {
                let num = data.resultData.contents.findIndex(item => item.lang == data.resultData.pLang);
                this.mainContent = data.resultData.contents[num].content;
                this.mainMedia = data.resultData.pMedia;
              }
              this.loading = false
            })
      }
    },
    deleteItem() {
      const storeName = 
        this.actionType == 4 || this.actionType == 5 ?
        'qppSocialReport' : 'qppSocialArticle'
      const storePath = 
        this.actionType == 4 ?
        'deleteComment' : this.actionType == 5 ?
        'deleteReply' : 'DeleteQuery'
      const data = 
        this.actionType == 4 ?
        { cId: this.item.cId } : this.actionType == 5 ?
        { rId: this.item.rId } : { pId: this.item.pId }
      var searchData = []
      searchData.push(data)
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch(`${storeName}/${storePath}`, searchData).then((res) => {
            if(res.status == '1') {
              $(this.$refs.forum_content).modal("hide");
              this.$eventBus.$emit('showAlert', '刪除成功');
              if (this.getDataList) {
                this.getDataList();
              }
            }
          });
        }
      });
    }
  },
};
</script>
