<template>
  <div class="tab-content">
    <form class="top-box clearfix">
      <div class="form-group m-0">
        <label class="control-label pull-left mt-7">查詢區間：</label>
        <div class="input-group date pull-left mb-10 mr-10">
          <div class="input-group-addon fa fa-calendar"></div>
          <DateRangePicker
            :propStartDate.sync="searchData.開始時間"
            :propEndDate.sync="searchData.結束時間"
            :propTimePicker="false"
          />
        </div>

        <div class="select-group input-group mb-10 mr-10">
          <select
            v-model="searchData.暱稱"
            class="form-control"
            required
          >
            <option value="" selected>全部角色</option>
            <option v-for="nick in nickList" :value="nick" :key="nick">
              {{ nick }}
            </option>
          </select>
        </div>

        <button type="submit" class="btn btn-primary pull-left mb-10">
          <i class="fa fa-search"></i>查詢
        </button>
      </div>
    </form>

    <div
        class="level-wrapper"
        style="display: flex; justify-content: space-between"
      >
    
      <div class="table-responsive mb-10" style="width: 48%">
        <table class="table table-bordered text-center">
          <thead>
            <tr class="bg-gray">
              <th style="min-width: 40px; width: 2%">
                編號
              </th>
              <th style="min-width: 100px; width: 5%">
                角色暱稱
              </th>
              <th style="min-width: 60px; width: 5%">
                會員階級
              </th>
              <th style="min-width: 60px; width: 5%">年</th>
              <th style="min-width: 40px; width: 5%">
                月份
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="8">查詢中</td>
            </tr>
            <tr v-else-if="message">
              <td colspan="8">{{ message }}</td>
            </tr>
            <tr v-else-if="list.length === 0">
              <td colspan="8">查無紀錄</td>
            </tr>
            <tr v-else v-for="(item, index) in pagedData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.暱稱 }}</td>
              <td>10</td>
              <td>2024</td>
              <td>5月</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="chartWrapper" style="width: 51%">
        <highcharts
          ref="highcharts"
          :options="chartOptions"
        ></highcharts>
      </div>

    </div>

    <div v-if="total > pageSize" class="text-center mt-10">
      <Pagination
        :pageLimitCount="pageSize"
        :total="total"
        v-on:pageMethod="onPageChange"
      />
    </div>

  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";


export default {
  components: {
    moment,
    DateRangePicker,
    Pagination,
    utils,
  },
  data() {
    return {
      searchData: {
        暱稱: "mimi",
        開始時間: new moment()
          .subtract(6, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
      },
      nickList: ["eeee", "aaaaa", "mimi"],

      message: "", //錯誤訊息
      loading: false,
      total: 13, //總筆數
      //從外邊傳進來
      user: {
        account: "0001",
        暱稱: ["eeee", "aaaaa", "mimi"],
        綁定門號: "0928123451",
        登入方式: "手機綁定",
        level: "7",
        生日: "2000-06-19",
        性別: "男",
        最後登入: "2024-04-30 13:50:46",
        角色狀態: "正常",
        city: "臺中市",
        zipcodes: "406北屯區",
        add: "市政北二路238號28樓",
      },

      list: [
        {
          暱稱: "mimi1",
          日期: "2024/05/17 18:08:34",
          發佈者: "李如欣",
          內容: "測試停權",
          分類: 230,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi2",
          日期: "2024/05/17 13:54:51",
          發佈者: "李如欣",
          內容: "測試用www",
          分類: 234,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/26 09:51:55",
          發佈者: "李如欣",
          內容: "test",
          分類: 226,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 17:09:59",
          發佈者: "李如欣",
          內容: "77777",
          分類: 233,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 17:09:57",
          發佈者: "李如欣",
          內容: "77777",
          分類: 233,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 17:09:29",
          發佈者: "李如欣",
          內容: "77777",
          分類: 232,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 16:43:39",
          發佈者: "李如欣",
          內容: "回饋金50",
          分類: 229,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 16:43:37",
          發佈者: "李如欣",
          內容: "回饋金50",
          分類: 229,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 11:49:33",
          發佈者: "林立國",
          內容: "test rebate 10",
          分類: 226,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 11:46:27",
          發佈者: "林立國",
          內容: "test rebate 10",
          分類: 226,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },

        {
          暱稱: "mimi",
          日期: "2024/04/24 11:46:25",
          發佈者: "林立國",
          內容: "test rebate 10",
          分類: 226,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi",
          日期: "2024/04/24 11:46:19",
          發佈者: "林立國",
          內容: "test rebate 10",
          分類: 226,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
        {
          暱稱: "mimi13",
          日期: "2024/04/24 10:44:23",
          發佈者: "李如欣",
          內容: " 回收回饋金10",
          分類: 230,
          專案識別: "omp_xin_my_web",
          啟用YN: 0,
        },
      ],
      
      serviceTypes: {},
      serviceTypesDisabled: {},
      pageNum: 1,
      pageSize: 10,

      chartOptions: {
        chart: {
          type: "spline",
        },
        title: {
          text: "角色階級歷程",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [
            "2024/02",
            "2024/03",
            "2024/04",
            "2024/05",
            "2024/06",
            "2024/07",
          ],
          accessibility: {
            description: "Months of the year",
          },
        },
        yAxis: {
          title: {
            text: "等級",
          },
          labels: {
            format: "{value}°",
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            name: "Tokyo",

            data: [5.2, 5.7, 8.7, 13.9, 18.2, 21.4],
          },
          {
            name: "Bergen",

            data: [1.6, 3.3, 5.9, 10.5, 13.5, 14.5],
          },
        ],
      },
    };
  },
  mounted() {},

  computed: {
    pagedData() {
      return this.list.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    },
  },

  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
      console.log(this.pageNum, this.pageSize);
    },

    getServiceInfo() {
      /*  this.loading = true;
      this.$store.dispatch("xsw/會員服務記錄查詢", this.searchData)
      .then( res => {
        this.list = res.datas || [];
        this.total = res.total || 0;
        this.message = "";
        this.loading = false;
      }, err => {
        this.list = [];
        this.total = 0;
        this.message = err;
        this.loading = false;
      }); */
    },


   

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>