<template>
    <div>
        <section class="content-header">
            <h1> {{ $t('發話歷程') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('發話歷程') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="tool">
                        <div style="display: flex;">
                            <i class="fa fa-calendar addon"></i>
                            <DateRangePicker :propEndDate.sync="searchData.EDate" :propStartDate.sync="searchData.SDate"
                                v-on:update:date="updatedate" propsIn="format2" />
                        </div>

                        <input class="form-control searchBox" type="text" :placeholder="$t('請輸入暱稱')"
                            v-model.trim="searchData.Partaker" style="width: 180px;" />
                        <select class="form-control" style="width: 180px;" v-model="searchData.ChannelType">
                            <option :value="0">{{ $t('全部頻道') }}</option>
                            <option :value="item" v-for="item of ChannelOptions">{{ $t('ChannelType' + item) }}</option>
                        </select>
                        <button class="btn btn-primary" @click="onSearch()"> {{ $t('查詢') }} </button>
                        <button class="btn btn-default" @click="exportData()"> {{ $t('匯出') }} </button>
                        <span class="sapn-tip text-red">{{ $t('僅可匯出一日的歷程') }}</span>
                    </div>


                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="bg-gray">
                                <th>{{ $t('建立時間') }}</th>
                                <th>{{ $t('玩家暱稱') }}</th>
                                <th>{{ $t('動作/頻道') }}</th>
                                <th>{{ $t('接收人') }}</th>
                                <th>{{ $t('內容') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of list" :key="item.SendId">
                                <td>{{ item.SendTime }}</td>
                                <td>
                                    <span :class="玩家控管功能 ? 'player-link' : ''" @click="玩家控管Click(item, item._玩家暱稱)">{{
                item._玩家暱稱 }}</span>
                                </td>
                                <td>{{ $t(item._act) }}</td><!--ACTEnum-->
                                <td>
                                    <span :class="玩家控管功能 ? 'player-link' : ''" @click="玩家控管Click(item, item._接收人)">{{
                item._接收人 }}</span>
                                </td>
                                <td>
                                    <template v-if="item.MsgType == MsgTypeEnum.Photo">
                                        <img :src="item.Content" alt="" style="max-width: 50px;"
                                            @click.stop="popUpImg(item.Content)">
                                    </template>
                                    <template v-else-if="item.MsgType == MsgTypeEnum.Voice">
                                        <button class="btn control-btn" @click="playAudio(item.Content)">{{ $t('播放音訊')
                                            }}</button>
                                    </template>
                                    <template v-else-if="item.MsgType == MsgTypeEnum.Sticker">
                                        &lt;{{ $t('貼圖') }}&gt;{{ item.Content }}
                                    </template>
                                    <template v-else>
                                        {{ item.Content }}
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="list.length == 0">
                                <td colspan="5">{{ $t('查詢無任何資料') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="box-footer text-center no-border">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <PopUpImg ref="popUpImg"></PopUpImg>
    </div>
</template>
<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import { mapState } from "vuex";
import { MsgTypeEnum, ChannelTypeEnum, GetMonitoringHistory } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
import PopUpImg from "@/views/projects/ChannelMonitor/components/PopUpImg";
import { H5AudioPlayer } from "@/store/MessageSystem/H5AudioPlayer";
import { ProjectSetting } from "@/store/MessageSystem/messageSystemConst";
import Pagination from "@/components/Pagination";
const PlayerFn = {
    async omp_nmj(item, target) {
        this.$router.push({
            name: 'NmjPlayerData',
            query: {
                nick: target
            }
        });
    },
    async omp_xin_my_web(item, target) {
        this.$router.push({
            name: 'XswMemberData',
            query: {
                nick: target
            }
        });
    }
}
const XLSX = require('xlsx');
function wait(sec) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res();
        }, sec * 1000);
    })
}
export default {
    components: {
        DateRangePicker,
        PopUpImg,
        Pagination
    },
    data() {
        return {
            searchData: {
                SDate: moment().format("YYYY-MM-DD"),
                EDate: moment().format("YYYY-MM-DD"),
                Partaker: "",
                ChannelType: 0,//全部
                Page: 1
            },
            list: [],
            total: 0,
            pageSize: 20
        };
    },
    computed: {
        ...mapState({
            projectId: state => state.projectId,
        }),
        MsgTypeEnum() {
            return MsgTypeEnum;
        },
        ChannelTypeEnum() {
            return ChannelTypeEnum;
        },
        SampleRate() {
            return ProjectSetting.SampleRate(this.projectId)
        },
        DegreeNoiseRate(){
            return ProjectSetting.DegreeNoiseRate(this.projectId)
        },
        ChannelOptions() {
            let channelTypes = ProjectSetting.ChannelTypes(this.projectId);
            let keys = Object.keys(channelTypes);
            let all = [];
            for(let k of keys){
                all = all.concat(channelTypes[k]);
            }
            return all;
        },
        玩家控管功能() {
            return PlayerFn[this.$store.state.projectId];
        },
    },
    async mounted() {
        try {
            this.initSearchData();
            if (this.searchData.Partaker) {
                await this.search();
            }
        } catch (e) {
            this.$eventBus.$emit("showAlert", e);
        }
        $(this.$refs.loading).fadeOut(400);
    },
    methods: {
        玩家控管Click(item, target) {
            if (this.玩家控管功能) {
                this.玩家控管功能.call(this, item, target);
            }
        },
        async playAudio(audioUrl) {
            try {
                await H5AudioPlayer(audioUrl, this.SampleRate, this.DegreeNoiseRate);
            } catch (e) {
                this.$eventBus.$emit("showAlert", `${this.$t('播放音訊出錯：')}：${e}`);
            }
        },
        updatedate(a, b) {
            this.searchData.SDate = a;
            this.searchData.EDate = b;
        },
        popUpImg(Content) {
            this.$refs.popUpImg.Open(Content);
        },
        initSearchData() {
            this.searchData.SDate = this.$route.query.SDate || moment().format("YYYY-MM-DD");
            this.searchData.EDate = this.$route.query.EDate || moment().format("YYYY-MM-DD");
            this.searchData.Partaker = this.$route.query.Partaker || "";
            this.searchData.ChannelType = this.$route.query.ChannelType || 0;
            this.searchData.Page = this.$route.query.Page || 1;
        },
        async onPageChange(page) {
            $(this.$refs.loading).show();
            try {
                this.initSearchData();
                this.searchData.Page = page;
                if (this.searchData.Partaker) {
                    await this.search();
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async onSearch() {
            if (!this.searchData.Partaker) {
                this.$eventBus.$emit("showAlert", this.$t('請輸入暱稱'));
                return;
            }
            this.searchData.Page = 1;
            $(this.$refs.loading).show();
            try {
                await this.search();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        async search() {
            let query = {
                ChannelType: this.searchData.ChannelType,
                Partaker: this.searchData.Partaker,
                SDate: this.searchData.SDate,
                EDate: this.searchData.EDate,
                Page: this.searchData.Page,
            };
            try {
                let { Total, list } = await GetMonitoringHistory(query, this.projectId);//await this.apiSearch(query);
                this.total = Total;
                this.list = list;
                this.$refs.pagination.setPage(
                    query.Page,
                    this.total
                );
                this.$router.push({ path: this.$router.currentRoute.path, query }).catch((e) => {
                    console.log("router的錯誤(同查詢條件再塞一次出錯)，不處理");
                    console.log(e);
                });
            } catch (e) {
                this.total = 0;
                this.list = [];
                this.$refs.pagination.setPage(
                    query.Page,
                    this.total
                );
                throw e;
            }
        },
        async exportData() {
            let query = { ...this.searchData };
            if (!query.Partaker) {
                this.$eventBus.$emit("showAlert", this.$t('請輸入暱稱'));
                return;
            }
            if (query.SDate != query.EDate) {
                this.$eventBus.$emit("showAlert", this.$t('僅可匯出一日的歷程，請將時間區間設為相同日期'));
                return false;
            }
            $(this.$refs.loading).show();
            let pageIdx = 0;
            let Total = 0;
            let list = [];

            let 建立時間 = this.$t('建立時間');
            let 玩家暱稱 = this.$t('玩家暱稱');
            let Act = this.$t('動作/頻道');
            let 接收人 = this.$t('接收人');
            let 內容 = this.$t('內容');

            try {
                let exportData = [];
                do {
                    pageIdx++;
                    query.Page = pageIdx;
                    let res = await GetMonitoringHistory(query, this.projectId);//await this.apiSearch(query);
                    Total = res.Total;
                    list = res.list;
                    for (let item of list) {
                        let tmp = {};
                        tmp[建立時間] = item.SendTime;
                        tmp[玩家暱稱] = item._玩家暱稱;
                        tmp[Act] = item._act;
                        tmp[接收人] = item._接收人;
                        tmp[內容] = (item.MsgType == MsgTypeEnum.Sticker) ? `<${this.$t('貼圖')}>${item.Content}` : item.Content;
                        exportData.push(tmp);
                    }
                    await wait(0.5);
                } while (Total > pageIdx * this.pageSize);
                if (exportData.length == 0) {
                    throw this.$t('無資料，請修改查詢條件');
                }
                // 创建工作簿
                let workbook = XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(exportData);
                XLSX.utils.book_append_sheet(workbook, worksheet, this.$t('發話歷程'));
                // 将工作簿导出为 Excel 文件
                XLSX.writeFile(workbook, `${query.Partaker} ${this.$t('發話歷程')}-${query.SDate}.xlsx`);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
    }
}
</script>
<style scoped>
.player-link {
    color: #3c8dbc;
    cursor: pointer;
}

.player-link:hover,
.player-link:active {
    color: #72afd2;
}

.tool {
    display: flex;
    flex-wrap: wrap;
}

.tool>* {
    margin-right: 10px;
    margin-bottom: 10px;
}

.addon {
    width: 38px;
    height: 34px;
    border: 1px solid #d2d6de;
    border-right: 0;
    line-height: 34px;
    text-align: center;
    flex-shrink: 0;
}

.addon~input {
    width: 180px;
}

.searchBox {
    display: inline-block;
}

.sapn-tip {
    line-height: 34px;
}
</style>