import api from "@/assets/js/app-api";

// initial state
const state = {
    detailQuery: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    fileUpload({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.post.fileUpload(data).then(res => {
                resolve(res.data);
            })
        })
    },
    QueryList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.post.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.resultData;
                    resolve(content);
                }
            });
        });
    },
    DetailQuery({ commit }, data) { //查詢單筆貼文
        api.qppSocial.post.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailQuery', res.data.resultData);
            }  
        })
    },
    DeleteQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.post.delete(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data;
                    resolve(content);
                }
            });
        });
    },
    ArticleAdd({ commit }, data) {
        api.qppSocial.post.add(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    ArticleEdit({ commit }, data) {
        api.qppSocial.post.edit(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    RecommendEdit({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.qppSocial.post.recommend(data).then(res => {
                const content = res.data;
                resolve(content);
            });
        });
    },
    TagQueryAll({ commit }, data) { //查詢單筆貼文
        return new Promise((resolve, reject) => {
            api.qppSocial.post.tagQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data;
                    resolve(content);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setDetailQuery(state, detailQuery) {
        state.detailQuery = detailQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}