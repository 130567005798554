<template>
  <div id="ClassManager">
    <section class="content-header">
      <h1>分類管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">分類管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <ClassManager :module="module" />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
  .ckbx-style-8 input:disabled + label{
    opacity: .6;
    cursor: default;
  }
  input[type="number"]{
    padding-right: 0;
  }
</style>

<script>
/* eslint-disable */
import ClassManager from "./components/ClassManager.vue"
// import { mapState, mapActions } from "vuex";

export default {
  components: {
    ClassManager,
  },
  props:{
    module: { type:String } 
  },
};
</script>
