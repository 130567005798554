import { render, staticRenderFns } from "./PartnerListGame.vue?vue&type=template&id=791b93d5&"
import script from "./PartnerListGame.vue?vue&type=script&lang=js&"
export * from "./PartnerListGame.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports