<template>
  <div id="TypeManager">
    <section class="content-header">
      <h1>服務類別管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">服務類別管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button type="button" class="btn btn-block btn-warning btn-add fa fa-plus" 
            @click="add()">
              新增
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px; width: 40%;">分類名稱</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 20%;">啟用狀態</th>
                  <!-- <th class="text-nowrap text-center" style="min-width: 100px; width: 20%;">最後編輯時間</th> -->
                  <th class="text-nowrap text-center" style="min-width: 140px; width: 20%;">功能</th>
                </tr>
                <tr v-for="(item, index) in datas" :key="item.編號">
                  <td class="text-left">
                    <p v-show="!item.isEdit" class="m-0">{{ item.名稱 }}</p>
                    <input v-show="item.isEdit" type="text" class="form-control" v-model="item.名稱" required />
                  </td>
                  <td>
                    <fieldset class="ckbx-style-8 ckbx toggleInput" :disabled="!can修改" :style="!can修改?'opacity:.5;':false">
                      <input type="checkbox" :true-value="0" :false-value="1" :id="'check'+index" v-model="item.啟用YN" @change="changeStatus(item)" name="ckbx-style-8" />
                      <label :for="'check'+index"></label>
                    </fieldset>
                  </td>
                  <td v-if="can修改">
                    <button v-show="!item.isEdit" @click="edit(item, index)" class="btn btn-primary">修改</button>
                    <button v-show="!item.isEdit" @click="remove(item, index)" class="btn btn-danger ml-5">刪除</button>
                    <button v-show="item.isEdit" @click="save(item, index)" class="btn btn-success">儲存</button>
                    <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default ml-5">取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <pre>{{ datas }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "./_Define";

export default {
  
  data() {
    return {
      lang: "zh-TW",
      editLang: "zh-tw",
      datas: [],
      isEditing: false,
      editBackup: {},

      searchData: {
        Lang: "zh-tw",
      },

      can新增: this.$user.hasPermission(dfn.permission.服務類別新增),
      can修改: this.$user.hasPermission(dfn.permission.服務類別修改),
    };
  },
  created() {
    this.queryAll();
  },
  mounted() {
    window.v = this;
  },
  watch: {
    response(value) {
      if (value.status == "1") this.$eventBus.$emit("showAlert", "更新成功!!");
      else this.$eventBus.$emit("showAlert", "更新失敗!!");
      // this.queryAll();
    },
  },
  methods: {
    // 顯示列表
    queryAll() {
      // console.log('run 服務類別查詢');

      let data = new FormData();
      data.append("lang", data);

      this.$store.dispatch("xinCRM/服務類別查詢",{
        projectId: this.searchData.projectId,
        module: "album",
        lang: "zh-tw",})
      .then((result) => {
        this.datas = result;
        // console.log(this.datas)
      });
    },

    // 新增類別
    add() {
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.datas.unshift({
        "名稱": "",
        "啟用YN": 0,
        "isNew": true,
      });
      this.edit(this.datas[0], 0);
    },
    // 切換文字或輸入框
    edit(item, index) {
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.editBackup = Object.assign({}, item);
      this.isEditing = true;

      item.isEdit = true;
      // console.log(this.datas[index]);
      this.$set(this.datas, index, item);
    },
    remove(item, index){
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinCRM/服務類別刪除", item)
            .then((result) => {
              this.datas = result;
            });
        }
      });
    },

    // 儲存
    save(item, index) {
      this.isEditing = false;
      delete item.isEdit;
      delete item.isNew;

      this.$store.dispatch("xinCRM/" + (item.編號 ? '服務類別修改' : '服務類別新增'), item).then(result => {
        console.log(result);
        this.datas = result;
      });
    },


    cancel(item, index) {
      this.isEditing = false;
      delete item.isEdit;
      if (item.isNew) this.datas.shift();
      else this.$set(this.datas, index, this.editBackup);
    },

    // 改變狀態
    changeStatus(item) {
      if (item.isEdit) return;
      this.$store.dispatch("xinCRM/服務類別修改", item);
    },
    uiDate(date) {
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
      else return '';
    }
  }
};
</script>