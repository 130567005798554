<template>
    <div>
        <div class="direct-chat-messages" @scroll="onScroll" ref="chatForm" id="defineHeight"
            v-bind:class="{ fixedHeight: historyType == 'none' }">
            <div v-for="item of messageData" :key="item.ChatId" :time="item.Time">
                <div style="background-color:#EBF7F3;text-align:center" v-if="item.firstEvent">{{ item.EventId }}</div>
                <template
                    v-if="item.ChatMsgType == ChatMsgType.CsToPlayer || item.ChatMsgType == ChatMsgType.OwnToCs || item.ChatMsgType == ChatMsgType.OwnToGroup">
                    <!--右邊 顯示自己的對話-->
                    <div class="direct-chat-msg right">
                        <div class="direct-chat-info clearfix">
                            <span class="direct-chat-name pull-right">
                                {{ item.SenderName }}
                            </span>
                            <span class="direct-chat-timestamp direct-chat-waitsend pull-left" v-show="item.IsWaitSend">
                                {{ $t('離線訊息') }}
                            </span>
                            <span class="direct-chat-timestamp pull-left">
                                {{ item.Time | 格式化時間 }}
                            </span>
                        </div>
                        <div class="direct-chat-img"
                            style="background-color: #f39c12;border-color: #f39c12;color: #fff;">
                            <div>{{ item.SenderName }}</div>
                        </div>
                        <div v-if="item.img">{{ item.img }}</div>
                        <div v-else class="direct-chat-text" style="white-space: pre-wrap;word-wrap: break-word;"
                            :style="{ color: item.MsgColor ? item.MsgColor : '#ffffff' }" :class="[
                historyType == 'CSHistory' ? 'direct-chat-text-cs' : '']">
                            {{ item.Msg }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <!--左邊 顯示他人的對話-->
                    <div class="direct-chat-msg">
                        <div class="direct-chat-info clearfix">
                            <span class="direct-chat-name pull-left"
                                v-show="item.ChatMsgType != ChatMsgType.PlayerToCs">{{
                item.SenderName
            }}</span>
                            <span class="direct-chat-timestamp pull-right">{{ item.Time | 格式化時間 }}</span>
                        </div>
                        <!-- <img class="direct-chat-img" src="" alt="Message User Image"> -->
                        <div class="direct-chat-img">
                            <div>{{ item.SenderName }}</div>
                        </div>
                        <!-- <div v-if="item.img" class="direct-chat-text-img">
                            <img :src="item.img">
                        </div> -->
                        <div class="direct-chat-text" style="white-space: pre-wrap;word-wrap: break-word;"
                            :style="{ color: item.MsgColor ? item.MsgColor : '#000000' }">
                            <img v-show="item.img" :src="item.img" @error="onImageError(item)" @mouseenter="onMouseenter($event,item.img)" @mouseout="onMouseout" @click="onMouseclick(item.img)">
                            <span v-show="!item.img">
                                {{ item.Msg }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div style="position: absolute;top: 0px;left: 0px;right: 0px;">
            <div class="box-title" ref="showTopTime" style="display: none;">
                這裡用來暫放滾動訊息視窗的捲軸時，要出現的文字
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { ChatMsgType } from "@/store/MessageSystem/messageSystemConst";
import { mapGetters } from "vuex";
import {
    訊息系統
} from "@/store/MessageSystem/messageSystemConst";
export default {
    props: {
        historyType: {
            type: String,
            default: "none",
        }
    },
    components: {
    },
    data() {
        return {
            topTimeInterval: 0,
            messages: [],
            // scrollHeight: 0,//紀錄scrollheight，用來載到歷史資料時，不要直接跳到最上層用
            ChatMsgType,
            imgHover:{
                interval:0,
                ms:500
            }
        };
    },
    computed: {
        ...mapGetters({
            ChatMessage: 'messageSystemServer/ChatMessage',
            ChatCsMessage: 'messageSystemServer/ChatCsMessage',
        }),
        messageData() {
            if (this.historyType == "CSHistory") {//客服群組聊天
                return this.ChatCsMessage;
            } else if (this.historyType == "ChatHistory") {//會員聊天
                return this.ChatMessage;
            } else {
                return this.messages;//客服歷程
            }
        },
        scrollLoadMode() {
            //top:當捲軸在頂部時，載入剩餘的資料；//客服群組聊天跟會員聊天用
            //down:當捲軸在底部時，載入剩餘的資料；//客服歷程用
            return (this.historyType == "CSHistory" || this.historyType == "ChatHistory") ? "top" : "down";
        }

    },
    watch: {
        messageData(newV, oldV) {
            if (this.scrollLoadMode == "top") {
                //判斷目前畫面的捲軸是否移到底部，若是，則收到新訊息後自動移到底部
                let target = this.$refs.chatForm;
                if (!target) {
                    return;
                }
                this.$nextTick(() => {
                    //if (newV.length > 0 && oldV.length > 0 && newV[newV.length - 1].ChatId == oldV[oldV.length - 1].ChatId) {
                    //不做任何事
                    //} else {
                    target.scroll(0, (target.scrollHeight - target.clientHeight)); //移動到底部                
                    //}
                });
            } else {
                if (newV && newV.length == 0) {
                    setTimeout(() => {
                        let target = this.$refs.chatForm;
                        if (!target) {
                            return;
                        }
                        target.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }, 500)
                }
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.imgHover.interval);
        clearInterval(this.topTimeInterval);
    },
    methods: {
        onImageError(item) {
            if(item.img!=""){
                item.img="/download/pic_unreadable.png";
            }
        },
        onScroll(e) {
            let target = e.target;
            let time = 0;
            for (let i of e.target.children) {
                if (i.offsetTop > target.scrollTop) {
                    time = i.getAttribute("time");
                    break;
                }
            }
            if (this.scrollLoadMode == "top") {
                if (target.scrollTop == 0) {
                    this.$emit("LoadNextToken");
                    if (this.historyType == "CSHistory") {
                        this.$store.dispatch("messageSystemServer/載入歷史訊息", 訊息系統.客服系統).then((res) => {
                            this.showTopTime(res ? this.$t('歷史訊息載入中') : this.$t('僅保留近2周訊息'));
                        });
                    } else if (this.historyType == "ChatHistory") {
                        this.$store.dispatch("messageSystemServer/載入歷史訊息", 訊息系統.會員系統).then((res) => {
                            this.showTopTime(res ? this.$t('歷史訊息載入中') : this.$t('無歷史訊息'));
                        });
                    }
                } else {
                    if (time) {
                        this.showTopTime(this.$t('最上面一則的時間') + "：" + new moment(parseInt(time)).format("YYYY-MM-DD HH:mm:ss"));
                    }
                }
            } else {
                if (time) {
                    this.showTopTime(this.$t('最上面一則的時間') + "：" + new moment(parseInt(time)).format("YYYY-MM-DD HH:mm:ss"));
                }
                if (target.scrollTop + target.clientHeight > target.scrollHeight - 5) {
                    this.$emit("LoadNextToken");
                }
            }
            // if (target.scrollTop + target.clientHeight == target.scrollHeight) {
            // console.error("到底部", target.scrollTop, target.clientHeight, target.scrollHeight);
            // }
        },
        showTopTime(msg) {
            // showTopTime
            clearInterval(this.topTimeInterval);
            this.topTimeInterval = setTimeout(() => {
                $(this.$refs.showTopTime).fadeOut(500);
            }, 1000);
            if (this.$refs.showTopTime) {
                this.$refs.showTopTime.style.display = '';
                this.$refs.showTopTime.innerHTML = msg;
            }
            // "最上面一則的時間：" + new moment(parseInt(time)).format("YYYY-MM-DD HH:mm:ss");
        },
        onMouseenter(e,url){
            clearTimeout(this.imgHover.interval);
            this.imgHover.interval = setTimeout(()=>{      
                try{
                    const rect = e.target.getBoundingClientRect();
                    this.$emit("onMouseenterImg", rect.x,rect.y,url);
                }catch(e){
                    //
                }
            },this.imgHover.ms);
        },
        onMouseout(){
            clearTimeout(this.imgHover.interval);
            this.$emit("onMouseoutImg");
        },
        onMouseclick(url){
            this.$emit("onMouseclickImg", url);
        }
    },
    filters: {
        格式化時間: function (value) {
            return /*value + "," +*/ new moment(value).format("YYYY-MM-DD HH:mm:ss");
        }
    },
};
</script>
<style scoped>
div.direct-chat-img {
    background-color: #d2d6de;
    overflow: hidden;
}

div.direct-chat-img>div {
    text-align: center;
    line-height: 40px;
}



.defineHeader {
    height: 73.125px;
}

.box-title {
    padding: 2px 0px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: rgba(123, 123, 123, .6);
}

.direct-chat-messages {
    background-color: #F8F8FA;
}

.fixedHeight {
    height: 560px;
}
</style>