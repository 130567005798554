<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-bullhorn"></i>站長推薦 {{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="goBack">站長推薦 {{ isEdit ? '編輯' : '新增' }}</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯內容' : '新增內容' }}</h4>
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm" @submit.prevent="Save()">
            <div class="box-body" style="max-width:900px;">
              <div class="form-group">
                <fieldset class="col-sm-12">
                  <label class="required" for="inputType">次分類名稱：</label>
                  <MultilangInput :langs="langs" v-model="edit.Langs" prop="Content" langProp="LanguageCode"></MultilangInput>
                </fieldset>
              </div>

              <div class="form-group">
                <fieldset class="col-sm-12">
                  <label class="required" for="inputType">指定遊戲：<small class="text-red">(※ 溫馨提醒：您必須先至<router-link :to="{name:'AWH5WebGame'}">「遊戲 → 遊戲清單」</router-link>建立遊戲資料後，此欄方能選取到該遊戲)</small></label>
                  <TagSelect v-model="tagEdit" :options="tagOptions" placeholder="選擇遊戲"></TagSelect>
                </fieldset>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <!-- <button class="btn btn-warning btn-lg mr-10" type="button" @click="preview()">
                  <i class="fa fa-fw fa-eye"></i> 預覽
                </button> -->

                <!-- <button class="btn btn-success btn-lg mr-10" type="button" @click="Save(true)">
                  <i class="fa fa-fw fa-save"></i> 儲存
                </button> -->

                <button class="btn btn-primary btn-lg" type="submit">
                  <i class="fa fa-fw fa-check"></i> 確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>
        {{ edit }}
        {{ tagOptions }}
      </pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import types from "./_Define";
import tinymce from "@/components/Tinymce";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import TagSelect from "@/views/projects/XSG/components/TagSelect";
import { objToPascalCase } from "@/assets/js/app-utils";
import { WebRecommendAPI as api } from "@/views/projects/AWH5/_API";


// 文件參考: https://vue-multiselect.js.org/#sub-multiple-select
class TagOption{
  constructor(id, label, isDisabled = false){
    this.id = id;
    this.label = label;
    this.$isDisabled = isDisabled;
  }
}

export default {
  components: {
    MultilangInput,
    TagSelect,
    tinymce,
  },
  props: {
    id: "",
  },
  data() {
    return {
      langs: types.projectData.langs,
      type: types.langs[0],

      edit: {
        Langs:[],
        Sort: 1,
        IsHidden: 0,
      },

      tagEdit: [],
      tagOptions: [],
      gameList: {},

      checkInterval: 0,
    };
  },
  created() {
    this.getGameList();
    if (this.isEdit) {
      this.GetData();
    }
    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 5*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {

  },
  computed: {
    isEdit() {
      return this.id > 0 && this.id != "New";
    },
    goBack(){
      return {name:'AWH5WebRecommend'}
    },
  },
  watch:{
    tagEdit:{
      handler(value){
        let arr = [];
        for (let tag of value) arr.push(tag.id);
        this.edit.AssignedGames = arr.join(",");
      },
      deep: true
    }
  },
  methods: {
    //取得該遊戲操作說明資料
    GetData() {
      this.$store.dispatch("xsa/post",{
        path: api.Query,
        data: {RecId: this.id }
      }).then((result) => {
        // this.$set(this, "edit", objToPascalCase(result));
        this.edit = objToPascalCase(result);
        let tags = [];
        for (let game of this.edit.AssignedGamesModel){
          tags.push(new TagOption(game.GId, game.Name))
        }
        this.tagEdit = tags;
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    getGameList(){
      this.$store.dispatch("xsw/遊戲清單列表查詢", {
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          let arr = [];
          for (let game of data){
            arr.push(new TagOption(game.gid, game.gameListLanguages.find(x => x.lang=='zh-TW').gameName))
          }
          this.tagOptions = arr;
        }
      });
    },

    //送出
    async Save(isPreview = false) {
      let err = [];
      if (this.edit.AssignedGames == ""){
        err.push(`請選擇指定遊戲`)
      } 

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }
      this.$store.dispatch("xsa/post", {
        path: this.isEdit ? api.Update : api.Create,
        data: this.edit,
      }).then((res) => {
        this.$eventBus.$emit("showAlert", (this.isEdit ? "修改" : "新增") + "成功");
        this.backList();
      }, (err) => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

     //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5WebRecommend",
      });
    },
  },
};
</script>

<style scoped>
legend, fieldset>label{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0 0 3px;
  grid-column: 1 / -1;
}
label{
  flex: 1 0 auto;
}
.required::before{
  content: "* ";
  color: #dd4b39;
  position: absolute;
  transform: translate(-120%);
}
input[type="checkbox"]{
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -.2em;
  accent-color: #3498db;
  cursor: pointer;
}
input[type="range"]{
  /* accent-color: #3498db; */
  overflow: hidden;
}
input[type='range']::-webkit-slider-runnable-track{
  color:#fff;
}
.form-group >>> .radio-date{
  display: flex;
  flex-wrap: wrap;
  gap: 2px 10px;
}
.form-group >>> .radio-date::after{
  content: none;
}
.form-group >>> .radio-date .input-group{
  width: 40%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
}
.nav-tabs-custom, .tab-content{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.nav-tabs-custom>.nav-tabs>li{
  margin-right: 0;
  cursor: pointer;
}
</style>