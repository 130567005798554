@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>新增中獎</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">新增中獎</li>
        <li class="active">
            {{ id == "New" ? "新增" : edit.獎品名稱 }}
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">
              {{ id == "New" ? "新增" : "編輯" }}內容
            </h4>
            <router-link
              :to="{
                name: 'PrizeList',
                params: { searchData: $route.params.searchData },
              }"
              class="btn btn-block btn-default btn-cog"
            >
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>
          <form
            class="form-horizontal"
            name="announcementForm"
            @submit="
              $event.preventDefault();
              Save();
            "
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label required">活動名稱：</label>
                <div class="col-sm-9 mt-7" v-if="id !== 'New'">
                  {{ 目前活動名稱 }}
                </div>
                
                <div v-else class="select-group col-sm-9">
                  <select
                    class="form-control"
                    v-model="目前活動"
                  >
                    <option
                      v-for="(name, key) in listdata"
                      :key="key"
                      :value="name.活動編號"
                    >
                      {{ name.活動名稱 }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">獎品名稱：</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control col-sm-9"
                    id="prname"
                    v-model="edit.獎品名稱"
                    required
                    placeholder=""
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">個數：</label>
                <div class="col-sm-9">
                  <input
                    type="number"
                    class="form-control col-sm-9"
                    id="prnum"
                    v-model="edit.數量"
                    required
                    placeholder=""
                  />
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic1"
                  >獎品圖片：</label
                >
                <div class="col-sm-9">
                  <ImageSelect
                    id="AdPic1"
                    ref="imageSelect1"
                    info="left"
                    :gid="edit.獎品ID ? edit.獎品ID.toString() : ''"
                    module="EventPrize"
                    :maxsize="308000"
                     accept="image/png, image/jpeg, image/jpg"
                    v-model="edit.files"
                  >
                  <p class="help-block">
                      *
                      <span class="text-red">圖片大小 400&times;500</span
                      >，檔案格式png/jpg • 大小上限: 300KB。
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">價值(USD)：</label>
              
                <div class="col-sm-9">
                  <input
                    type="number"
                    class="form-control col-sm-9"
                    id="prize"
                    v-model="edit.價值"
                    required
                    placeholder=""
                  />
                </div>
              </div>

              <div class="form-group">
                <span class="col-sm-3 control-label required">排序：</span>
             
                <div class="col-sm-9">
                  <input
                    type="number"
                    class="form-control col-sm-9"
                    id="prorder"
                    v-model="edit.排序"
                    required
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
input[type="checkbox"] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.2em;
  accent-color: #3498db;
  cursor: pointer;
}
label.required::before {
  content: "* ";
  color: #dd4b39;
}
.form-control.sort {
  display: inline-block;
  width: 54px;
  padding: 5px;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
}
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    tinymce,
    ImageSelect,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton,
  },
  props: {
    id: "",
    searchData: {},
  },
  data() {
    return {
      // bannerType: types.EventBannerType,
      // def: define,
      目前活動: "",
      目前活動名稱: "",
      listdata:[],
      edit: {
        獎品ID:'',
        活動編號: null,
        獎品名稱: null,
        數量: null,
        價值: null,
        排序: 1,
      },
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {},
  watch: {
    // "edit.動作1": function(value){
    //   this.updateActions();
    // },
    // "isForever": function(value){
    //   //如果常駐顯示=true，顯示結束時間帶null
    //   this.edit.顯示結束時間 = value ? null : new moment().add('month', 1).format("YYYY/MM/DD 00:00:00");
    // },
    // "isOptLevel": function(value){
    //   this.edit.minlevel = value ? 1 : 0;
    //   this.edit.maxlevel = value ? 1000 : 0;
    // }
  },
  methods: {
    getData() {
        this.$store
        .dispatch("xsw/活動名稱列表查詢", {
          
        })
        .then(
          (res) => {
            this.message = "";
            this.listdata = res || [];
            //this.目前活動 = this.listdata[0].活動編號;
             if (this.$route.params.searchData != undefined) {
                this.目前活動= this.$route.params.目前活動;
            }else{
                this.目前活動 = this.listdata[0].活動編號;
            }
             this.$nextTick(() => {
              this.getDataList();
            });
          },
          (err) => {
            this.message = err;
            this.listdata = [];
          }
        );    
    },
    getDataList(){
      if (this.id == "New") return;
      this.loading = true;   
      this.$store
        .dispatch("xsw/活動獎品管理查詢", { 獎品ID: Number(this.id) })
        .then(
          (res) => {           
              this.目前活動 = res.活動編號;             
              this.edit=res;
              const currentActivity = this.listdata.find(item => item.活動編號 === this.目前活動);
              const activityName = currentActivity ? currentActivity.活動名稱 : "活動不存在";
              this.目前活動名稱=activityName;              
          },
          (err) => {
            this.$eventBus.$emit("showAlert", err);
          }
        );
    },
    //*---- 返回
    backList() {
      this.$router.push({
        name: "PrizeList",
        params: { searchData: this.$route.params.searchData  },
      });
    },

    //送出
    async Save() {
      let err = [];
      console.log(this.edit);
      let api = this.id == "New" ? "新增" : "修改";
      this.edit.價值=Number(this.edit.價值);
      this.edit.數量=Number(this.edit.數量);
      this.edit.排序=Number(this.edit.排序);
      this.edit.活動編號=this.目前活動;
      let sendData = Object.assign({}, this.edit);
      delete sendData.files;

      if (this.id == "New") {
        delete sendData.獎品ID;
      }

      this.$store
        .dispatch("xsw/活動獎品管理" + api, sendData)
        .then((result) => {
          if (this.id == "New") {
            this.edit.獎品ID = result;
          }
          this.$nextTick(
            () => {
              console.log(this.edit.獎品ID)
              Promise.all([
                this.$refs.imageSelect1.upload(),
              ]).then(
                (res) => {
                  if(this.id == "New"){
                    this.$eventBus.$emit("showAlert", "成功新增");
                  }else{
                     this.$eventBus.$emit("showAlert", "修改成功");
                  }                 
                  this.backList();
                },
                (err) => {
                  
                  this.$eventBus.$emit("showAlert", err);
                }
              );
            },
            (error) => {
              this.$eventBus.$emit("showAlert", error);
            }
          );
        });
    },
  },
};
</script>