<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-gift"></i>必勝獲利組合包</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">必勝獲利組合包</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <fieldset class="top-box" :disabled="isEditing">
            <div class="select-group mr-10 mb-10">
              <select class="form-control" v-model="searchData.LanguageCode" @change="getDataList()">
                <option v-for="lang in langs" :value="lang.id">{{ lang.id + ' ' + lang.name }}</option>
              </select> 
            </div>
            <div class="pull-left mt-5">
              <span class="control-label" for="checkActive">
                <strong>此功能區塊是否開啟：</strong>
              </span>
              <div class="ckbx-style-8 ckbx toggleInput mr-5 mb-2" v-if="can修改">
                <input type="checkbox" v-model="isHidden" :false-value="1" :true-value="0" id="checkActive" name="ckbx-style-8"
                  @change="changeStatus(item)" />
                <label for="checkActive"></label>
              </div>
              {{ isHidden ? '關閉' : '開啟' }}
            </div>

            <button v-if="can新增" type="button" class="btn btn-block btn-warning btn-add mb-10" @click="add()" :disabled="isEditing">
              <i class="fa fa-plus"></i> 新增內容
            </button>
            <!-- <button v-if="can清暫存" type="button" class="btn btn-block btn-default btn-add mb-10 mr-10" @click="clearCatch()">
              <i class="fa fa-refresh"></i> 清除Cache
            </button> -->

            <!-- <div class="select-group mb-10">
              <select class="form-control" v-model="searchData.LanguageCode">
                <option v-for="lang in langs" :value="lang.id">{{ lang.id + ' ' + lang.name }}</option>
              </select> 
            </div> -->

            <!-- <div class="text">
              <p class="data-num">資料總筆數: <span id="total">{{ total }}</span></p>
            </div> -->
          </fieldset>

          <div data-class="table-responsive">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%">排序</th>
                  <th style="min-width:240px; width:90%">指定遊戲</th>
                  <th style="min-width: 80px; width: 5%">動作</th>
                </tr>
                <tr v-if="!isEditing && message"><td colspan="10">{{ message }}</td></tr>
                <tr v-else-if="!isEditing && total <= 0"><td colspan="10">查詢無任何資料</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.PkgId" :class="{'bg-gray-light': isEditing && !item.isEdit }">
                  <td class="text-nowrap">
                    <span v-if="!can修改">{{ item.Sort }}</span>
                    <fieldset v-else style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                      <input type="number" class="form-control p-5" v-model.number="item.Sort" min="1" max="" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add btn-upDate" @click="save(item, index)">
                        <i class="fa fa-fw fa-arrows-v"></i>更新
                      </button>
                    </fieldset>
                  </td>
                  <td class="text-left">
                    <TagSelect v-if="!item.isEdit" :value="getTags(item)" readonly></TagSelect>
                    <form v-else class="form-horizontal" :id="`editForm_${item.PkgId}`" @submit.prevent="save(item, index)">
                      <TagSelect v-model="tagEdit" :options="tagOptions" :readonly="!item.isEdit" :disabled="isEditing && !item.isEdit" :multiple="false" required placeholder="選擇遊戲"></TagSelect>
                    </form>
                  </td>
                  <td>
                    <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                      <button v-show="!item.isEdit && can修改" @click="edit(item)" class="btn btn-primary">
                        <i class="fa fa-fw fa-pencil"></i>修改
                      </button>
                      <!-- <button v-show="!item.isEdit" @click="copyItem(item)" class="btn btn-warning">
                        <i class="fa fa-fw fa-copy"></i>複製
                      </button> -->
                      <button v-show="item.isEdit" type="submit" :form="`editForm_${item.PkgId}`" class="btn btn-success">
                        <i class="fa fa-fw fa-save"></i>儲存
                      </button>
                      <button v-show="!item.isEdit && can刪除" @click="deleteItem(item, index)" class="btn btn-danger" :disabled="datas.length <= 1">
                        <i class="fa fa-fw fa-close"></i>刪除
                      </button>
                      <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default">
                        <i class="fa fa-fw fa-reply"></i>取消
                      </button>
                    </fieldset>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>{{ datas }}</pre>
    <pre>{{ tagOptions }}</pre>
    <pre>{{ gameList }}</pre> -->
  </div>
</template>

<script>
/* eslint-disable */
import TagSelect from "@/views/projects/XSG/components/TagSelect";
import types from "@/views/projects/AWH5/_Define";
import { WebPackageAPI as api, WebPackagePermission as auth } from "@/views/projects/AWH5/_API";
import { default as utils, objToPascalCase } from "@/assets/js/app-utils";

// 文件參考: https://vue-multiselect.js.org/#sub-multiple-select
class TagOption{
  constructor(id, label, isDisabled = false){
    this.id = id;
    this.label = label;
    this.$isDisabled = isDisabled;
  }
}

export default {
  components: {
    TagSelect
  },
  props: {
    id: "",
  },
  data() {
    return {
      langs: types.projectData.langs,
      lang: types.projectData.langs[0].id,

      searchData: {
        "RecId": -1,   // -1 = 全部
        "LanguageCode": "zh-TW"
      },
      total: 0, //總筆數
      datas: [],
      message: "",
      pageNum: 1,
      pageSize: 10, //每頁顯示筆數
      isHidden: 0,
      isEditing: false,
      editBackup: {},

      gameList: {},
      tagEdit: {},

      can新增: this.$user.hasPermission(auth.Create),
      can修改: this.$user.hasPermission(auth.Update),
      can刪除: this.$user.hasPermission(auth.Delete),
    };
  },
  async created() {
    this.getGameList();
  },
  mounted() {
    this.getDataList();
  },
  computed:{
    tagOptions(){
      let arr = [];
      for (let [id, langs] of Object.entries(this.gameList)){
        let name = langs[this.searchData.LanguageCode] || langs['zh-TW'] || id;
        arr.push(new TagOption(id, name));
      }
      return arr;
    },
    getTags: () => {
      return function (item){
        return [
          this.gameList[item.Gid][this.searchData.LanguageCode] || this.gameList[item.Gid]["zh-TW"] || item.Gid
        ]
      }
    },
  },
  methods: {
    getDataList() {
      let waitLoad = setTimeout(()=>{this.message = "查詢中"}, 100);
      //列表
      this.$store.dispatch("xsa/post", {
        path: api.QueryList,
        data: Object.assign(this.searchData, {RecId: -1}),
      }).then((result) => {
        clearTimeout(waitLoad);
        this.datas = objToPascalCase(result) || [];
        this.total = this.datas.length;
        this.isHidden =  this.datas[0] ? this.datas[0].IsHidden : 0;
        for (let data of this.datas){
          if (this.gameList[data.Gid]) this.$set(this.gameList[data.Gid], this.searchData.LanguageCode, data.GameName);
          else this.$set(this.gameList, data.Gid, {[this.searchData.LanguageCode]: data.GameName});
        }
        this.message = "";
      }, err => {
        clearTimeout(waitLoad);
        this.message = err;
        this.total = 0;
        this.datas = [];
      });

    },
    getGameList(){
      this.$store.dispatch("xsw/遊戲清單列表查詢", {
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          let dict = {}; 
          for (let game of data){
            dict[game.gid] = (()=>{
              let obj = {};
              for (let x of game.gameListLanguages){
                obj[x.lang] = x.gameName;
              }
              return obj
            })()
          }
          this.gameList = dict;
        }
      });
    },

    changeStatus(){
      this.$eventBus.$emit("showConfirm", "確定要更新功能區塊狀態嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Update,
            data: { PkgId: 0, IsHidden: this.isHidden} // 若要開啟/關閉站長推薦區塊，則PkgId帶0，IsHidden帶 1：隱藏 0：顯示
          }).then(res => {
            this.$eventBus.$emit("showAlert", "更新成功！");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
        else {
          this.isHidden = +!this.isHidden;
        }
      });
    },

    add(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      let newItem = {
        Gid: "",
        GameName: "",
        Sort: 1,
        IsHidden: 0,
        isNew: true
      }
      this.tagEdit = {};
      this.datas.unshift(newItem);
      this.edit(this.datas[0]);
    },

    edit(item){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      if (this.gameList.length == 0) this.getGameList();
      this.editBackup = utils.deepCloneObj(item);
      this.tagEdit = new TagOption(item.Gid, item.GameName);
      this.isEditing = true;
      item.isEdit = true;
    },
    cancel(item, index){
      if (item.isNew) this.datas.shift();
      else this.$set(this.datas, index, this.editBackup);
      this.isEditing = false;
    },
    save(item, index){
      let err = [];
      if (this.isEditing){
        if (!this.tagEdit.id && !this.tagEdit.label) err.push("請選擇指定遊戲");
        item.Gid = this.tagEdit.id;
        item.GameName = this.tagEdit.label;
      }
      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let sendData = Object.assign({}, item, {
        isEdit: undefined,
        isNew: undefined
      });

      this.$store.dispatch("xsa/post", {
        path: item.isNew ? api.Create : api.Update,
        data: sendData,
      }).then(async reulst => {
        this.isEditing = false;
        this.$eventBus.$emit("showAlert", this.$t(( item.isNew ? "新增" : "修改" ) + '成功'));
        this.getDataList();
      }, error => {
        this.$eventBus.$emit("showAlert", error );
      });
    },
    deleteItem(item){
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsa/post", {
            path: api.Delete,
            data: { PkgId: item.PkgId },
          }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
  },
};
</script>

<style scoped>
  input:disabled + label{
    opacity: .75;
    cursor: not-allowed;
  }  
</style>