export const WebChampionAPI = {
  "Query": "/ApexWin/ChampionGames/QueryChampionGame",
  "QueryList": "/ApexWin/ChampionGames/QueryChampionGames",
  "Create": "/ApexWin/ChampionGames/CreateChampionGame",
  "Update": "/ApexWin/ChampionGames/UpdateChampionGame",
  "Delete": "/ApexWin/ChampionGames/DeleteChampionGame",
  "Copy": "/ApexWin/ChampionGames/CopyChampionGame",
}
export const WebChampionPermission = {
  "Query": "QueryChampionGame",
  "QueryList": "QueryChampionGames",
  "Create": "CreateChampionGame",
  "Update": "UpdateChampionGame",
  "Delete": "DeleteChampionGame",
  "Copy": "CopyChampionGame",
}

export const WebPackageAPI = {
  "QueryList": "/ApexWin/WinningPackages/QueryWinningPackages",
  "Create": "/ApexWin/WinningPackages/CreateWinningPackage",
  "Update": "/ApexWin/WinningPackages/UpdateWinningPackage",
  "Delete": "/ApexWin/WinningPackages/DeleteWinningPackage",
}
export const WebPackagePermission = {
  "Query": "QueryWinningPackages",
  "Create": "CreateWinningPackage",
  "Update": "UpdateWinningPackage",
  "Delete": "DeleteWinningPackage",
}


export const WebRecommendAPI = {
  "Query": "/ApexWin/AdminRecommendations/QueryAdminRecommendation",
  "QueryList": "/ApexWin/AdminRecommendations/QueryAdminRecommendations",
  "Create": "/ApexWin/AdminRecommendations/CreateAdminRecommendation",
  "Update": "/ApexWin/AdminRecommendations/UpdateAdminRecommendation",
  "Delete": "/ApexWin/AdminRecommendations/DeleteAdminRecommendation",
}
export const WebRecommendPermission = {
  "Query": "QueryAdminRecommendation",
  "QueryList": "QueryAdminRecommendations",
  "Create": "CreateAdminRecommendation",
  "Update": "UpdateAdminRecommendation",
  "Delete": "DeleteAdminRecommendation",
}

export default {
  WebChampionAPI,
  WebChampionPermission,
  WebPackageAPI,
  WebPackagePermission,
  WebRecommendAPI,
  WebRecommendPermission
}