<template>
  <div id="home">
    <section class="content home">
      <h1 id="test" style="color:#3c8dbc;">⇦請點選左側列表進行操作</h1>
    </section>
    <midify ref="midify" :proplogin="false" />
    <!-- 設定信箱彈窗 -->
    <div class="modal fade" id="attention-mail">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> 注意
            </h4>
          </div>
          <div class="modal-body">
            <p>尚未設定信箱，請盡快設定</p>
            <p>( 當忘記密碼時將會傳送新密碼至您所設定信箱 )</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" type="button" @click="midify()">前往設定信箱</button>
            <button
              class="btn btn-default pull-right"
              id="attention-dismiss"
              data-dismiss="modal"
              type="button"
            >取消</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<style scoped>
/*測試*/
#test {
  position: relative;
  animation: TestMove 1s infinite; /*IE*/
  -moz-animation: TestMove 1s infinite; /*FireFox*/
  -webkit-animation: TestMove 1s infinite; /*Chrome, Safari*/
}
@keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import midify from "@/views/Login/midify";

export default {
  components:{
    midify
  },
  data() {
    return {
      employeeId: this.$store.state.loginUser.EmployeeId,
      orderLunchRecord: [],
      orderDinnerRecord: [],
      eatStartDay: new moment().startOf("isoWeek").format("YYYY/MM/DD"),
      eatEndDay: new moment()
        .add(1, "weeks")
        .endOf("isoWeek")
        .format("YYYY/MM/DD"),
      mealDaily: [],
      eatDays: 0,
      orderDeadline: "",
      tip: "",
      files: [],
      fileTypes: [],
      fileTypeId: "",
      announcements: [],
      announcementTypes: [],
      announcementTypeId: ""
    };
  },
  async created() {},
  mounted() {
    if(this.$store.state.loginUser.EmpEmail == "") {
      $("#attention-mail").modal("show");
    }
    $("body")
      .removeClass()
      .addClass("skin-black sidebar-mini wysihtml5-supported fixSidebar fixed");
      if (this.employeeId == undefined) this.$router.push('Login');
  },
  methods: {
    midify() {
      this.$refs.midify.showMidifyBox("info");
    },
  }
};
</script>