<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>公告內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公告列表</li>
        <li class="active">公告內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">新增內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: 70%;">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 預設語系：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="edit.pLang"
                  >
                    <option value="">請選擇預設語系</option>
                    <option value="繁體">繁體</option>
                    <option value="簡體">簡體</option>
                    <option value="英文">英文</option>
                    <option value="日文">日文</option>
                    <option value="韓文">韓文</option>
                    <option value="越">越</option>
                    <option value="泰文">泰文</option>
                    <option value="印">印</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[index == editLangIndex ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(index)"
                      v-for="(item, index) in edit.contents"
                      :key="index"
                    >{{item.lang}}</button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <!-- 工具欄容器 -->
                    <!-- <div class="toolbar-container"></div> -->
                    <!-- 編輯器容器 -->
                    <!-- <div class="CKEditor"></div> -->
                    <tinymce
                      v-model="articleContent"
                      @input="saveContent()"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span> -->
                  圖片：
                </label>
                <div class="col-sm-10">
                  <input
                    accept="image/*"
                    id="AnnouncementPic"
                    type="file"
                    @change="onFileChange($event)"
                  />
                  <div class="help-block">
                    <span class="text-red">圖片建議尺寸 350*250</span>
                  </div>

                  <div class="row ann-imgChoose mt-10">
                    <div class="col-xs-6 ">
                      <img :src="edit.pMedia" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  標籤：
                </label>
                <div class="col-sm-10">
                  <multiselect
                    placeholder="自行輸入標籤"
                    label="Tag"
                    track-by="Tag"
                    required
                    :options="options"
                    v-model="edit.tags"
                    deselect-label=""
                    select-label=""
                    :show-labels="false"
                    :custom-label="nameWithLang"
                    @input="select()"
                    @search-change="getTagQueryAll"
                    max-elements="已達標籤上限"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.Tag }}
                        </span>
                        <span class="option__small" style="float: right">
                          使用文章數：{{ props.option.UsedCount || 0 }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group" v-if="projectId != 'omp_fantacity'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.pDelete"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.pDelete"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save()"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import multiselect from "vue-multiselect";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    tinymce,
    multiselect,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      projectId: this.$store.state.projectId,
      isEdit: false,
      edit: {
        UNick: '官方',
        pLang: '',
        pMedia: '',
        pDelete: 0,
        pState: 1, //0:公開，1:僅限追蹤
        pRecommend: 0,
        pRecommendSort: 0, // 官方推薦次序{預設0,愈小愈優先 1 ~ 3}
        tags: [],
        contents: [
          {
            lang: "繁體",
            content: ""
          },
          {
            lang: "簡體",
            content: ""
          },
          {
            lang: "英文",
            content: ""
          },
          {
            lang: "日文",
            content: ""
          },
          {
            lang: "韓文",
            content: ""
          },
          {
            lang: "越",
            content: ""
          },
          {
            lang: "泰文",
            content: ""
          },
          {
            lang: "印",
            content: ""
          },
        ],
      },
      options: [],
      editLangIndex: 0,
      articleContent: ""
    };
  },
  created() {
    this.getTagQueryAll();

    if ( this.$route.params.id != null) {
      this.isEdit = true;
      this.GetAnnouncement();
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      detailQuery: state => state.qppSocialArticle.detailQuery,
      response: state => state.qppSocialArticle.response
    })
  },
  watch: {
    detailQuery() {
      this.edit = this.detailQuery
      this.edit.contents.forEach((item, index) => {
        if(item.lang != this.edit.pLang) 
        return
        this.editLangIndex = index
        this.articleContent = item.content
      })
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        this.backList();
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    }
  },
  methods: {
    //取得該公告資料
    GetAnnouncement() {
      this.$store.dispatch("qppSocialArticle/DetailQuery", {
        pId: this.$route.params.id
      });
    },
    getTagQueryAll() {
      this.$store
        .dispatch("qppSocialArticle/TagQueryAll", {})
        .then((res) => {
          if (res.resultData.length > 0) {
            this.options = [];
            res.resultData.forEach((item) => {
              this.options.push({
                Tag: item.tag,
                UsedCount: item.count,
              });
            });
          }
        })
    },
    select(){
      let tag = this.edit.tags.Tag
      this.edit.tags = []
      this.edit.tags.push(tag)
    },
    nameWithLang (tag) {
      return tag
    },
    addTag(newTag) {
      const tag = { Tag: newTag.trim() };
      this.options.push(tag);
      this.data.tags.push(tag);
    },
    saveContent() {
      this.edit.contents[this.editLangIndex].content = this.articleContent;
    },
    ChangeLang(id) {
      this.editLangIndex = id;
      this.articleContent = this.edit.contents[this.editLangIndex].content;
    },

    //*---- 返回
    backList() {
      this.$router.push({ name: "QppSocialAnnouncementList"});
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files);
    },
    
    uploadFile(files) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.$store.dispatch("qppSocialArticle/fileUpload", data).then((res) => {
        this.edit.pMedia = res.resultData[0].imageUrl
      });
    },

    //送出
    Save() {
      this.edit.pState = parseInt(this.edit.pState)

      if (this.edit.pLang == "") {
        this.$eventBus.$emit("showAlert", "請選擇語系");
        return;
      }
      this.edit.contents.forEach(item => {
        if(item.Lang == this.edit.pLang) {
          if (item.content == "") {
            this.$eventBus.$emit("showAlert", "預設語系內容不得為空");
            return;
          }
        }
      })

      if (this.isEdit) {
        this.$store.dispatch("qppSocialArticle/ArticleEdit", this.edit);
      } else {
        this.$store.dispatch("qppSocialArticle/ArticleAdd", this.edit);
      }
    }
  }
};
</script>
