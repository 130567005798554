/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { stat } from 'fs';
import api from "@/assets/js/app-api";
import classTypes from "@/store/classTypes";
import module from "@/store/module";
import news from "@/store/news";
import banner from "@/store/banner";
import media from "@/store/media";
import file from "@/store/file";
import salePoint from "@/store/salePoint";
import payment from "@/store/payment";
import game from "@/store/game";
import activity from "@/store/activity";
import award from "@/store/award";
import awardsItem from "@/store/awardsItem";
import exchanges from "@/store/exchanges";
import exchangeItems from "@/store/exchangeItems";
import exchangeCode from "@/store/exchangeCode";
import appUtils from '@/assets/js/app-utils';
import bonusAndRefund from "@/store/bonusAndRefund";
import redeemPrize from "@/store/redeemPrize";
import event from "@/store/event";
//import fundation from "@/store/fundation/fundation";
import donation from "@/store/fundation/donation";
import phoneVerify from "@/store/phoneVerify";
import contactUs from "@/store/contactUs";
import link from "@/store/link";
import qppArticle from "@/store/qppArticle";
import qppMute from "@/store/qppMute";
import qppReport from "@/store/qppReport";
import newtalk from "@/store/newtalk";
import fcVersion from "@/store/fcVersion";
import fcService from "@/store/fcService";
import fcDeposit from "@/store/fcDeposit";
import fcReward from "@/store/fcReward";
import fcPlayerData from "@/store/fcPlayerData";
import fcPlayerHistory from "@/store/fcPlayerHistory";
import fcAnnouncement from "@/store/fcAnnouncement";
import fcPlayerSetting from "@/store/fcPlayerSetting";
import nmjClassTypes from "@/store/nmjClassTypes";
import nmjServiceTypes from "@/store/nmjServiceTypes";
import nmjService from "@/store/nmjService";
import nmjHistory from "@/store/nmjHistory";
import nmjAnnouncement from "@/store/nmjAnnouncement";
import nmjAppBanner from "@/store/nmjAppBanner";
import nmjMarquee from "@/store/nmjMarquee";
import nmjNotification from "@/store/nmjNotification";
import nmjWebBanner from "@/store/nmjWebBanner";
import nmjWebNews from "@/store/nmjWebNews";
import nmjWebQuestion from "@/store/nmjWebQuestion";
import nmjWebHighlight from "@/store/nmjWebHighlight";
import nmjMail from "@/store/nmjMail";
import nmjPlayerData from "@/store/nmjPlayerData";
import nmjGameRecord from "@/store/nmjGameRecord";
import nmjGameBattle from "@/store/nmjGameBattle";
import nmjGameBet from "@/store/nmjGameBet";
import mftdBefore from "@/store/mftdBefore";
import starCastle from "@/store/starCastle";
import album from "@/store/album";
import kolBigData from "@/store/kolBigData";
import ipsPaymentOwed from '@/store/ipsPaymentOwed';
import IPSSetting from '@/store/ips/IPSSetting';
import xinAccount from '@/store/xinAccount';
import fbRobot from '@/store/fbRobot';
import KF3Before from "@/store/KF3Before";
import messageSystemServer from '@/store/MessageSystem/messageSystemServer';
import product from '@/store/ips/product';
import invoice from '@/store/ips/invoice';
import sms from "@/store/sms";
import xinGameIntro from './store/xinstars/xinGameIntro';
import xinPlatformBuy from './store/xinstars/xinPlatformBuy';
import slot from "@/store/slot";
import qppSocialMute from "@/store/qppSocialMute";
import qppSocialReport from "@/store/qppSocialReport";
import qppSocialArticle from "@/store/qppSocialArticle";
import xinCRM from "@/store/xinstars/xinCRM";
import xinVideo from './store/xinstars/xinVideo';
import xinQuestion from './store/xinstars/xinQuestion';
import electionGroup from './store/electionGroup';
import electionPerson from './store/electionPerson';
import smm from "@/store/smm";
import xsw from "@/store/xsw";
import xsa from "@/store/xsa";
import DemBefore from "@/store/DemBefore";
import xinEvent from "@/store/xinstars/xinEvent";

Vue.use(Vuex)

/**
 * 
 * @param {*} menu 所有專案的menu
 * @param {*} recordProjectId cooke暫存的project
 * @returns {menu:回傳當前專案的menu, menuProjectId:當前projectId}
 */
function getProjectMenu(menu, recordProjectId) {
  let projectId = "";
  let filterMenu = menu.filter(project => {
    if (project.menus.length > 0) {
      if (projectId == "") projectId = project.projectId;
      if (recordProjectId == project.projectId) projectId = recordProjectId;
      return true;
    }
  });
  return {
    menu: filterMenu, menuProjectId: projectId
  }
}
export default new Vuex.Store({
  modules: {
    classTypes,
    module,
    news,
    banner,
    media,
    file,
    payment,
    salePoint,
    game,
    activity,
    award,
    awardsItem,
    exchanges,
    exchangeItems,
    exchangeCode,
    bonusAndRefund,
    redeemPrize,
    event,
    fundation: {
      namespaced: true,
      modules: {
        donation
      }
    },
    phoneVerify,
    contactUs,
    link,
    qppArticle,
    qppMute,
    qppReport,
    newtalk,
    fcVersion,
    fcService,
    fcDeposit,
    fcReward,
    fcPlayerData,
    fcPlayerHistory,
    fcAnnouncement,
    fcPlayerSetting,
    nmjClassTypes,
    nmjServiceTypes,
    nmjService,
    nmjHistory,
    nmjAnnouncement,
    nmjAppBanner,
    nmjMarquee,
    nmjNotification,
    nmjWebBanner,
    nmjWebNews,
    nmjWebQuestion,
    nmjWebHighlight,
    nmjMail,
    nmjPlayerData,
    nmjGameRecord,
    nmjGameBattle,
    nmjGameBet,
    kolBigData,
    mftdBefore,
    starCastle,
    album,
    kolBigData,
    ipsPaymentOwed,
    IPSSetting,
    xinAccount,
    fbRobot,
    KF3Before,
    DemBefore,
    messageSystemServer,
    product,
    invoice,
    sms,    
    slot,
    qppSocialMute,
    qppSocialReport,
    qppSocialArticle,
    xinPlatformBuy,
    xinGameIntro,
    xinCRM,
    xinVideo,
    xinQuestion,
    electionGroup,
    electionPerson,
    smm,
    xsw,
    xsa,
    xinEvent
  },
  state: {
    projectId: "",
    loginUser: "",
    loginUserMenu: "",
    loginUserPermission: "",
    loginResponse: "",
    checkResponse: "",
    response: "",
    jwtToken: "",
    projects: ["omp_dk", "omp_yoe", "omp_jx2", "omp_asgard"],
    isRouterAlive: false,
  },
  getters: {
    //
  },
  mutations: {
    setLoginUser(state, user) {   //使用者
      state.loginUser = user;
    },
    setProjectId(state, projectId, liveDay = 1) {  //專案ID
      appUtils.setCookie("projectId", projectId, liveDay);
      state.projectId = projectId;
    },
    setLoginUserMenu(state, menu) { //選單
      state.loginUserMenu = menu;
    },
    setLoginPermission(state, permission) { //權限
      state.loginUserPermission = permission;
    },
    setCheckResponse(state, response) {
      state.checkResponse = response;
    },
    setLoginResponse(state, response) {
      state.loginResponse = response;
    },
    setResponse(state, response) {
      state.response = response;
    },
    setJwtToken(state, token) {
      state.jwtToken = token;
    },
    setIsRouterAlive(state, isAlive) {
      state.isRouterAlive = isAlive;
    },
  },
  actions: {
    async loginUser({ commit, dispatch }, data) {
      data.projects = this.state.projects;
      let res = await api.login.login(data);
      if (res.data.status == "1") {
        let result = JSON.parse(res.data.result);
        let { menu, menuProjectId } = getProjectMenu(result.menu, appUtils.getCookie("projectId"));
        commit("setLoginUserMenu", menu);
        await this.dispatch('switchProjectId', menuProjectId);
        commit("setLoginPermission", result.permission);
        commit('setLoginUser', result.user);//觸發前端換頁，放最後執行
      }
      commit("setLoginResponse", res.data);
    },
    setRouterAlive({ commit }, isAlive) {
      commit("setIsRouterAlive", isAlive);
    },
    async loginCheck({ commit }) {
      let check = false;
      try {
        const projectId = appUtils.getCookie("projectId");
        const data = {
          projectId: "",
          jwtProjects: [projectId]
        }
        let res = await api.login.check(data);
        if (res.data.status == "1") {
          let result = JSON.parse(res.data.result);
          let { menu, menuProjectId } = getProjectMenu(result.menu, appUtils.getCookie("projectId"));
          commit("setLoginUserMenu", menu);
          await this.dispatch('switchProjectId', menuProjectId);
          commit("setLoginPermission", result.permission);

          //使用者如使用F5重整頁面，由這取專案的jwtToken
          commit("setJwtToken", result.jwtToken);
          commit('setLoginUser', result.user);
          check = true;
        } else {
          console.log("check false", res.data)
        }
        commit("setCheckResponse", res.data);
      } catch (error) {
        console.error(error)
      }
      return check;
    },
    loginOut({ commit }) {
      api.login.logout().then(res => {
        commit("setJwtToken", "");
        commit("setResponse", res.data);
        commit("setProjectId", "", 0);
        commit('setLoginUser', "");
      })
    },
    checkSession() {
      api.login.checkSession();
    },
    async switchProjectId({ commit }, projectId) {
      let res = await api.login.getUserJWT({ jwtProjects: [projectId] });
      if (res.data.status == "1") {
        this.commit("setJwtToken", res.data.result);
        this.commit("setProjectId", projectId);
        return true;
      }
      return false;

    }
  }
})
