<template>
    <div>
        <PopForm ref="popform" class="popform">
            <template v-slot:slot-title>
                {{ $t('發送密語') }}-{{ 聊天會員對象 }}
                <!-- {{ 聊天會員對象Alias }} -->
            </template>
            <template v-slot:slot-body>
                <div>
                    <span width="20%">{{ $t('新增罐頭訊息') }}：</span>
                    <multiselect v-model="value" :options="options" :custom-label="nameWithLang"
                        :placeholder="$t('選擇罐頭訊息')" label="answer" track-by="track" @select="onSelect"></multiselect>
                    <br>
                    <span>{{ $t('內容') }}：</span>
                    <textarea class="form-control" :placeholder="$t('請填入密語內容')" ref="inputArea" v-model.trim="msg"
                        @keydown.enter.exact="keyEnter"></textarea>
                </div>
            </template>
            <template v-slot:slot-footer>
                <button class="btn btn-primary" @click="SendChatMessage" :disabled="btnDisable">
                    {{ $t('確認送出') }}</button>
                <button class="btn btn-default pull-right" style="margin-left: 5px;" @click="$refs.popform.hide();">{{
                    $t('取消') }}
                </button>
            </template>
        </PopForm>
    </div>
</template>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 600px;
}

.popform>>>.box {
    border-top-width: 0;
}

.popform>>>.box-footer {
    display: flex;
    justify-content: flex-end;
}

.popform>>> {
    color: rgb(68, 68, 68);
}
</style>
<script>
import {
    ProjectSetting,
    InvokeCmd,
} from "@/store/MessageSystem/messageSystemConst";
import PopForm from "@/views/Template/PopFormTemplate";
import Multiselect from "vue-multiselect";
import { CsOmpAPI as CsAPI } from "@/store/MessageSystem/messageSystemLib";
import api from "@/assets/js/app-api";
export default {
    components: {
        PopForm,
        Multiselect,
    },
    data() {
        return {
            聊天會員對象: "",
            聊天會員對象Alias: "",
            msg: "",
            value: null,
            options: [
                // {
                // answer: "TEST"
                // question: "TEST"
                // sort: -5
                // track: "TESTTEST"
                // }
            ]
        }
    },
    async mounted() {
        let project = ProjectSetting.罐頭訊息Copy站台(this.$store.state.projectId);
        let 預設類別Type = ProjectSetting.密語預設類別(project);
        let categories = (CsAPI(await api.CS.GetCategoryList({ Project: project }))).result;
        let find = categories.find((類別) => {
            return 類別.keyName == 預設類別Type;
        });

        let categoryID = find ? find.categoryID : 0;
        let 固定罐頭訊息 = (CsAPI(await api.CS.GetCannedMsgList({
            Project: project,
            CategoryID: categoryID,
            Keyword: "",
            IsEnable: true,
            Page: -1,//拉全部
            IsCustomize: false//this.isCustomize,
        }))).result.cannedMsgList;
        let 自定義罐頭訊息 = (CsAPI(await api.CS.GetCannedMsgList({
            Project: project,
            CategoryID: categoryID,
            Keyword: "",
            IsEnable: true,
            Page: -1,//拉全部
            IsCustomize: true//this.isCustomize,
        }))).result.cannedMsgList;
        this.options = [];
        for (let i of 固定罐頭訊息) {
            this.options.push({
                question: i.question,
                answer: i.answer,
                sort: i.sort,
                track: `${i.question}${i.answer}`
            });
        }
        for (let i of 自定義罐頭訊息) {
            this.options.push({
                question: i.question,
                answer: i.answer,
                sort: i.sort,
                track: `${i.question}${i.answer}`
            });
        }
        this.options.sort((a, b) => { return a.sort - b.sort });
    },
    computed: {
        btnDisable() {
            return this.msg.trim() == "";
        }
    },
    methods: {
        async show(聊天會員對象, 聊天會員對象Alias) {
            this.聊天會員對象 = 聊天會員對象;
            this.聊天會員對象Alias = 聊天會員對象Alias;
            this.msg = "";
            this.$refs.popform.show();
            this.$nextTick(() => {
                this.$refs.inputArea.focus();
            });
        },
        nameWithLang({
            answer,
            question,
            sort,
            track,
        }) {
            return `${answer}`
        },
        onSelect(selectedOption, id) {
            this.msg = selectedOption.answer;
            this.$nextTick(() => {
                this.$refs.inputArea.focus();
            });
        },
        keyEnter(event) {
            if (event.keyCode == 13) {
                this.SendChatMessage();
                event.cancelBubble = true;
                event.preventDefault();
                event.stopPropagation();
            }
        },
        SendChatMessage() {
            let msg = this.msg.trim();
            if (msg != "") {
                this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.SendMessage, this.聊天會員對象, msg, true]);
                this.$refs.popform.hide();
            } else {
                this.$eventBus.$emit("showAlert", this.$t('請輸入傳送訊息'));
            }
        },
    },
};
</script>