<template>
    <div>
        <div style="margin: 9px 0;display: flex;padding: 0 15px;">
            <select class="form-control" v-model="當前類別Id" style="margin-right: 10px;">
                <option :value=-1>{{ $t('全部類別') }}</option>
                <option v-for="item of 罐頭類別List" :value="item.id" :key="item.id">
                    {{ item.類別 }}
                </option>
            </select>
            <input type="text" class="form-control" :placeholder="$t('請輸入關鍵字')" v-model.trim="search字串" />
        </div>
        <!-- 685-56=629 -->
        <div class="cal-list-height" style="border-top: 1px solid #E8E8E8;">
            <div class="can-msg-title" @click="isShow自定義 = !isShow自定義">{{ $t('自定義訊息') }}
                <i class="fa fa-fw fa-arrow-down" v-show="isShow自定義"></i>
                <i class="fa fa-fw fa-arrow-up" v-show="!isShow自定義"></i>
            </div>
            <div class="can-msg-list" v-for="item of 罐頭訊息List.自定義list" :title="item.Q + '\n\n' + item.A" :key="item.id"
                @click="addCanMessag(item.A)" v-show="isShow自定義">
                <div class="font-style-q font-style-ellipsis">{{ item.Q }}</div>
                <div class="font-style-a font-style-ellipsis">{{ item.A }}</div>
            </div>
            <template v-if="罐頭訊息List.自定義list.length == 0 && isShow自定義">
                <div class="can-msg-noList">
                    {{ $t('無資料') }}
                </div>
            </template>
            <div class="can-msg-title" @click="isShow固定 = !isShow固定">{{ $t('通用訊息') }}
                <i class="fa fa-fw fa-arrow-down" v-show="isShow固定"></i>
                <i class="fa fa-fw fa-arrow-up" v-show="!isShow固定"></i>
            </div>
            <div class="can-msg-list" v-for="item of 罐頭訊息List.固定list" :title="item.Q + '\n\n' + item.A" :key="item.id"
                @click="addCanMessag(item.A)" v-show="isShow固定">
                <div class="font-style-q font-style-ellipsis">{{ item.Q }}</div>
                <div class="font-style-a font-style-ellipsis">{{ item.A }}</div>
            </div>
            <template v-if="罐頭訊息List.固定list.length == 0 && isShow固定">
                <div class="can-msg-noList">
                    {{ $t('無資料') }}
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { CsOmpAPI as CsAPI } from "@/store/MessageSystem/messageSystemLib";
import api from "@/assets/js/app-api";
import {
    ProjectSetting,
} from "@/store/MessageSystem/messageSystemConst";
export default {
    components: {},
    data() {
        let data = {
            CannedMsgForm: false,
            罐頭類別List: [],
            search字串: "",
            isShow自定義: true,
            isShow固定: false,
        };
        data.當前類別Id = -1;
        return data;
    },
    computed: {
        罐頭訊息List() {
            if (this.當前類別Id == -1) {
                let 固定list = [];
                let 自定義list = [];
                for (let i of this.罐頭類別List) {
                    if (this.search字串 == "") {
                        固定list.push(...i.固定list);
                        自定義list.push(...i.自定義list);
                    } else {
                        for (let tmpstr of i.固定list) {
                            let reg = new RegExp(this.search字串, "i");
                            if (tmpstr.Q.search(reg) >= 0 || tmpstr.A.search(reg) >= 0) {
                                固定list.push(tmpstr);
                            }
                        }
                        for (let tmpstr of i.自定義list) {
                            let reg = new RegExp(this.search字串, "i");
                            if (tmpstr.Q.search(reg) >= 0 || tmpstr.A.search(reg) >= 0) {
                                自定義list.push(tmpstr);
                            }
                        }
                    }
                }
                return {
                    固定list: 固定list.sort((a, b) => { return a.sort - b.sort }),
                    自定義list: 自定義list.sort((a, b) => { return a.sort - b.sort }),
                };
            } else {
                for (let i of this.罐頭類別List) {
                    if (i.id == this.當前類別Id) {
                        if (this.search字串 == "") {
                            let 當前類別 = this.罐頭類別List.find((item) => { return item.id == this.當前類別Id });
                            return {
                                固定list: (當前類別 ? 當前類別.固定list : []).sort((a, b) => { return a.sort - b.sort }),
                                自定義list: (當前類別 ? 當前類別.自定義list : []).sort((a, b) => { return a.sort - b.sort }),
                            };
                        } else {
                            let 當前類別 = this.罐頭類別List.find((item) => { return item.id == this.當前類別Id });
                            if (當前類別) {
                                let 固定list = [];
                                let 自定義list = [];
                                for (let tmpstr of 當前類別.固定list) {
                                    let reg = new RegExp(this.search字串, "i");
                                    if (tmpstr.Q.search(reg) >= 0 || tmpstr.A.search(reg) >= 0) {
                                        固定list.push(tmpstr);
                                    }
                                }
                                for (let tmpstr of 當前類別.自定義list) {
                                    let reg = new RegExp(this.search字串, "i");
                                    if (tmpstr.Q.search(reg) >= 0 || tmpstr.A.search(reg) >= 0) {
                                        自定義list.push(tmpstr);
                                    }
                                }
                                return {
                                    固定list: 固定list.sort((a, b) => { return a.sort - b.sort }),
                                    自定義list: 自定義list.sort((a, b) => { return a.sort - b.sort }),
                                };
                            } else {
                                return {
                                    固定list: [],
                                    自定義list: [],
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this) {
                return;
            }
            let categories = [];
            try {
                categories = (CsAPI(await api.CS.GetCategoryList({ Project: ProjectSetting.罐頭訊息Copy站台(this.$store.state.projectId) }))).result;
            } catch (e) {
                //
            }
            for (let i of categories) {
                if (i.enable == false) { continue; }
                this.罐頭類別List.push({
                    id: i.categoryID, 類別: i.name, type: i.keyName, 固定list: [], 自定義list: []
                })
            }
            let 固定罐頭訊息 = (CsAPI(await api.CS.GetCannedMsgList({
                Project: ProjectSetting.罐頭訊息Copy站台(this.$store.state.projectId),
                CategoryID: 0,
                Keyword: "",
                IsEnable: true,
                Page: -1,//拉全部
                IsCustomize: false
            }))).result.cannedMsgList;

            let 自定義罐頭訊息 = (CsAPI(await api.CS.GetCannedMsgList({
                Project: ProjectSetting.罐頭訊息Copy站台(this.$store.state.projectId),
                CategoryID: 0,
                Keyword: "",
                IsEnable: true,
                Page: -1,//拉全部
                IsCustomize: true
            }))).result.cannedMsgList;

            for (let item of 固定罐頭訊息) {
                let find類別 = this.罐頭類別List.find((類別) => { return 類別.id == item.categoryID });
                if (!find類別) {
                    continue;
                }
                find類別.固定list.push({ id: item.id, Q: item.question, A: item.answer, sort: item.sort });
            }
            for (let item of 自定義罐頭訊息) {
                let find類別 = this.罐頭類別List.find((類別) => { return 類別.id == item.categoryID });
                if (!find類別) {
                    continue;
                }
                find類別.自定義list.push({ id: item.id, Q: item.question, A: item.answer, sort: item.sort });
            }
        },
        Public設定選取類別(預設選取類別) {
            let 預設類別 = this.罐頭類別List.find((類別) => {
                return 類別.type == 預設選取類別;
            });
            this.當前類別Id = 預設類別 ? 預設類別.id : -1/*this.罐頭類別List[0].id*/;
        },
        addCanMessag(msg) {
            this.$emit("addCanMessag", msg);
        },
    },
    watch: {
        search字串() {
            this.isShow自定義 = true;
            this.isShow固定 = true;
        },
        當前類別Id() {
            this.isShow自定義 = true;
            this.isShow固定 = true;
        }
    }
};
</script>
<style src="@/assets/css/default/message.css" lang="css" scoped />
<style scoped>
div.can-msg-title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background-color: #bfbfbf;
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid white;
}

div.can-msg-list {
    cursor: pointer;
    padding: 10px 15px;
}

div.can-msg-noList {
    padding: 10px 15px;
    background-color: #eeecec;
}

div.can-msg-list:nth-child(2n+1) {
    background-color: #eeecec;
}

.font-style-q::before {
    content: "Ｑ： ";
}

.font-style-a::before {
    content: "Ａ： ";
}
</style>