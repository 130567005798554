<template>
  <FormDialog ref="memberVIPDialog" :title="'VIP階級調整'"
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>VIP階級</th>
            <td>6 -> 7</td>
          </tr>
          <tr>
            <th>活躍值</th>
            <td>{{data.活躍值}}</td>
          </tr>
          <tr>
            <th>異動項目</th>
            <td>升階</td>
          </tr>
          <tr>
            <th>服務類型</th>
            <td>
              會員資料修改
            </td>
          </tr>
          <tr>
            <th>原因</th>
            <td>彩鑽審核通過升階</td>
          </tr>
          <tr>
            <th>注意事項</th>
            <td>
              <ul class="mb-0" style="padding-left: 25px;">
                <li>升階只允許6階到7階</li>
                <li>升7階活躍值需要大於10萬</li>
              </ul>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        level:"6",
        活躍值:"1111",
      },
      player: null,
      isAddHonor: null, //榮譽正負
    };
  },
  mounted(){
  },
  methods: {
    async show(player, isAddHonor) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player;
      this.data.暱稱 = player.暱稱;
      this.isAddHonor = isAddHonor !== undefined ? isAddHonor : null;
      this.$refs.memberVIPDialog.show();
    },
    submit(){
      let data = this.data;
      if (this.isAddHonor === false && this.榮譽值 > this.player.榮譽值){
        this.$eventBus.$emit("showAlert", "送出失敗，剩餘榮譽值不得少於0");
        return;
      }
      data.榮譽值 = this.isAddHonor ? data.榮譽值 : data.榮譽值 * -1;
      this.$store.dispatch("xsw/會員榮譽值異動", data).then(() => {
        this.$refs.memberVIPDialog.hide();
        this.$eventBus.$emit("showAlert", "異動成功！");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
