import api from "@/assets/js/app-api";

const state = {
    queryAll: {},
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    //操作說明
    GameOperationList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationList(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameOperationQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationQuery(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameOperationCreat({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationCreat(data).then(res => {
                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                } 
                resolve(res.data);
            })
        })
    },
    
    GameOperationUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationUpdate(data).then(res => {
                // this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameOperationDel({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationDel(data).then(res => {
                // this._vm.$eventBus.$emit('showAlert', res.data.message);

                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                }
            });
        });
    },

    GameOperationClear({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationClear(data).then(res => {
                // this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameOperationCopy({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameOperationCopy(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    //遊戲介紹
    GameIntroList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroList(data).then(res => {
                resolve(res.data.result);
            });
        });
    },

    GameIntroQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroQuery(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameIntroCreat({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroCreat(data).then(res => {
                /* if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                } */

                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }

                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    
    GameIntroUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroUpdate(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    GameIntroDel({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroDel(data).then(res => {
                // this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                }
            });
        });
    },

    GameIntroClear({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroClear(data).then(res => {
                // this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    GameIntroCopy({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.GameIntroCopy(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    xinUploadImg({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.xinUploadImg(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },

    xinDelImg({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.xinDelImg(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    xinUpdateImg({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.xinUpdateImg(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

    //開箱文 清 catch
    unboxingCatch({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.unboxingCatch(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },

}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}