<template>
    <div class="modal" ref="PopForm" @click="clickBg()">
        <div class="modalWidth" style="margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box">
                <div class="box-header with-border">
                    <h3 class="box-title">
                        <slot name="slot-title">
                            TodoTitle
                        </slot>
                    </h3>
                    <button type="button" class="close" @click="hide()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="scroll-area" ref="scrollArea">
                    <div class="box-body">
                        <slot name="slot-body">
                            TodoBody
                        </slot>
                    </div>
                    <div class="box-footer">
                        <slot name="slot-footer">
                            TodoFooter
                        </slot>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.hide();
    },
    methods: {
        show() {
            $(this.$refs.PopForm).modal("show");
            $(this.$refs.loading).fadeOut(400);
            this.$refs.scrollArea.scrollTop = 0;
        },
        hide() {
            $(this.$refs.PopForm).modal("hide");
        },
        loading(bol) {
            bol ? $(this.$refs.loading).show() : $(this.$refs.loading).fadeOut(400);
        },
        clickBg() {
            this.$emit("onBGClick");
        },
    },
}
</script>
<style scoped>
/* .mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
} */

.scroll-area {
    max-height: 80vh;
    overflow-y: auto;
}

.box-footer {
    display: flex;
    justify-content: space-evenly;
}
</style>