<template>
  <div id="EventRecordList">
    <section class="content-header">
      <h1>活動歷程列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動管理</li>
        <li class="active">活動歷程列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <!-- 分類下拉 -->
            <div class="select-group mr-20">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.活動編號"
                @change="GetEventTaskList"
              >
                <option
                  :value="item.活動編號"
                  v-for="(item, index) in eventInfoQueryAll"
                  :key="index"
                >
                  {{ item.活動名稱 }}
                </option>
              </select>
            </div>

            <div class="input-group mr-20">
              <label class="control-label">國碼：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋國碼"
                type="text"
                v-model="searchData.國碼"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋門號"
                type="text"
                v-model="searchData.門號"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">暱稱：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋暱稱"
                type="text"
                v-model="searchData.暱稱"
              />
            </div>

            <div class="select-group mr-20">
              <label class="control-label">玩家類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.玩家類型"
              >
                <option value="-1">全部</option>
                <option value="1">新玩家</option>
                <option value="2">老玩家</option>
              </select>
            </div>

            <div class="select-group mr-20">
              <label class="control-label">獎勵：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.獎勵ID"
              >
                <option value="-1">全部</option>
                <option
                  :value="item.獎勵ID"
                  v-for="(item, index) in eventTaskPrizeQueryAll"
                  :key="index"
                >
                  {{ item.獎勵名稱 }}
                </option>
              </select>
            </div>

            <div class="input-group mr-20">
              <label class="control-label">獎勵序號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋獎勵序號"
                type="text"
                v-model="searchData.獎勵序號"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">兌換對象：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋兌換對象"
                type="text"
                v-model="searchData.兌換對象"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">驗證碼：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋驗證碼"
                type="text"
                v-model="searchData.驗證碼"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.開始時間" />
              </div>
            </div>

            <div class="input-group">
              <label class="control-label">結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.結束時間" />
              </div>
            </div>

            <div class="form-group fl mr-20 snmMT w100-md mt-15-md">
              <button
                @click="clickSearch"
                class="btn btn-warning w100-md"
                type="button"
              >
                查詢
              </button>
            </div>

            <div class="text mt-15">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 10px; width: 3%">No</th>
                  <th style="min-width: 20px; width: 3%">活動編號</th>
                  <th style="min-width: 50px; width: 5%">任務</th>
                  <th style="min-width: 20px; width: 2%">國碼</th>
                  <th style="min-width: 50px; width: 5%">門號</th>
                  <th style="min-width: 50px; width: 5%">暱稱</th>
                  <th style="min-width: 10px; width: 1%">玩家類型</th>
                  <th style="min-width: 10px; width: 1%">回流玩家</th>
                  <th style="min-width: 50px; width: 5%">獎勵名稱</th>
                  <th style="min-width: 50px; width: 5%">獎勵序號</th>
                  <th style="min-width: 10px; width: 1%">獎勵配發</th>
                  <th style="min-width: 50px; width: 5%">驗證碼</th>
                  <th style="min-width: 10px; width: 1%">是否兌換</th>
                  <th style="min-width: 50px; width: 5%">兌換對象</th>
                  <th style="min-width: 50px; width: 5%">備註</th>
                  <th style="min-width: 50px; width: 5%">兌換時間</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 50px; width: 5%"
                  >
                    建立時間
                  </th>
                </tr>
                <template v-if="total > 0">
                  <tr :key="item.id" v-for="(item, index) in datas">
                    <td align="center">
                      {{
                        index +
                        1 +
                        (searchData.開始筆數 - 1) * searchData.結束筆數
                      }}
                    </td>
                    <td align="center">{{ item.活動編號 }}</td>
                    <td align="center">{{ GetTask(item.任務ID) }}</td>
                    <td align="center">{{ item.國碼 }}</td>
                    <td align="center">{{ item.門號 }}</td>
                    <td align="center">{{ item.暱稱 }}</td>
                    <td align="center">{{ GetPlayerType(item.玩家類型) }}</td>
                    <td align="center">{{ IsReturning(item.是否回流玩家) }}</td>
                    <td align="center">{{ GetPrize(item.獎勵ID) }}</td>
                    <td align="center">{{ item.獎勵序號 }}</td>
                    <td align="center">
                      {{ GetDistribute(item.獎勵配發狀態) }}
                    </td>
                    <td align="center">{{ item.驗證碼 }}</td>
                    <td align="center">{{ IsReceive(item.是否兌換) }}</td>
                    <td align="center">{{ item.兌換對象 }}</td>
                    <td align="center">{{ item.備註 }}</td>
                    <td align="center">
                      {{
                        item.兌換時間
                          ? $utils.formatTime(
                              item.兌換時間,
                              "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss"
                            )
                          : "-"
                      }}
                    </td>
                    <td align="center">
                      {{
                        $utils.formatTime(
                          item.建立時間,
                          "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss"
                        )
                      }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="17">查詢無任何資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.結束筆數"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import DatePicker from "@/components/DatePicker";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Pagination,
    DatePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        活動編號: "",
        任務ID: -1,
        國碼: "",
        門號: "",
        暱稱: "",
        玩家類型: -1,
        是否回流玩家: -1,
        獎勵ID: -1,
        獎勵序號: "",
        獎勵配發狀態: -1,
        驗證碼: "",
        是否兌換: -1,
        兌換對象: "",
        開始時間: "",
        結束時間: "",
        兌換開始時間: "",
        兌換結束時間: "",
        開始筆數: 1,
        結束筆數: 10, //每頁顯示筆數
      },

      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.GetEventList();
  },
  computed: {
    ...mapState({
      eventInfoQueryAll: (state) => state.xinEvent.EventInfoQueryAll,
      eventTaskQueryAll: (state) => state.xinEvent.EventTaskQueryAll,
      eventTaskPrizeQueryAll: (state) => state.xinEvent.EventTaskPrizeQueryAll,
      eventTaskRecordQueryAll: (state) =>
        state.xinEvent.EventTaskRecordQueryAll,
    }),
  },
  watch: {
    "searchData.活動編號"(newVal, oldVal) {
      if (newVal) {
        this.datas = [];
        this.$store.state.xinEvent.EventTaskPrizeQueryAll = []
        this.$store.state.xinEvent.eventTaskQueryAll = []
      }
    },
    eventInfoQueryAll() {
      if (this.eventInfoQueryAll != null && this.eventInfoQueryAll.length > 0) {
        this.searchData.活動編號 = this.eventInfoQueryAll[0].活動編號;
        this.GetEventTaskList();
      }
    },
    eventTaskQueryAll() {
      this.searchData.任務ID = -1;
      this.GetEventTaskPrizeList();
    },
    eventTaskPrizeQueryAll() {
      this.searchData.獎勵ID = -1;
    },
    eventTaskRecordQueryAll() {
      this.datas = this.eventTaskRecordQueryAll.datas;
      this.total = this.eventTaskRecordQueryAll.total;
      this.$refs.pagination.setPage(
        this.searchData.開始筆數,
        this.eventTaskRecordQueryAll.total
      );
    },
  },
  methods: {
    getDataList() {
      //列表
      this.$store.dispatch(
        "xinEvent/EventTaskRecordQueryList",
        this.searchData
      );
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.開始筆數 = pageIdx;
      this.searchData.結束筆數 = pageSize;
      this.getDataList();
    },

    GetEventList() {
      //活動列表
      this.$store.dispatch("xinEvent/EventInfoQueryList", {
        啟停用: 1,
        是否提前測試: true,
      });
    },

    GetEventTaskList() {
      //任務列表
      this.$store.dispatch("xinEvent/EventTaskQueryList", {
        活動編號: this.searchData.活動編號,
      });
    },

    GetEventTaskPrizeList() {
      //任務獎勵列表
      this.$store.dispatch("xinEvent/EventTaskPrizeQueryList", {
        活動編號: this.searchData.活動編號,
        任務ID: -1,
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },

    GetTask(taskID) {
      let 類型 = "";
      switch (this.searchData.活動編號) {
        case 100081:
          switch (taskID) {
            case 1:
              類型 = "參加禮";
              break;
            case 2:
              類型 = "儲值禮";
              break;
          }
          break;
        default:
          const data = this.eventTaskQueryAll.find(
            (item) => item.任務ID === taskID
          );
          if (data != null) {
            switch (data.任務類型) {
              case 1:
                類型 = "參加禮";
                break;
              case 2:
                類型 = "儲值禮";
                break;
              case 3:
                類型 = "登記";
                break;
              case 4:
                類型 = "序號兌換";
                break;
            }
          }
          break;
      }
      return 類型;
    },

    GetPrize(prizeID) {
      switch (this.searchData.活動編號) {
        case 100081:
          switch (prizeID) {
            case 1:
              return "抵用金50元";
            case 2:
              return "抵用金100元";
            case 3:
              return "抵用金10元";
          }
          break;
        default:
          const data = this.eventTaskPrizeQueryAll.find(
            (item) => item.獎勵ID === prizeID
          );
          return data != null ? `${data.獎勵名稱}` : "";
      }
    },

    GetPlayerType(status) {
      switch (status) {
        case 1:
          return "新玩家";
        case 2:
          return "老玩家";
      }
    },
    GetDistribute(status) {
      switch (status) {
        case 0:
          return "未配發";
        case 1:
          return "已配發";
        case 2:
          return "待確認";
      }
    },
    IsReturning(status) {
      return status ? "是" : "否";
    },
    IsReceive(status) {
      return status ? "是" : "否";
    },
  },
};
</script>
  