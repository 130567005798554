@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動歷程</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">活動歷程</li>
      </ol>
    </section>

    <section class="content">
      <div class="nav-tabs-custom tab-warning" v-if="tab != 0">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="tab = 1" style="cursor: pointer">抽獎券兌換歷程查詢</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="tab = 2" style="cursor: pointer"> 統計查詢 </a>
          </li>
        </ul>
        <div v-show="tab == 1" class="box-body">
          <div class="box-body">
            <form
              class="top-box"
              @submit.prevent="clickSearch()"
              style="display: flex"
            >
              <!-- <router-link
                :to="{
                  name: 'XsgEventListEdit',
                  params: { id: 'New', searchData },
                }"
                class="btn btn-block btn-warning btn-add fa fa-plus"
                >新增</router-link
              > -->
              <div style="width: 700px">
                <div class="select-group">
                  <select
                    class="form-control"
                    v-model="目前活動"
                    @change="clickSearch()"
                  >
                    <option
                      v-for="(name, key) in listdata"
                      :key="key"
                      :value="name.活動編號"
                    >
                      {{ name.活動名稱 }}
                    </option>
                  </select>
                </div>
                <div class="date-group" style="width: auto">
                  <label class="control-label">查詢期間：</label>
                  <div class="input-group date">
                    <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div>
                    <div class="pull-right" style="width: 320px">
                      <DateRangePicker
                        :propTimePicker="true"
                        :propEndDate.sync="searchData.結束時間"
                        :propStartDate.sync="searchData.開始時間"
                        propsIn="format"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class=""
                  style="
                    display: flex;
                    align-items: center;
                    width: 586px;
                    word-break: keep-all;
                    padding-top: 15px;
                  "
                >
                  <span>UID查詢:</span>
                  <input
                    type="text"
                    class="form-control p-5"
                    v-model="searchData.UID"
                    placeholder="輸入UID"
                    style="padding-right: 0; width: 220px; margin: 0 10px"
                  />
                  <span>抽獎券編號:</span>
                  <input
                    type="text"
                    class="form-control p-5"
                    v-model="searchData.獎項序號"
                    placeholder="輸入抽獎券編號"
                    style="padding-right: 0; width: 220px; margin: 0 10px"
                  />
                </div>
              </div>
              <div class="m-0" style="    position: absolute;    right: 30px;">
                <button
                  class="btn btn-primary pull-left mb-0"
                  
                >
                  {{ $t("查詢") }}
                </button>
                <div class="text">
                  <p>資料總筆數：{{ total }}</p>
                </div>
              </div>
            </form>

            <div class="table-responsive mt-10 text-center">
              <table class="table table-hover table-bordered AdListTable">
                <tbody>
                  <tr class="bg-gray text-nowrap">
                    <th style="min-width: 50px; width: 2%">編號</th>
                    <th style="min-width: 80px; width: 5%">活動名稱</th>
                    <th style="min-width: 120px; width: 10%">時間</th>
                    <th style="min-width: 100px; width: 10%">暱稱</th>
                    <th style="min-width: 100px; width: 10%">UID</th>
                    <th style="min-width: 100px; width: 10%">兌換活動點數</th>
                    <th style="min-width: 100px; width: 10%">兌換抽獎券張數</th>
                    <th style="min-width: 100px; width: 10%">兌換後剩餘點數</th>
                    <th style="min-width: 100px; width: 5%">抽獎券號碼</th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="11">{{ $t("查詢中") }}</td>
                  </tr>
                  <tr v-else-if="message">
                    <td colspan="11">{{ $t(message) }}</td>
                  </tr>
                  <tr v-else-if="total === 0">
                    <td colspan="11">{{ $t("查無紀錄") }}</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="item.id">
                    <!-- <td>{{ item.活動編號 }}</td> -->
                    <td>{{ index + 1 }}</td>
                    <td>{{ 目前活動名稱 }}</td>
                    <td>{{ item.兌換時間 }}</td>
                    <td>{{ item.暱稱 }}</td>
                    <td>{{ item.uid }}</td>
                    <td>{{ item.活動點數 }}</td>
                    <td>{{ item.抽獎券張數 }}</td>
                    <td>{{ item.備註 }}</td>

                    <!-- 
                  <td class="text-nowrap">
                    <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="!can修改">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.狀態" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add" @click="changeStatus(item)" :disabled="!item.狀態">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </fieldset>
                  </td> -->
                    <td>
                      <div
                        style="display: flex; flex-direction: column; gap: 5px"
                      >
                        <button
                          @click="openTicket(item)"
                          class="btn btn-primary"
                        >
                          查詢
                        </button>
                        <!-- <button class="btn btn-danger" v-if="can刪除" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer text-center no-border">
              <Pagination
                ref="pagination"
                :total="total"
                :pageLimitCount="searchData.pageSize"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>
        </div>
        <div v-show="tab == 2" class="box-body">
          <div class="box-body">
            <form class="top-box" @submit.prevent="clickSearchStatus()">
              <!-- <router-link
                :to="{
                  name: 'XsgEventListEdit',
                  params: { id: 'New', searchData },
                }"
                class="btn btn-block btn-warning btn-add fa fa-plus"
                >新增</router-link
              > -->
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="目前活動"
                  @change="clickSearchStatus()"
                >
                  <option
                    v-for="(name, key) in listdata"
                    :key="key"
                    :value="name.活動編號"
                  >
                    {{ name.活動名稱 }}
                  </option>
                </select>
              </div>
               <div class="m-0" style="display: flex;    align-items: center;    word-break: keep-all;    padding-top: 0;">
                  <span>UID查詢:</span>
                  <input
                    type="text"
                    class="form-control p-5"
                    v-model="searchHistory.UID"
                    placeholder="輸入UID"
                    style="padding-right: 0; width: 220px; margin: 0 10px"
                  />
                    <button class="btn btn-primary pull-left mb-0">
                  {{ $t("查詢") }}
                </button>
              </div>
              <div class="m-0" style="position: absolute; right: 30px">
              
                <!-- <div class="text">
                  <p>資料總筆數：{{ total }}</p>
                </div> -->
              </div>


              <!-- <div class="text">
                <p>資料總筆數：{{ totalStat }}</p>
              </div> -->
            </form>

            <div
              class="table-responsive mt-10 text-center"
              style="max-width: 800px"
            >
              <table class="table table-hover table-bordered AdListTable">
                <tbody>
                  <tr class="bg-gray text-nowrap">
                    <th style="min-width: 80px; width: 10%">活動名稱</th>
                    <th style="min-width: 120px; width: 10%">兌換UID總計</th>
                    <th style="min-width: 100px; width: 10%">
                      兌換活動點數總計
                    </th>
                    <th style="min-width: 100px; width: 10%">
                      兌換抽獎券張數總計
                    </th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="11">{{ $t("查詢中") }}</td>
                  </tr>
                  <tr v-else-if="messageStat">
                    <td colspan="11">{{ $t(messageStat) }}</td>
                  </tr>
                  <tr>
                    <td>{{ 目前活動名稱 }}</td>
                    <td>{{ dataStat.不重複UID總數 }}</td>
                    <td>{{ dataStat.活動點數 }}</td>
                    <td>{{ dataStat.抽獎券張數 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
      </div>
      <div class="box"></div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->

    <div class="modal fade" ref="ticketAlert">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">
              <span>抽獎券詳細</span>
            </h4>
          </div>

          <div class="modal-body">
            <table class="table table-borderless">
              <tbody>
                <tr class="bg-gray">
                  <th
                    class="text-center"
                    style="width: 20%; border: 1px solid gray"
                  >
                    編號
                  </th>
                  <th style="width: 80%; border: 1px solid gray">抽獎券編號</th>
                </tr>
                <tr v-for="(item, index) in mydata" :key="index">
                  <td class="text-center" style="border: 1px solid gray">
                    {{ index + 1 }}
                  </td>
                  <td style="border: 1px solid gray">
                    <span>{{ item.序號 }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
input[type="checkbox"] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.2em;
  accent-color: #3498db;
  cursor: pointer;
}
label.required::before {
  content: "* ";
  color: #dd4b39;
}
.form-control.sort {
  display: inline-block;
  width: 54px;
  padding: 5px;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
}
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    iCheckRedioButton,
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      bannerType: types.gameBannerType,
      // def: define,
      projectId: this.$store.state.projectId,
      tab: 1,
      目前活動: "",
      目前活動名稱: "",
      searchData: {
        活動編號: -1,
        UID: "",
        獎項序號: "",
        // 標題: "",
        開始時間: new moment().subtract(3, "month").format("YYYY/MM/DD HH:mm"),
        結束時間: new moment().format("YYYY/MM/DD HH:mm"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      searchHistory:{
          活動編號:-1,
          UID: "",
          開始時間: new moment().subtract(3, "month").format("YYYY/MM/DD HH:mm"),
          結束時間: new moment().format("YYYY/MM/DD HH:mm"),
      },
      //  searchUID: {
      //   活動編號: -1,
      //   UID:'',
      //   開始時間:  new moment().subtract(3, "month").format("YYYY/MM/DD hh:mm"),
      //   結束時間: new moment().format("YYYY/MM/DD hh:mm"),
      //   pageNum: 1,
      //   pageSize: 10, //每頁顯示筆數
      // },
      startTime: new moment().subtract(3, "month").format("YYYY/MM/DD HH:mm"),
      endTime: new moment().format("YYYY/MM/DD HH:mm"),
      loading: false,
      message: "",
      listdata: [],
      mydata: [],
      dataStat: [],
      messageStat: "",
      total: 0, //總筆數
      totalStat: 0,
      can新增: this.$user.hasPermission(dfn.permission.活動兌換歷程新增),
      can修改: this.$user.hasPermission(dfn.permission.活動兌換歷程修改),
      can刪除: this.$user.hasPermission(dfn.permission.活動兌換歷程刪除),
    };
  },
  async created() {
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    // getImageUrl() {
    //   return (item, info) => {
    //     const logo1File = item.files.find((file) => file.info === info);
    //     return logo1File ? logo1File.filesUrl : "";
    //   };
    // },
  },
   watch: {
    tab(newValue, oldValue) {
      if(newValue == 2){
          this.getStatus();
      }else{
          this.getDataList();
      }
    },
       // response() {
    //   if (this.response.status == "1")
    //     this.$eventBus.$emit("showAlert", "更新成功!");
    //   else this.$eventBus.$emit("showAlert", "更新失敗!");
    //   this.GetBannerQueryAll();
    // },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // async onPageChange2(pageIdx, pageSize) {
    //   this.searchData.pageNum = pageIdx;
    //   this.searchData.pageSize = pageSize;
    //   this.getData();
    // },
    getData() {
      this.loading = true;
      //get 活動list
      this.$store.dispatch("xsw/活動名稱列表查詢", {}).then(
        (res) => {
          this.message = "";

          this.listdata = res || [];
          this.目前活動 = this.listdata[0].活動編號;
          this.$nextTick(() => {
            this.getDataList();
          });
        },
        (err) => {
          this.message = err;
          this.listdata = [];
        }
      );
    },
    getDataList() {
      this.loading = true;
      this.searchData.活動編號 = this.目前活動;

      const selectedItem = this.listdata.find(
        (item) => item.活動編號 === this.目前活動
      );

      this.目前活動名稱 = selectedItem ? selectedItem.活動名稱 : "";

      console.log(this.目前活動名稱);

      
      this.$store.dispatch("xsw/活動兌換歷程查詢", this.searchData).then(
        (res) => {
          console.log(res.datas);
          this.message = "";
          this.datas = res.datas || [];
          this.total = res.total || 0;
          this.loading = false;
        },
        (err) => {
          this.message = err;
          this.datas = [];
          this.total = 0;
          this.loading = false;
        }
      );
    },

    getStatus() {
      console.log("getStatus");
      this.loading = true;
      this.searchData.活動編號 = this.目前活動;
      this.searchHistory.活動編號 = this.目前活動;
      const selectedItem = this.listdata.find(
        (item) => item.活動編號 === this.目前活動
      );
      this.目前活動名稱 = selectedItem ? selectedItem.活動名稱 : "";

      this.$store.dispatch("xsw/活動兌換歷程統計", this.searchHistory).then(
        (res) => {
          console.log(res);
          this.message = "";
          this.dataStat = res || [];
          //this.totalStat = res.total || 0;
          this.loading = false;
        },
        (err) => {
          this.message = err;
          this.dataStat = [];
          //  this.total = 0;
          this.loading = false;
        }
      );
    },
    clickSearchStatus() {
       this.getStatus();
    },
    clickSearch() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    openTicket(item) {
      // this.loading = true;
      // this.$store
      //   .dispatch("xsw/活動兌換歷程查詢", {
      //     活動編號: item.活動編號,
      //     UID: item.uid,
      //     獎項序號: "",
      //     開始時間: this.searchData.開始時間,
      //     結束時間: this.searchData.結束時間,
      //     PageNum: 1,
      //     PageSize: 10,
      //   })
      //   .then(
      //     (res) => {
      this.mydata = item.tickets || [];
      this.openBox();
      //     },
      //     (err) => {
      //       this.message = err;
      //       this.loading = false;
      //     }
      //   );
    },
    changeStatus(item) {
      // this.$store.dispatch("xsw/活動兌換歷程修改", {
      //   活動編號: item.活動編號,
      //   活動名稱: item.活動名稱,
      //   活動開始時間: item.活動開始時間,
      //   活動結束時間: item.活動結束時間,
      //   兌換截止時間: item.兌換截止時間
      // }).then(()=>{
      //   this.$eventBus.$emit("showAlert", "修改成功");
      //   this.getData();
      // });
    },
    openBox() {
      $(this.$refs.ticketAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.ticketAlert).modal("hide");
    },
    //*---- 刪除
    deleteItem(item) {
      // this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
      //   if (isOK) {
      //     this.$store.dispatch("xsw/活動兌換歷程刪除", item).then(()=>{
      //       this.$eventBus.$emit("showAlert", "刪除成功");
      //       this.getData();
      //     });
      //   }
      // });
    },
    uiDate(date) {
      let d = new Date(date);
      if (date && d)
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
      else return "";
    },
  },
};
</script>