<template>
  <div id="ForumMuteSetting">
    <section class="content-header">
      <h1>動態牆禁言管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">動態牆禁言管理</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  v-bind:propEndDate.sync="searchData.endTime"
                  v-bind:propStartDate.sync="searchData.startTime"
                  v-on:restList="GetRestList"
                />
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="輸入ID"
                type="text"
                v-model="searchData.uId"
              />
            </div>
            <button @click="clickSearch()" class="btn btn-default" type="button">查詢</button>
            <p class="text text-red">* 禁言天數不輸入或大於3650天，為永久禁言。</p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-center">編號</th>
                  <th style="min-width: 100px;">ID</th>
                  <th style="min-width: 80px;">禁言天數</th>
                  <th style="min-width: 100px;">更新日期</th>
                  <th style="min-width: 100px;">結束日期</th>
                  <th style="min-width: 200px;">備註</th>
                  <th style="min-width: 80px;">操作人</th>
                  <th class="text-nowrap text-center" style="min-width: 150px;">功能</th>
                </tr>
                <tr v-if="muteTotal === 0">
                  <td class="text-center" colspan="8">沒有資料</td>
                </tr>
                <tr
                  :key="index"
                  v-else
                  v-for="(item, index) in muteList"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ item.uId }}</td>
                  <td class="text-center">
                    <span v-if="!item.editor">
                      <p v-if="item.bDays > 3650"></p>
                      <p v-else-if="item.bDays === 0">已解禁</p>
                      <p v-else>{{ item.bDays }} 天</p>
                    </span>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="max-width: 100px;"
                      type="number"
                      v-else
                      v-model="item.bDays"
                    />
                  </td>
                  <td class="text-center">{{ item.bCreateTime | dateFormat }}</td>
                  <td class="text-center">
                    <p class="text-red" v-if="item.bDays > 3650">永久禁言</p>
                    <p v-else>{{ item.bEndTimeStamp | dateFormat }}</p>
                  </td>
                  <td>
                    <p v-if="!item.editor">{{ item.bNote }}</p>
                    <input class="form-control" type="text" v-else v-model="item.bNote" />
                  </td>
                  <td>{{ item.bCreator }}</td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="!item.editor">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="!item.editor"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="muteTotal"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-show="muteTotal > 0"
          />
        </div>
      </div>
    </section>
    <forumMuteAdd ref="forumMuteAdd" v-on:restList="GetRestList" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import forumMuteAdd from '@/views/projects/qppSocial/ForumMuteAdd';

export default {
  components: {
    Pagination,
    DateRangePicker,
    forumMuteAdd,
  },
  data() {
    return {
      defaultDate: '',
      defaultReason: '',
      editorName: this.$store.state.loginUser.EmployeeName,
      reason: '',
      searchData: {
        uId: '',
        page: 1,
        pageSize: 10,
        startTime: new moment().subtract(3, 'M').format('YYYY/MM/DD'),
        endTime: new moment().format('YYYY/MM/DD'),
      },
    };
  },
  mounted() {
    this.queryAll();
  },
  computed: {
    ...mapState({
      muteList: (state) => state.qppSocialMute.muteList.banList,
      muteTotal: (state) => state.qppSocialMute.muteList.totalCount,
      response: (state) => state.qppSocialMute.muteResponse,
    }),
  },
  watch: {
    muteList() {
      this.$refs.pagination.setPage(this.searchData.page, this.muteTotal);
    },
    defaultDate(newValue, oldValue) {
      this.defaultDate = newValue;
    },
    defaultReason(newValue, oldValue) {
      this.defaultReason = newValue;
    },
    response(value) {
      if (value.status == '1') {
        this.$eventBus.$emit('showAlert', value.message);
        this.queryAll();
      }
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;

      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;

      this.queryAll();
    },
    clickSearch() {
      this.onPageChange(1, this.searchData.pageSize);
    },
    GetRestList() {
      this.queryAll();
    },
    queryAll() {
      this.$store.dispatch('qppSocialMute/query', this.searchData);

    },
    // 切換文字或輸入框
    change(item) {
      item.editor = item.editor == true ? false : true;
      this.defaultDate = item.bDays;
      this.defaultReason = item.bNote;
      this.$forceUpdate();
    },
    // 儲存
    save(item) {
      if (item.bDays == 0) {
        this.$eventBus.$emit('showAlert', '天數不可為0');
        return;
      }

      if (item.bDays != this.defaultDate) {
        if (item.bDays == '' || item.bDays > 3650) {
          this.$eventBus.$emit(
            'showConfirm',
            '天數為空或超過3650天，將設定為永久禁言，是否確認?',
            (isOK) => {
              if (isOK) {
                if (item.bDays == '') item.bDays = 0;
                this.editMute(item);
              }
            }
          );
        } else {
          item.bDays = item.bDays;
          this.editMute(item);
        }
      }

      if (item.bNote != this.defaultReason) {
        item.bDays = item.bDays;
        this.editMute(item);
      }

      if (item.bDays == this.defaultDate && item.bNote == this.defaultReason) {
        item.editor = item.editor == true ? false : true;
      }
    },

    editMute(item) {
      this.$store.dispatch('qppSocialMute/edit', 
        {
          bId: item.bId,
          bDays: parseInt(item.bDays),
          bNote: item.bNote,
        },
      );
      item.editor = item.editor == true ? false : true;
      this.queryAll();
    },

    // 新增
    addNew() {
      this.$refs.forumMuteAdd.showAddMuteBox();
    },

    cancel(item, index) {
      if (item.classTypeId == '') {
        this.muteList.datas.splice(index, 1);
      } else {
        this.queryAll();
      }
    },

    // 刪除
    deleteType(item) {
      let data = {
        bId: item.bId
      };
      this.$eventBus.$emit(
        'showConfirm',
        '刪除即解除禁言，確定要刪除嗎？',
        (isOK) => {
          if (isOK) {
            this.$store.dispatch('qppSocialMute/delete', data);
            this.queryAll();
          }
        }
      );
    },
  },
};
</script>
