<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ this.$route.name == 'HetiSpecialOfferEdit' ? '新增優惠' : '公告編輯' }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>{{ this.$route.name == 'HetiSpecialOfferEdit' ? '優惠專案' : '公告列表' }}</li>
        <li class="active">{{ this.$route.name == 'HetiSpecialOfferEdit' ? '新增優惠' : '公告編輯' }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group" v-if="edit.projectId !== 'omp_heti'">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(item.id)"
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="edit.projectId !== 'omp_heti'">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 公告分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId" @change="changeType">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      v-for="(item, index) in newsTypes.datas"
                    >{{item.className}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group" v-if="edit.projectId === 'omp_heti'">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 標題：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>

              <div class="form-group" v-if="edit.projectId !== 'omp_xinstars'">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red" 
                    v-if="edit.projectId !== 'omp_heti'">*</span> 是否置頂：
                </label>
                <div class="col-sm-10">
                  <div class="checkbox">
                    <label>
                      <iCheckCheckBox
                        v-bind:propChecked="edit.isTop"
                        v-on:update:checked="edit.isTop=$event['checked']"
                      />
                    </label>
                    <span v-if="edit.projectId !== 'omp_heti'">置頂</span>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="edit.projectId !== 'omp_heti'">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <!-- 工具欄容器 -->
                    <!-- <div class="toolbar-container"></div> -->
                    <!-- 編輯器容器 -->
                    <!-- <div class="CKEditor"></div> -->
                    <tinymce v-model="edit.detailTopInfo" :propLang="editLang" />
                  </div>
                </div>
              </div>

              <template v-if="isSpecialPage">
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    <span class="text-red">*</span> 起始日期：
                  </label>
                  <div class="col-sm-10">
                    <div class="radio radio-inline" style="padding-left: 0;">
                      <iCheckRedioButton
                        checkValue="1"
                        message="即日起"
                        name="radioisNowOn"
                        v-model="edit.isNowOn"
                      />
                    </div>
                    <div class="radio radio-inline _time">
                      <iCheckRedioButton
                        checkValue="0"
                        message=""
                        name="radioisNowOn"
                        v-model="edit.isNowOn"
                      />
                      <div class="radio-date">
                        <div class="input-group date">
                          <div class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </div>
                          <div class="pull-right">
                            <DatePicker :propValue.sync="local.startTime" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    <span class="text-red">*</span> 活動結束時間：
                  </label>
                  <div class="col-sm-10">
                    <div class="radio-date">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <div class="pull-right">
                          <DatePicker :propValue.sync="local.endTime" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div
                class="form-group"
                v-if="!(edit.projectId === 'omp_heti' || edit.projectId === 'omp_dem') || (edit.projectId === 'omp_heti' && isSpecialPage)"
              >
                <label class="col-sm-2 control-label">
                  <span class="text-red" v-if="isSpecialPage || edit.projectId == 'omp_xinstars'">*</span>
                  圖片：
                </label>
                <div class="col-sm-10">
                  <div class="pic">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="AnnouncementPic"
                    type="file"
                    @change="onFileChange($event)"
                    :multiple="
                      edit.projectId == 'omp_wanin' ||
                      edit.projectId == 'omp_heti' ?
                      false : true
                    "
                  />
                  <span v-if="edit.projectId == 'omp_heti' && isSpecialPage" class="text-red">
                    建議尺寸 1000*1000
                  </span>
                  <div class="help-block" v-if="!isSpecialPage">
                    <span class="text-red">{{picLimit}}</span>
                  </div>

                  <div class="row ann-imgChoose mt-10">
                    <div
                      class="col-sm-3 col-xs-6 chooseBox"
                      v-for="(item, index) in imgList"
                      :key="index"
                    >
                      <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div>
                      <img :src="item.imgFullFileName" />
                      <div class="radio">
                        <input
                          type="radio"
                          :id="'mainImg'+index"
                          name="mainImg"
                          v-bind:checked="item.isMain== 1"
                          v-on:change="SetPicIsMain(item)"
                        />主圖
                      </div>

                      <span v-if="edit.projectId == 'omp_xinstars'">
                        <input
                          class="form-control mt-7"
                          id="imgTitle"
                          type="text"
                          v-model="item.title"
                          placeholder="圖片title"
                        />
                        <input
                          class="form-control mt-7"
                          id="imgTitle"
                          type="text"
                          v-model="item.alt"
                          placeholder="圖片alt"
                        />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

              <div v-if="edit.projectId == 'omp_xinstars'">
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="introduction">
                    吉祥物：
                  </label>
                  <div class="col-sm-10">
                    <select class="form-control" v-model="edit.吉祥物" placeholder="請選擇吉祥物">
                      <option disabled="disabled" value>請選擇吉祥物</option>
                      <option
                        :key="item.id"
                        :value="item.id"
                        v-for="item in mascotList"
                      >{{item.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-2 control-label" for="introduction">
                    引言：
                  </label>
                  <div class="col-sm-10">
                    <textarea
                      class="form-control"
                      id="introduction"
                      v-model="edit.introduction"
                      placeholder="內容建議二行內"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-2 control-label" for="Keywords">
                    <span class="text-red">*</span>Keywords：
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control"
                      id="Keywords"
                      type="text"
                      v-model="edit.keyword"
                      placeholder="網頁的關鍵字"
                    />
                  </div>
                </div>
                
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="Description">
                    <span class="text-red">*</span>Description：
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control"
                      id="Description"
                      type="text"
                      v-model="edit.description"
                      placeholder="網頁的描述"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="edit.projectId == 'omp_kf3'">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 標籤：
                </label>
                <div class="col-sm-10">
                  <multiselect
                    placeholder="自行輸入標籤"
                    label="Tag"
                    track-by="Tag"
                    required
                    :options="tagList"
                    :hide-selected="true"
                    :internal-search="false"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="tag"
                    selectLabel=""
                    @tag="addTag"
                    max-elements="已達標籤上限"
                    >
                    <!-- @search-change="updateTags" -->
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.Tag }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div
                  class="col-sm-10"
                  :style="[edit.projectId === 'omp_heti' ? {'display': 'flex'} : '']"
                >
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="上架"
                        name="radioMode"
                        v-model="edit.isStop"
                      />
                    </div>
                  
                    <div class="input-group date" v-show="edit.projectId !== 'omp_heti'">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="local.publishTime"/>
                      </div>
                    </div>
                    <div class="input-group time" v-show="edit.projectId !== 'omp_heti'">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                    </div>

                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="edit.projectId == 'omp_xinstars'">
                  <label class="col-sm-2 control-label">
                    <span class="text-red">*</span> 活動結束時間：
                  </label>

                  <div class="col-sm-10">
                    <div class="radio-date">
                      <div class="radio">
                        <iCheckRedioButton
                          checkValue="1"
                          message="是"
                          name="radioisEnd"
                          v-model="edit.isEnd"
                        />
                      </div>
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <div class="pull-right">
                          <DatePicker :propValue.sync="local.endTime"/>
                        </div>
                      </div>
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timePickerEnd" type="text" />
                      </div>
                    </div>

                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="否"
                        name="radioisEnd"
                        v-model="edit.isEnd"
                      />
                    </div>
                    
                  </div>
              </div>

              <div class="form-group" v-if="edit.projectId == 'omp_wanin'">
                <label class="col-sm-2 control-label" for="inputTitle">
                  網銀官網Link：
                </label>
                <div>
                  <div class="col-sm-5">
                    <input
                      class="form-control"
                      id="inputNewsId1"
                      type="text"
                      v-model="splitNewsIdList.link1"
                      @change="ChangeNewsId($event)"
                    />
                  </div>
                  <div class="col-sm-5">
                    <input
                      class="form-control"
                      id="inputNewsId2"
                      type="text"
                      v-model="splitNewsIdList.link2"
                      @change="ChangeNewsId($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-warning btn-lg"
                  style="margin-right: 10px;"
                  type="button"
                  @click="Save(true)"
                  v-if="
                    edit.isStop == 1 &&
                    (edit.projectId == 'omp_mftd' ||
                    edit.projectId == 'omp_dk' ||
                    edit.projectId == 'omp_jx2' ||
                    edit.projectId == 'omp_xinstars' ||
                    edit.projectId == 'omp_heti' ||
                    edit.projectId == 'omp_dem' ||
                    edit.projectId == 'omp_kf3')
                  "
                >預覽</button>
                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import moment from "moment";
import multiselect from "vue-multiselect";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce,
    multiselect
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      timer: null,
      isEdit: false,
      editLang: "zh-tw",
      edit: {
        newsId: utils.getTimeId(), //公告Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        lang: this.editLang, // 語系
        newsDetailId: "", // 內容Id
        detailTopTitle: "", //主旨
        detailTopInfo: "", // 內容
        isStop: this.$store.state.projectId == 'omp_heti' ? 1 : 0, // 發佈
        isTop: 0, // 置頂
        isNowOn: 1, //0:參考startTime ; 1:即日起
        publishTime: "",
        相關公告編號: null,
        startTime: '',
        endTime: '',
        isEnd: 0,
      },
      local: {
        startTime: new moment().format("YYYY-MM-DD"),
        endTime: new moment().format("YYYY-MM-DD"),
        publishTime: new moment().format("YYYY-MM-DD"),
      },
      langTypes: {},
      timeStringOption: {timeStyle: "short"},
      timeStringEndOption: {timeStyle: "short"},
      timepickerOption: {},
      timepickerEndOption: {},

      detailNewsLang: [],
      imgList: [],
      isPreview: false,
      picLimit: "圖片建議尺寸 350 * 250。",
      url: config.dkOption.url,
      splitNewsIdList: {
        link1: "",
        link2: "",
      },
      isSpecialPage: this.$route.name == 'HetiSpecialOfferEdit' ? true : false,
      tag: [],
      tagList:[],
      catchOb: {
        newsID: "",
      },
      mascotList:[
        {name: '小7' , id: 1},
        {name: '星寶' , id: 2},
        {name: '栗子' , id: 3},
        {name: '桔子' , id: 4},
        {name: '菟編' , id: 5},
        {name: '撲滿豬' , id: 6 },
        {name: '橘栗醬' , id: 7},
      ],
    };
  },
  created() {
    this.checkSession();
    this.GetAllLang();
    if (this.edit.projectId == "omp_xinbo") {
      this.picLimit = "圖片建議尺寸 1000*350。";
    } else if(this.edit.projectId == "omp_wanin"){
      this.picLimit = "圖片建議尺寸 1600*610。";
    } else if(this.edit.projectId == "omp_foundation"){
      this.picLimit = "圖片建議尺寸 650 * 430。";
    } else if(this.edit.projectId == "omp_xinstars"){
      this.picLimit = "圖片建議尺寸 690 * 385。";
    } else if (this.edit.projectId == "omp_yoe") {
      this.picLimit = "圖片建議尺寸 350 * 250，檔案大小不超過 300KB。";
    }

  },
  mounted() {
    this.timepickerOption.showInputs = false;
    this.timepickerEndOption.showInputs = false;

    if (this.edit.projectId == "omp_dk") {
      this.timepickerOption.showSeconds = true;
      this.timeStringOption = {};
    }

    if(this.edit.projectId == "omp_kf3") this.GetTagList();

    $(".timepicker").timepicker(this.timepickerOption);

    if (this.edit.projectId == "omp_xinstars") {
      $(".timePickerEnd").timepicker(this.timepickerEndOption);
    }
    

    if (this.edit.projectId == "omp_xinbo") {
      this.$store.dispatch("news/newsQueryAll", {
        classTypeId: "",
        module: "news",
        isDelete: 0,
        isStop: 0,
      });
    }

    

    // console.log('this.edit.newsId:',this.edit.newsId)
  },
  computed: {
    ...mapState({
      newsTypes: (state) => state.classTypes.queryAll,
      news: (state) => state.news.query,
      newsList: (state) => state.news.queryAll,
      newsDetailQueryAll: (state) => state.news.detailQueryAll,
      response: (state) => state.news.response,
      projectData: (state) => state.module.queryAll,
      imgUrlList: (state) => state.news.detailImgList,
    }),
  },
  watch: {
    news() {
      this.edit = this.news;
      if (this.edit.publishTime != "") {
        let time = new Date(this.edit.publishTime).toLocaleDateString()
        this.local.publishTime = new moment(time).format("YYYY-MM-DD")
        // $(".datepicker").val(
        //   new Date(this.edit.publishTime).toLocaleDateString()
        // );
        $(".timepicker").val(
          new Date(this.edit.publishTime).toLocaleTimeString("en-US", this.timeStringOption)
        );
      }
      if (this.edit.startTime != "" && this.edit.startTime !== null) {
        let time = new Date(this.edit.startTime).toLocaleDateString()
        this.local.startTime = new moment(time).format("YYYY-MM-DD")
      }
      if (this.edit.endTime != "" && this.edit.endTime !== null) {
        let time = new Date(this.edit.endTime).toLocaleDateString()
        this.local.endTime = new moment(time).format("YYYY-MM-DD")

        $(".timePickerEnd").val(
          new Date(this.edit.endTime).toLocaleTimeString("en-US", this.timeStringEndOption));
      }
      this.GetAnnouncementLang();
      if (this.edit.projectId == "omp_wanin" && this.edit.相關公告編號) {
        this.GetSplitNewsIdList();
      }
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetNewsClassTypes();
      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetAnnouncement();
      } else {
        this.detailNewsLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.newsId + i + 1;
          this.detailNewsLang.push({
            newsDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo,
            introduction: this.edit.introduction,
            keyword: this.edit.keyword,
            description: this.edit.description,

          });
        }
      }
    },
    newsDetailQueryAll() {
      let that = this;
      this.detailNewsLang = [];
      if (this.newsDetailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          let findLang = false;
          const Id = this.edit.newsId + i + 1;
          this.newsDetailQueryAll.datas.some((newsDetail) => {
            if (that.langTypes[i].id == newsDetail.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.newsDetailId = newsDetail.newsDetailId;
                that.edit.detailTopTitle = newsDetail.title;
                that.edit.detailTopInfo = newsDetail.info;
                that.edit.introduction = newsDetail.introduction;
                that.edit.keyword = newsDetail.keyword;
                that.edit.description = newsDetail.description;

              }
              that.detailNewsLang.push({
                newsDetailId: newsDetail.newsDetailId,
                lang: newsDetail.lang,
                title: newsDetail.title,
                info: newsDetail.info,
              });
              findLang = true;
              return true;
            }
          });

          if (!findLang) {
            that.detailNewsLang.push({
              newsDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: "",
            });
          }
        }
        this.GetImgList();
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        // console.log(this.edit)

        if (this.edit.projectId === 'omp_xinstars') {
          this.catchOb.newsID = this.edit.newsId;
          this.$store.dispatch("xinGameIntro/unboxingCatch" , this.catchOb)
        }


        if (!this.isPreview)
          this.$router.push({
            name: this.isSpecialPage ? "HetiSpecialOffer" : "AnnouncementList",
            params: {
              searchData: this.$route.params.searchData,
            },
          });
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");

      if (this.isPreview) {
        switch (this.edit.projectId) {
          case "omp_mftd":
            window.open(`${process.env.VUE_APP_MFTDWEB_PATH}/web/new/${this.edit.newsId}?preview`);
            break;
          case "omp_dk":
            window.open(
              this.url + "News/Article/" +
                this.edit.newsId +
                "?preview=1"
            );
            break;
          case "omp_dem":
          window.open(`${process.env.VUE_APP_Dem_PATH}/news/${this.edit.newsId}?preview=1`);
          break;
          case "omp_jx2":
            window.open(
              "http://stage-js2.molome.tw/News/Article/" +
                this.edit.newsId +
                "?preview=1"
            );
            break;            
          case "omp_xinstars":
            // window.open(`https://dev2.xin-stars.com/GameIntro/GAME_Unboxing/${this.edit.newsId}&preview=1`);
            window.open(`${process.env.VUE_APP_XIN_PATH}/GameIntro/GAME_Unboxing/${this.edit.newsId}?preview=1`);
            break;
          case "omp_heti":
            var id = this.edit.newsId
            if(this.edit.isStop == 1) {
              id = `preview_${this.edit.newsId}`
            }
            if(this.$route.name == 'HetiSpecialOfferEdit') {
              window.open(`${process.env.VUE_APP_HETIWEB_PATH}/promo/${id}`);
              return
            } 
            window.open(`${process.env.VUE_APP_HETIWEB_PATH}/news/${id}`);
            break;
          case "omp_kf3":
            window.open(`${process.env.VUE_APP_KF3_PATH}/web/news/preview_${this.edit.newsId}`);
            break;
        }
      }
    },
    imgUrlList() {
      this.imgList = [];
      this.imgList = this.imgUrlList;
    },
  },
  methods: {
    //取得該公告資料
    GetAnnouncement() {
      this.$store.dispatch("news/newsQuery", {
        newsId: this.$route.params.id,
      });
    },

    //取得該公告多語系資料
    GetAnnouncementLang() {
      this.$store.dispatch("news/newsDetailQueryAll", {
        newsId: this.$route.params.id,
      });
    },

    //取得分類資料
    GetNewsClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        // module: this.isSpecialPage ? 'special_offer' : "news",
        module: this.edit.projectId == "omp_xinstars" ? "unboxing" : this.isSpecialPage ? 'special_offer' : "news" ,
        lang: this.editLang,
        isDelete: 0,
        isStop: 0,
      });
      
    },

    //取標籤分類(for kf3)
    GetTagList() {
      api.kf3.tag.queryAll({
        專案代號: this.edit.projectId,
        關聯ID: this.$route.params.id || "",
        // 標籤編號: -1,
        // 標籤名稱: "",
        // 每頁數量: 0,
        // 頁碼: 0
      }).then((res) => {
        res.data.result.datas.forEach(item => this.tagList.push({Tag: item.標籤名稱}));
        if(this.$route.params.id) res.data.result.datas.forEach(item => this.tag.push({Tag: item.標籤名稱}));
      })
    },

    GetAllLang() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetImgList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("news/newsDetailImgList", {
        newsDetailId: this.edit.newsDetailId,
      });
    },

    //取得該語系資料
    GetDetailNewsLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.newsDetailId = this.detailNewsLang[i].newsDetailId;
          this.edit.detailTopTitle = this.detailNewsLang[i].title;
          this.edit.detailTopInfo = this.detailNewsLang[i].info;
          this.edit.introduction = this.detailNewsLang[i].introduction;
          this.edit.keyword = this.detailNewsLang[i].keyword;
          this.edit.description = this.detailNewsLang[i].description;

        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      this.removeFile();
      this.SetLangData(this.editLang);
      this.GetDetailNewsLang(lang);
      this.editLang = lang;
      this.GetImgList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailNewsLang[i].title = this.edit.detailTopTitle;
          this.detailNewsLang[i].info = this.edit.detailTopInfo;
          this.detailNewsLang[i].introduction = this.edit.introduction;
          this.detailNewsLang[i].keyword = this.edit.keyword;
          this.detailNewsLang[i].description = this.edit.description;
        }
      }
    },

    //檢查Session
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
        this.newsCheckSession();
      }, 600000);
    },

    newsCheckSession() {
      this.catchOb.newsID = this.edit.newsId;
      // console.log(this.catchOb.newsID);

      this.$store
        this.$store.dispatch("news/newsCheck", this.catchOb)
        .then(() => {
            console.log("已延長session");
        });
    },

    onFileChange(evt) {
      this.SetLangData(this.editLang);
      this.GetDetailNewsLang(this.editLang);

      if (this.edit.projectId == "omp_wanin" 
        || this.edit.projectId == "omp_foundation" 
        || this.edit.projectId == "omp_xinstars"
        || this.edit.projectId == "omp_heti") {
        if (this.imgList.length > 0) {
          this.$eventBus.$emit(
            "showAlert",
            "公告圖片僅限上傳一張。"
          );
          return;
        }
      }

      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, "news", this.edit.newsDetailId, true);
    },
    removeFile() {
      document.getElementById("AnnouncementPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.editLang);
      if (this.edit.projectId == "omp_wanin" 
        || this.edit.projectId == "omp_foundation" 
        || this.edit.projectId == "omp_xinstars"
        || this.edit.projectId == "omp_heti") data.append("isMain", "1"); //預設為主圖
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then((res) => {
        if (res.status != "0") this.GetImgList();
      });
    },

    SetPicIsMain(item) {
      this.$store.dispatch("file/setIsMain", item).then((res) => {
        if (res.status != "0") {
          this.imgList = [];
          this.GetImgList();
        }
      });
    },

    DeletePic(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("file/fileDelete", item).then((res) => {
            if (res.status != "0") { 
              this.GetImgList();
              this.removeFile();
            }
          });
        }
      });
    },
    GetSplitNewsIdList() {
      var newsIdString = this.edit.相關公告編號; // ex:20210720135039339,20210720135117942
      var arr = newsIdString.split(",");
      this.splitNewsIdList.link1 = arr[0];
      this.splitNewsIdList.link2 = arr[1];
    },
    ChangeNewsId(event) {
      let newsId = event.target.value;
      if (newsId) {
        let query = {
          newsId: newsId,
          isDelete: 0,
          isStop: 0,
          skipCommit: true, // 略過commit
        };
        this.$store.dispatch("news/newsQuery", query).then((res) => {
          if (res.status != "1") {
            event.target.value = "";
            this.$eventBus.$emit("showAlert", "公告編號：" + newsId + "不存在");
            return;
          }
        });
      } else {
        event.target.value = "";
      }
    },
    changeType() {
      this.newsTypes.datas.forEach(item => {
        if(item.classTypeId == this.edit.classTypeId) {
          if(item.classId == "top") this.edit.isTop = 1
          else this.edit.isTop = 0
        }
      })
    },
    //*---- 返回
    backList() {
      this.$router.push({
        name: this.isSpecialPage ? "HetiSpecialOffer" : "AnnouncementList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },

    formatTime: function (t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },

    //送出
    async Save(isPreview) {
      this.isPreview = isPreview;
      this.SetLangData(this.editLang);
      this.edit.isStop = parseInt(this.edit.isStop);
      this.edit.isEnd = parseInt(this.edit.isEnd);
      
      this.edit.publishTime =
        this.local.publishTime + " " + $(".timepicker").val();
    
      if (this.edit.projectId == "omp_xinstars") { 
        this.edit.endTime =
          this.local.endTime + " " + $(".timePickerEnd").val();
      }
     
      if (this.edit.classTypeId == "" && this.edit.projectId !== 'omp_heti') {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "主旨不能為空");
        return;
      }

      if (this.edit.detailTopInfo == "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }

      if (this.edit.publishTime == "") {
        this.$eventBus.$emit("showAlert", "發佈時間不可為空");
        return;
      }

      // 賀緹優惠專案圖片為必填
      if(this.imgList.length < 1 && this.$route.name == 'HetiSpecialOfferEdit') {
        this.$eventBus.$emit("showAlert", "請上傳圖片");
        return;
      }

      this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
      this.edit.startTime = new Date(this.local.startTime + " UTC");
      this.edit.endTime = this.edit.projectId === "omp_xinstars" 
        ? new Date(this.edit.endTime + " UTC")  : new Date(this.local.endTime + " UTC");
     
      this.edit.isNowOn = parseInt(this.edit.isNowOn)
      

      if(this.edit.isStop == 1) {
        this.edit.isTop = 0
      }

      if (
        this.edit.projectId == "omp_xinbo" &&
        this.edit.isStop == 0 &&
        this.newsList.total >= 5 &&
        !this.isNewsInList()
      ) {
        this.$eventBus.$emit("showAlert", "最新消息已上架5則!!");
        return;
      }
      // 網銀官網Link
      if (this.edit.projectId == "omp_wanin") {
        let addLinkNum = 0;
        if (!this.splitNewsIdList.link1) {
          addLinkNum++;
        }
        if (!this.splitNewsIdList.link2) {
          addLinkNum++;
        }
        if (addLinkNum > 0) {
          let query = {
            classTypeId:this.edit.classTypeId,
            projectId: this.$store.state.projectId,
            isStop: 0,
            isDelete: 0,
            skipCommit: true, // 略過commit
          };
          let res = await this.$store.dispatch("news/newsQueryAll", query);
          if (res.status == "1" && res.result.total > 0) {
              let newsIdOrderList = this.getNewsIdOrderList(res.result.datas); // 取得排序後newsId陣列
              if(newsIdOrderList.length > 0) {
                let queryNewsList = newsIdOrderList;
                let index1,index2;
                index1 = 0;
                index2 = 1;
                this.GetReateNewsId(queryNewsList, index1, index2);
            }
          }
        }
        if(this.splitNewsIdList.link1 && this.splitNewsIdList.link2){
          this.edit.相關公告編號 = this.splitNewsIdList.link1 + "," + this.splitNewsIdList.link2;
        } else if(this.splitNewsIdList.link1){
          this.edit.相關公告編號 = this.splitNewsIdList.link1;
        } else if(this.splitNewsIdList.link2) {
          this.edit.相關公告編號 = this.splitNewsIdList.link2;
        }
      }

      if(this.edit.projectId === 'omp_heti') {
        this.edit.classTypeId = this.newsTypes.datas[0].classTypeId;
        let date = moment(new Date()).format('YYYY-MM-DD h:mm A')
        this.edit.publishTime = new Date(date + " UTC")
        if(!this.isEdit && this.isPreview) this.edit.preview = "1";
      }

      //中文版星城
      if (this.edit.projectId == "omp_xinstars") {
        

        if (this.edit.description == "") {
          this.$eventBus.$emit("showAlert", "Description 不能為空");
          return;
        }

        if (this.edit.keyword == "") {
          this.$eventBus.$emit("showAlert", "Keywords 不能為空");
          return;
        }

        if (this.imgList.length > 0){
          let that  = this;
          that.imgList.forEach(function(item){
            
            that.$store.dispatch("file/updateFile", {
              FilesId: item.filesId,
              Alt: item.alt,
              Title: item.title,
            });
          });
        } else {
          this.$eventBus.$emit("showAlert", "請上傳主圖");
          return;
        }

        switch (this.edit.classTypeId) {
          case "20220901155401318":
            this.edit.classId = "UnboxingGame"
            break;

          case "20220901155501870":
            this.edit.classId = "UnboxingActivity"
            break;
          
          case "20220901155718855":
            this.edit.classId = "UnboxingNews"
            break;

          
        } 
      }
      // 賀緹
      if (this.edit.projectId == "omp_heti") {
        if(this.edit.isStop == 1) this.edit.isHint = 0;
      }

      // console.log(this.edit);

      if (this.isEdit) {
        this.$store.dispatch("news/newsUpdate", {
          master_model: this.edit,
          detail_model: this.detailNewsLang,
          tags: this.tag.map(e => { return e.Tag }),
        });
      } else {
        this.$store.dispatch("news/newsCreate", {
          master_model: this.edit,
          detail_model: this.detailNewsLang,
          tags: this.tag.map(e => { return e.Tag }),
        });
        this.isEdit = true;
      }

      
    },
    isNewsInList() {
      if (this.isEdit) {
        let index = this.newsList.datas.findIndex(
          (news) => news.newsId == this.edit.newsId && news.isStop == 0
        );
        if (index != -1) return true;
      }
      return false;
    },
    getNewsIdOrderList(newsList) {
      let newsIdList = [];
      newsList.forEach((item, index) => {
         // 排除自己
        if(item.newsId != this.edit.newsId){
          newsIdList.push(item.newsId);
        }
      });
      newsIdList.sort(function(a, b){return b-a});
      return newsIdList;
    },
    GetReateNewsId(queryNewsList, index1, index2) {
     if (!this.splitNewsIdList.link1 && !this.splitNewsIdList.link2) {
          this.splitNewsIdList.link1 = queryNewsList[index1] ? queryNewsList[index1] : null;
          this.splitNewsIdList.link2 = queryNewsList[index2] ? queryNewsList[index2] : null;
        } else if (!this.splitNewsIdList.link1) {
          this.splitNewsIdList.link1 = queryNewsList[index1];
          if(this.splitNewsIdList.link1 == this.splitNewsIdList.link2) {
            this.splitNewsIdList.link1 = "";
          }
        } else if (!this.splitNewsIdList.link2) {
          this.splitNewsIdList.link2 = queryNewsList[index1];
          if(this.splitNewsIdList.link1 == this.splitNewsIdList.link2) {
            this.splitNewsIdList.link2 = "";
          }
        }
    },
    addTag(newTag) {
      const tag = { Tag: newTag.trim() };
      this.tagList.push(tag);
      this.tag.push(tag);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },

 

  xinCatch(){
    this.catchOb.newsID = this.edit.newsId;
    // console.log(this.catchOb);

    this.$store
          .dispatch("xinGameIntro/unboxingCatch" , this.catchOb)
          .then(() => {
              console.log("已清除Catch");
          });
  },

  newsCheckSession() {
      this.catchOb.newsID = this.edit.newsId;
      // console.log(this.catchOb.newsID);

      this.$store
        this.$store.dispatch("news/newsCheck", this.catchOb)
        .then(() => {
            console.log("已延長session");
        });
    },

};
</script>

<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>