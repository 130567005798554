@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">活動列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box">
        <div class="box-body">
          <form class="top-box">
            <router-link :to="{name:'XsgEventListEdit', params:{id:'New', searchData ,目前活動}}" class="btn btn-block btn-warning btn-add fa fa-plus">新增</router-link>
            <div class="select-group">
              <select class="form-control" v-model="目前活動" @change="clickSearch()" >
                <option value="">全部活動</option>
               <option
                    v-for="(name, key) in listdata"
                    :key="key"
                    :value="name.活動編號"
                  >
                    {{ name.活動名稱 }}
                  </option> 
              </select>
            </div>

            <div class="date-group" style="width: auto;">
              <label class="control-label">查詢期間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right" style="    width: 320px;">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="結束時間"
                    :propStartDate.sync="開始時間"
                    propsIn="format"
                  />
                </div>
              </div>
            </div>

              <div class="pull-left m-0">
                <button class="btn btn-primary pull-left mb-10" @click="clickSearch()">
                  {{$t('查詢')}}
                </button>
              </div>
            <!-- <div class="text"><p>資料總筆數：{{ total }}</p></div> -->
          </form>
          
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 50px; width: 2%">編號</th>
                  <th style="min-width: 80px; width: 5%">活動名稱</th>                  
                  <th style="min-width:120px; width:10%">縮圖</th>
                  <th style="min-width:100px; width:10%">活動開始時間</th>
                  <th style="min-width:100px; width:10%">活動結束時間</th>
                  <th style="min-width:100px; width:10%">抽獎券兌換截止時間</th>
                  <th style="min-width:100px; width:10%">狀態</th>
                  <th style="min-width:100px; width:10%">異動人員</th>
                  <th style="min-width:100px; width:10%">異動時間</th>
                  <th style="min-width:100px; width: 5%">功能</th>
                </tr>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="total === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.id">
                  <template v-if="item.啟停用">
                  <!-- <td>{{ item.活動編號 }}</td> -->
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.活動名稱 }}</td>
                  <td style="min-width:120px; width:10%">
                    <img :src="getImageUrl(item,'desk')" draggable="false" alt="" />
                    <!-- <div class="pic"><img :src="getImageUrl(item,'1')" draggable="false" alt="" /></div> -->
                  </td>
                  <td>{{ item.活動開始時間 }}</td>
                  <td>{{ item.活動結束時間 }}</td>
                  <td>{{ item.兌換截止時間 }}</td>
                  <td v-html="checktime( item.活動開始時間,item.活動結束時間)">
                    <!-- <fieldset class="ckbx-style-8 ckbx toggleInput" :disabled="!can修改" :style="!can修改?'opacity:.5;':false">
                      <input type="checkbox" :true-value="1" :false-value="0" :id="`check${index}`" v-model.number="item.啟停用" @change="changeStatus(item)" name="ckbx-style-8" />
                      <label :for="`check${index}`"></label>
                    </fieldset>
                    {{item.啟停用}} -->
                    <!-- <p>{{ item.啟停用 ? '開啟' : '關閉' }}</p> -->
                  </td>
                  <td>{{ item.修改者 == "" ? item.建立者 : item.修改者 }}</td>
                  <td>{{ item.修改者 == "" ? item.建立時間 : item.修改時間}}</td>
                                  
<!-- 
                  <td class="text-nowrap">
                    <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="!can修改">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.狀態" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add" @click="changeStatus(item)" :disabled="!item.狀態">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </fieldset>
                  </td> -->
                  <td>
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <router-link class="btn btn-primary" v-if="can修改" :to="{name:'XsgEventListEdit', params:{id:item.活動編號, searchData}}">
                        <i class="fa fa-fw fa-pencil"></i> 修改
                      </router-link>
                      <!-- <button class="btn btn-danger" v-if="can刪除" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button> -->
                    </div>
                  </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer text-center no-border">
            <Pagination ref="pagination" :total="total" :pageLimitCount="searchData.pageSize" v-on:pageMethod="onPageChange" />
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
  input[type="checkbox"]{
    position: relative;
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import DateRangePicker from '@/components/DateRangePicker';
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    iCheckRedioButton
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      bannerType: types.gameBannerType,
      // def: define,
      目前活動:'',
      projectId: this.$store.state.projectId,
      searchData: {
        // 標題: "",
        開始時間: '',
        結束時間: '',
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      開始時間: new moment().subtract(3, "month").format("YYYY/MM/DD 00:00:00"),
      結束時間: new moment().format("YYYY/MM/DD 23:59:59"),
      loading: false,
      message: "",
      datas: [],
      listdata:[],
      total: 0, //總筆數      
      can新增: this.$user.hasPermission(dfn.permission.活動管理新增),
      can修改: this.$user.hasPermission(dfn.permission.活動管理修改),
      can刪除: this.$user.hasPermission(dfn.permission.活動管理刪除),
    };
  },
  async created() {
    //this.getData();
    this.$store
        .dispatch("xsw/活動名稱列表查詢", {
          
        })
        .then(
          (res) => {
            this.message = "";
            this.listdata = res || [];
            //this.目前活動 = this.listdata[0].活動編號;
            this.$nextTick(() => {
              this.getData();
            });
          },
          (err) => {
            this.message = err;
            this.listdata = [];
          }
        );
  },
  mounted() {
    window.v = this;
  },
  computed: {
    getImageUrl(item) {
      return (item, info) => {
        const logo1File = item.files.find((file) => file.info === info);
        return logo1File ? logo1File.filesUrl : "";
      };
    },
  },
  watch: {
    // response() {
    //   if (this.response.status == "1")
    //     this.$eventBus.$emit("showAlert", "更新成功!");
    //   else this.$eventBus.$emit("showAlert", "更新失敗!");

    //   this.GetBannerQueryAll();
    // },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getData();
    },
    getData(){

      this.loading = true;
      this.$store.dispatch("xsw/活動管理列表查詢", this.searchData).then(res => {
       
        this.message = "";
        this.datas = res.datas || [];
         //console.log(this.datas)
        this.total = res.total || 0;
        this.loading = false;
      }, err => {
        this.message = err;
        this.datas =  [];
        this.total = 0;
        this.loading = false;
      });
    },
    checktime(startTime,endTime){
        const now = new Date();  // 
        const start = new Date(startTime);  // 
        const end = new Date(endTime);  // 

        if (now >= start && now <= end) {
          return "<span style='color:blue'>活動進行中</span>";  
        }
        else if (now > end) {
          return "<span style='color:#909090'>已結束</span>";  // 活動已結束
        }
        else {
          return "<span style='color:green'>尚未開放</span>";  // 活動尚未開始
        }
    },
    clickSearch() {
      this.searchData.開始時間=this.開始時間;
      this.searchData.結束時間= this.結束時間;
      this.searchData.pageNum = 1;
      this.getDataSearch();
    },
    getDataSearch(){
      if(this.目前活動 == ''){
        this.getData();
        return;
      }
      this.loading = true;      
      this.$store.dispatch("xsw/活動管理查詢", { "活動編號": this.目前活動 }).then(res => {
        //console.log(res)
        this.message = "";
        this.datas = [res] || [];
        this.loading = false;
      }, err => {
        this.message = err;
        this.datas =  [];
        this.total = 0;
        this.loading = false;
      });
    },
    changeStatus(item){
      this.$store.dispatch("xsw/活動管理修改", {
        活動編號: item.活動編號,
        活動名稱: item.活動名稱,
        活動開始時間: item.活動開始時間,
        活動結束時間: item.活動結束時間,
        兌換截止時間: item.兌換截止時間
      }).then(()=>{
        this.$eventBus.$emit("showAlert", "修改成功");
        this.getData();
      });
    },

    //*---- 刪除
    // deleteItem(item) {
    //   this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
    //     if (isOK) {
    //       this.$store.dispatch("xsw/活動管理刪除", item).then(()=>{
    //         this.$eventBus.$emit("showAlert", "刪除成功");
    //         this.getData();
    //       });
    //     }
    //   });
    // },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>